import React, { useState, useContext, useEffect } from "react";
import { Checkbox, FormControlLabel, FormGroup, Input, InputAdornment, Radio, RadioGroup, TextField, Box } from "@mui/material";
import {Search } from '@mui/icons-material';
import { ApiPost } from "../../../../../../services/AxiosInstance";
import Autocomplete from '@mui/material/Autocomplete';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fontSize } from "../../../../../../components/AppFieldsAttribute/fontSize";

const ReciepientsComponent = (props) => {
    const {
        handleRecipient,
        handleSearchReciept,
        corpContacts,
        selectedRecip,
        handleSelectRecipt,
        recipients,
        recipientErr,
        disableIndicator,
        companyList,
        searchRecipient,
        corpId,
        role,
        handleSelectedGroup
    } = props;

    const updatedArray = corpContacts.filter(item => {
        return !selectedRecip.some(itemToRemove => itemToRemove.id === item.id);
    });

    console.log("corpContacts", corpContacts)

    const [data, setData] = useState(companyList)

    useEffect(()=>{
        handleSelectDepartment()
    },[data])

    const handleSelectDepartment = async () => {

        const tempData= []

        for(const res of data){
            if(res?.isChecked){
                tempData.push (res)
            }
        }

        handleSelectedGroup(tempData)
    }


    const handleParentChange = (parentIndex) => {
        const newData = [...data];
        newData[parentIndex].isChecked = !newData[parentIndex].isChecked;

        newData[parentIndex].children.forEach((child, index) => {
            newData[parentIndex].children[index] = {
                ...child,
                isChecked: newData[parentIndex].isChecked,
            };

            // if (newData[parentIndex].isChecked) {
            //     handleSelectDepartment(child.subComp, child.deptId,true);
            // }else{
            //     handleSelectDepartment(child.subComp, child.deptId,false);
            // }

        });
        setData(newData);
    };


    const handleChildChange = (parentIndex, childIndex) => {
        const newData = [...data];
        newData[parentIndex].children[childIndex] = {
            ...newData[parentIndex].children[childIndex],
            isChecked: !newData[parentIndex].children[childIndex].isChecked,
        };
    
        // Check or uncheck the parent based on the state of its children
        const allChildrenChecked = newData[parentIndex].children.some((child) => child.isChecked);

        // newData[parentIndex].children.forEach((child, index) =>{
        //     newData[parentIndex].children[childIndex] = {
        //         ...child,
        //         isChecked: !newData[parentIndex].children[childIndex].isChecked,
        //     };

        //     // if (newData[parentIndex].isChecked) {
        //     //     handleSelectDepartment(child.subComp, child.deptId,true);
        //     // }else{
        //     //     handleSelectDepartment(child.subComp, child.deptId,false);
        //     // }
    
        // })
        
       
        newData[parentIndex].isChecked = allChildrenChecked;
        setData(newData);
    };
    
    const children = () => {
        console.log("Data", data)
        return (
            <>
                {data && data?.length > 0 ? (
                    <Box>
                    {data?.map((item, parentIndex) => (
                    <Accordion key={parentIndex}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${parentIndex + 1}-content`}
                        id={`panel${parentIndex + 1}-header`}
                        style={{ height: 10 }}
                        >
                        <FormControlLabel
                            label={item.label}
                            control={
                            <Checkbox
                                checked={item.isChecked ? true : false}
                                onChange={() => handleParentChange(parentIndex)}
                            />
                            }
                        />
                        </AccordionSummary>
                        <AccordionDetails>
                            {item.children.length > 0 ? 
                            (
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                    {item.children &&
                                    item?.children?.map((child, childIndex) => {
                                        return (
                                            <FormControlLabel
                                            key={childIndex}
                                            label={child.label}
                                            style={{ marginTop: -10, borderWidth: 1, borderColor: 'red' }}
                                            control={
                                                <Checkbox
                                                    checked={child.isChecked ? true : false}
                                                    onChange={() => handleChildChange(parentIndex, childIndex, child)}
                                                    // value={childIndex.deptId}
                                                />
                                            }
                                            />
                                        )
                                    })}
                                </Box>
                            )
                            : <div className="inputHolder">
                            <label className='' style={{fontSize: '13px'}}>No designated department.</label></div>}
                        
                        </AccordionDetails>
                    </Accordion>
                    ))}
                </Box>
                ) : <div className="inputHolder">
                <label className='' style={{fontSize: '13px'}}>No designated company/department.</label></div> }
                
            </>
        )
    };

    return (
        <>
            <label className='input-text'>Recipients</label>
            <RadioGroup
                value={recipients}
                onChange={(e) => handleRecipient(e.target.value)}
            >
            {role === "Owner" ?(
                <FormControlLabel style={{fontSize:"15px"}} className="flabel" value="Everyone" control={<Radio disabled={disableIndicator} />} label="Everyone" />
            )
            : null}
            <FormControlLabel style={{fontSize:"15px"}} className="flabel" value="Company/Department" control={<Radio disabled={disableIndicator} />} label="Designated Department" />
            <FormControlLabel style={{fontSize:"15px"}} className="flabel" value="Specific" control={<Radio disabled={disableIndicator} />} label="Specify recipients" />
                { recipients === "Specific" ? (
                <div className='recipientsHolder'>
                    <div className='reciptCard'>
                    <div className='reciptCardHead'>
                        <Input
                            className='searchInput'
                            type={'text'}
                            disabled={disableIndicator}
                            startAdornment={
                                <InputAdornment position="start">
                                <Search />
                                </InputAdornment>
                            }
                        onChange={(e) => handleSearchReciept(e.target.value)}
                        />
                    </div>
                    <div className='reciptCardBody'>
                        <FormGroup sx={{minHeight: 150}}>
                        {
                          updatedArray.length > 0 ? updatedArray.map((item, index) => {
                            return (
                                <FormControlLabel style={{fontSize:"15px"}} className="flabel" control={<Checkbox checked={item.status} />} label={item.name} onChange={() => handleSelectRecipt(index,item.id)} disabled={disableIndicator} />
                            )
                            }) : <p style={{fontSize:"12px"}}>no active corp users available</p>
                        }
                        </FormGroup>
                    </div>
                    </div>
                    <div className='reciptCard' style={{borderColor: recipientErr? "red":""}}>
                    <div className='reciptCardHead' style={{borderColor: recipientErr? "red":""}}>
                        <p style={{fontSize:"14px"}}>Selected ({selectedRecip.length})</p>
                    </div>
                    <div className='reciptCardBody'>
                        <FormGroup sx={{minHeight: 150}}>
                        {
                            selectedRecip.map((item, index) => {
                            return (
                                <FormControlLabel style={{fontSize:"15px"}} control={<Checkbox checked={item.status} />} label={item.name} onChange={() => handleSelectRecipt(index,item.id)} disabled={disableIndicator} />
                            )
                            })
                        }
                        </FormGroup>
                    </div>
                    </div>
                </div>
                ) : recipients === "Company/Department" ? (
                    <div style={{marginTop: 20}}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            Sub-Company
                            </AccordionSummary>
                            <AccordionDetails>
                                {children()}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ) : null}
            </RadioGroup>

        </>
    );

}

export default ReciepientsComponent;