const stepsCompany = [
    {
        target: '#step1company',
        title: 'Company Details Tab',
        content: 'In this tab, you can see the details of your company and the list of people who manage your CMS account.',
        disableBeacon: true
    },
    {
        target: '#step2company',
        title: 'Company Details',
        content: 'These are the details of your company that you used for registration.',
        disableBeacon: true
    },
    {
        target: '#step3company',
        title: 'Admin Lists',
        content: 'This is the list of people who manage your Lexiscard CMS account. You can determine whether a person is an owner or an admin by checking here.',
        disableBeacon: true
    },
    {
        target: '#step4company',
        title: 'Add Admin Button',
        content: 'This is the button where you can add an administrator of your Lexiscard CMS. Note, that only up to two admins can be added.',
        disableBeacon: true
    }
]

const stepsPlan = [
    {
        target: '#step1plan',
        title: 'Plan & Billing Tab',
        content: 'This tab contains information about your plan and billing.',
        disableBeacon: true
    },
    {
        target: '#step2plan',
        title: 'Purchase Details',
        content: 'These are the additional details from your company registration.',
        disableBeacon: true
    },
    {
        target: '#step3plan',
        title: 'List of Invoices',
        content: 'This is the list of invoices for purchases made by your company.',
        disableBeacon: true
    },
    {
        target: '#step4plan',
        title: 'View Invoice Button',
        content: 'This button allows you to view the detailed invoice and print it out.',
        disableBeacon: true
    }
]

const stepsAccount = [
    {
        target: '#step1account',
        title: 'Account Tab',
        content: 'This tab holds additional settings that your account can utilize.',
        disableBeacon: true
    },
    {
        target: '#step2account',
        title: 'Two Factor Authentication',
        content: "This is the toggle button for enabling and disabling two factor authentication for your account.",
        disableBeacon: true
    },
    {
        target: '#step3account',
        title: 'QR Code',
        content: 'Scan your generated QR Code using your Google Authenticator App. This will provide the secret key needed for your two factor authentication that will display in your Google Authenticator App.',
        disableBeacon: true
    },
    {
        target: '#step4account',
        title: 'Regenerate QR Code',
        content: 'This will generate another QR Code that will replace your old QR Code after confirmation.',
        disableBeacon: true
    }
]

const stepsSSO = [
    {
        target: '#step1SSO',
        title: 'SSO Tab',
        content: 'In this tab you can configure and setup your SSO Active Directory settings.',
        disableBeacon: true
    }
]

const stepsOthers = [
    {
        target: '#step1others',
        title: 'Others Tab',
        content: 'This tab holds additional settings that your company can utilize.',
        disableBeacon: true
    },
    {
        target: '#step2others',
        title: 'User Password Settings',
        content: "These are the password settings for users. You can adjust the allowed consecutive numbers for the last digit of your users' passwords.",
        disableBeacon: true
    },
    {
        target: '#step3others',
        title: 'Sub-company List',
        content: 'These are the sub-company settings used to filter templates and users. You can add a sub-company here and assign department and which admin can manage it.',
        disableBeacon: true
    },
    {
        target: '#step4others',
        title: 'Department List',
        content: 'These are the department settings used to filter templates and users. You can add a department here and assign which admin can manage it. This will also apply the same department as the other template.',
        disableBeacon: true
    }
]

export { stepsCompany, stepsPlan, stepsAccount, stepsSSO, stepsOthers }