import React, { useState, useEffect, useRef } from 'react';
import "./styles.scss";
import Draggable from 'react-draggable';
import { Resizable } from "react-resizable";
import { AppButton, AppLoader } from "../../components"
import { sampleData } from './sampleData';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconByName } from '../../utilities/fontAwesomeIcons';

const AppEditorFields = (props) => {

    const userData = useSelector((state) => state.user.userData);
    const front_data = sampleData[0].front; //userData.data[0].metadata[0].front
    const back_data = sampleData[1].back; //userData.data[0].metadata[1].back
    const cacheBuster = new Date().getTime();

    const { fields, editable, saveTemplate, isFront, isLoading, closeLoader, cancelEdit, orientation=false, onUploadTemplate, isMulti=false, updatedAlignment, onClickFieldFocus, onPreviewNameCard } = props
    const [newFields, setNewFields] = useState(fields)
    const [cssContent, setCssContent] = useState('');
    const [dragArray, setDragArray] = useState([]);

    useEffect(()=>{
        setNewFields(fields);
    },[fields])

    useEffect(()=>{
        fetchFontUrls();
        getInitialAlignment();
    })

    const getInitialAlignment = () => {
        updatedAlignment(newFields)
    }

    const DraggableField = ({ field, label, value, index, style, isIcon, iconLabel, isMultiAddress, iconType }) => {
      
        let axisX = 0;
        let axisY = 0;
        let font = "";
        let alignment = "default";
        let fieldOrientation = "horizontal";
        let color = "black";
        let size = "16px";
        let weight = "normal";
        let transform = "none";
        let width = '100%';
        let height = '0px';
        let labelSize = "16px"
        let labelColor = "black"
        let labelWidth = field.labelStyle?.width ? field.labelStyle?.width+ "px" : undefined

        axisX = style?.x;
        axisY = style?.y;
        font = style?.font;
      
        alignment = style?.alignmentX;
        fieldOrientation = style?.orientation;
        color = style?.color;
        size = style?.size + "px";
        weight = style?.weight;
        transform = style?.textTransform;
        width = style?.width + "%";
        height = style?.height + "px";

        labelSize = field.labelStyle?.size + "px"
        labelColor = field.labelStyle?.color

        if (fieldOrientation === "vertical") {
            fieldOrientation = 'vertical-lr'
        } else {
            fieldOrientation = 'horizontal-tb'
        }

        const [position, setPosition] = useState({
            activeDrags: 0,
            deltaPosition: {
              x: field.style.x, y: field.style.y
            },
            controlledPosition: {
              x: field.style.x, y: field.style.y
            }
        });

        const [initialPosition, setInitialPosition] = useState({
            activeDrags: 0,
            deltaPosition: {
              x: 0, y: 0
            },
            controlledPosition: {
              x: 0, y: 0
            }
        });

        const onStart = () => {
            setPosition({...position, activeDrags: position.activeDrags + 1});
        };
        
        const onStop = (e, ui) => {
            setPosition({...position, activeDrags: position.activeDrags - 1, controlledPosition: {x: ui.x, y: ui.y}});
        };
      
        const handleDrag = (e, ui) => {
            setPosition({
                deltaPosition: {
                    x: ui.x,
                    y: ui.y,
                }
            });
        };

        const handleDragStart = (e, ui) => {
            setInitialPosition({
                deltaPosition: {
                  x: ui.x,
                  y: ui.y,
                }
            });
        };

        const handleDragStop = (e, ui) => {
            if (position.deltaPosition.x !== initialPosition.deltaPosition.x || position.deltaPosition.y !== initialPosition.deltaPosition.y) {

                let axisX = ui.x
                let axisY = ui.y

                if(!orientation){
                    if(ui.x < 0){
                        axisX = 0;
                    }else if(ui.x > 600){
                        axisX = 0;
                    }
            
                    if(ui.y < 0){
                        axisY = 0;
                    }else if(axisY > 320){
                        axisY = 0;
                    }
                }
                
                if(dragArray.length > 0){

                    const updatedFields = new Set();

                    setNewFields(
                        newFields.map((moveField) => {
                            if (dragArray.includes(moveField.field) && !updatedFields.has(moveField.field)) {
                                updatedFields.add(moveField.field);
                                return {
                                    ...moveField,
                                    style: {
                                        x: axisX,
                                        y: moveField.field === field.field ? axisY : moveField.style.y,
                                        font: moveField.style.font,
                                        alignmentX: moveField.style.alignmentX,
                                        orientation: moveField.style.orientation,
                                        color: moveField.style.color,
                                        size: moveField.style.size,
                                        weight: moveField.style.weight,
                                        textTransform: moveField.style.textTransform,
                                        width: moveField.style.width,
                                        height: moveField.style.height,
                                    },
                                };
                            } else {
                                return { ...moveField };
                            }
                        })
                    );  
                }else{
                    setNewFields(
                        newFields.map(moveField => {
                            if (moveField.field === field.field) {
                                
                                return {
                                    ...moveField,
                                    style:{
                                        x: axisX,
                                        y: axisY,
                                        font: moveField.style.font,
                                        alignmentX: moveField.style.alignmentX,
                                        orientation: moveField.style.orientation,
                                        color: moveField.style.color,
                                        size: moveField.style.size,
                                        weight: moveField.style.weight,
                                        textTransform: moveField.style.textTransform,
                                        width: moveField.style.width,
                                        height: moveField.style.height
                                    }
                                }
                            }else{
                                return {
                                    ...moveField
                                }
                            }
                        })
                    ) 
                }

                setDragArray([])
                
                getInitialAlignment();
            }
        };

        const dragHandlers = {onStart: onStart, onStop: onStop};
      
        let right = orientation ? 300 : 600;
      
        let bottomLimit = orientation ? 575 : 320;

        const icon = getIconByName(iconLabel);

        const isFieldInArray = dragArray.includes(field.field);

        const fieldStyle = {
            cursor: "grab",
            position: "absolute",
            top: 0,
            left: 0,
            right: 390,
            fontSize: size,
            color: color,
            fontWeight: weight,
            textTransform: transform,
            width: width,
            bottom: 'auto',
            border: isFieldInArray ? '1px solid red' :  '1px solid #000',
            writingMode: fieldOrientation,
            textOrientation: 'upright',
            height: height,
            fontFamily: font,
            wordWrap: 'break-word',
            textAlign: alignment
        }

        const onClickField = (field, e) => {
            onClickFieldFocus(field.field)
            
            const updatedDragArray = [...dragArray];
            const isCtrlPressed = e.ctrlKey || e.metaKey;

            if (isCtrlPressed) {
                const updatedFields = new Set(updatedDragArray);
        
                setNewFields(
                    newFields.map((moveField) => {
                        if (updatedFields.has(moveField.field)) {
                            return {
                                ...moveField,
                                style: {
                                    ...moveField.style,
                                    x: field.style.x,
                                },
                            };
                        } else {
                            return { ...moveField };
                        }
                    })
                );
            } else {
                const indexToRemove = updatedDragArray.indexOf(field.field);

                if (indexToRemove !== -1) {
                    updatedDragArray.splice(indexToRemove, 1);
                } else {
                    updatedDragArray.push(field.field);
                }
            }

            setDragArray(updatedDragArray);
        }
        
        return (              
            <Draggable
                defaultPosition={{x: axisX, y: axisY}}
                bounds={{top: 0, left: 0, right: orientation ? right : right, bottom: bottomLimit}}
                {...dragHandlers}
                onDrag={handleDrag}
                onStart={handleDragStart}
                onStop={handleDragStop}
                // grid={[5, 5]}
            >
            {field.type === "logo" && value ? (
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: "grab",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 390,
                    bottom: 300,
                    width: width,
                    height: height,
                    bottom: 'auto',
                    border: isFieldInArray ? '1px solid red' :  '1px solid #000',
                    backgroundSize:'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${value+'?'+cacheBuster})`
                }}
                onClick={(e) => onClickField(field, e)}
                ></div>
            ) : (
                field.field === 'address' ? 
                    !isMultiAddress ? 
                        <div
                            style={fieldStyle}
                            onClick={(e) => onClickField(field, e)}
                            >
                            {isIcon === true ? (
                                iconType === 'select' || iconType === undefined ? <React.Fragment>
                                    {icon !== null ? <><FontAwesomeIcon icon={icon} style={{fontSize: labelSize, color: labelColor}} /><span style={{fontSize: labelSize, color: labelColor}}>: </span></> : null}
                                </React.Fragment> : iconType === 'url' || iconType === 'upload' ? iconLabel !== '' ? <>
                                    <img
                                        src={iconLabel + '?' + cacheBuster}
                                        alt="Icon Label"
                                        style={{
                                            display: 'inline-block',
                                            width: labelSize,
                                            height: labelSize,
                                            objectFit: 'contain'
                                        }}
                                    /><span style={{fontSize: labelSize, color: labelColor}}>: </span>
                                </> : null : null
                            ) : (
                                label ? <span style={{fontSize: labelSize, color: labelColor}}>{label}: </span> : null
                            )}
                            {value}
                        </div>
                    : <div></div>
                : <div
                    style={fieldStyle}
                    onClick={(e) => onClickField(field, e)}
                    >
                    {isIcon === true ? (
                        iconType === 'select' || iconType === undefined ? <React.Fragment>
                            {icon !== null ? <><FontAwesomeIcon icon={icon} style={{fontSize: labelSize, color: labelColor}} /><span style={{fontSize: labelSize, color: labelColor}}>: </span></> : null}
                        </React.Fragment> : iconType === 'url' || iconType === 'upload' ? iconLabel !== '' ? <>
                            <img
                                src={iconLabel + '?' + cacheBuster}
                                alt="Icon Label"
                                style={{
                                    display: 'inline-block',
                                    width: labelSize,
                                    height: labelSize,
                                    objectFit: 'contain'
                                }}
                            /><span style={{fontSize: labelSize, color: labelColor}}>: </span>
                        </> : null : null
                    ) : (
                        label ? <span style={{fontSize: labelSize, color: labelColor, width: labelWidth, display: 'inline-block'}}>{label} :</span> : null
                    )}
                    {value}
                </div>
            )}
            </Draggable>
        );
    };

    const NotDraggableFields = ({ field, label, value, index, style, isIcon, iconLabel, isMultiAddress, iconType }) => {

        let axisX = 0;
        let axisY = 0;
        let font = "";
        let alignment = "default";
        let fieldOrientation = "horizontal";
        let color = "black";
        let size = "16px";
        let weight = "normal";
        let transform = "none";
        let width = '100%';
        let height = '0px';
        let labelSize = "16px"
        let labelColor = "black"
        let labelWidth = field.labelStyle?.width ? field.labelStyle?.width+ "px" : undefined
      
        axisX = style?.x;
        axisY = style?.y;
        font = style?.font;
      
        alignment = style?.alignmentX;
        fieldOrientation = style?.orientation;
        color = style?.color;
        size = style?.size + "px";
        weight = style?.weight;
        transform = style?.textTransform;
        width = style?.width+ "%";
        height = style?.height + "px";

        labelSize = field.labelStyle?.size + "px"
        labelColor = field.labelStyle?.color

        const icon = getIconByName(iconLabel);

        if (fieldOrientation === "vertical") {
            fieldOrientation = 'vertical-lr'
        } else {
            fieldOrientation = 'horizontal-tb'
        }

        let right = orientation ? 320 : 445

        let bottomLimit = orientation ? 575 : 300;
        let widthInput = orientation ? 344 : '100%';

        const fieldStyle = {
            cursor: "grab",
            position: "absolute",
            top: 0,
            left: 0,
            right: 390,
            fontSize: size,
            color: color,
            fontWeight: weight,
            textTransform: transform,
            width: width,
            bottom: 'auto',
            writingMode: fieldOrientation,
            textOrientation: 'upright',
            height: height,
            fontFamily: font,
            wordWrap: 'break-word',
            textAlign: alignment
        }
        
        return (
            <Draggable 
                defaultPosition={{x: field.style.x, y: field.style.y}}
                bounds={{top: 0, left: 0, right: right, bottom: 320}}
                onStart={() => false}
            >
                {field.type === "logo" && value ? (
                    <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "grab",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 390,
                        bottom: 300,
                        width: width,
                        height: height,
                        bottom: 'auto',
                        backgroundSize:'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${value+'?'+cacheBuster})`
                    }}
                    ></div>
                ) : (
                    field.field === 'address' ? 
                        !isMultiAddress ? 
                            <div
                                style={fieldStyle}
                                >
                                {isIcon === true ? (
                                    iconType === 'select' || iconType === undefined ? <React.Fragment>
                                        {icon !== null ? <><FontAwesomeIcon icon={icon} style={{fontSize: labelSize, color: labelColor}} /><span style={{fontSize: labelSize, color: labelColor}}>: </span></> : null}
                                    </React.Fragment> : iconType === 'url' || iconType === 'upload' ? iconLabel !== '' ? <>
                                        <img
                                            src={iconLabel + '?' + cacheBuster}
                                            alt="Icon Label"
                                            style={{
                                                display: 'inline-block',
                                                width: labelSize,
                                                height: labelSize,
                                                objectFit: 'contain'
                                            }}
                                        /><span style={{fontSize: labelSize, color: labelColor}}>: </span>
                                    </> : null : null
                                ) : (
                                    label ? <span style={{fontSize: labelSize, color: labelColor}}>{label}:</span> : null
                                )}
                                {value}
                            </div>
                        : <div></div>
                    : <div
                        style={fieldStyle}
                        >
                        {isIcon === true ? (
                            iconType === 'select' || iconType === undefined ? <React.Fragment>
                                {icon !== null ? <><FontAwesomeIcon icon={icon} style={{fontSize: labelSize, color: labelColor}} /><span style={{fontSize: labelSize, color: labelColor}}>: </span></> : null}
                            </React.Fragment> : iconType === 'url' || iconType === 'upload' ? iconLabel !== '' ? <>
                                <img
                                    src={iconLabel + '?' + cacheBuster}
                                    alt="Icon Label"
                                    style={{
                                        display: 'inline-block',
                                        width: labelSize,
                                        height: labelSize,
                                        objectFit: 'contain'
                                    }}
                                /><span style={{fontSize: labelSize, color: labelColor}}>: </span>
                            </> : null : null
                        ) : (
                            label ? <span style={{fontSize: labelSize, color: labelColor, width: labelWidth, display: 'inline-block'}}>{label} :</span> : null
                        )}
                        {value}
                    </div>
                )}
            </Draggable>
        );
    };

    const fetchFontUrls = async () => {
        try {
            const fontText = 'https://lexicard-public.s3.ap-southeast-1.amazonaws.com/fonts/fonts.txt';
            const response = await fetch(fontText);
        
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        
            const data = await response.text();
            const fontUrls = data.split('\n').map((line) => line.trim()).filter(Boolean);
      
            const fontFiles = fontUrls.map((fontUrl) => {
                return 'https://lexicard-public.s3.ap-southeast-1.amazonaws.com/fonts/' + fontUrl;
            });
      
            let cssContent = '';
            fontFiles.forEach((fontFile) => {
                const fontFilename = fontFile.split('/').pop();
                const fontFamily = fontFilename.replace(/\.[^/.]+$/, '');
        
                cssContent += `
                @font-face {
                    font-family: '${fontFamily}';
                    src: url('${fontFile}') format('truetype'),
                        /* Safari, Android, iOS */
                        url('${fontFile.replace(/\.[^/.]+$/, '.woff')}') format('woff');
                        /* Modern Browsers */
                }
                `;
            });
        
            setCssContent(cssContent);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const alignFieldsLeft = (fields, dragArray) => {
        if (dragArray.length > 0) {
            const firstField = fields.find((field) => field.field === dragArray[0]);
    
            if (firstField) {
                const newX = firstField.style.x;
    
                const updatedFields = fields.map((field) => {
                    if (dragArray.includes(field.field)) {
                        return {
                            ...field,
                            style: {
                                ...field.style,
                                x: newX,
                            },
                        };
                    }
                    return field;
                });
    
                setNewFields(updatedFields);
                setDragArray([]);
            }
        } else {
            setDragArray([]);
        }
    };

    const alignFieldsRight = (fields, dragArray) => {
        if (dragArray.length > 0) {
            const parentField = fields.find((field) => field.field === dragArray[0]);
    
            if (parentField) {
                const parentWidth = parseInt(parentField.style.width, 10);
                const parentX = parentField.style.x;
    
                const updatedFields = fields.map((field) => {
                    if (dragArray.includes(field.field)) {
                        const cardWidth = orientation ? 344 : 630
                        const newX = cardWidth * integerToDecimal(parentWidth, 2)

                        if(field.field !== dragArray[0]){
                            return {
                                ...field,
                                style: {
                                    ...field.style,
                                    x: newX,
                                },
                            };
                        }
                    }
                    return field;
                });
    
                setNewFields(updatedFields);
                setDragArray([]);
            }
        } else {
            setDragArray([]);
        }
    };

    const integerToDecimal = (integerValue, decimalPlaces) => {
        var divisor = Math.pow(10, decimalPlaces);
        var decimalValue = integerValue / divisor;

        return decimalValue;
    }
  
    return (
        <>
            <style>
                {cssContent}
            </style>
            <div style={{position: "relative"}}>
            {
                editable ? 
                    newFields.map((field, index) => {
                        return(
                            <DraggableField
                                field={field}
                                label={field?.label}
                                value={field?.template_val ? field.template_val : ''}
                                key={index}
                                index={index}
                                style={field?.style}
                                isIcon={field?.isIcon}
                                iconLabel={field?.iconLabel}
                                isMultiAddress={isMulti}
                                iconType={field?.iconType}
                            />

                        )
                    })
                :
                    newFields.map((field, index) => {
                        return (
                            <NotDraggableFields
                                field={field}
                                label={field?.label}
                                value={field?.template_val ? field.template_val : ''}
                                key={index}
                                index={index}
                                style={field?.style}
                                isIcon={field?.isIcon}
                                iconLabel={field?.iconLabel}
                                isMultiAddress={isMulti}
                                iconType={field?.iconType}
                            />
                        )
                    })
            }
            </div>
            {
                editable ?
                    <>
                        <div className="action-button" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: orientation ? 680 : 410, left: orientation ? 150 : 20}}>
                            <div id="step5Edit" >
                                <AppButton buttonText="Save Template" onClick={()=> saveTemplate(newFields) } style={{}}/>
                            </div>
                        </div>

                        <div className="action-button" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: orientation ? 680 : 410, right: orientation ? 20 : 130}}>
                            <AppButton
                                variant='text'
                                buttonText="Cancel"
                                onClick={()=> cancelEdit(false) }
                                className='cancel-btn'
                                style={{color: '#FF0000', background: null, marginLeft: 10}}
                            />
                        </div>

                        {
                            dragArray.length > 1 ?
                            <>
                                <div className="action-button" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: orientation ? 640 : 345, left: orientation ? -378 : -536}}>
                                    <div id="step1Edit" >
                                        <AppButton buttonText="Align Left" onClick={()=> alignFieldsLeft(newFields, dragArray) } style={{zIndex: 999, borderWidth: 1, borderColor: '#01082E', background: '#FFFFFF'}} textStyle={{color: '#01082E'}} variant={'outlined'}/>
                                    </div>
                                </div>
                                <div className="action-button" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: orientation ? 640 : 345, left: orientation ? -245 : -400}}>
                                    <div id="step1Edit" >
                                        <AppButton buttonText="Align Right" onClick={()=> alignFieldsRight(newFields, dragArray) } style={{borderWidth: 1, borderColor: '#01082E', background: '#FFFFFF'}} textStyle={{color: '#01082E'}} variant={'outlined'}/>
                                    </div>
                                </div>
                            </> : null
                        }
                       
                        <div className="action-button" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: orientation ? 685 : 390, left: orientation ? -330 : -488}}>
                            <div id="step1Edit" >
                                <AppButton buttonText="Upload Template" onClick={()=> onUploadTemplate() } style={{background: '#01082E'}}/>
                            </div>
                        </div>
                        <div className="action-button" style={{width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: orientation ? 730 : 435, left: orientation ? -263 : -420}}>
                            <div id="step1Edit" >
                                <AppButton buttonText="Preview Actual Namecard" onClick={()=> onPreviewNameCard() } style={{background: '#01082E'}}/>
                            </div>
                        </div>
                    </>
                :
                    ""
            }

            <AppLoader loader={isLoading} closeLoader={closeLoader} /> 
        </>
    );
}

export default AppEditorFields;