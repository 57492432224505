import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AppButton, AppInput,AppLoader } from "../../../components";
import logo from "../../../assets/image/logo.png";
import { Link } from "react-router-dom";
import { EmailValidator } from "../../../utilities";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation } from "react-router-dom";
import { ApiPost } from "../../../services/AxiosInstance";
import { useNavigate } from 'react-router-dom';

export default function NewPasswordReset() {
  const [newPassword, setNewPassword] = useState(false);
  const [decryptedEmail, serDecryptedEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOldPass, setShowOldpass] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isLoading,setIsloading] = useState(false)
  const [success,setSuccess] = useState(false)
  const location = useLocation();
    
 const navigate = useNavigate()
 const navigateTouserList = () =>{
    navigate('/')
  }


    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        const e = searchParams.get("e");
        
        const decodedData = atob(e);
        const decrypteedData = JSON.parse(decodedData);
        serDecryptedEmail(decrypteedData.email);      
    },[])

  const [passwordInputConfig, setPasswordInputConfig] = useState({
    password: "",
    confirm_password: "",
  });

  const [errorConfig, setErrorConfig] = useState({
    status: false,
    msg: "",
  });

  
    const onChangePassword = (e, type) => {
    setPasswordInputConfig((prev) => {
      return {
        ...prev,
        [type]: e.target.value,
      };
    });
  };

  const errorHandling = (status, message) => {
    setErrorConfig({ status, message });
  };
  const submitNewPassword = async() => {
    if (
      !passwordInputConfig.password ||
      !passwordInputConfig.confirm_password
    ) {
      errorHandling(true, "Please enter your new password");
    } else {
      if (
        passwordInputConfig.password !== passwordInputConfig.confirm_password
      ) {
        errorHandling(true, "Password don't match");
      } else {
        errorHandling(false, "");
        setIsloading(true)
        const data = await ApiPost("/corps/reset-password",{email:decryptedEmail,password:passwordInputConfig.confirm_password})
        setSuccess(true)
        setIsloading(false);

        //before navigating prompt a success change
        setTimeout(()=>{
        setSuccess(false);
        navigateTouserList();
        },3000)



      }
    }
  };

  return (
    <>
        <div className="landing-container">
          <div className="landing-card">
            <div className="landing-card__logo">
              <img className="" src={logo} />
            </div>
            <h3 className="landing-card__title">Reset Password</h3>
            <div className="landing-card__container">
              <label style={{color: errorConfig.status && 'red'}}>Enter your new password</label>

              <FormControl variant="outlined" style={{ width: "100%" }}>
                <OutlinedInput
                  size="small"
                  onChange={(e) => onChangePassword(e, "password")}
                  error={errorConfig.status}
                  id="outlined-adornment-password"
                  type={showOldPass ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowOldpass(!showOldPass)}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {showOldPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="landing-card__container">
              <label style={{color: errorConfig.status && 'red'}}>Confirm your new password</label>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <OutlinedInput
                  size="small"
                  onChange={(e) => onChangePassword(e, "confirm_password")}
                  error={errorConfig.status}
                  id="outlined-adornment-password"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {success && <p style={{fontSize:"12px",padding:"10px",backgroundColor:"#32a852",marginTop:"10px",color:'white',borderRadius:"5px"}}>Your password is set succesfully.</p> }
              {errorConfig.status && (
                <p className="error-message">{errorConfig.message} </p>
              )}
              {/*<AppInput />*/}
            </div>
            <AppButton buttonText="Submit" onClick={submitNewPassword} />
          </div>
        </div>
                
      <AppLoader loader={isLoading} closeLoader={()=>setIsloading(false)} /> 
    </>

  );
}
