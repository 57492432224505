import React, { useState } from "react";
import "./styles.scss";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AppButton, AppInput } from "../../../components";
import logo from "../../../assets/image/logo.png";
import { Link } from "react-router-dom";
import { EmailValidator } from "../../../utilities";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApiPost } from "../../../services/AxiosInstance";
import {AppLoader} from "../../../components";

export default function ResetPasswordPage(navigate) {
  const [newPassword, setNewPassword] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [success,setSuccess] = useState(false)
  const [isLoading,setIsloading] = useState(false)
  const [passwordInputConfig, setPasswordInputConfig] = useState({
    password: "",
    confirm_password: "",
  });

  const [errorConfig, setErrorConfig] = useState({
    status: false,
    msg: "",
  });

  const send = async() => {
    const emailValidation = EmailValidator(emailInput);

    if (emailInput && emailValidation) {
      setIsloading(true)
      errorHandling(false, "");
      // setNewPassword(true);
      //if the api call is success set the password to true
      const response = await ApiPost("/corps/forgot-password",{email:emailInput})
      //tapos mabutang kalang notification if email is succesffuly sentt
     if(!response.data.data){
      setSuccess(false)
      setIsloading(false)
      errorHandling(true, response.data.message);
     }else{
      setSuccess(true)
      setIsloading(false)
      setTimeout(()=>{
         setSuccess(false)
      },5000)

     }
      
    } else {
      errorHandling(true, "Please enter a valid email");
    }
  };


  const inputEmail = (e) => {
    setEmailInput(e.target.value);
    setError(false);
  };

  const onChangePassword = (e, type) => {
    setPasswordInputConfig((prev) => {
      return {
        ...prev,
        [type]: e.target.value,
      };
    });
  };

  const errorHandling = (status, message) => {
    setErrorConfig({ status, message });
  };
  const submitNewPassword = () => {
    if (
      !passwordInputConfig.password ||
      !passwordInputConfig.confirm_password
    ) {
      errorHandling(true, "Please enter your password");
    } else {
      if (
        passwordInputConfig.password !== passwordInputConfig.confirm_password
      ) {
        errorHandling(true, "Password don't  match");
      } else {
        errorHandling(false, "");
        console.log("correct")
      }
    }
  };

  return (
    <>
      {!newPassword ? (
        <div className="landing-container">
          <p className="landing-container__backbtn" href="#">
            {" "}
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              {"<"} Back
            </Link>
          </p>
          <div className="landing-card">
            <div className="landing-card__logo">
              <img className="" src={logo} />
            </div>
            <h3 className="landing-card__title">Reset Password</h3>
            <p className="landing-card__description landing-card__description--left">
              Reset Password Enter your email and we will send you instructions
              on how to reset your password.
            </p>

            <div className="landing-card__container">
              <label>Email</label>
              <TextField
                size="small"
                id="time"
                type="text"
                style={{ width: "100%" }}
                onChange={(e) => inputEmail(e)}
                // error={errorConfig.status}
              />
              {success && <p style={{marginTop:"10px",color:'#000'}}>If email exists you'll receive reset password link.</p> }
              {errorConfig.status && (
                <p style={{marginTop:"10px",color:'#000'}}>If email exists you'll receive reset password link.</p>
              )}

              {/*<AppInput />*/}
            </div>
            <AppButton buttonText="Send" onClick={send} />
          </div>
        </div>
      ) : (
        <div className="landing-container">
          <div className="landing-card">
            <div className="landing-card__logo">
              <img className="" src={logo} />
            </div>
            <h3 className="landing-card__title">Reset Password</h3>
            <div className="landing-card__container">
              <label>Enter your new password</label>

              <FormControl variant="outlined" style={{ width: "100%" }}>
                <OutlinedInput
                  size="small"
                  onChange={(e) => onChangePassword(e, "password")}
                  error={errorConfig.status}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="landing-card__container">
              <label>Confirm your new password</label>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <OutlinedInput
                  size="small"
                  onChange={(e) => onChangePassword(e, "confirm_password")}
                  error={errorConfig.status}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {errorConfig.status && (
                <p className="error-message">{errorConfig.message} </p>
              )}
              {/*<AppInput />*/}
            </div>
            <AppButton buttonText="Submit" onClick={submitNewPassword} />
          </div>
        </div>
      )}{" "}
      
      <AppLoader loader={isLoading} closeLoader={()=>setIsloading(false)} /> 
    </>
  );
}
