import { Home, Groups, Settings, DesignServices, CircleNotifications } from '@mui/icons-material';
import { getEnvVars } from '../../services/Environment';
import { useSelector } from 'react-redux';

const useMenuData = () => {
  const userData = useSelector((state) => state.user.userData);

  return getEnvVars().env === 'production'
    ? [
        {
          name: 'Dashboard',
          icon: <Home />,
          route: '/dashboard',
        },
        {
          name: 'User List',
          icon: <Groups />,
          route: '/userlist',
        },
        (userData && userData[0]?.role === 'Owner' ||
          (userData && userData[0]?.role === 'Admin' &&
            (!userData[0]?.metadata?.permission ||
              (
                userData[0]?.metadata?.permission?.editTemplate &&
                userData[0]?.metadata?.permission?.viewTemplate
              ) ||
              (
                userData[0]?.metadata?.permission?.viewTemplate
              ) ||
              (
                userData[0]?.metadata?.permission?.editTemplate
              )
            )
          )
        ) && {
          name: 'Name Card Design',
          icon: <DesignServices />,
          route: '/namecarddesign',
        },
        {
          name: 'Push Notifications',
          icon: <CircleNotifications />,
          route: '/notifications',
        },
        {
          name: 'Settings',
          icon: <Settings />,
          route: '/settings',
        },
      ]
    : [
        {
          name: 'Dashboard',
          icon: <Home />,
          route: '/dashboard',
        },
        {
          name: 'User List',
          icon: <Groups />,
          route: '/userlist',
        },
        (userData && userData[0]?.role === 'Owner' ||
          (userData && userData[0]?.role === 'Admin' &&
            (!userData[0]?.metadata?.permission ||
              (
                userData[0]?.metadata?.permission?.editTemplate &&
                userData[0]?.metadata?.permission?.viewTemplate
              ) ||
              (
                userData[0]?.metadata?.permission?.viewTemplate
              ) ||
              (
                userData[0]?.metadata?.permission?.editTemplate
              )
            )
          )
        ) && {
          name: 'Name Card Design',
          icon: <DesignServices />,
          route: '/namecarddesign',
        },
        {
          name: 'Push Notifications',
          icon: <CircleNotifications />,
          route: '/notifications',
        },
        {
          name: 'Settings',
          icon: <Settings />,
          route: '/settings',
        },
      ];
};

export default useMenuData;
