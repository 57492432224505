import ExcelJS from 'exceljs';

export const exportToExcelContacts = async (data) => {
  console.log(data)
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Contacts');

  // Add headers to the worksheet
  worksheet.addRow(['Name', 'Email', 'Mobile Number', 'Office Number', 'Date Added', 'Namecard']);

  // Iterate through the data and add rows to the worksheet
  data.forEach((contact) => {
    worksheet.addRow([
      contact.name,
      contact.email,
      contact.mobile_number,
      contact.office_number,
      contact.date_added,
    ]);
  });

  // Add images to the worksheet
  let row = 2; // Start from row 2 to avoid the header row
  const imagePromises = data.map(async (contact) => {
    if (contact.namecard?.startsWith('http')) {
      try {
        const response = await fetch(contact.namecard);
        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }

        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = (e) => {
          const imgData = e.target.result;

          const image = workbook.addImage({
            base64: imgData,
            extension: 'png',
          });

          worksheet.addImage(image, {
            tl: { col: 6, row: row },
            ext: { width: 200, height: 100 },
          });
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error fetching or processing image:', error);
      }
    }
    row++;
  });

  await Promise.all(imagePromises);

  // Generate the Excel file
  const buffer = await workbook.xlsx.writeBuffer();

  // Create a download link for the Excel file
  const dataBlob = new Blob([buffer], { type: 'application/octet-stream' });
  const downloadUrl = window.URL.createObjectURL(dataBlob);

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = 'contacts.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
