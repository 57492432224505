import * as React from 'react';
import "./styles.scss";
import CloseIcon from '@mui/icons-material/Close';
import AppButton from '../AppButton';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import AppDropdown from '../AppDropdown';
import { useSelector, useDispatch } from 'react-redux';

const AppModal = ({
  showModal,
  toggleModal,
  draftClick,
  header,
  content,
  onClick,
  cancelText,
  draftText,
  functionText, 
  footer,
  buttonStyle={},
  cancelButton=true,
  daftButton=false,
  modalStyle,
  disableIndicator,
  style,
  contentStyle,
  headerStyle,
  closeIconStyle,
  onClear,
  dataEntry=false
}) => {

  const Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    maxHeight: '98%',
    overflow: 'auto'
  };
  
  return (
    <Modal
        open={showModal}
        onClose={() => { toggleModal();
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="mdal" sx={{ width: '27%', ...Style, ...style }}>
          <div className="modal-header">
            <Typography style={{fontWeight: 'bold', fontSize: 15, ...headerStyle}}>{header}</Typography>
            <IconButton
              aria-label="close"
              onClick={()=>toggleModal()}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              style={{
                ...closeIconStyle
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className='modal-content' style={contentStyle}>
            {content}
          </div>
          <div className='modal-footer' style={dataEntry ? {width: '82%', marginLeft: '8%'} : null}>
            {
              dataEntry ? 
                <AppButton
                  buttonText="Upload Photo"
                  onClick={() => onClear()}
                  style={{ marginRight: '68%', marginTop: 15}}
                  className="modal-button"
                />
                : null
            }
            {
              cancelButton ? 
              <AppButton 
                variant='text'
                onClick={()=>{toggleModal()}} 
                buttonText={cancelText ? cancelText : "Cancel"} 
                className='cancel-btn'
                style={{color: '#666666', background: null, marginRight: 20}}
              />
              :
              ''
            }
            {
              daftButton ? 
                <AppButton 
                  variant='text'
                  onClick={()=>draftClick()} 
                  buttonText={draftText ? draftText : "Save as Draft"} 
                  className='draft-btn'
                  style={{color: '#007aff', background: null, marginRight: 20}}
                />
              :
              ''
            }
            {!disableIndicator && 
            <AppButton 
                onClick={()=>{ onClick ? onClick() : console.log('close') }} 
                buttonText={functionText ? functionText : " Submit"} 
                className="modal-button" 
                style={buttonStyle}
              />
            }
          </div>
        </Box>
    </Modal>
  );
}

export default AppModal;