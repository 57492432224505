import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import errorIcon from '../../assets/image/error-icon.PNG'
import successIcon from '../../assets/image/success-icon.png'
import AppButton from '../AppButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppImportUserDialog = ({
    visible,
    onPositivePress,
    onNegativePress,
    onClose,
    title,
    subTitle,
    message,
    positiveButtonName
}) => {

  return (
    <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
    >
    <DialogContent style={{width: '350px'}}>
        <DialogContentText id="alert-dialog-slide-description">
            {/* <div dangerouslySetInnerHTML={{__html: message}}> */}
            <div style={{display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                {
                    title === 'Success Import' ? 
                    <img src={successIcon} alt="Error Logo" style={{ width: '130px', marginBottom: '10px' }} /> :
                    <img src={errorIcon} alt="Error Logo" style={{ width: '130px', marginBottom: '10px' }} />
                }
                <h2 style={{color: '#000'}}>{title}</h2>
                <h4 style={{color: '#000', fontWeight: 500}}>{subTitle}</h4>
            </div>
            
            <div dangerouslySetInnerHTML={{__html: message}} style={{marginTop: 15, border: '1px solid #CFCFCF', borderRadius: 10, padding: 25, maxHeight: 300, overflowY: 'auto' }}>
            </div>
            {/* </div> */}
        </DialogContentText>
    </DialogContent>
    <DialogActions style={{display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20}}>
        {/* { onNegativePress && <Button onClick={onNegativePress}>No</Button> } */}
        {/* <Button onClick={onPositivePress}>{positiveButtonName ? positiveButtonName : 'Yes'}</Button> */}
        { onNegativePress && <AppButton
            variant='text'
            buttonText="No"
            onClick={onNegativePress}
            className='cancel-btn'
            style={{color: '#FF0000', background: null, width: '100%', marginTop: 0, marginBottom: 0}}
        /> }
        <AppButton buttonText={positiveButtonName ? positiveButtonName : 'Yes'} onClick={onPositivePress} style={{width: '100%'}}/>
    </DialogActions>
    </Dialog>
  );
}

export default AppImportUserDialog;