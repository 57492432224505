import React, { useEffect, useRef } from 'react'
import "./styles.scss";
import QRCodeStyling from 'qr-code-styling';

const AppQRGenerator = ({ data, qrJsonMeta }) => {

    const qrCodeRef = useRef(null);

    useEffect(() => {
        // Create a new object with updated data
        const updatedQrJsonMeta = { ...qrJsonMeta, data };

        // Initialize QRCodeStyling with the updated meta
        const qrCode = new QRCodeStyling(updatedQrJsonMeta);

        qrCodeRef.current.innerHTML = '';

        // Attach the QR code to the DOM
        qrCode.append(qrCodeRef.current);

    }, [data, qrJsonMeta]);

    return <div ref={qrCodeRef}></div>;
}

export default AppQRGenerator;
