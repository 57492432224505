import React, { useState, useEffect } from 'react';
// import "./styles.scss";
import Draggable from 'react-draggable';
import { AppButton, AppModal } from "../../components"
import { useSelector } from 'react-redux';


const AppAddFieldsButton = (props) => {

    const { availFields, currentFields, addNewField, addNewLogo } = props

    return (
        <>
            {
                availFields.map((field, index) => {

                    const data = currentFields.find(item => item.field === field.field);
                    return (
                        <>
                            <AppButton 
                                key={index}
                                buttonText={field.title}
                                variant={data && field.field === data.field ? 'contained' : 'outlined'}
                                style={{background: data && field.field === data.field ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12}}
                                textStyle={{fontSize: 12, color: data && field.field === data.field ? 'white' : '#01082E'}}
                                onClick={() => field.field === 'logo' ? addNewLogo(field.field) : addNewField(field.field)}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

export default AppAddFieldsButton;