import React, {useState, useEffect} from "react";
import './styles.scss';
import {AppContactUs, AppModal} from '../../../../../components';

import { TextField } from '@mui/material';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ApiPost } from "../../../../../services/AxiosInstance";


const ContactUsModal = (props) => {
  const {onClose, display, onSubmit } = props
  const [file,setFile] = useState('');
  const [message,setMessage] = useState("");
  const [subject,setSubject] = useState("");

  const contactUs = async() =>{
    const formData = new FormData();
    formData.append('email', 'contact@lexiscard.asia');
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('file', file[0]);
    const response = await ApiPost('/corps/contact-us',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    onClose()    
  
  }

  return (
    <AppContactUs 
        file={file}
        showModal={display}
        toggleModal={() => onClose()}
        setFile={(val) => setFile(val)}
        setMessage={(value) => setMessage(value)}
        setSubject={(val) => setSubject(val)}
        contactUs={() => contactUs()}
      />
    // <AppModal 
    //   showModal={display} 
    //   header="Contact Us" 
    //   toggleModal={() => onClose()}
    //   onClick={() => handleSubmit()}
    //   content={

    //     <div id="notifPageContactUsModal" className='content-container'> 
    //       <div className="inputHolder">
    //         <label className='input-text'>Subject</label>
    //         <TextField
    //           className='input'
    //           type="text"
    //           size="small"
    //           fullWidth
    //         />
    //       </div>            
    //       <div className="inputHolder">
    //         <label className='input-text'>Message</label>
    //           <TextField
    //             className='input'
    //             type="text"
    //             multiline
    //             maxRows={10}
    //             minRows={10}
    //             fullWidth
    //           />
    //       </div>            
    //       <div style={{paddingTop:"10px"}}>
    //         <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload File</label>
    //           <Dropzone onDrop={acceptedFiles => {
    //             console.log("import")
    //           }}>
    //             {({getRootProps, getInputProps}) => (
    //               <section className='drag_drop_container'>
    //                 <div {...getRootProps()} className='files_container'>
    //                   <input {...getInputProps()}/>
    //                   <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
    //                   <p style={{color:'rgb(20, 110, 228)'}}>Drag and drop files here or browse</p>
    //                 </div>
    //               </section>
    //             )}
    //           </Dropzone>
    //         <div style={{paddingTop:"10px",color:'gray',fontSize:'12px'}}>
    //         <p>Supported files: jpg, jpeg, png, pdf<br/>Max. file size: 5mb</p>
    //         </div>
    //       </div>
    //     </div>
    //   }
    // />
  )

}

export default ContactUsModal;