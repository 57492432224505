import * as React from 'react';
import "./styles.scss";
import { Button, Typography } from '@mui/material';

const AppButton = ({
  buttonText, 
  onClick,
  style={},
  textStyle,
  disabled,
  variant="contained",
  textVariant="subtitle1"
}) => {
  return (
    <Button 
      variant={variant}
      disabled={disabled} 
      onClick={onClick} 
      style={{background: "linear-gradient(90deg, #AB66FF, #8BB9E3, #70FFCB)", marginTop: 12, marginBottom: 12, ...style}}
    >
      <Typography variant={textVariant} style={{fontSize: 13, ...textStyle}}>
        {buttonText}
      </Typography>
    </Button>
  );
}

export default AppButton;