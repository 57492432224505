
const headCells = [
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: 'Push Date & Time',

    },
    {
      id: 'content',
      numeric: true,
      disablePadding: false,
      label: 'Content',
    },
    {
      id: 'no_receipient',
      numeric: true,
      disablePadding: false,
      label: 'No. of Recipients',
    },
    {
      id: 'credit_used',
      numeric: true,
      disablePadding: false,
      label: 'Credit Used',
    },
    {
        id: 'created_by',
        numeric: true,
        disablePadding: false,
        label: 'Created By',
    },
    
  ];
  
  
  const rows = [
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:22,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:23,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:24,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:25,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:26,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:27,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:28,
      credit_used:30,
      created_by:"Yamamoto",
      modified_on:"02-02-2029"
      
    },
    {
        date:"01-01-2022, 12:00",
        content:"this is the notification content",
        no_receipient:26,
        credit_used:30,
        created_by:"Yamamoto",
        modified_on:"02-02-2029"
        
    },
    {
        date:"01-01-2022, 12:00",
        content:"this is the notification content",
        no_receipient:27,
        credit_used:30,
        created_by:"Yamamoto",
        modified_on:"02-02-2029"
        
    },
    {
        date:"01-01-2022, 12:00",
        content:"this is the notification content",
        no_receipient:28,
        credit_used:30,
        created_by:"Yamamoto",
        modified_on:"02-02-2029"
        
    },
  ]

  export {headCells,rows}