import CryptoJS from 'crypto-js';
const secretKey = 'your-secret-key';
const encryptedData = 'excrypted-data';

const saveAccessToken = (token) => {
    const encrypted = CryptoJS.AES.encrypt(token, secretKey).toString();
    localStorage.setItem(encryptedData, encrypted);
}

const fetchAccessToken = () => {
    try {
        const storedEncryptedData = localStorage.getItem(encryptedData);
        const decryptedData = CryptoJS.AES.decrypt(storedEncryptedData, secretKey).toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch(error){
        return null;
    }
}

const removeAccessToken = () => {
    localStorage.removeItem(encryptedData);
}

export default {
    saveAccessToken,
    fetchAccessToken,
    removeAccessToken
}