import * as React from 'react';
import PropTypes from 'prop-types';
import AppDropdown from '../AppDropdown';
import AppInput from '../AppInput';
import AppButton from '../AppButton';
import "./styles.scss";
import AppModal from '../AppModal';
import { AppDropDownMenu } from '../index';
import { PropagateLoader } from 'react-spinners';
import CSVFile from '../../assets/csv/SampleCSV.csv'
import formatFile from '../../assets/csv/format.xlsx'

/* MUI */
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import { visuallyHidden } from '@mui/utils';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector, useDispatch } from "react-redux";
import { setUseCredits } from '../../redux/reducer/User';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import useCacheBuster from './useCacheBuster';

function descendingComparator(a, b, orderBy) { 
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function sortDate(a, b, orderBy) {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);

  if (orderBy === 'asc') {
    return dateA - dateB;
  } else {
    return dateB - dateA;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => orderBy === 'date_added' ? sortDate(a, b, orderBy) : descendingComparator(a, b, orderBy)
    : (a, b) => orderBy === 'date_added' ? -sortDate(a, b, orderBy) : -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({ 
  onSelectAllClick, 
  order, 
  orderBy, 
  numSelected, 
  rowCount, 
  onRequestSort,
  headCells 
}) {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.position? headCell.position : "left"}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false} 
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function AppTable({
  headCells,
  step,
  rows,
  actions,
  component,
  toggleModal,
  toggleDelete,
  handleSuspend,
  hundleActivationLink,
  handleEdit,
  handleView,
  handleAdd,
  deleteMultiple,
  deleteMultipleUser,
  activateMultipleUser,
  filterItem,
  filterItemDropDown,
  ExportToExcel,
  exportToExcelSelected,
  toggleSelected,
  trackSelected,
  isLoading,
  numerSelected,
  isForSub=false,
  handleImport,
  otherTemplateCount=0,
  totalCounts=0,
  totalRowsPerPage=10,
  currentPage=1,
  setPropsPage=null,
  setPropsItemPerPage=null,
  hasFilterHeader=true,
  isAction = true,
  isCredit,
  totalTableCount=0,
  onPreviewNameCard,
  handleSendPasswordReset,
  canImport=true,
}) {

  const userData = useSelector((state) => state.user.userData);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(totalRowsPerPage);
  const [showModal,setShowModal ] = React.useState(false);
  const [isEdit,setIsEdit ] = React.useState(false);
  const [isSuspend,setisSuspend] = React.useState(false);
  const [isDelete,setIsDelete] = React.useState(false);
  const [isRemove,setIsRemove ] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterItemName, setfilterItemName] = React.useState("");
  const [filterItemStatus, setfilterItemStatus] = React.useState("All");
  const [filterItemKey, setFilterItemKey] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState("");
  const dispatch = useDispatch();
  const [showImageFull ,setShowImageFull] = React.useState({visible: false, image: ""}); 
  const [showImageFullNameCard ,setShowImageFullNameCard] = React.useState({visible: false, image: ""}); 
  const { cacheBuster, updateCacheBuster } = useCacheBuster();

  React.useEffect(()=>{
    updateCacheBuster();
  },[rows])

  React.useEffect(()=>{
    if(isCredit){
     getusedCreds();
    }
  },[rows.length])
  
  const getusedCreds = () =>{  
    if(rows.length > 0){
      let total = 0;
  
      rows.forEach((row) => {
      total += Number(row.credit_used);
    });

    }
  }  

  // const cacheBuster = new Date().getTime();

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddUser = (event) => {
    setAnchorElAdd(event.currentTarget);
    setOpen(true);
  }

  const handleCloseAdd = () => {
    setOpen(false);
  }

  const handleSelect = (selected) => {
    setOpen(false);
    
    if(selected === 'Add Manually'){
      handleAdd()
    } else if(selected === "Download XLSX/CSV Template"){
      document.querySelector("#downloadCSVSample").click()
    } else{
      handleImport()
    }
  }

  const handleSelectMore = (selected) => {
    setOpen(false);
    
    if(selected === 'Preview Actual Namecard'){
      onPreviewNameCard(currentRow)
    }else if(selected === 'Preview QR (NFC Print)'){
      onPreviewNameCard(currentRow, true)
    }else if(selected === 'Send Activation Link'){
      hundleActivationLink(currentRow)
    }else if(selected === 'Suspend Account' || selected === 'Unsuspend Account'){
      {
        handleSuspend(currentRow);
        setisSuspend(!isSuspend)
      }
    }else if(selected === 'Send Password Reset Link'){
      handleSendPasswordReset(currentRow)
    }else{
      if(selected === 'Delete License'){
        toggleDelete(currentRow);
      }else{
        {
          toggleDelete(currentRow);
        }
      }
    }

    handleClose()
  }

  React.useEffect(()=>{
      if(trackSelected){
        const selectedData = selected.filter((selectedItem) => {
          return trackSelected.findIndex((data) => data.corp_id === selectedItem.corp_id) === -1;
        });
        
        setSelected([])
        
      }
  },[rows?.length, rows, currentPage])

  const [currentIndex, setCurrentIndex] = React.useState(-1);

  const toggleMore = (index) =>{
    setCurrentIndex(index === currentIndex ? -1 : index)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n);
      deleteMultiple(newSelected)
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected,row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    deleteMultiple(newSelected);
    setSelected(newSelected);    
  };

  const handleChangePage = (event, newPage) => {
    if(setPropsPage) {
      setPropsPage(newPage)
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if(setPropsItemPerPage) {
      setPropsItemPerPage(event.target.value)
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

//   const handleChangeDense = (event) => {
//     setDense(event.target.checked);
//   };

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelected = (corp_id) => {
    return selected.findIndex(selectedItem => selectedItem.corp_id === corp_id) !== -1;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const isContact = component === "contacts" || component === "licenses" ? true : false;

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const isFieldObject = (value) => {
    const isObject = typeof value === 'object' && value !== null;
        
    if (isObject) {
      if(value?.extension){
        return value.number+' ext '+value.extension;
      }else{
        return value.number;
      }
    } else {
      return value;
    }
  }

  return (
    <>
      <Box sx={{ width: '100%'}}>
      {
        hasFilterHeader && <div className='table_header_custum'>
          <div className='tableft_maincontainer'>
            <a id="downloadCSVSample" style={{display: 'none'}} href={formatFile} download="format.xlsx"></a>

            {
              !isContact ? selected.length > 0 && 
                <p style={{color:'gray',marginRight:'25px'}}>{selected.length} selected</p>
              : null
            }
            {
              rows.length > 0 &&
                <div className='tableleft_header' style={{cursor:"pointer"}} onClick={()=> {selected.length > 0 ? exportToExcelSelected() : ExportToExcel()}}>
                  <DownloadIcon style={{color:'rgb(20, 110, 228)',marginRight:"5px",}}/>
                  <p style={{color:'rgb(20, 110, 228)'}}>Export as Excel</p>
                </div>
            } 
            {
              !isContact ? selected.length > 0 &&
                <div className='tableleft_header'  style={{marginLeft:'25px',cursor:"pointer"}} onClick={()=>{ toggleSelected() ; deleteMultipleUser(selected)}}>
                  <DeleteIcon style={{color:'red',marginRight:"5px"}} />
                  <p style={{color:'red'}}>Delete Selected</p>
                </div>
              : null
            }
          {/*  {
              selected.length > 0 &&
                <div className='tableleft_header'  style={{marginLeft:'25px',cursor:"pointer"}} onClick={()=>{  activateMultipleUser(selected)}}
                >
                  <EmailIcon style={{color:'rgb(20, 110, 228)',marginRight:"5px",}} />
                  <p  style={{color:'rgb(20, 110, 228)'}}>Send Activation Link</p>
                </div>
            }*/}
          </div>
          <div className='tableright_header' style={{justifyContent:selected.length > 0 && 'flex-end'}}>
          {
            component === "licenses" ? (
              <AppDropdown 
                onSelect={(val)=> {
                  setfilterItemStatus(val)
                  setFilterItemKey('status')
                  filterItemDropDown(val, filterItemName, 'status')
                }} 
                data={[
                  {label: "All", value: "All"},
                  {label: "Active", value: "Active"},
                  {label: "Inactive", value: "Inactive"}
                ]} 
                style={{ width:"27%", fontSize:"13px", marginRight:"15px"}}
              />
            ) : (
              selected.length <= 0 && component === "default" ? (
                <AppDropdown 
                  onSelect={(val)=> {
                    setfilterItemStatus(val)
                    setFilterItemKey('status')
                    filterItem(val, filterItemName, 'status')
                  }} 
                  data={[
                    {label: "All", value: "All"},
                    {label: "Active", value: "Active"},
                    {label: "Inactive", value: "Inactive"},
                    {label: "Suspended", value: "Suspended"}
                  ]} 
                  style={{ width:"27%", fontSize:"13px", marginRight:"15px"}}
                />
              ) : (
                component === "others" ? (
                  <AppDropdown 
                    onSelect={(val)=> {
                      setfilterItemStatus(val)
                      setFilterItemKey('namecard_status')
                      filterItem(val, filterItemName, filterItemKey)
                    }} 
                    data={[
                      {label: "All", value: "All"},
                      {label: "Main", value: "Main"},
                      {label: "Sub", value: "Sub"}
                    ]} 
                    style={{ width:"27%", fontSize:"13px", marginRight:"15px"}}
                  />
                ) : null
              )
            )
          }

          <AppInput icon={<SearchIcon/>} defaultValue={filterItemName} onChange={(e)=> {
            filterItem(filterItemStatus, e, filterItemKey)
            setfilterItemName(e)
          }} inputStyle={{margin:"0px"}} style={{width:"43%",marginRight:"15px"}}/>

          {
            !isContact ? selected.length <= 0 &&
              // <AppButton buttonText="Add User(s)" style={{width:"25%",margin:"0px",fontWeight:'100'}} onClick={()=>handleAdd()} />
              <>
                {
                  isForSub ? 
                    otherTemplateCount > 0 ?
                      <>
                        <AppButton buttonText="Add User(s)" style={{width:"25%",margin:"0px",fontWeight:'100'}} onClick={handleAddUser} />
                        <AppDropDownMenu 
                          anchorEl={anchorElAdd} 
                          open={open} 
                          handleClose={handleCloseAdd} 
                          handleSelect={handleSelect}
                          style={{width:"25%"}}
                          DataList={[
                            {menu: 'Add Manually', disabled: false },
                            ...(canImport ? [{ menu: 'Import XLS/CSV', disabled: false }] : [])
                          ]}
                        />
                      </>
                      :
                      ''
                  :
                  <>
                    <div style={{width:"25%",margin:"0px",fontWeight:'100'}} id={step}>
                      <AppButton buttonText="Add User(s)" style={{width:"100%", margin:"0px",fontWeight:'100'}} onClick={handleAddUser}/>
                    </div>
                    <AppDropDownMenu 
                      anchorEl={anchorElAdd} 
                      open={open} 
                      handleClose={handleCloseAdd} 
                      handleSelect={handleSelect}
                      style={{width:"25%"}}
                      DataList={[
                        {menu: 'Add Manually', disabled: false },
                        ...(canImport ? [{ menu: 'Import XLS/CSV', disabled: false }] : []),
                        ...(canImport ? [{menu: 'Download XLSX/CSV Template'}] : [])
                      ]}
                        // {menu: 'Import XLS/CSV', disabled: false}, 
                        // {menu: 'Download XLSX/CSV Template'}]}
                    />
                  </>
                }
              </>
            : null
          }
          </div>
        </div>
      }
        
        
        <Paper sx={{ width: '100%', mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={component === 'contacts' ? 'date_added' : orderBy}
                headCells={headCells}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                isAction={isAction}
              />
              <TableBody>
                {
                  isLoading ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <PropagateLoader color="#AB66FF" style={{width: '100%', margin: 12}}/>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {
                        rows.length > 0 ? (
                          stableSort(rows, getComparator(order, component === 'contacts' ? 'date_added' : orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.corp_id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                // key={new Date().getTime() + index}
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                              >
                                {/* <TableCell padding="checkbox" style={{minWidth:"80px"}}>
                                  <Checkbox
                                    color="primary"
                                    onClick={(event) => handleClick(event, row)}
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell> */}
                              {
                              isAction &&
                                <TableCell align="left" padding='none'>
                                  {
                                    component === 'licenses' ? (
                                      <IconButton
                                        onClick={(event) => {
                                          setAnchorEl(event.currentTarget);
                                          setCurrentRow(row);
                                        }}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                    ) : component === 'default' ? (
                                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <IconButton
                                          onClick={() => {
                                            toggleModal();
                                            handleEdit(row);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <div>
                                          <IconButton
                                            onClick={(event) => {
                                              setAnchorEl(event.currentTarget);
                                              setCurrentRow(row);
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: row.status === 'Queuing' ? 'center' : 'flex-start',
                                        }}
                                      >
                                        {row.status !== 'Queuing' && (
                                          <IconButton
                                            onClick={() => {
                                              toggleModal();
                                              handleEdit(row);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        )}
                                        {row.status === 'Queuing' && (
                                          <IconButton
                                            onClick={() => {
                                              handleView(row, true);
                                            }}
                                          >
                                            <RemoveRedEyeIcon />
                                          </IconButton>
                                        )}
                                        {row.role === 'sub' ? (
                                          <IconButton
                                            onClick={() => {
                                              toggleDelete(row);
                                              handleClose();
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        ) : component ? (
                                          (row.status === 'Active' || row.status === 'Inactive') && (
                                            <IconButton
                                              onClick={(event) => {
                                                setAnchorEl(event.currentTarget);
                                                setCurrentRow(row);
                                              }}
                                            >
                                              <MoreHorizIcon />
                                            </IconButton>
                                          )
                                        ) : (
                                          <IconButton
                                            onClick={(event) => {
                                              setAnchorEl(event.currentTarget);
                                              setCurrentRow(row);
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                        )}
                                      </div>
                                    )
                                  }

                                    
                                </TableCell>
                              }
                              
                                {headCells.map((key)=>{
                                    const k = key.id
                                    if(k !== "actions"){
                                      if(k === "logo"){

                                        let userImage = 'https://lexicard-public.s3.amazonaws.com/namecards/logo/user_photo_holder.png'

                                        if(row[k]){
                                          userImage = row[k]
                                        }

                                        return <TableCell align="left">
                                          <button
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              border: 'none',
                                              background: 'none',
                                              padding: 0,
                                              margin: '10px auto',
                                              cursor: 'zoom-in'
                                            }}
                                            onClick={()=> component === 'contacts' ? setShowImageFullNameCard({visible: true, image: userImage}) : setShowImageFull({visible: true, image: userImage})}
                                          >
                                          <div
                                            style={{
                                              width: 100,
                                              height: 100,
                                              boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.5)',
                                              backgroundSize: 'contain',
                                              backgroundPosition: 'center',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundImage: `url(${userImage+'?'+cacheBuster})`
                                            }}
                                          ></div>
                                        </button></TableCell>
                                      }else{
                                        return <TableCell align="left">
                                          {
                                          k === 'content' ? row[k] :
                                          k !== 'role' ? isFieldObject(row[k]) : 
                                          <p style={{backgroundColor: k === "role" ? row.role === 'main' ? '#58608a' : '#a5add6' : null, color: k === "role" ? 'white' : null, display: 'flex', justifyContent: 'center', padding: 5, borderRadius: 10}}>{ capitalizeFirstLetter(row[k])}
                                          </p>}
                                          </TableCell>
                                      }
                                    }
                                   
                                })} 

                                {/* <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row['name']}
                                </TableCell>

                                <TableCell align="left">{row.position}</TableCell>
                                <TableCell align="left">{row.office_number}</TableCell>
                                <TableCell align="left">{row.mobile_number}</TableCell>
                                <TableCell align="left">{row.fax_number}</TableCell>
                                <TableCell align='left'>{row.email}</TableCell>
                                {component === "default" && <TableCell align='left'>{row.status || "TEST"}</TableCell> } */}
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9} align="center">
                              No data found...
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </>
                  )
                }
              {/*  {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={9} />
                  </TableRow>
                )}*/}
              </TableBody>
            </Table>
            
          </TableContainer>

          {rows.length >  0 &&    
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalTableCount || totalCounts}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          }

          <AppDropDownMenu 
            row={currentRow}
            anchorEl={anchorEl} 
            open={anchorEl} 
            handleClose={handleClose} 
            handleSelect={handleSelectMore}
            style={{width:"25%"}}
            DataList={
              component === 'licenses' ? 
              [
                {menu: 'Delete License', disabled: currentRow?.license_status === 'Active' ?  true : false}
              ] 
              : 
              [
                {menu: 'Preview Actual Namecard'},
                ...(currentRow?.status === 'Active' ? [{menu: 'Preview QR (NFC Print)'}] : []),
                {menu: 'Send Activation Link'},
                ...(currentRow?.isPasswordUpdated ? [{ menu: 'Send Password Reset Link' }] : []),
                {menu: currentRow?.status === 'Suspended' ? 'Unsuspend Account' : 'Suspend Account'},
                {menu: 'Delete Account'}
              ]
            }
          />

          {/* Remove */}
          <AppModal
            showModal={isRemove}
            header="Remove Account"
            toggleModal={()=> setIsRemove(!isRemove)}
            functionText={"Remove"}
            buttonStyle={{background: '#E5383B'}}
            content={
              <div>
                <p style={{marginBottom:"20px"}}>Remove the following user from Challenger x ChopeShift name card?</p>
                <p style={{marginBottom:"10px"}}>This user will still have access to his/her primary name card.</p>
                <div style={{padding:"10px",backgroundColor:"lightgray",borderRadius:"5px"}}>
                  <p>Example1</p>
                  <p>example1@email.com</p>
                </div>
              </div>
            }
          />

          <AppModal
            showModal={showImageFull.visible}
            header="Photo Preview"
            toggleModal={()=> setShowImageFull({visible: false, image: ""})}
            cancelButton={false}
            disableIndicator={true}
            functionText={"Close"}
            onClick={()=>setShowImageFull({visible: false, image: ""})}
            content={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.5)',
                  width: 350,
                  height: 350,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${showImageFull.image})`,
                  margin: '10px auto'
                }}
              ></div>
            }
          />

          <AppModal
            showModal={showImageFullNameCard.visible}
            header="NameCard Preview"
            toggleModal={()=> setShowImageFullNameCard({visible: false, image: ""})}
            cancelButton={false}
            disableIndicator={true}
            functionText={"Close"}
            onClick={()=>setShowImageFullNameCard({visible: false, image: ""})}
            style={{width: '50%'}}
            content={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.5)',
                  width: 634,
                  height: 344,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${showImageFullNameCard.image})`,
                  margin: '10px auto'
                }}
              ></div>
            }
          />
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    </>
  );
}
