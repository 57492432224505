import React from "react";
import './styles.scss';
import {AppModal} from '../../../../../components';


const ConfirmationModal = (props) => {
    const {onClose, display, onSubmit, onDraft } = props
    return (
        <AppModal 
          showModal={display} 
          header="Confirm Push Notification"
          toggleModal={() => onClose()}
          draftClick={() => onDraft('draft')}
          functionText="Confirm"
          daftButton={true}
          // buttonStyle={{background:'red',color:"white"}}
          onClick={() => onSubmit('queuing')}
          content={
            <div id="confirmModal"> 
                <p>Your data cannot be edited or changed once submitted, so please review it carefully before proceeding.</p>
            </div>
          }
        />
    )

}

export default ConfirmationModal;