import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppAlertDialog = ({
    visible,
    onPositivePress,
    onNegativePress,
    onIgnorePress,
    onClose,
    title,
    message,
    positiveButtonName,
    cancelText='No'
}) => {

  return (
    <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        style={{zIndex: 1301}}
    >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            {
              message
            }
            {/* <div dangerouslySetInnerHTML={{__html: message}}></div> */}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        { onNegativePress && <Button onClick={onNegativePress} style={{color: 'red'}}>{cancelText}</Button> }
        { onIgnorePress && <Button onClick={onIgnorePress} style={{color: '#FF5F15'}}>Ignore</Button> }
        { onPositivePress && <Button onClick={onPositivePress}>{positiveButtonName ? positiveButtonName : 'Yes'}</Button>  }
    </DialogActions>
    </Dialog>
  );
}

export default AppAlertDialog;