import React from 'react';
import "./styles.scss";
import { Typography } from "@mui/material";
import moment from 'moment';

const InvoicePdf = ({selectedInvoice,billingData,companyDetails,subscriptionDetails}) => {
    
    const baseUsers = subscriptionDetails[0].metadata.base_users;
    const numberUsers = subscriptionDetails[0].metadata?.number_users ? Number(subscriptionDetails[0].metadata.number_users):0;
    const additionalUsers = Math.abs(numberUsers - Number(subscriptionDetails[0].metadata.base_users));
    const extraUsers =  numberUsers !== 0? additionalUsers * Number(subscriptionDetails[0].metadata.perUser):0;
    const basePlanCost =  extraUsers + Number(subscriptionDetails[0].metadata.perBaseUser);
    const subTotal = subscriptionDetails[0]?.metadata?.subtotal ? Math.abs(Number(subscriptionDetails[0].metadata.subtotal)):Math.abs(basePlanCost);

    return(
        // <Typography>Work on progress</Typography>
        <div id='invoice-modal-content'>
            <div className='invoice-modal-header'>
                <div className='header-left'>
                    <Typography variant="h7" className='invoice-header-title'>INVOICE - {selectedInvoice.invoice}</Typography>
                    <Typography variant="h7" className='invoice-header-plan'>Plan: {selectedInvoice.plan}</Typography>
                    <Typography variant="h7" className='invoice-header-date'>{selectedInvoice.date}</Typography>
                </div>
            </div>
            <div className='invoice-company-info'>
                    <Typography variant="h7" className='invoice-header-company'>{companyDetails[0].corp_name}</Typography>
                    { companyDetails[0].metadata.uen && 
                        <Typography variant="h7" className='invoice-header-order'>{companyDetails[0].metadata.uen}</Typography>
                    }
                    <div style={{display: 'flex', marginBottom: 15}}>
                        <Typography variant="h7" className='invoice-header-website'>{subscriptionDetails[0].metadata.email}</Typography>
                        { companyDetails[0].metadata.contact &&
                            <>
                                <div className="vertical-line"></div>
                                <Typography variant="h7" className='invoice-header-contact'>{ companyDetails[0].metadata.contact }</Typography>
                            </>
                        }
                    </div>
                    <hr style={{ margin: 0, padding: 0, borderTop: '1px solid #DBD9D9', marginBottom: 15}}/>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <Typography variant="h7" className='invoice-info'>Invoice Number:</Typography>
                            <Typography variant="h7" className='invoice-info'>Invoice Date:</Typography>
                            <Typography variant="h7" className='invoice-info'>Due:</Typography>
                        </div>
                        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Typography variant="h7" className='invoice-info' style={{float: 'right'}}>{selectedInvoice.invoice}</Typography>
                            <Typography variant="h7" className='invoice-info' style={{float: 'right'}}>{moment(selectedInvoice.date).format("DD MMMM YYYY")}</Typography>
                            <Typography variant="h7" className='invoice-info' style={{float: 'right'}}>{moment(subscriptionDetails[0].subscription_exp).format("DD MMMM YYYY")}</Typography>
                        </div>
                    </div>
                </div>
            <div className='invoice-breakdown'>
                <div className='black-container'><Typography variant="h7" className='invoice-breakdown-title'>Cost Breakdown</Typography></div>
                <div className='white-container'>
                    <Typography variant="h7" className='invoice-breakdown-title'>Plan</Typography>
                    <Typography variant="h7" className='invoice-breakdown-title'>Cost</Typography>
                </div>
            </div>
            
            <div className='transparent-container'>
                <div className='transparent-content'>
                    <Typography variant="h7" className='transparent-content-title'>{selectedInvoice.plan}</Typography>
                    <Typography variant="h7" className='transparent-content-title'>${subscriptionDetails[0].metadata.perBaseUser}/year, {baseUsers} users</Typography>
                </div>
                {/* <hr style={{ margin: 0, padding: 0, borderTop: '1px solid #DBD9D9'}}/>
                <div className='transparent-content'>
                    <Typography variant="h7" className='transparent-content-title'>Cpremium</Typography>
                    <Typography variant="h7" className='transparent-content-title'>$100/year, 20 users</Typography>
                </div> */}
            </div>
            <div className='summary-container'>
                <div className='summary-content'>
                    <Typography variant="h7" className='summary-container-title'>Base Plan ({subscriptionDetails[0].metadata.base_users} users)</Typography>
                    <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.perBaseUser).toFixed(2)}</Typography>
                </div>
                { numberUsers !== 0 &&
                    <div className='summary-content'>
                        <Typography variant="h7" className='summary-container-title'>Additional Users ({additionalUsers} users)</Typography>
                        <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.perUser).toFixed(2)}</Typography>
                    </div>
                }
                <div className='summary-content'>
                    <Typography variant="h7" className='summary-container-title'>Subtotal</Typography>
                    <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subTotal).toFixed(2)}</Typography>
                </div>
                <div className='summary-content'>
                    <Typography variant="h7" className='summary-container-title'>GST 8%</Typography>
                    <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.gst).toFixed(2)}</Typography>
                </div>
                <div className='summary-content'>
                    <Typography variant="h7" className='summary-container-title-bold'>Total</Typography>
                    <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.amount).toFixed(2)}</Typography>
                </div>
            </div>
            <div className='footer-container'>
                <div style={{padding: 40, display: 'flex', justifyContent: 'space-between'}}>
                    <div className='left-footer'>
                        <Typography variant="h7" className='footer-title-bold' style={{marginBottom: 10}}>{selectedInvoice.plan}</Typography>
                        <Typography variant="h7" className='footer-title'>Annual billing</Typography>
                        <Typography variant="h7" className='footer-title'>Renews on {moment(subscriptionDetails[0].subscription_exp).format("DD MMMM YYYY")}</Typography>
                        <Typography variant="h7" className='footer-title-bold' style={{marginBottom: 30}}>S${Number(selectedInvoice.total).toFixed(2)}</Typography>
                        {billingData.metadata.address &&
                            <>
                                <Typography variant="h7" className='footer-title-bold' style={{marginBottom: 10}}>Billing Details</Typography>
                                <Typography variant="h7" className='footer-title'>123 Street</Typography>
                                <Typography variant="h7" className='footer-title'>#01-01</Typography>
                                <Typography variant="h7" className='footer-title'>Singapore 123123</Typography>
                            </>
                        }
                    </div>
                    <div className='right-footer'>
                        <Typography variant="h7" className='right-footer-title' style={{justifyContent: 'right'}}>Total amount</Typography>
                        <Typography variant="h7" className='right-footer-title-bold'>SGD${Number(subscriptionDetails[0].metadata.amount).toFixed(2)}</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoicePdf