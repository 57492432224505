import React, { useState } from "react";
import "./styles.scss";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography
} from "@mui/material";
import { AppButton, AppInput, AppModal } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { EmailValidator, AccessToken } from "../../../utilities";
import logo from "../../../assets/image/logo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApiPost } from "../../../services/AxiosInstance";
import { useSelector, useDispatch } from 'react-redux';
import { setUserData, setOnSyncUsers } from "../../../redux/reducer/User";
import { Audio } from 'react-loader-spinner';
import AppLoader from "../../../components/AppLoader";
import OTPInput from 'react-otp-input';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

export default function Login() {
  
  const { saveAccessToken } = AccessToken;
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorConfig, setErrorConfig] = useState({
    status: false,
    msg: "",
  });
  const [loader,setLoader] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false)
  const [otp, setOTP] = useState('');
  const [secretKeyData, setSecretKeyData] = useState()
  const [tempUserData, setTempUserData] = useState()
  const [alertAttr, setAlertAttr] = React.useState({visible: false, message: null, color: null});
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };
  
  const dispatch = useDispatch();

  const errorHandling = (status, message) => {
    setErrorConfig({ status, message });
  };

  const inputUserEmail = (e) => {
    setUserEmail(e.target.value);
  };

  const inputUserPassword = (e) => {
    setUserPassword(e.target.value);
  };

  const handleLogin = async() => {
    const emailValidation = EmailValidator(userEmail);

    if (!emailValidation) {
      errorHandling(true, "Please enter a valid email");
    } else {
      if (!userPassword) {
        errorHandling(true, "Please enter your password");
      } else {
      //login here
       setLoader(true);
        ApiPost("/corps/user-login",{email: userEmail, password: userPassword})
        .then((response) => {
            const data = response.data;
            if(data.message === 'success'){
            if (data?.data[0]?.authentication_keys?.isEnabled === true) {
                setTempUserData(data)
                setSecretKeyData(data.data[0].authentication_keys.secret_key)
                setOtpVisible(true)
              } else {
                //saveAccessToken(data.data[0].userSession.token);
                dispatch(setUserData(data.data));
                dispatch(setOnSyncUsers(false));
                navigate('/dashboard');
                setUserEmail('')
                setUserPassword('')
              }
            } else {
              errorHandling(true, data.message);
            }
            
            setLoader(false);
        })
        .catch((error) => {
          errorHandling(true, "Please check you internet connection");
          setLoader(false)
          console.log("Log in error: ", error)
        });
      }
    }
  };

  const verifySecretKey = async () => {
    setLoader(true);
    ApiPost("/corps/two-fa-validation",{token: otp, secretData: secretKeyData})
    .then((validateResp) => {
      const validateRes = validateResp.data.data[0]
      setLoader(false);
      if (validateRes.validated) {
        setOtpVisible(false)
        //saveAccessToken(tempUserData.data[0].userSession.token);
        dispatch(setUserData(tempUserData.data));
        navigate('/dashboard');
        setUserEmail('')
        setUserPassword('')
      } else {
        setAlertAttr({visible: true, message: 'Invalid Secret Key', color: '#b2102f'});
      }
    })
    .catch((error) => {
      setLoader(false)
      console.log("Secret key validating error: ", error)
      setAlertAttr({visible: true, message: 'Secret key validation error.', color: '#b2102f'});
    })
  }

  const handleOTPChange = (newOTP) => {
    setOTP(newOTP);
  };

  const handleCloseAlert = () =>{
    setAlertAttr({visible: false, message: null, color: null});
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="landing-container">
      <Snackbar
        ContentProps={{
          sx: {
            background: alertAttr.color
          }
        }}
        open={alertAttr.visible}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={anchorOrigin}
        message={alertAttr.message}
        action={action}
      />
      <div className="landing-card">
        <div className="landing-card__logo">
          <img className="" src={logo} />
        </div>
        <p className="landing-card__description landing-card__description--left">
          {" "}
          Log in to your corporate LexisCard dashboard{" "}
        </p>
        <div className="landing-card__container">
          <label>Email</label>
          <TextField
            size="small"
            id="time"
            defaultValue={userEmail}
            type="text"
            style={{ width: "100%" }}
            onChange={(e) => setUserEmail(e.target.value)}
            error={errorConfig.status}
          />
        </div>
        <div className="landing-card__container">
          <label>Password</label>
          <Link to="resetPassword" className="landing-card__forgotlabel">
            Forgot Password{" "}
          </Link>

          <FormControl variant="outlined" style={{ width: "100%" }}>
            <OutlinedInput
              size="small"
              onChange={(e) => setUserPassword(e.target.value)}
              error={errorConfig.status}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {errorConfig.status && (
            <p className="error-message">{errorConfig.message} </p>
          )}
        </div>
        <AppButton buttonText="Login" style={{width: '100%'}} onClick={handleLogin} />
        <p>
          Want to have a corporate account?{" "}
          <a href="https://www.lexiscard.asia/"> Learn more</a>
        </p>
      </div>
       <AppLoader loader={loader} closeLoader={()=>setLoader(false) } /> 
       <AppModal
        showModal={otpVisible}
        toggleModal={() => setOtpVisible(false)}
        header="Enter Secret Key Code"
        headerStyle={{
          color: '#FFF'
        }}
        closeIconStyle={{
          color: '#FFF'
        }}
        content={
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <>
              <Typography variant="subtitle1" style={{fontSize: 16, color: '#FFF', marginBottom: 20}}>Refer to your Google Authenticator App for the displayed Secret Key</Typography>
              <Typography variant="subtitle1" style={{fontSize: 16, color: '#FFF', marginBottom: 20}}>Enter your Secret Key Code:</Typography>
              <OTPInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={6}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{ width: '3rem', height: '3rem', marginRight: '1rem' }}
                renderInput={(props) => <input {...props} />}
                containerStyle={{
                  marginBottom: 20
                }}
              />
            </>
            <Typography variant="subtitle1" style={{fontSize: 10, color: '#FFF', marginBottom: 20}}>{`You enabled Two Factor Authentication in you account settings. You can disable this in Settings > Account`}</Typography>
          </div>
        }
        style={{
          backgroundColor: '#58608a',
          width: 'auto'
        }}
        cancelButton={false}
        onClick={() => verifySecretKey()}
       />
    </div>
  );
}
