import { Button, Modal } from '@mui/material'
import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import AppButton from '../AppButton'

export default function AppImageCropper({ 
    image,
    imageCropped = () => {},
    modalOpen,
    modalClose,
    cropShape = 'rect'
}) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels)
            imageCropped(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, image, imageCropped])

  return (
    <Modal
        open={modalOpen}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div>
            <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                zoomWithScroll={false}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropSize={{ width: 192, height: 192 }} 
                cropShape={cropShape}
            />

            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    
                }}
            >
                <div style={{
                    backgroundColor: '#CCC7BF',
                    padding: '10px',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 20
                }}>

                    <input
                        type="range"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => setZoom(Number(e.target.value))}
                        className="zoom-range"
                    />
                    <label htmlFor="cropSizeRange" style={{ marginBottom: 10, marginTop: 5, fontSize: 18 }}>Zoom</label>
                </div>

                <AppButton
                    buttonText="Crop Image"
                    onClick={showCroppedImage}
                    className="modal-button"
                    style={{ alignSelf: "center", background: null, marginTop: 10, width: '30%' }}
                />

                <AppButton
                    variant='text'
                    buttonText="Skip Cropping"
                    onClick={modalClose}
                    className='cancel-btn'
                    style={{ color: '#FF0000', background: null, alignSelf: "center", background: null, marginTop: 10, width: '30%', fontSize: 18 }}
                />
            </div>
        </div>
    </Modal>
  )
}

const getCroppedImg = (imageSrc, pixelCrop) => {
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous')
            image.src = url
        })

    return createImage(imageSrc)
        .then(image => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        return canvas.toDataURL('image/jpeg')
    })
    .catch(error => {
        console.error('Error creating cropped image:', error)
        throw error
    })
}
