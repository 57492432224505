import { useEffect, useState } from "react";
import { AppTable } from "../../../../components"
import { ApiPost } from "../../../../services/AxiosInstance";
import { headCells,rows } from "./dummy"
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { setDisableIndicator, setUseCredits,setCorpCreds } from "../../../../redux/reducer/User";


const NotificationDraft = ({onEdit,onDelete,isSuccess,trigger}) =>{
      
const [itemsPerPage, setItemPerPage] = useState(10);
const [page,setPage] = useState(0);
const [notificationList,setNotificationList] = useState([])
const [totalRows,setTotalRows] = useState(0)
const [loading,setLoading] = useState(false)

const userData = useSelector((state)=> state.user.userData);
const corp_id = userData[0].corp_id;



useEffect(()=>{
    getNotification()
},[isSuccess,trigger])

const dispatch = useDispatch();

const restructure = (result) =>{

const restuctureList =  result?.map((item)=>{
  console.log(item)
     
    const ContentItem = ({ metadata }) => {
        return (
          <>
            <b>{metadata?.title}</b><br></br>
            <p>{metadata?.content}</p>
          </>
        );
      };

      const date = moment(item?.metadata.pushed_time_date.date).format("YYYY-MM-DD");
      const time = moment(item?.metadata.pushed_time_date.time).format("LT");
      const modifiedOn = item?.modifiedon ? moment(item?.modifiedon).format("YYYY-MM-DD") : ""
      const status = item?.status;

      const newList = {
          date:  date + ", " + time,
          content:  <ContentItem metadata={item?.metadata} />,
          no_receipient: item?.metadata.isEveryone ? "Everyone": item?.metadata.no_recipients.length,
          credit_used: item?.metadata.credits_used,
          created_by: item?.created_by,
          modified_on: modifiedOn,//dynamic dapat
          id: item?.id,
          status: String(status).charAt(0).toUpperCase() + String(status).slice(1),
          url:item?.metadata?.url
        }              
      return newList
    })

    setNotificationList(restuctureList)

}

const handleEdit = (val,isDisabled) =>{
    console.log("this is the ediiitit",isDisabled)
    onEdit(val,getNotification)
    dispatch(setDisableIndicator(isDisabled))
}

const handleDelete = (row) =>{
   onDelete(row)
}

const handleUserListsPerPage = async(val) =>{
    setItemPerPage(val)
    setLoading(true)
    const data = await ApiPost("/corps/get-notification",{corp_id:corp_id,limit:val,page:page, isHistory: false, isDraft: true})
    const result = data.data.data
    setTotalRows(result.totalRow)
    restructure(result.result)
    dispatch(setCorpCreds(result.corpCreds[0].metadata.credits))
    dispatch(setUseCredits(result.totalcredsUsed))
    setLoading(false)

}

const handleUserListsPage = async (val) => {
    console.log("per page", val)
    setLoading(true)
    setPage(val)
    const data = await ApiPost("/corps/get-notification",{corp_id:corp_id,limit:itemsPerPage,page:val, isHistory: false, isDraft: true})
    const result =data.data.data
    setTotalRows(result.totalRow)
    restructure(result.result)
    dispatch(setCorpCreds(result.corpCreds[0].metadata.credits))
    dispatch(setUseCredits(result.totalcredsUsed))
    setLoading(false)
}

const getNotification = async() =>{
        setLoading(true)
        try{
          const data = await ApiPost("/corps/get-notification",{corp_id:corp_id,limit:itemsPerPage,page:page, isHistory: false, isDraft: true})
          const result = data.data.data
          if(result.result.length > 0){
           setTotalRows(result.totalRow)
           restructure(result.result)   
           setLoading(false)
           dispatch(setCorpCreds(result.corpCreds[0].metadata.credits))
           dispatch(setUseCredits(result.totalcredsUsed))   
            
          }else{
            setNotificationList([])
            setTotalRows(result.totalRow)
            dispatch(setCorpCreds(result.corpCreds[0].metadata.credits))
            dispatch(setUseCredits(result.totalcredsUsed)) 
            setLoading(false)
          }
          
        }catch(error){  
          console.log(error)
          setLoading(false)
        }
       
}


    return(
        <div style={{width:"100%"}}>
         <AppTable 
            rows={notificationList} 
            headCells={headCells} 
            hasFilterHeader={false}
            isLoading={loading}
            toggleModal={()=>console.log("toggle edit modal state here")} 
            toggleDelete={(val)=>handleDelete(val)} 
            handleEdit={(val,status) => handleEdit(val,status)}
            setPropsItemPerPage={(val) => handleUserListsPerPage(val)}
            setPropsPage={val => handleUserListsPage(val)}
            isCheckbox ={false}
            totalRowsPerPage={itemsPerPage}
            totalCounts={totalRows}
            currentPage={page}
            isCredit={true}
          />
        </div>
    )
}

export default NotificationDraft