import { Routes, Route, useNavigate } from "react-router-dom";
import { LoginPage, ResetPasswordPage, NewPasswordReset } from "../pages/LandingPage";
import { DashboardPage, SettingsPage, UserListPage, NameCardDesignPage, NotificationPage } from "../pages/HomePage";
import ErrorPage from "../pages/ErrorPage";
import { Sidebar } from '../layouts';
import { ProtectedRoute } from "./ProtectedRoute";

export default function AppRoutes() {

  const navigate = useNavigate();

  return (
    <Routes navigate={navigate}>
      <Route exact path="/" element={<ProtectedRoute isLanding={true}><LoginPage /></ProtectedRoute> }/>
      <Route exact path="/resetPassword" element={<ProtectedRoute isLanding={true}><ResetPasswordPage /></ProtectedRoute> }/>
      <Route exact path="/newPasswordReset" element={<ProtectedRoute isLanding={true}><NewPasswordReset /></ProtectedRoute> }/>
      
      <Route path="/" element={<Sidebar />}>
        <Route exact path="dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute> }/>
        <Route exact path="userlist" element={<ProtectedRoute><UserListPage /></ProtectedRoute> }/>
        <Route exact path="namecarddesign" element={<ProtectedRoute><NameCardDesignPage /></ProtectedRoute> }/>
        <Route exact path="settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute> }/>

         <Route exact path="notifications" element={<ProtectedRoute><NotificationPage /></ProtectedRoute> }/>
      </Route>

      <Route path ='*' element={<ErrorPage />} />
    </Routes>
  );
}
