import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../services/AxiosInstance';
import { SignOut, jwtExpToSeconds } from '../utilities';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getEnvVars } from '../services/Environment';
import constants from '../constants';
import { AppAlertDialog  } from '../components';
import { ApiPost } from '../services/AxiosInstance';

export default function AppProvider({children}) {

    const { GLOBAL_VARIABLES } = constants;
    const { apiUrl } = getEnvVars();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [alertAttr, setAlertAttr] = React.useState({visible: false, message: null, color: null});
    const inactivityTimerId = React.useRef();
    const [dialogAlert, setDialogAlert] = React.useState({
        visible: false,
        title: '',
        message: ''
    });
    const [alertShown, setAlertShown] = React.useState(false);

    React.useEffect(() => {
        setSessionTimer(true);
        
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        document.addEventListener('visibilitychange', handleUserActivity);
    
        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            document.removeEventListener('visibilitychange', handleUserActivity);
            clearInterval(GLOBAL_VARIABLES.REF_SESSION.current);
            clearTimeout(inactivityTimerId.current);
        };
    }, [userData]);

    React.useEffect(() => {
        if(userData){
            const subscription_exp = userData[0]?.subscription_exp;

            if(subscription_exp){
                const status = checkSubscriptionStatus(subscription_exp);
                if (status) {
                    // triggerEmailJobQueue(corp_name, useremail, name, status);
                    const date = new Date(subscription_exp);
                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
                    
                    setDialogAlert({
                        visible: true,
                        title: 'Subscription Expiry Notification',
                        message: (
                            <div>
                                Your Subscription will expire in <span style={{ color: 'red' }}>{formattedDate}</span>
                            </div>
                        )
                    });
                    setAlertShown(true);
                }
            }
        }
        
    }, [userData, alertShown]);

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const status = error?.response?.status;
            const baseURL = error?.response?.config?.baseURL;
            if(status === 401 && baseURL.includes(apiUrl)){
                goToLogin();
            }
            return Promise.reject(error);
        }
    );

    const checkSubscriptionStatus = (subscriptionExp) => {
        if (!subscriptionExp) {
          return null;
        }
    
        const expDate = new Date(subscriptionExp);
        const currentDate = new Date();
    
        const timeDiff = expDate - currentDate;
    
        const oneDay = 24 * 60 * 60 * 1000;
        const oneWeek = 7 * oneDay;
        const oneMonth = 30 * oneDay;
    
        if (timeDiff <= oneDay) {
            return '1 day left';
        } else if (timeDiff <= oneWeek) {
            return '1 week left';
        } else if (timeDiff <= oneMonth) {
            return '1 month left';
        } else {
            return null;
        }
    };

    // const triggerEmailJobQueue = async (corp_name, useremail, name, subscriptionExp) => {
    
    //     if (subscriptionExp === '1 month left') {
    

    //         let data = ''
    //         console.log('NAG SULOD')
    //         data = await ApiPost("/corps/add-jobque-subscription-notif", { corps_name: corp_name, useremail: useremail, name: name });

    //         console.log(data,' : payload : ')
    //     }
    // };

    const handleClose = () => {
        setDialogAlert({ ...dialogAlert, visible: false });
    };

    const handleUserActivity = () => {

        setSessionTimer(true);

        if (document.visibilityState !== 'visible') {
            setSessionTimer(false);
        }
  
        if (inactivityTimerId.current) {
          clearTimeout(inactivityTimerId.current);
        }
  
        inactivityTimerId.current = setTimeout(() => {
          setSessionTimer(false);
        }, 5000); // Set the inactivity threshold (5 seconds in this example)
    };

    const setSessionTimer = (isActive) => {

        if(userData){
            countDownSession(1800, isActive);
        } else {
            clearInterval(GLOBAL_VARIABLES.REF_SESSION.current);
        }

        // if(userData && userData[0]?.userSession?.exp){
        //     // countDownSession(jwtExpToSeconds(userData[0].userSession.exp));
        //     countDownSession(900, isActive);
        // } else {
        //     clearInterval(GLOBAL_VARIABLES.REF_SESSION.current);
        // }
    }

    const countDownSession = (ttl, isActive) => {
        clearInterval(GLOBAL_VARIABLES.REF_SESSION.current);

        if(ttl){
            let num = ttl;
            GLOBAL_VARIABLES.REF_SESSION.current = setInterval(() => {
                if (!isActive && num > 0) {
                    num = num - 1;
                    //document.title = num;
                    //console.log('countDownSession', num)
                } else if(num <= 0) {
                    clearInterval(GLOBAL_VARIABLES.REF_SESSION.current);
                    goToLogin();
                }
            }, 1000);
        }
    }

    const goToLogin = () => {
        SignOut(dispatch, navigate);
        setAlertAttr({visible: true, message: 'Your session has expired', color: '#FFC107'});
    }

    const handleCloseAlert = () =>{
        setAlertAttr({visible: false, message: null, color: null});
    }

    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Snackbar
                ContentProps={{
                    sx: {
                        background: alertAttr.color
                    }
                }}
                open={alertAttr.visible}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                message={alertAttr.message}
                action={action}
            />
            <AppAlertDialog 
                visible={dialogAlert.visible}  
                title={dialogAlert.title}
                message={dialogAlert.message}
                cancelText='Close'
                onNegativePress={handleClose}
                onClose={handleClose}
            />
            {children}
        </>
    )
}

