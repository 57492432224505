import React from 'react';
import logo from "../../assets/image/chuGo-logo.png"

export const sampleData = [

  {
    "front":[
      {
        "field": "name",
        "data": "Anthony Tan",
      },
      {
        "field": "position",
        "data": "Marketing Executive",
      },
      {
        "field": "department",
        "data": "Engineering Department",
      },
      {
        "field": "address",
        "data": "1 Ubi Link Challenger TecHub Singapore 408553",
      },
      {
        "field": "office_number",
        "data": "+65 XXXX XXXX.",
      },
      {
        "field": "mobile_number",
        "data": "+65 XXXX XXXX",
      },
      {
        "field": "fax_number",
        "data": "+65 XXXX XXXX",
      },
      {
        "field": "email",
        "data": "test.email@gmail.com",
      },
      {
        "field": "website",
        "data": "www.sample.com",
      }
    ],
  },
  {
    "back":[
      {
        "field": "office_number",
        "data": "Tel: +65 XXXX XXXX.",
      },
      {
        "field": "mobile_number",
        "data": "Mobile: +65 XXXX XXXX",
      },
      {
        "field": "fax_number",
        "data": "Fax: +65 XXXX XXXX",
      },
      {
        "field": "website",
        "data": "www.sample.com",
      },
      {
        "field": "address",
        "data": "1 Ubi Link Challenger TecHub Singapore 408553",
      }
    ]
  }
];