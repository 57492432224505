const headCells = [
    {
      id: 'actions',
      numeric: false,
      disablePadding: true,
      label: 'Actions',
      position:"center"
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: 'Push Date & Time',
      position:"center"
    },
    {
      id: 'content',
      numeric: true,
      disablePadding: false,
      label: 'Content',
    },
    {
      id: 'no_receipient',
      numeric: true,
      disablePadding: false,
      label: 'No. of Recipients',
    },
    {
      id: 'credit_used',
      numeric: true,
      disablePadding: false,
      label: 'Credit Used',
    },
    {
        id: 'created_by',
        numeric: true,
        disablePadding: false,
        label: 'Created By',
    },
    {
        id: 'modified_on',
        numeric: true,
        disablePadding: false,
        label: 'Modified On',
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
  },
  ];
  
  
  const rows = [
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:22,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:23,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:24,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:25,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:26,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:27,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    },
    {
      date:"01-01-2022, 12:00",
      content:"this is the notification content",
      no_receipient:28,
      credit_used:30,
      created_by:"kurusaki",
      modified_on:"02-02-2029"
      
    }
  ]

  export {headCells,rows}