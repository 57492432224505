import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const AppLineChart = ({ data, step, variant="total_scans", show="all" }) => {

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const totalShares = show === 'all' ? payload[1].value : payload[0].value;
      // console.log("payload", payload[0])

      return (
        <div className='custom-tooltip' style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: 3 }}>
          <p style={{marginBottom: 12}}>{`${payload[0].payload.name}`}</p>

          {
            payload[0].value > 0 && show !== 'share' ?
            <div style={{marginBottom: 16}}>
              {/* <b style={{color: payload[0]?.dataKey === 'uv'?'violet':'skyblue'}}>Total No. of Scans: {payload[0].value}</b> */}
              {
                payload[0].payload.top_scanner.map((item, k)=>{
                  const userName = item.name !== '' ? item.name : 'Unknown';
                  return <p style={{color: payload[0]?.dataKey === 'uv'?'violet':'skyblue'}}>{k+1}.) {userName} - No. of Scans: {item.scans}</p>;
                })
              }
            </div>
            :
            show !== 'scan'?"":
            <p style={{color: 'black'}}>No data available</p>
          }
            
          {
            payload[0]?.value > 0 && show !== 'scan'?
            <div style={{marginBottom: 16}}>
              {/* <b style={{color: 'skyblue'}}>Total - No. of Shares: {totalShares}</b> */}
              {
                payload[0].payload.top_sharer.map((item, k)=>{
                  const userName = item.name !== '' ? item.name : 'Unknown';
                  return <p style={{color: 'skyblue'}}>{k+1}.) {userName} - No. of Shares: {item.shares}</p>;
                })
              }
            </div>
            :
            show !== 'share'?"":
            <p style={{color: 'black'}}>No data available</p>
          }
        </div>
      );
    }
    return null;
  };

  return (
      <LineChart
        width={900}
        height={500}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        id={step}
      >
      {variant === "total_scans" ?
        <>
          <Line type="monotone" dataKey="uv" stroke="violet" name='No. of Scans' />
          <Line type="monotone" dataKey="pv" stroke="skyblue" name='No. of Shares' />
      
          <Tooltip />
        </>
      :
        <>
          {
            show === "all" ?
            <>
              <Line type="monotone" dataKey="uv" stroke="violet" />
              <Line type="monotone" dataKey="pv" stroke="skyblue" />
              <Tooltip content={<CustomTooltip />} />
            </>
            :
            show === "scan" ?
            <>
              <Line type="monotone" dataKey="uv" stroke="violet" />
              <Tooltip content={<CustomTooltip />} />
            </>
            :
            <>
              <Line type="monotone" dataKey="pv" stroke="skyblue" />
              <Tooltip content={<CustomTooltip />} />
            </>
          }
        </>
      }
      {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
      <XAxis dataKey="name" />
      <YAxis type='number' domain={[100,200,300,400,500,600,700,800,900,1000]}/>
      {/* <Legend verticalAlign="top" height={36} style={{display:"none"}} labelStyle={{ fontSize: 18 }}/> */}
      </LineChart>
      )
}

export default AppLineChart
