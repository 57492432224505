export const steps = [
    {
      target: '#step1',
      title: 'Default User Lists',
      content: 'In this tab you can see the list of users under the default template of your company.',
      disableBeacon: true
    },
    {
      target: '#step2',
      title: 'Add Users',
      content: 'This is the button where you can add users or import multiple users by uploading CSV or XLSX.',
      disableBeacon: true,
      placement: 'left'
    },
    {
      target: '#step3',
      title: 'Other User Lists',
      content: 'In this tab you can see the list of users assigned to different templates or departments under your company.',
      disableBeacon: true
    },
    {
      target: '#step4',
      title: 'Filter Template Buttons',
      content: 'These are the buttons used to change the list of users in different templates or departments in your company.',
      disableBeacon: true
    },
    {
      target: '#step5',
      title: 'Contact List Tab',
      content: 'This is the list of all registered users contacts.',
      disableBeacon: true
    }
  ]