import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  addresscheckbox:null
}

const slice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddressCheckBox: (state, action) => {
      state.addresscheckbox = action.payload
    },
  },
})

export const { setAddressCheckBox } = slice.actions

export default slice.reducer