import React, { useState } from 'react'
import "./styles.scss";
import GetAppIcon from '@mui/icons-material/GetApp';
import AppInvoicePdf from '../AppInvoicePdf';
import ReactToPrint from 'react-to-print';
import { Box, Modal, Typography } from '@mui/material';
import AppButton from '../AppButton';
import AppLoader from '../AppLoader';
import moment from 'moment';

const AppInvoices = ({
    thead, 
    data,
    billingData,
    subscriptionDetails,
    companyDetails
}) => {
    const tableHeader = ["Date","Description","Invoice Total","Status", "Invoice"];
    const [showModal, setShowModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState({});

    let baseUsers
    let numberUsers
    let additionalUsers
    let extraUsers
    let basePlanCost
    let subTotal
    
    
    if(subscriptionDetails[0]?.metadata){
        console.log(subscriptionDetails)
        baseUsers = subscriptionDetails[0].metadata.base_users;
        numberUsers = subscriptionDetails[0].metadata?.number_users ? Number(subscriptionDetails[0].metadata.number_users):0;
        additionalUsers = Math.abs(numberUsers - Number(subscriptionDetails[0].metadata.base_users));
        extraUsers =  numberUsers !== 0? additionalUsers * Number(subscriptionDetails[0].metadata.perUser):0;
        basePlanCost =  extraUsers + Number(subscriptionDetails[0].metadata.perBaseUser);
        subTotal = subscriptionDetails[0]?.metadata?.subtotal ? Math.abs(Number(subscriptionDetails[0].metadata.subtotal)):Math.abs(basePlanCost);
    }

    const handleSelectedInvoice = (data) => {
        setShowLoader(true);
        setSelectedInvoice(data);

        setTimeout(()=>{
            setShowModal(true);
            setShowLoader(false);
        },800)
    }

    // console.log("billingData", billingData)
    // console.log("subscriptionDetails", subscriptionDetails)
    // console.log("data", data)
    // console.log("extraUsers", extraUsers)
    // console.log('subscriptionDetails[0].metadata.gst : ',subscriptionDetails)

    return (
        <div id="step3plan">
            <table className='table_container' border={2}>
                {
                    data ? 
                    <>
                        <div style={{padding:"10px",fontWeight:'bold'}}>Invoices</div>
                        <tr style={{borderTop:"1px solid #e2e2e2"}}>
                            {tableHeader.map((data)=>{
                                return <th>{data}</th>
                            })}
                        </tr>
                        <tbody>
                            {data.map((data, key)=>{
                                return <tr key={data.id}>
                                    <td>{data.date}</td>
                                    <td>{data.plan}</td>
                                    <td>{data.total ? "S$" + data.total : null}</td>
                                    <td>
                                        <div style={{display:"flex",alignItems:'center'}}>
                                        <p>
                                        {data.status}
                                        </p>
                                        <GetAppIcon style={{marginLeft:"5px",color:'rgb(44, 44, 238)'}}/>
                                        </div>
                                    </td>
                                    <td id="step4plan">
                                        <AppButton
                                            buttonText="View Invoice"
                                            style={{margin: 0}}
                                            onClick={() => handleSelectedInvoice(data)}
                                        />
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </>
                    : 
                    <div style={{padding:"10px",fontWeight:'bold'}}>Invoices</div>
                }
                
            </table>    
            <div>
                {/* <p style={{color:"rgb(44, 44, 238)"}}>Load More</p> */}
            </div>

            {
                subscriptionDetails[0]?.metadata ?
                    <Modal
                        open={showModal}
                        onClose={() => { setShowModal(false);}}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                        className="invoice-modal"
                    >
                        <div className='invoice-modal-container'>
                            <div className='invoice-modal-content'>
                                <div className='invoice-modal-header'>
                                    <div className='header-left'>
                                        <Typography variant="h7" className='invoice-header-title'>INVOICE - {selectedInvoice.invoice}</Typography>
                                        <Typography variant="h7" className='invoice-header-plan'>Plan: {selectedInvoice.plan}</Typography>
                                        <Typography variant="h7" className='invoice-header-date'>{selectedInvoice.date}</Typography>
                                    </div>
                                    <div className='header-right'>
                                        {/* <Typography variant="h7" className='invoice-header-print'>Print</Typography>
                                        */}
                                        <AppInvoicePdf selectedInvoice={selectedInvoice} companyDetails={companyDetails} subscriptionDetails={subscriptionDetails} billingData={billingData} />
                                    </div>
                                </div>
                                <div className='invoice-company-info'>
                                    <Typography variant="h7" className='invoice-header-company'>{companyDetails[0].corp_name}</Typography>
                                    { companyDetails[0].metadata.uen && 
                                        <Typography variant="h7" className='invoice-header-order'>{companyDetails[0].metadata.uen}</Typography>
                                    }
                                    <div style={{display: 'flex', marginBottom: 15}}>
                                        <Typography variant="h7" className='invoice-header-website'>{subscriptionDetails[0].metadata.email}</Typography>
                                        { companyDetails[0].metadata.contact &&
                                            <>
                                                <div className="vertical-line"></div>
                                                <Typography variant="h7" className='invoice-header-contact'>{ companyDetails[0].metadata.contact }</Typography>
                                            </>
                                        }
                                    </div>
                                    <hr style={{ margin: 0, padding: 0, borderTop: '1px solid #DBD9D9', marginBottom: 15}}/>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '50%'}}>
                                            <Typography variant="h7" className='invoice-info'>Invoice Number:</Typography>
                                            <Typography variant="h7" className='invoice-info'>Invoice Date:</Typography>
                                            <Typography variant="h7" className='invoice-info'>Due:</Typography>
                                        </div>
                                        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                            <Typography variant="h7" className='invoice-info' style={{float: 'right'}}>{selectedInvoice.invoice}</Typography>
                                            <Typography variant="h7" className='invoice-info' style={{float: 'right'}}>{moment(selectedInvoice.date).format("DD MMMM YYYY")}</Typography>
                                            <Typography variant="h7" className='invoice-info' style={{float: 'right'}}>{moment(subscriptionDetails[0].subscription_exp).format("DD MMMM YYYY")}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className='invoice-breakdown'>
                                    <div className='black-container'><Typography variant="h7" className='invoice-breakdown-title'>Cost Breakdown</Typography></div>
                                    <div className='white-container'>
                                        <Typography variant="h7" className='invoice-breakdown-title'>Plan</Typography>
                                        <Typography variant="h7" className='invoice-breakdown-title'>Cost</Typography>
                                    </div>
                                </div>
                                <div className='transparent-container'>
                                    <div className='transparent-content'>
                                        <Typography variant="h7" className='transparent-content-title'>{selectedInvoice.plan}</Typography>
                                        <Typography variant="h7" className='transparent-content-title'>${Number(subscriptionDetails[0].metadata.amount).toFixed(2)}/year, {numberUsers} users</Typography>
                                    </div>
                                    {/* <hr style={{ margin: 0, padding: 0, borderTop: '1px solid #DBD9D9'}}/>
                                    <div className='transparent-content'>
                                        <Typography variant="h7" className='transparent-content-title'>Cpremium</Typography>
                                        <Typography variant="h7" className='transparent-content-title'>$100/year, 20 users</Typography>
                                    </div> */}
                                </div>
                                <div className='summary-container'>
                                    <div className='summary-content'>
                                        <Typography variant="h7" className='summary-container-title'>Base Plan ({subscriptionDetails[0].metadata.base_users} users)</Typography>
                                        <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.perBaseUser).toFixed(2)}</Typography>
                                    </div>
                                    { numberUsers !== 0 &&
                                        <div className='summary-content'>
                                            <Typography variant="h7" className='summary-container-title'>Additional Users ({additionalUsers} users)</Typography>
                                            <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.perUser).toFixed(2)}</Typography>
                                        </div>
                                    }
                                    <div className='summary-content'>
                                        <Typography variant="h7" className='summary-container-title'>Subtotal</Typography>
                                        <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subTotal).toFixed(2)}</Typography>
                                    </div>
                                    <div className='summary-content'>
                                        <Typography variant="h7" className='summary-container-title'>GST {subscriptionDetails[0].metadata.gst_percentage}%</Typography>
                                        <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.gst).toFixed(2)}</Typography>
                                    </div>
                                    <div className='summary-content'>
                                        <Typography variant="h7" className='summary-container-title-bold'>Total</Typography>
                                        <Typography variant="h7" className='summary-container-title-bold'>SGD${Number(subscriptionDetails[0].metadata.amount).toFixed(2)}</Typography>
                                    </div>
                                </div>
                                <div className='footer-container'>
                                    <div style={{padding: 40, display: 'flex', justifyContent: 'space-between'}}>
                                        <div className='left-footer'>
                                            <Typography variant="h7" className='footer-title-bold' style={{marginBottom: 10}}>{selectedInvoice.plan}</Typography>
                                            <Typography variant="h7" className='footer-title'>Annual billing</Typography>
                                            <Typography variant="h7" className='footer-title'>Renews on {moment(subscriptionDetails[0].subscription_exp).format("DD MMMM YYYY")}</Typography>
                                            <Typography variant="h7" className='footer-title-bold' style={{marginBottom: 30}}>S${Number(selectedInvoice.total).toFixed(2)}</Typography>
                                            {billingData.metadata.address &&
                                                <>
                                                    <Typography variant="h7" className='footer-title-bold' style={{marginBottom: 10}}>Billing Details</Typography>
                                                    <Typography variant="h7" className='footer-title'>123 Street</Typography>
                                                    <Typography variant="h7" className='footer-title'>#01-01</Typography>
                                                    <Typography variant="h7" className='footer-title'>Singapore 123123</Typography>
                                                </>
                                            }
                                        </div>
                                        <div className='right-footer'>
                                            <Typography variant="h7" className='right-footer-title' style={{justifyContent: 'right'}}>Total amount</Typography>
                                            <Typography variant="h7" className='right-footer-title-bold'>SGD${Number(subscriptionDetails[0].metadata.amount).toFixed(2)}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                : null
            }
            
            <AppLoader loader={showLoader} />
        </div>
    )
}

export default AppInvoices;
