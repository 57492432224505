import * as React from "react";
import "./styles.scss";
import { Header } from "../../../layouts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  AppButton,
  AppInput,
  AppModal,
  AppInvoices,
  AppContactUs,
  AppLoader,
  AppDropdown
} from "../../../components";
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import TableViewIcon from "@mui/icons-material/TableView";
import IconButton from '@mui/material/IconButton';
import CloseButton from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import { MD5 } from 'crypto-js';
import { useSelector, useDispatch } from "react-redux";
import { ApiPost } from "../../../services/AxiosInstance";
import { setUserData } from "../../../redux/reducer/User";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { SortablePane, Pane } from 'react-sortable-pane';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import { BorderBottom } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Joyride from 'react-joyride';
import { stepsCompany, stepsPlan, stepsOthers, stepsAccount, stepsSSO } from './walkthroughStepsSettings'
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QRCode from 'qrcode.react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditOffIcon from '@mui/icons-material/EditOff';
import Editor from 'react-simple-wysiwyg';

export default function SettingsPage() {
  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [billingDetails, setBillingDetails] = React.useState([]);
  const [invoiceDetails, setInvioceDetails] = React.useState([]);
  const [companyDetails, setCompanyDetails] = React.useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [succes, setSuccess] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState();
  const [password, setPassword] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [isPassWordValid,setIspasswordValid] = React.useState(false)
  const [isNoPassword,setIsNoPassword] = React.useState(false)
  const [html, setHtml] = React.useState(companyDetails[0]?.metadata?.aboutUs);
  const [content, setContent] = React.useState('');

  const defaultAdminpermission = {
    importUsers: true,
    editTemplate: true,
    viewTemplate: true,
    institutionAccess: true,
    departmentAccess: true,
    manageAdmin: true
  }
  const [formData, setFormData] = React.useState({
    email: "",
    name: "",
    department: [],
    permission: defaultAdminpermission
  });
  const [contactUsModal, setContactUsModal] = React.useState(false)
  const [file,setFile] = React.useState('')
  const [message,setMessage] = React.useState("")
  const [subject,setSubject] = React.useState("")

  const [alertAttr, setAlertAttr] = React.useState({visible: false, message: null, color: null});
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const [metaData, setMetadata] = React.useState({
    name: "",
    position: "",
    office_number: "",
    mobile_number: "",
    fax_number: "",
    email: "",
    status: "",
  });

  const [passwordOptions, setPasswordOptions] = React.useState([
    {
      count: "0",
      field: "fax_number",
      name: "Fax Number"
    },
    {
      count: "1",
      field: "mobile_number",
      name: "Mobile Number"
    },
    {
      count: "2",
      field: "office_number",
      name: "Office Number"
    }
  ])

  const [passwordDefault, setPasswordDefault] = React.useState([
    {
      count: "0",
      field: "fax_number",
      name: "Fax Number"
    },
    {
      count: "1",
      field: "mobile_number",
      name: "Mobile Number"
    },
    {
      count: "2",
      field: "office_number",
      name: "Office Number"
    }
  ])

  const [onSortPassword, setOnSortPassword] = React.useState(false)

  const [isLoading,setIsloading] = React.useState(false)
  const [showLoader,setShowLoader] = React.useState(false)

  const [sortOrderName, setSortOrderName] = React.useState(null);
  const [sortOrderCostCenter, setSortOrderCostCenter] = React.useState(null);
  const [sortOrderNameList, setSortOrderNameList] = React.useState(null);
  const [sortOrderCostCenterList, setSortOrderCostCenterList] = React.useState(null);
  const [searchDeptVal, setSearchDeptVal] = React.useState("")
  const [searchDeptVal2, setSearchDeptVal2] = React.useState("")
  const [departmentVal, setDepartmentVal] = React.useState("")
  const [costCenterVal, setCostCenterVal] = React.useState("")
  const [departmentList, setDepartmentList] = React.useState([]);
  const [departmentList2, setDepartmentList2] = React.useState([]);
  const [departmentListChoice, setDepartmentListChoice] = React.useState([]);
  const [departmentPanes, setDepartmentPanes] = React.useState();
  const [deleteDeptProps, setDeleteDeptProps] = React.useState({visible: false, data:{}})
  const [editDeptProps, setEditDeptProps] = React.useState({visible: false, data:{}})

  const userData = useSelector((state) => state.user.userData);
  const userFilter = userData[0]?.metadata?.filters ? userData[0]?.metadata?.filters :  []
  const role = userData[0].role;

  const plan = userData[0].privilege.plan;
  const corp_id = userData[0].corp_id;

  const [selectedDepartments, setSelectedDepartments] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  const [tourSteps, setTourSteps] = React.useState([]);
  const [runTour, setRunTour] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [tabIndicator,setTabIndicator] = React.useState("company");
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = React.useState(userData[0]?.authentication_keys?.isEnabled);
  const [qrImg, setQrImg] = React.useState(userData[0]?.authentication_keys?.qrImg);
  const [authData, setAuthData] = React.useState();
  const [regeneratedQr, setRegeneratedQr] = React.useState(false);
  const [regeneratedQrImg, setRegeneratedQrImg] = React.useState();
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const [adConfig, setADConfig] = React.useState();
  const [isSSOEnabled, setIsSSOEnabled] = React.useState(false);
  const [isSSOEnabled1, setIsSSOEnabled1] = React.useState(false);
  const [clientId, setClientId] = React.useState();
  const [secretKey, setSecretKey] = React.useState();
  const [manifest, setManifest] = React.useState();
  const fileInputRef = React.useRef(null);
  const [tenantId, setTenantId] = React.useState();
  const [isEditSSO, setIsEditSSO] = React.useState(false);
  const [textToCopy, setTextToCopy] = React.useState('');
  const [redirectURL, setRedirectURL] = React.useState(
    'com.chugo.lexiscard://com.chugo.lexiscard/android/callback'
  );
  const [redirectURLIos, setRedirectURLIos] = React.useState(
    'com.chugo.lexiscard://com.chugo.lexiscard/ios/callback'
  );
  const [hideSSO, setHideSSO] = React.useState(false);
  const [isEditPassword, setIsEditPassword] = React.useState(false);
  const [deptSyncVal, setDeptSyncVal] = React.useState();
  const [defaultDeptSearch, setDefaultDeptSearch] = React.useState([]);
  const [deptInput, setDeptInput] = React.useState('');
  const [selectedDept, setSelectedDept] = React.useState([]);

  const [isEditable, setIsEditable] = React.useState(false);
  const [aboutUs, setAboutUs] = React.useState();
  const [hideEdit, setHideEdit] = React.useState('');

  const [subCompVal, setSubCompVal] = React.useState("")
  const [subCompList, setSubCompList] = React.useState([]);
  const [subCompPanes, setSubCompPanes] = React.useState();
  const [subCompPanesModal, setSubCompPanesModal] = React.useState();
  const [deleteSubCompProps, setDeleteSubCompProps] = React.useState({visible: false, data:{}})
  const [editSubCompProps, setEditSubCompProps] = React.useState({visible: false, data:{}})
  const [addSubCompProps, setAddSubCompProps] = React.useState({visible: false, data: {}})
  const [deleteSubCompDeptProps, setDeleteSubCompDeptProps] = React.useState({visible: false, id: null, departments: [], data:{}})
  const [selectedSubComp, setSelectedSubComp] = React.useState([]);
  const [adminPermissions, setAdminPermissions] = React.useState(defaultAdminpermission);
  const [searchAdminKey, setSearchAdminKey] = React.useState('');
  const [adminList, setAdminList] = React.useState([]);
  const [searchInstitutionKey, setSearchInstitutionKey]= React.useState('');
  const [departmentListFilter, setDepartmentListFilter] = React.useState([]);
  const [subCompListFiilter, setSubCompListFilter] = React.useState([]);

  const [listData, setListData] = React.useState([]);
  const [isFromUsers,  setIsFromUsers] = React.useState(true);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [isAccordionExpanded, setAccordionExpanded] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const firstRef = React.useRef(null);
  const secondRef = React.useRef(null);
  const thirdRef = React.useRef(null);
  const fourthRef = React.useRef(null);
  const fifthRef = React.useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    getCorpDetails();
    getListData();
  }, [succes]);

  React.useEffect(() => {
    // hideSSOTab();
    getBilling();
    getInvoices();
    getSubscriptionDetails();
    getDepartments();
    getSubCompany();
    joyrideSteps(0);
    getADConfig();
  }, []);

  React.useEffect(() => {

    let filteredSubCompList = subCompListFiilter
    let filteredDepartmentList = departmentListFilter

    if(role === 'Admin'){
      if(userFilter){
        filteredSubCompList = subCompList.filter(subComp => 
          userFilter.some(filterItem => filterItem.subCompId === subComp.id)
        );
      
        // Extract unique department IDs from userFilter
        const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];
      
        // Filter departmentList based on uniqueDeptIds
        filteredDepartmentList = departmentList.filter(dept => 
          uniqueDeptIds.includes(dept.id)
        );
      }
      
    }

    const panes = filteredSubCompList.map((item, index) => (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={item?.departments && item?.departments.length > 0 ? <ExpandMoreIcon /> : null}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto'}}>{item.name}</Typography>
    
          {filteredDepartmentList && filteredDepartmentList.length > 0 ?
            <IconButton onClick={(event) => {
              setAddSubCompProps({visible: true, data:item})
              event.stopPropagation()
            }}>
              <AddIcon style={{ fontSize: 20 }}/>
            </IconButton>
          : item?.departments && item.departments.length  > 0 ? <IconButton><CircularProgress size={20}/></IconButton> : null}
          
          <IconButton onClick={(event) => {
            setEditSubCompProps({visible: true, data:item})
            event.stopPropagation()
          }}>
            <EditIcon style={{ fontSize: 20 }}/>
          </IconButton>
    
          <IconButton onClick={(event) => {
            setDeleteSubCompProps({visible: true, data:item})
            event.stopPropagation()
          }} style={{ marginRight: 15 }}>
            <DeleteIcon style={{ fontSize: 20 }}/>
          </IconButton>
    
        </AccordionSummary>
        {item?.departments && item.departments.length > 0 && (
          <AccordionDetails>
            {item.departments
              .filter(deptId => departmentListFilter.some(item => item.id === deptId))
              .map((deptItem, index) => {
                const getDept = filteredDepartmentList.find(item => item.id === deptItem);
                if(getDept){
                  return (
                    <div className="sub_comp_dept_container" key={index} style={{ borderBottom: index === item.departments.length - 1 ? 'none' : '1px solid #ccc' }}>
                      <div className="sub_comp_dept_header" style={{ paddingRight: 10, paddingLeft: 10 }}>
                        <div className="sub_comp_dept_text">
                          <p className="sub_comp_dept_label">{`${getDept?.department_name}${getDept?.cost_center ? ` - ${getDept?.cost_center}` : ''}`}</p>
                        </div>
                        <div className="sub_comp_dept_actions">
                          <IconButton onClick={() => setDeleteSubCompDeptProps({ visible: true, id: item.id, departments: item.departments, data: getDept })}>
                            <DeleteIcon style={{ fontSize: 20 }} />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </AccordionDetails>
        )}
      </Accordion>
    ));
    
    
    setSubCompPanes(panes);
  }, [subCompListFiilter, departmentListFilter]);

  React.useEffect(() => {

    let filteredSubCompList = subCompList
    let filteredDepartmentList = departmentList
    if(role === 'Admin'){
      if(userFilter){
        filteredSubCompList = subCompList.filter(subComp => 
          userFilter.some(filterItem => filterItem.subCompId === subComp.id)
        );
      
        // Extract unique department IDs from userFilter
        const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];
      
        // Filter departmentList based on uniqueDeptIds
        filteredDepartmentList = departmentList.filter(dept => 
          uniqueDeptIds.includes(dept.id)
        );
      }
      
    }

    const panes = filteredSubCompList.map((item, parentIndex) => {

      const subCompDepts = item?.departments
      let existingData = subCompDepts

      if (role === 'Admin' && userFilter) {
        existingData = subCompDepts?.filter((deptId) =>
          userFilter.some((dept) => dept.subCompId === item.id && dept.deptId === deptId)
        );
      }
      
      const matchingDepartmentIds = existingData?.filter((deptId) => filteredDepartmentList.some((dept) => dept.id === deptId)).map((deptId) => deptId) || [];

      let checkSelectAll = false
      if(matchingDepartmentIds && matchingDepartmentIds.length > 0){
        checkSelectAll = matchingDepartmentIds.every((deptId) =>
          selectedDepartments.some((data) => data.subCompId === item.id && data.deptId === deptId)
        );
      }else{
        const newFilter = { subCompId: item.id, deptId: null };

        const filterIndex = selectedDepartments.findIndex((data) => data.subCompId === newFilter.subCompId && data.deptId === newFilter.deptId);
        
        if (filterIndex !== -1) {
          checkSelectAll = true
        }else{
          checkSelectAll = false
        }
      }
      

      return (
        <Accordion key={parentIndex}>
          <AccordionSummary
            expandIcon={matchingDepartmentIds && matchingDepartmentIds.length > 0 ? <ExpandMoreIcon /> : null}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <FormControlLabel
              label={item.label}
              control={
                <Checkbox
                    checked={checkSelectAll}
                    onChange={() => handleParentChange(item)}
                />
              }
            />

            <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto'}}>{item.name}</Typography>
          </AccordionSummary>
          {
            matchingDepartmentIds && matchingDepartmentIds.length > 0 ?
            <AccordionDetails style={{marginTop: '-20px'}}>
              {matchingDepartmentIds.map((deptItem, index) => {
                const getDept = filteredDepartmentList.find(item => item.id === deptItem);

                if(getDept){
                  return (
                    <div className="sub_comp_dept_container" key={index} style={{ marginTop: 10, borderBottom: index === matchingDepartmentIds.length - 1 ? 'none' : '1px solid #ccc' }}>
                      <div className="sub_comp_dept_header" style={{ paddingRight: 10, paddingLeft: 10 }}>
                        <div className="sub_comp_dept_text">
                          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            <FormControlLabel
                              key={index}
                              label={`${getDept?.department_name}${getDept?.cost_center ? ` - ${getDept?.cost_center}` : ''}`}
                              style={{ marginTop: -10, borderWidth: 1, borderColor: 'red' }}
                              control={
                                <Checkbox
                                  checked={selectedDepartments.some(data => data.subCompId === item.id && data.deptId === getDept.id)}
                                  onChange={() => handleChildChange(item.id, getDept.id)}
                                />
                              }
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                  );
                }else{
                  return null;
                }
                
              })}
            </AccordionDetails> :  null
          }
          
        </Accordion>
      )
    });

    setSubCompPanesModal(panes);
  }, [selectedDepartments]);

  React.useEffect(() => {

    let filteredSubCompList = subCompList
    let filteredDepartmentList = departmentList2
    if(role === 'Admin'){
      if(userFilter){
        filteredSubCompList = subCompList.filter(subComp => 
          userFilter.some(filterItem => filterItem.subCompId === subComp.id)
        );
      
        // Extract unique department IDs from userFilter
        const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];
      
        // Filter departmentList based on uniqueDeptIds
        filteredDepartmentList = departmentList2.filter(dept => 
          uniqueDeptIds.includes(dept.id)
        );
      }
      
    }

    const deptPanes = filteredDepartmentList.map((item, index) => (
      <div className="admin_container" key={index}>
        <div className="admin_header" style={{paddingRight: 25, paddingLeft: 10}}>
          <table style={{width: '80%'}}>
            <tbody>
              <tr>
                <td className="admin_labels" style={{textAlign: 'left', border: 'none', width: '50%'}}>{item.department_name}</td>
                <td className="admin_labels" style={{textAlign: 'left', border: 'none'}}>{item.cost_center && `${item.cost_center}`}</td>
              </tr>
            </tbody>
          </table>
          <div className="admin_actions">
            <IconButton
              onClick={() => {
                setEditDeptProps({visible: true, data:item})
              }}
            >
              <EditIcon />
            </IconButton>
            {userData[0].role === 'Owner' || (userData[0].role === 'Admin' && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess)) ? (
              <IconButton
                onClick={() => {
                  setDeleteDeptProps({visible: true, data:item})
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
      </div>
    ));    
    setDepartmentPanes(deptPanes);
  }, [departmentList2]);

  React.useEffect(() => {
    if (
      userData[0]?.metadata?.permission !== undefined &&
      (userData[0]?.metadata?.permission?.manageAdmin === undefined ||
        userData[0].metadata.permission.manageAdmin ||
        userData[0]?.metadata?.permission?.institutionAccess) &&
      userData[0].id !== currentItem?.id
    ) {
      const selectedArray = userFilter?.flatMap((filter) => {
        const matchingSubComp = subCompList?.find(
          (subComp) => subComp?.id === filter?.subCompId && subComp?.departments?.includes(filter?.deptId)
        );
  
        if (matchingSubComp) {
          const subCompInfo = { subCompId: matchingSubComp.id, name: matchingSubComp.name };
          return [
            {
              ...subCompInfo,
              deptId: filter.deptId,
              department_name:
                departmentList.find((dept) => dept.id === filter.deptId)?.department_name || null,
            },
          ];
        } else {
          return [];
        }
      });
  
      setSelectAll(selectedDepartments?.every(Boolean) && selectedDepartments?.length === selectedArray?.length);
    } else {
      const selectedArray = subCompList?.flatMap((item) =>
        item?.departments
          ? item.departments
              .filter(
                (deptId) =>
                  departmentList.some((dept) => dept.id === deptId) &&
                  subCompList.some((subComp) => subComp.id === item.id)
              )
              .map((deptId) => ({
                subCompId: item.id,
                deptId: deptId,
              }))
          : [{ subCompId: item.id, deptId: null }] // If departments don't exist or empty, add a default filter with deptId as null
      );
  
      setSelectAll(selectedDepartments?.every(Boolean) && selectedDepartments?.length === selectedArray?.length);
    }
  }, [selectedDepartments]);
  
  const hideSSOTab = () => {
    if (window.location.href.includes(".asia")) {
      if(!window.location.href.includes("dev-corp")){
        setHideSSO(true)
      }
    }
  }

  const joyrideSteps = async (activeTab) => {
    setSelectedIndex(activeTab)
    
    if(activeTab === 0){
      if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.company){
        setTourSteps(stepsCompany);
        setRunTour(true);
      }
    }else if(activeTab === 1){
      if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.plan){
        setTourSteps(stepsPlan);
        setRunTour(true);
      }
    }else if(activeTab === 2){
      if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.account){
        setTourSteps(stepsAccount);
        setRunTour(true);
      }
    }else if(activeTab === 3){
      if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.sso){
        setTourSteps(stepsSSO);
        setRunTour(true);
      }
    }else{
      if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.others){
        setTourSteps(stepsOthers);
        setRunTour(true);
      }
    }
    
  }

  const reRunWalkthrough = async () => {
    const userMeta = userData[0].metadata;

    const updatedUserData = {
      ...userMeta,
      walkthrough: {
        dashboard: false,
        userlist: false,
        namecard: false,
        namecard_edit: false,
        notification: false,
        company: false,
        plan: false,
        account: false,
        sso:false,
        others: false,
      }
    };

    const data = await ApiPost("/corps/update-corps-user", {
      id: userData[0].id,
      metadata: updatedUserData,
      walkthrough: true
    });

    if(data.status === 200){
      const updatedArray = userData.map(obj => ({
        ...obj,
        metadata: updatedUserData
      }));

      dispatch(setUserData(updatedArray));
      navigate('/dashboard')
    }
  } 

  const updateWalkthrough = async (value) => {
    const userMeta = userData[0].metadata;
    
    const updatedMeta = {
      ...userMeta,
      walkthrough: {
        ...(userMeta.walkthrough || {}),
        [value]: true,
      },
    };

    const data = await ApiPost("/corps/update-corps-user", {
      id: userData[0].id,
      metadata: updatedMeta,
      walkthrough: true
    });

    if(data.status === 200){
      const updatedArray = userData.map(obj => ({
        ...obj,
        metadata: updatedMeta
      }));

      dispatch(setUserData(updatedArray));
      setRunTour(false)
    }
  }

  const switchToTab = async (item) => {
    const userMeta = userData[0].metadata;
    
    if(selectedIndex === 0){
      if(item.action === 'reset'){
        if (!userMeta.walkthrough || !userMeta.walkthrough.company) {
          await updateWalkthrough('company');
        }
      }
    }else if(selectedIndex === 1){
      if(item.action === 'reset'){
        if (!userMeta.walkthrough || !userMeta.walkthrough.plan) {
          await updateWalkthrough('plan');
        }
      }
    }else if(selectedIndex === 2){
      if(item.action === 'reset'){
        if (!userMeta.walkthrough || !userMeta.walkthrough.account) {
          await updateWalkthrough('account');
        }
      }
    }else if(selectedIndex === 3){
      if(item.action === 'reset'){
        if (!userMeta.walkthrough || !userMeta.walkthrough.sso) {
          await updateWalkthrough('sso');
        }
      }
    }else{
      if(item.action === 'reset'){
        if (!userMeta.walkthrough || !userMeta.walkthrough.others) {
          await updateWalkthrough('others');
        }
      }
    }
  };

  const getDepartments = async () => {
    const response = await ApiPost("/corps/get-department", {
      corp_id: corp_id,
    });

    setDepartmentList(response.data.data)
    setDepartmentList2(response.data.data)
    setDepartmentListChoice(response.data.data)
    setDepartmentListFilter(response.data.data)
  }

  const getBilling = async () => {
    try {
      const response = await ApiPost("/corps/get-billing", { corp_id: corp_id });
    setBillingDetails(response.data[0]);
    } catch (error) {
      console.log("error on billing",error )
    }
  };

  const getInvoices = async () => {
    const response = await ApiPost("/corps/get-invoices", { corp_id: corp_id });
    const data = response.data;
    
    if(data.message === "success"){
      
      // const newObject = {id,date,plan,total,status}
      const newData = data.data.map((data, index) => {
        const date = new Date(data.createdon);
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return {
          id: data.id + index,
          date: formattedDate,
          plan: data.metadata.selectedPlan,
          total: data.metadata.amount,
          status: data.status,
          invoice: data.metadata.invoiceNo,
        };
      });
      setInvioceDetails(newData);
    }
  };

  const getCorpDetails = async () => {
    try {
      setIsloading(true)
      const response = await ApiPost("/corps/details", { corp_id: corp_id });
      if (response.data.data.length > 0) {
        setCompanyDetails(response.data.data);
        setAdminList(response.data.data[0]?.users.Admin)
        setAboutUs(response.data.data[0]?.metadata?.aboutUs)

        if (role === 'Admin') {
          let updatedMetadata = userData;
        
          const adminArray = response.data.data[0].users.Admin;
          
          const indexToUpdate = adminArray.findIndex(obj => obj.id === updatedMetadata[0].id);
        
          if (indexToUpdate !== -1) {
            // Create a new object with updated metadata
            const updatedUserData = {
              ...userData,
              [0]: {
                ...userData[0],
                metadata: adminArray[indexToUpdate].metadata,
              },
            };
        
            dispatch(setUserData(updatedUserData));
          } else {
            console.log('User not found.');
          }
        }
        
        

        setIsloading(false)
        if(response.data.data[0]?.metadata?.passwordSetting){
          setPasswordOptions(response.data.data[0]?.metadata?.passwordSetting)
          setPasswordDefault(response.data.data[0]?.metadata?.passwordSetting)
        }
      } else {
        getCorpDetails();
      }
    } catch (err) {
      console.log(err);
      setIsloading(false)
    }
  };

  const getSubscriptionDetails = async () => {
    try {
      const response = await ApiPost("/corps/get-subscription-details", {
        corp_id: corp_id,
      });
      setSubscriptionDetails(response.data);
    } catch (error) {
      console.log("error", error)
    }
  };

  const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const addAdmin = async () => {
    setShowLoader(true);

    if(formData.password && !passwordCheck(formData.password)){
      if(validatePassword(formData.password)){
        if(formData.email && formData.name && validateEmail(formData.email)) {
          const data = await ApiPost("/corps/add-admin-user", {
            user_id: userData[0].id,
            corp_id: corp_id,
            useremail: formData.email,
            password: formData.password,
            metadata: JSON.stringify({ name: formData.name, email: formData.email, department: formData?.department, filters: formData?.filters, permission: formData?.permission }),
            role: "Admin",
          });
  
          setAdminPermissions(defaultAdminpermission)
          setFormData({
            email: "",
            name: "",
            department: [],
            permission: defaultAdminpermission
          })
          setIsFromUsers(true)
          setShowPassword(false)
          if(data.data.message === 'Duplicate Email'){
            setShowModal(!showModal);
            setAlertAttr({visible: true, message: 'Email is already existing in the system.', color: '#b2102f'})
          }else if(data.data.message === 'success'){
            setSuccess(!succes);
            setShowModal(!showModal);
            setAlertAttr({visible: true, message: 'Admin added successfully.', color: '#4caf50'})
          }else{
            setShowModal(!showModal);
            setAlertAttr({visible: true, message: 'Error adding admin.', color: '#b2102f'})
          }        
        }else{
          setAlertAttr({visible: true, message: 'Please enter a correct details.', color: '#b2102f'})
        }
        setIsNoPassword(false);
        setShowLoader(false);
      }else{
        setShowLoader(false);
        setIsNoPassword(true);
        setAlertAttr({visible: true, message: 'Password must contain upper case, number and a special character', color: '#b2102f'});
      }
    }else{
      setAlertAttr({visible: true, message: 'Invalid password format or password is empty.', color: '#b2102f'})
      setIsNoPassword(true);
      setShowLoader(false);
    }
  };

  const deleteAdmin = async () => {
    const data = await ApiPost("/corps/delete-admin-user", {
      id: currentItem.id,
    });
    setSuccess(!succes);
    setShowDelete(false);
    setShowLoader(true);
    setTimeout(()=>{setShowLoader(false)},800);

    setAdminPermissions(defaultAdminpermission)
    if(data.data.message === 'success'){
      setAlertAttr({visible: true, message: 'Admin deleted successfully.', color: '#4caf50'})
    }else{
      setAlertAttr({visible: true, message: 'Error deleting the admin.', color: '#b2102f'})
    }
  };

  const editFormSave = async () => {
    setShowLoader(true);
    if(!isChecked) {
      const { id, metadata } = currentItem

      if(isEditPassword){
        if(validatePassword(userPassword)){
          const data = await ApiPost("/corps/update-corps-user", {
            id: id,
            user_id: userData[0].id,
            metadata: metadata,
            password: userPassword
          });
  
          setAdminPermissions(defaultAdminpermission)
          if(data.data.message === 'success'){
            setShowEdit(false);
            setSuccess(!succes);
            setAlertAttr({visible: true, message: 'Password updated successfully', color: '#4caf50'});
          }else{
            setShowEdit(false);
            setSuccess(!succes);
            setAlertAttr({visible: true, message: 'Error Updating Corp Admin', color: '#b2102f'});
          }
          
          setIsEditPassword(false)
          setIsNoPassword(false);
          setUserPassword("")
        }else{
          setIsNoPassword(true);
          setAlertAttr({visible: true, message: 'Password must contain upper case, number and a special character', color: '#b2102f'});
        }
      }else{
        const data = await ApiPost("/corps/update-corps-user", {
          id: id,
          user_id: userData[0].id,
          metadata: metadata
        });

        if(data.data.message === 'success'){
          setShowEdit(false);
          setSuccess(!succes);
          setAlertAttr({visible: true, message: 'Admin updated successfully', color: '#4caf50'});
        }else{
          setShowEdit(false);
          setSuccess(!succes);
          setAlertAttr({visible: true, message: 'Error Updating Corp Admin', color: '#b2102f'});
        }

        setIsNoPassword(false);
        setUserPassword("")
        setIsEditPassword(false);
      }
      dispatch(setUserData([{ ...userData[0] }]));
      setShowLoader(false);
      
      
    } else {
      if(passWordValidator(password)){
        currentItem.metadata.department = []

        const data = await ApiPost("/corps/transfer-admin-ownership", {
          corp_id: corp_id,
          owner_id: userData[0].id,
          id: currentItem.id,
          metadata: currentItem.metadata,
          password: password,
        });
        dispatch(setUserData([{ ...userData[0], role: "Admin" }]));
        setShowEdit(false);
        setSuccess(!succes);
        setIsChecked(false)
        setAlertAttr({visible: true, message: 'Owner transfered successuflly', color: '#4caf50'});
      }else{
        setIspasswordValid(false)
        setAlertAttr({visible: true, message: 'Password did not match', color: '#b2102f'});
      }
      setShowLoader(false);
    }

  };

  const validatePassword = (input) => {
    const regexUpperCase = /[A-Z]/;
    const regexNumber = /\d/;
    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const hasUpperCase = regexUpperCase.test(input);
    const hasNumber = regexNumber.test(input);
    const hasSpecialChar = regexSpecialChar.test(input);

    return hasUpperCase && hasNumber && hasSpecialChar;
  }


  const passwordCheck = (password) => {
    return /\s/.test(password);
  }

  const passWordValidator = (pass) => {
      const hash = MD5(pass).toString();
      if(hash === userData[0].password){
        setIspasswordValid(true)
        return true
      }
  }

  const current = (item, action) => {
    const department = item.metadata?.filters
      ? item.metadata.filters.filter((filterItem, index, self) =>
          filterItem.deptId !== null || // Keep items with deptId as null
          self.findIndex((item) => item.subCompId === filterItem.subCompId) === index // Keep items with unique subCompId
        )
        .filter((filterItem) =>
          departmentList.some((dept) => dept.id === filterItem.deptId) ||
          subCompList.some((subComp) => subComp.id === filterItem.subCompId)
        )
      : [];

    setSelectedDepartments(department)
    setAdminPermissions(item.metadata?.permission ? item.metadata?.permission : defaultAdminpermission)
    setCurrentItem(item);
    action()
  };

  const hideCardNumber = (atmNumber) => {
    const atmToString = atmNumber.toString();
    const length = atmToString.length;
    return atmNumber
      .toString()
      .replace(atmToString.substring(0, length - 4), "●●●●●●●●");
  };

  const formatAnnualBillingYear = (data) => {
    const date = new Date(data);
    date.setFullYear(date.getFullYear() + 1);
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const handleEditAdmin = (value, type) => {
  
    const updatedMetadata = {
      ...currentItem?.metadata,
      [type]: value,
    };
  
    setCurrentItem({
      ...currentItem,
      metadata: updatedMetadata,
    });
  };

  const handleCloseAlert = () =>{
    setAlertAttr({visible: false, message: null, color: null});
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const toggleCloseEditModal = () => {
    setCurrentItem();
    setShowPassword(false);
    setIsEditPassword(false);
    getCorpDetails();
    setShowEdit(!showEdit);
    setIspasswordValid(true);
    setIsChecked(false);
    setUserPassword("");
    setPassword("");
    setSelectedDepartments([])
    setAdminPermissions(defaultAdminpermission)
  }

  const contactUs = async() =>{
    const formData = new FormData();
    formData.append('email', 'contact@lexiscard.asia');
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('file', file[0]);
    const response = await ApiPost('/corps/contact-us',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setContactUsModal(false)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const items = Array.from(passwordOptions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    const newOrder = items.map((item) => item.count);
    const reorderedData = passwordOptions.map((option) => {
      const newCount = newOrder.indexOf(option.count).toString();
      return {
        ...option,
        count: newCount,
      };
    });
  
    const sortedOptions = reorderedData.sort((a, b) => parseInt(a.count) - parseInt(b.count));
  
    setPasswordOptions(sortedOptions);
  };

  const onSaveSort = async() => {

    let metadata = companyDetails[0]?.metadata
    let updatedMetadata = []

    if(metadata?.passwordSetting){
      updatedMetadata = {
        ...metadata,
        passwordSetting: passwordOptions,
      };
    }else{
      updatedMetadata = {
        ...metadata,
        passwordSetting: passwordOptions
      }
    }

    const data = await ApiPost("/corps/update-password-setting", {
      corp_id: corp_id,
      user_id: userData[0].id,
      metadata: updatedMetadata
    });

    if(data.data.message === 'success'){
      setPasswordOptions(passwordOptions);
      setPasswordDefault(passwordOptions);
      setAlertAttr({visible: true, message: 'Password Setting Updated Successfully', color: '#4caf50'});
      setOnSortPassword(false);
    }else{
      setAlertAttr({visible: true, message: 'Error Updating Password Setting', color: '#b2102f'});
      setOnSortPassword(false);
    }

  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
        ))(({ theme }) => ({
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
          },
  }));

  const getSubCompany = async () => {
    const response = await ApiPost("/corps/get-sub-company", {
      corp_id: corp_id,
    });

    setSubCompList(response.data.data)
    setSubCompListFilter(response.data.data)
  }

  const onAddSubCompany = async () => {
    setShowLoader(true);
  
    if (/^[^\s].*[^\s]$/.test(subCompVal)) {
      const data = await ApiPost("/corps/add-sub-company", {
        corp_id: corp_id,
        name: subCompVal,
        user_id: userData[0].id,
      });
  
      if (data.data.status === 203) {
        setAlertAttr({ visible: true, message: data.data.message, color: '#e3b732' });
      } else if (data.data.status === 200) {
        setAlertAttr({ visible: true, message: 'Sub-company added successfully', color: '#4caf50' });
        getSubCompany();
      } else {
        setAlertAttr({ visible: true, message: data.data.message, color: '#b2102f' });
      }
  
      setSubCompVal("");
    } else {
      setAlertAttr({ visible: true, message: 'Please enter a valid sub-company name', color: '#b2102f' });
    }
  
    setShowLoader(false);
  };

  const onDeleteSubCompany = async (subCompId) => {
    setShowLoader(true);

    const data = await ApiPost("/corps/delete-sub-company", {
      id: subCompId
    });

    if(data.data.message === 'success'){
      setAlertAttr({visible: true, message: 'Sub-company deleted successfully', color: '#4caf50'});
      getSubCompany();
    }else{
      setAlertAttr({visible: true, message: 'Error deleted sub-company', color: '#b2102f'});
    }

    setDeleteSubCompProps({visible: false, data: {}})

    setShowLoader(false);
  }

  // delete sub company dept
  const onDeleteSubCompanyDept = async (subCompDeptId, subCompId, departments) => {
    setShowLoader(true);

    const index = departments.indexOf(subCompDeptId);
    if (index !== -1) {
      departments.splice(index, 1);
    }

    const response = await ApiPost("/corps/update-sub-company", {
      id: subCompId,
      data: departments,
      corp_id: corp_id,
      isEditName: false
    });

    if(response.data.status === 202){
      setAlertAttr({visible: true, message: response.data.message, color: '#e3b732'});
    }else if(response.data.status === 200){
      setAlertAttr({visible: true, message: 'Sub-company updated successfully', color: '#4caf50'});
      await getSubCompany()

    }else{
      setAlertAttr({visible: true, message: response.data.message, color: '#b2102f'});
    }

    setDeleteSubCompDeptProps({visible: false, id: null, departments: [], data:{}});
    setShowLoader(false);
  }

  const onAddDepartment = async () => {
    setShowLoader(true);
  
    if (/^[^\s].*[^\s]$/.test(departmentVal)) {
      const data = await ApiPost("/corps/add-department", {
        corp_id: corp_id,
        name: departmentVal,
        cost: costCenterVal,
        user_id: userData[0].id,
      });
  
      if (data.data.status === 203) {
        setAlertAttr({ visible: true, message: data.data.message, color: '#e3b732' });
      } else if (data.data.status === 200) {
        setAlertAttr({ visible: true, message: 'Department added successfully', color: '#4caf50' });
        getDepartments();
      } else {
        setAlertAttr({ visible: true, message: data.data.message, color: '#b2102f' });
      }
  
      setDepartmentVal("");
      setCostCenterVal("");
    } else {
      setAlertAttr({ visible: true, message: 'Please enter a valid department name', color: '#b2102f' });
    }
  
    await getSubCompany()
    setShowLoader(false);
  };
  

  const deleteDepartment = async (department) => {
    setShowLoader(true);

    const data = await ApiPost("/corps/delete-department", {
      id: department.id
    });

    if(data.data.message === 'success'){
      setAlertAttr({visible: true, message: 'Department deleted successfully', color: '#4caf50'});
      getDepartments();
    }else{
      setAlertAttr({visible: true, message: 'Error deleted department', color: '#b2102f'});
    }

    setDeleteDeptProps({visible: false, data: {}})

    setShowLoader(false);
    await getSubCompany()
  }

  // edit sub company
  const editSubCompany = async (subCompany) => {
    setShowLoader(true);

    if (/^[^\s].*[^\s]$/.test(subCompany.data.name)) {
      const data = await ApiPost("/corps/update-sub-company", {
        id: subCompany.data.id,
        data: subCompany.data.name,
        corp_id: corp_id,
        isEditName: true
      });
      
      if(data.data.status === 202){
        setAlertAttr({visible: true, message: data.data.message, color: '#e3b732'});

      }else if(data.data.status === 200){
        setAlertAttr({visible: true, message: 'Sub-company updated successfully', color: '#4caf50'});
        await getSubCompany()

      }else{
        setAlertAttr({visible: true, message: data.data.message, color: '#b2102f'});
      }

      setShowLoader(false);
      setEditSubCompProps({visible: false, data:{}})
    }else{
      setAlertAttr({ visible: true, message: 'Please enter a valid sub-company name', color: '#b2102f' });
    }

    setShowLoader(false);
  }

  // add department to a sub company
  const addDepartmentToSubCompany = async (data) => {

    setShowLoader(true);

    const response = await ApiPost("/corps/update-sub-company", {
      id: data.id,
      data: data.departments,
      corp_id: corp_id,
      isEditName: false
    });

    if(response.data.status === 202){
      setAlertAttr({visible: true, message: response.data.message, color: '#e3b732'});
    }else if(response.data.status === 200){
      setAlertAttr({visible: true, message: 'Sub-company updated successfully', color: '#4caf50'});
      await getSubCompany()

    }else{
      setAlertAttr({visible: true, message: response.data.message, color: '#b2102f'});
    }

    setSearchDeptVal('');
    setSortOrderName(null);
    setSortOrderCostCenter(null);
    setDepartmentListChoice(departmentList);

    setAddSubCompProps({visible: false, data:{}})
    setShowLoader(false);
  };

  const onCloseAddDepartment = () => {
    setAddSubCompProps({visible: false, data:{}})
    setSearchDeptVal('');
    setSortOrderName(null);
    setSortOrderCostCenter(null);
    setDepartmentListChoice(departmentList);
  }

  const onSelectToAddDepartment = (selectedDept, subCompId, departments) => {

    let deptChecked = []

    if(departments){
      deptChecked = [...departments];
    }

    const check = deptChecked.includes(selectedDept);

    if(!check){
      deptChecked.push(selectedDept);
    } else {
      const index = deptChecked.indexOf(selectedDept);
      if (index !== -1) {
        deptChecked.splice(index, 1);
      }
    }

    setAddSubCompProps((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        departments: deptChecked,
      },
    }));
  }

  // edit department here
  const editDepartment = async (department) => {
    setShowLoader(true);

    if (/^[^\s].*[^\s]$/.test(department.data.department_name)) {
      const data = await ApiPost("/corps/update-department", {
        id: department.data.id,
        name: department.data.department_name,
        cost: department.data.cost_center,
        corp_id: corp_id
      });
      
      if(data.data.status === 202){
        setAlertAttr({visible: true, message: data.data.message, color: '#e3b732'});

      }else if(data.data.status === 200){
        setAlertAttr({visible: true, message: 'Department updated successfully', color: '#4caf50'});
        await getDepartments()

      }else{
        setAlertAttr({visible: true, message: data.data.message, color: '#b2102f'});
      }

      setShowLoader(false);
      setEditDeptProps({visible: false, data:{}})
    } else {
      setAlertAttr({ visible: true, message: 'Please enter a valid department name', color: '#b2102f' });
    }
  
    setShowLoader(false);
    await getSubCompany()
  }

  const handleDepartmentToggle = (subCompId, departmentId) => {

    const hasMatchingPair = selectedDepartments.some(item => item.subCompId === subCompId && item.deptId === departmentId);

    if (hasMatchingPair) {
      const updatedDepartments = selectedDepartments.filter(item => !(item.subCompId === subCompId && item.deptId === departmentId));
    
      setSelectedDepartments(updatedDepartments);
    
      setFormData({...formData, filters: updatedDepartments})
    } else {
      setSelectedDepartments([...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]);
      const metadata = [...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]

      setFormData({...formData, filters: metadata})
    }

    // if (selectedDepartments.includes(departmentId)) {
    //   setSelectedDepartments(selectedDepartments.filter(id => id !== departmentId));
    //   setFormData({...formData, department: selectedDepartments.filter(id => id !== departmentId)})
    // } else {
    //   setSelectedDepartments([...selectedDepartments, departmentId]);
    //   setFormData({...formData, department: [...selectedDepartments, departmentId]})
    // }
  };

  const handleSelectAllToggle = () => {
    let filteredSubCompList = subCompList;
    let filteredDepartmentList = departmentList;

    if (role === 'Admin' && userFilter) {
        filteredSubCompList = subCompList.filter(subComp => 
            userFilter.some(filterItem => filterItem.subCompId === subComp.id)
        );

        const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];

        filteredDepartmentList = departmentList.filter(dept => 
            uniqueDeptIds.includes(dept.id)
        );
    }

    if (selectAll) {
        setSelectedDepartments([]);
        setFormData({ ...formData, filters: [] });
    } else {
        let selectedArray = []
        selectedArray = filteredSubCompList.flatMap(item =>
            item.departments.flatMap(deptId => {
                const dept = filteredDepartmentList.find(d => d.id === deptId);
                if (!dept) return []; // Skip if department not found in filteredDepartmentList
                const alreadyExists = selectedDepartments.some(selectedDept => 
                    selectedDept.subCompId === item.id && selectedDept.deptId === dept.id
                );
                return alreadyExists ? [] : [{
                    subCompId: item.id,
                    deptId: dept.id,
                    subCompName: item.name,
                    deptName: dept.department_name,
                    cost: dept?.cost_center
                }];
            })
        );

        if(role === 'Admin' && userFilter){
          selectedArray = selectedArray.filter(selectedItem =>
            userFilter.some(filterItem =>
                filterItem.subCompId === selectedItem.subCompId && filterItem.deptId === selectedItem.deptId
            )
          );
        }
        

        console.log('selectedArray : ', selectedArray);
        setSelectedDepartments(selectedArray);
        setFormData({ ...formData, filters: selectedArray });
    }
    setSelectAll(!selectAll);
  };

  

  const handleDepartmentToggleEdit = (subCompId, departmentId) => {

    let metadata = currentItem.metadata

    const hasMatchingPair = selectedDepartments.some(item => item.subCompId === subCompId && item.deptId === departmentId);

    if (hasMatchingPair) {
      const updatedDepartments = selectedDepartments.filter(item => !(item.subCompId === subCompId && item.deptId === departmentId));
    
      setSelectedDepartments(updatedDepartments);
    
      metadata['filters'] = updatedDepartments;
    
      setCurrentItem({
        ...currentItem,
        metadata
      });
    } else {
      setSelectedDepartments([...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]);
      metadata['filters'] = [...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]

      setCurrentItem({
        ...currentItem,
        metadata
      })
    }
  };

  const handleSelectAllToggleEdit = () => {
    let metadata = currentItem.metadata;
  
    if (
      userData[0]?.metadata?.permission !== undefined &&
      (userData[0]?.metadata?.permission?.manageAdmin === undefined ||
        userData[0].metadata.permission.manageAdmin ||
        userData[0]?.metadata?.permission?.institutionAccess) &&
      userData[0].id !== currentItem?.id
    ) {
      let selectedArray = [];
  
      if (selectAll) {
        setSelectedDepartments([]);
        metadata['filters'] = [];
  
        setCurrentItem({
          ...currentItem,
          metadata,
        });
      } else {
        const selectedArray = userFilter.flatMap((filter) => {
          const matchingSubComp = subCompList?.find(
            (subComp) => subComp?.id === filter?.subCompId && subComp?.departments?.includes(filter?.deptId)
          );
                
          if (matchingSubComp) {
            const subCompInfo = { subCompId: matchingSubComp.id, name: matchingSubComp.name };
            return [
              {
                ...subCompInfo,
                deptId: filter.deptId,
                department_name:
                  departmentList.find((dept) => dept.id === filter.deptId)?.department_name || null,
              },
            ];
          } else {
            return [];
          }
        });
  
        setSelectedDepartments(selectedArray);
        metadata['filters'] = selectedArray;
  
        setCurrentItem({
          ...currentItem,
          metadata,
        });
      }
      setSelectAll(!selectAll);
    } else {
      let selectedArray = [];
  
      if (selectAll) {
        setSelectedDepartments([]);
        metadata['filters'] = [];
  
        setCurrentItem({
          ...currentItem,
          metadata,
        });
      } else {
        selectedArray = subCompList?.flatMap((item) =>
          item?.departments
            ? item.departments
                .filter(
                  (deptId) =>
                    departmentList.some((dept) => dept.id === deptId) &&
                    subCompList.some((subComp) => subComp.id === item.id)
                )
                .map((deptId) => ({
                  subCompId: item.id,
                  deptId: deptId,
                }))
            : [{ subCompId: item.id, deptId: null }]
        );
  
        setSelectedDepartments(selectedArray);
        metadata['filters'] = selectedArray;
  
        setCurrentItem({
          ...currentItem,
          metadata,
        });
      }
      setSelectAll(!selectAll);
    }
  };

  const handleInputEditChange = (newValue) => {
    setEditDeptProps((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        department_name: newValue,
      },
    }));
  };

  const handleInputEditChangeCost = (newValue) => {
    setEditDeptProps((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        cost_center: newValue,
      },
    }));
  };

  // sub company input edit
  const handleSubCompInputEditChange = (newValue) => {
    setEditSubCompProps((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        name: newValue,
      },
    }));
  };

  const getQrCode = (regenerate = false) => {
    if (regenerate) {
      ApiPost("/corps/two-fa-register", {email: userData[0].useremail})
      .then((regResponse) => {
        const respData = regResponse.data
        if (respData.message === "Success") {
          setRegeneratedQrImg(respData.data[0].qrImg)
          setAuthData(respData.data[0].secret)
          setShowConfirmation(true)
        } else {
          setAlertAttr({visible: true, message: 'Error generating the QR Code.', color: '#b2102f'});
        }
        setTimeout(()=>{setShowLoader(false)},500);
      })
      .catch((error) => {
        setTimeout(()=>{setShowLoader(false)},500);
        setAlertAttr({visible: true, message: 'Secret key registration error', color: '#b2102f'});
        console.log("Secret key registration error: ", error)
      })
    } else {
      setShowLoader(true)
      ApiPost("/corps/two-fa-register", {email: userData[0].useremail})
      .then((regResponse) => {
        const respData = regResponse.data
        if (respData.message === "Success") {
          setQrImg(respData.data[0].qrImg);
          setAuthData(respData.data[0].secret)
          saveTwoFactorAuthSettings(respData.data[0].secret, respData.data[0].qrImg, true)
        } else {
          setAlertAttr({visible: true, message: 'Error generating the QR Code.', color: '#b2102f'});
        }
        setTimeout(()=>{setShowLoader(false)},500);
      })
      .catch((error) => {
        setTimeout(()=>{setShowLoader(false)},500);
        console.log("Secret key registration error: ", error)
        setAlertAttr({visible: true, message: 'Secret key registration error', color: '#b2102f'});
      })
    }
  }

  const handleToggle = async (isChecked) => {
    setIsTwoFactorEnabled(prevState => !prevState);
    if (!userData[0]?.authentication_keys?.qrImg && isChecked) {
      getQrCode()
    } else {
      saveTwoFactorAuthSettings({}, "", false)
    }
  };

  const handleSSOToggle = async (isChecked) => {
    setIsSSOEnabled(prevState => !prevState)
  };

  const saveTwoFactorAuthSettings = (secretKeyData, qrCodeImg, isTrue = false) => {
    setShowLoader(true)
    ApiPost("/corps/two-fa-addAuthKeys", {isEnabled: isTrue, secretData: regeneratedQr ? authData : secretKeyData, qrImg: regeneratedQr ? regeneratedQrImg : qrCodeImg, corpID: userData[0].id})
    .then((response) => {
      const respData = response.data
      if (respData.message === "Success") {
        setShowLoader(false)
        dispatch(setUserData([{ ...userData[0], authentication_keys: respData?.data[0]?.auth_keys}]));
        setQrImg(respData.data[0].auth_keys.qrImg)
        setAlertAttr({visible: true, message: regeneratedQr ? 'Regenerate Successfully!' : 'Saved successfully!', color: '#4caf50'});
        setShowConfirmation(false)
      } else {
        setShowLoader(false)
        setAlertAttr({visible: true, message: 'Error saving your configuration.', color: '#b2102f'});
      }
    })
    .catch((error) => {
      setShowLoader(false)
      console.log("Authentication keys registration error: ", error)
      setAlertAttr({visible: true, message: 'Error saving your configuration.', color: '#b2102f'});
    })
  }

  const getADConfig = async() =>{
    const response = await ApiPost("/get-ad-config",{corp_id:corp_id})
    const config = response.data.data

    setIsSSOEnabled(config?.isEnabled)
    setIsSSOEnabled1(config?.isEnabled)
    setClientId(config?.clientId)
    setSecretKey(config?.secretKey)
    setTenantId(config?.tenantID)
    setManifest(config?.manifest)
    setDefaultDeptSearch(config?.department)
    setDeptSyncVal(config?.department)
    setSelectedDept(config?.department)
    setDeptInput('');
  }

  const updateDeptVal = (value) => {
    setDeptSyncVal(value);
    setSelectedDept(value);
  }

  const addToDeptSync = (value) => {
    setDeptInput(value)
  }

  const handleCopyClick = async (url) => {
    try {
      // Use the Clipboard API to copy the text to the clipboard
      await navigator.clipboard.writeText(url);
      setAlertAttr({visible: true, message: 'Copied to clipboard.', color: '#4caf50'});
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const manifestUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        setManifest(content);
      };

      reader.readAsText(selectedFile);
    }
  };

  const onSaveSSOConfig = async() => {
    setShowLoader(true);

    let deptArray = deptSyncVal

    if (deptInput) {
      const additionalArray = deptInput.split(', ').map(value => value.trim());
    
      if (!deptArray) {
        deptArray = [];
      }
    
      deptArray = [...deptArray, ...additionalArray];
    }

    const metadata = {isEnabled:isSSOEnabled, clientId:clientId, secretKey:secretKey, tenantID:tenantId, manifest:manifest, department:deptArray}

    if(isSSOEnabled){
      if(!clientId || !tenantId || !secretKey || !manifest){
        setShowLoader(false)
        setAlertAttr({visible: true, message: 'Please complete all the configuration IDs.', color: '#e3b732'});
      }else{
        const response = await ApiPost("/save-ad-config",{user_id:userData[0].id, corp_id:corp_id, metadata:metadata})
        setIsEditSSO(false)
        setShowLoader(false)
        setIsSSOEnabled1(isSSOEnabled)

        if(response.data.message === 'success'){
          setAlertAttr({visible: true, message: 'SSO configuration updated successfully.', color: '#4caf50'});
        }else{
          setAlertAttr({visible: true, message: 'Error saving your configuration.', color: '#b2102f'});
        }
      }

    }else{
      const response = await ApiPost("/save-ad-config",{user_id:userData[0].id, corp_id:corp_id, metadata:metadata})
        setIsEditSSO(false)
        setShowLoader(false)
        setIsSSOEnabled1(isSSOEnabled)

        if(response.data.message === 'success'){
          setAlertAttr({visible: true, message: 'SSO configuration updated successfully.', color: '#4caf50'});
        }else{
          setAlertAttr({visible: true, message: 'Error saving your configuration.', color: '#b2102f'});
        }
    }
    await getADConfig();
  }

  const onCancelEditSSO = async () => {
    await getADConfig();
    setIsEditSSO(false)
  }

  const onSyncUsersAD = async() =>{
    setShowLoader(true)
    const response = await ApiPost("/get-ad-config",{corp_id:corp_id})
    const config = response.data.data

    if(config?.isEnabled){
      await adSync(config)
    }

  }

  const adSync = async(config) => {
    const data = await ApiPost("/corps/corp-template", { corp_id: corp_id });
    const templates = data.data;

    const defaultTemp = templates.filter(obj => {
      return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
    });

    let deptSync = deptInput
    let displayValue

    if(deptSyncVal?.length > 0){
      displayValue = deptSyncVal.join(', ');

      if(deptInput){
        deptSync = displayValue+', '+deptInput
      }else{
        deptSync = displayValue
      }
    }

    const response = await ApiPost("/active-directory-sync",{
      clientId: config.clientId,
      clientSecret: config.secretKey,
      tenantId: config.tenantID,
      corp_id: corp_id,
      template_id: defaultTemp[0].id,
      dept: deptSync
    })

    setShowLoader(false);
    setDeptSyncVal('');
    if(response.data.message == "success"){
      setAlertAttr({visible: true, message: 'User sync successfully.', color: '#4caf50'});
    }else if(response.data.message == "Number of users is full."){
      setAlertAttr({visible: true, message: 'User list is full', color: '#e3b732'});
    }else{
      setAlertAttr({visible: true, message: 'Error syncing your user list.', color: '#b2102f'});
    }
  }

  const handleSaveAboutUs = async () => {
    const companyData = companyDetails[0].metadata;
    let newData = {...companyData, aboutUs: aboutUs}
    const response = await ApiPost("/corps/update-about-us",{
      id: companyDetails[0]?.id,
      metadata: newData
    });
    if(response.data.message == "success"){
      setAlertAttr({visible: true, message: 'Company data updated successfully.', color: '#4caf50'});
      setIsEditable(false);
      getCorpDetails();
      setHideEdit(true);
    }else{
      setAlertAttr({visible: true, message: 'Error updating company data.', color: '#b2102f'});
    }
  }

  const handleEditButtonDisplay = () => {
    setIsEditable(true);
  }

  const onTogglePermission = (value, permission) => {


    let metadata = showModal ? formData : currentItem.metadata

    let updatedPermissions = adminPermissions

    if(permission === 'importUsers'){
      updatedPermissions = {
        ...adminPermissions,
        importUsers: !value
      };
    }else{
      if(permission === 'editTemplate'){
        updatedPermissions = {
          ...adminPermissions,
          editTemplate: !value,
          viewTemplate: !value,
        };
      }else{
        if(adminPermissions.editTemplate && permission === 'viewTemplate' && value){
          updatedPermissions = {
            ...adminPermissions,
            editTemplate: !value,
            viewTemplate: !value,
          };
        }else{
          if(permission === 'institutionAccess'){
            updatedPermissions = {
              ...adminPermissions,
              institutionAccess: !value,
              departmentAccess: !value,
              manageAdmin: !value
            };
          }else{
            if(adminPermissions.departmentAccess && permission === 'departmentAccess' && value){
              updatedPermissions = {
                ...adminPermissions,
                institutionAccess: !value,
                departmentAccess: !value,
                manageAdmin: !value,
              };
            }else{
              if(permission === 'manageAdmin'){
                if(adminPermissions.institutionAccess && value){
                  updatedPermissions = {
                    ...adminPermissions,
                    manageAdmin: !value,
                  };
                }else{
                  updatedPermissions = {
                    ...adminPermissions,
                    institutionAccess: !value,
                    manageAdmin: !value,
                  };
                }
              }else{
                updatedPermissions = {
                  ...adminPermissions,
                  [permission]: !value,
                };
              }
            }
          }
        }
      }
    }
    
    
    setAdminPermissions(updatedPermissions)

    metadata['permission'] = updatedPermissions;
    
    if(showModal){
      setCurrentItem({
        ...formData,
        metadata
      });
    }else{
      setCurrentItem({
        ...currentItem,
        metadata
      });
    }
    
  };

  let filteredSubCompList = [];

  if (role === "Admin" && userData[0]?.metadata?.permission?.institutionAccess) {
    const result = userFilter.flatMap(filter => {
      const matchingSubComp = subCompList?.find(subComp =>
        subComp.id === filter?.subCompId && subComp?.departments?.includes(filter?.deptId)
      );
    
      if (matchingSubComp) {
        const subCompInfo = { subCompId: matchingSubComp.id, name: matchingSubComp.name };
        return [{
          ...subCompInfo,
          deptId: filter.deptId,
          department_name: departmentList.find(dept => dept.id === filter.deptId)?.department_name || null,
        }];
      } else {
        return [];
      }
    });
  
  }

  const handleSelectToggle = (event) => {
    setIsFromUsers(!isFromUsers)
  }

  const getListData = async() =>{
    const data = await ApiPost("/corps/user-lists",{
      corp_id: corp_id,
      limit: 10,
      status: 'Main',
      keyValue: 'namecard_status',
    })
    if(data?.data?.data){

      const newListUsers = data?.data.data.users.map(item => {

        const rowData = {
          user_id: item.id,
          name: item.metadata[0].front[0].name,
          email: item.metadata[0].front[0].email
        };
        return rowData;
      });

      setListData(newListUsers)
    }
  }

  const selectUser = (data) => {
    setFormData({ ...formData, name: data.name, email: data.email })
  }

  const searchMainUser = async (event, value) => {

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      const result = await ApiPost("/corps/user-lists-search", {
        corp_id: corp_id,
        page: 1,
        search: value
      });
  
      if (result?.data?.data?.users) {
        const newListUsers = result?.data.data.users.map(item => {

          const rowData = {
            user_id: item.id,
            name: item.metadata[0].front[0].name,
            email: item.metadata[0].front[0].email
          };
          return rowData;
        });
  
        setListData(newListUsers)
      }
    }, 1500);

    setTimeoutId(newTimeoutId);
  }

  const handleParentChange = (item) => {

    let filteredDepartmentList = departmentList;
    const itemDepartments = item?.departments?.filter((deptId) => filteredDepartmentList.some((dept) => dept.id === deptId)).map((deptId) => deptId) || [];

    if(itemDepartments && itemDepartments.length > 0){
      const checkSelectAll = itemDepartments.every((deptId) =>
        selectedDepartments.some((data) => data.subCompId === item.id && data.deptId === deptId)
      );
    
      const selectAllDepartment = itemDepartments.map((deptItem, index) => {
        const getDept = filteredDepartmentList.find((item) => item.id === deptItem);
    
        if (getDept) {
          if(showEdit){
            if (checkSelectAll) {
              setSelectedDepartments((prevSelected) =>
                prevSelected.filter(
                  (data) => !(data.subCompId === item.id)
                )
              );

              setCurrentItem((prevFormData) => ({
                ...prevFormData,
                metadata: {
                  ...prevFormData.metadata,
                  filters: prevFormData?.metadata?.filters.filter(
                    (data) => !(data.subCompId === item.id)
                  ),
                },
              }));
            } else {
              const newDepartment = { subCompId: item.id, deptId: getDept.id };

              if (!selectedDepartments.some((data) => data.subCompId === newDepartment.subCompId && data.deptId === newDepartment.deptId)) {
                setSelectedDepartments((prevSelected) => [...prevSelected, newDepartment]);
              }

              setCurrentItem((prevFormData) => {
                const filtersArray = Array.isArray(prevFormData?.metadata?.filters) ? prevFormData?.metadata?.filters : [];
                
                const isDuplicate = filtersArray.some(
                  (data) => data.subCompId === newDepartment.subCompId && data.deptId === newDepartment.deptId
                );
              
                if (!isDuplicate) {
                  return {
                    ...prevFormData,
                    metadata: {
                      ...prevFormData?.metadata,
                      filters: [...filtersArray, newDepartment]
                    }
                  };
                }
              
                return prevFormData;
              });          
            }
          }else{
            if (checkSelectAll) {
              setSelectedDepartments((prevSelected) =>
                prevSelected.filter(
                  (data) => !(data.subCompId === item.id)
                )
              );
    
              setFormData((prevFormData) => ({
                ...prevFormData,
                filters: selectedDepartments.filter(
                  (data) => !(data.subCompId === item.id)
                ),
              }));
            } else {  
              const newDepartment = { subCompId: item.id, deptId: getDept.id };

              if (!selectedDepartments.some((data) => data.subCompId === newDepartment.subCompId && data.deptId === newDepartment.deptId)) {
                setSelectedDepartments((prevSelected) => [...prevSelected, newDepartment]);
              }

              setFormData((prevFormData) => {
                const filtersArray = Array.isArray(prevFormData?.filters) ? prevFormData.filters : [];
                
                const isDuplicate = filtersArray.some(
                  (data) => data.subCompId === newDepartment.subCompId && data.deptId === newDepartment.deptId
                );
              
                if (!isDuplicate) {
                  return {
                    ...prevFormData,
                    filters: [...filtersArray, newDepartment],
                  };
                }

                return prevFormData;
              });
            }
          }
        }
      });
    }else{
      const newFilter = { subCompId: item.id, deptId: null };

      // Check if the filter already exists
      const filterIndex = selectedDepartments.findIndex((data) => data.subCompId === newFilter.subCompId && data.deptId === newFilter.deptId);
      
      if (filterIndex !== -1) {
        setSelectedDepartments((prevSelected) => prevSelected.filter((_, index) => index !== filterIndex));
        
        if (showEdit) {
          setCurrentItem((prevFormData) => ({
            ...prevFormData,
            metadata: {
              ...prevFormData.metadata,
              filters: prevFormData?.metadata?.filters.filter((_, index) => index !== filterIndex),
            },
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            filters: prevFormData.filters.filter((_, index) => index !== filterIndex),
          }));
        }
      } else {
        setSelectedDepartments((prevSelected) => [...prevSelected, newFilter]);
        if (showEdit) {
          setCurrentItem((prevFormData) => ({
            ...prevFormData,
            metadata: {
              ...prevFormData.metadata,
              filters: [...(prevFormData?.metadata?.filters || []), newFilter],
            },
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            filters: [...(prevFormData.filters || []), newFilter],
          }));
        }
      }
    }
    
  };
  
  const handleChildChange = (subCompId, departmentId) => {

    const hasMatchingPair = selectedDepartments.some(item => item.subCompId === subCompId && item.deptId === departmentId);

    if(showEdit){
      let metadata = currentItem.metadata

      if (hasMatchingPair) {
        const updatedDepartments = selectedDepartments.filter(item => !(item.subCompId === subCompId && item.deptId === departmentId));
        metadata['filters'] = updatedDepartments;

        setSelectedDepartments(updatedDepartments);      
        setCurrentItem({...currentItem, metadata});
      } else {
        metadata['filters'] = [...selectedDepartments, {subCompId: subCompId, deptId: departmentId}];

        setSelectedDepartments([...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]);
        setCurrentItem({...currentItem, metadata})
      }
    }else{
      if (hasMatchingPair) {
        const updatedDepartments = selectedDepartments.filter(item => !(item.subCompId === subCompId && item.deptId === departmentId));
      
        setSelectedDepartments(updatedDepartments);
        setFormData({...formData, filters: updatedDepartments})
      } else {
        setSelectedDepartments([...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]);
        const metadata = [...selectedDepartments, {subCompId: subCompId, deptId: departmentId}]
  
        setFormData({...formData, filters: metadata})
      }
    }
    
  };

  const handleAccordClick = () => {
    
    const allowExpand = false;

    if (allowExpand) {
      setAccordionExpanded(!isAccordionExpanded);
    }
  };
  const [tableCode, setTableCode] = React.useState('<table><tr><td>Item</td><td>Item</td></tr></table>')

  const handleInsertTable = () => {
    // const tableCode = '<table><tr><td></td><td></td></tr></table>';
    setTableCode('<table style="width: 100%"><tr style=""><td style="word-break: break-all; width: 45%; text-align: left; vertical-align: top; height: 50%;">Item</td><td style="word-break: break-all; width: 45%; text-align: left; vertical-align: top; height: 50%;">Item</td></tr></table>');
    setHtml(aboutUs + tableCode); // Update editor content with HTML
    // setAboutUs(html)
    setAboutUs(aboutUs + tableCode)
  };

  const handleContentChange = (e) => {
    setHtml(e.target.value)
    setAboutUs(e.target.value);
  };

  const scrollToRef = (event, ref) => {
    const clickedTabButton = event.currentTarget;


    document.querySelectorAll('.tab_others').forEach(tab => {
      tab.classList.remove('tab_others_active');
    });

    clickedTabButton.classList.add('tab_others_active');

    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const onSearchDept = (value) => {
    setSearchDeptVal(value);

    if(value){
      const filteredDepartments = departmentList.filter(dept =>
        dept.department_name.toLowerCase().includes(value.toLowerCase()) || 
        (dept.cost_center && dept.cost_center.toLowerCase().includes(value.toLowerCase()))
      );

      setDepartmentListChoice(filteredDepartments);
    }else{
      setDepartmentListChoice(departmentList);
    }
  }

  const onSearchDept2 = (value) => {
    setSearchDeptVal2(value);

    if(value){
      const filteredDepartments = departmentList.filter(dept =>
        dept.department_name.toLowerCase().includes(value.toLowerCase()) || 
        (dept.cost_center && dept.cost_center.toLowerCase().includes(value.toLowerCase()))
      );

      setDepartmentList2(filteredDepartments);
    }else{
      setDepartmentList2(departmentList);
    }
  }

  const handleSortList = (field) => {
    let newSortOrder = field === 'department_name' ? sortOrderNameList : sortOrderCostCenterList;
    if (!newSortOrder || newSortOrder === 'desc') {
      newSortOrder = 'asc';
    } else {
      newSortOrder = 'desc';
    }

    // Set the sorting order for the specified field
    if (field === 'department_name') {
      setSortOrderNameList(newSortOrder);
    } else {
      setSortOrderCostCenterList(newSortOrder);
    }

    // Implement sorting logic for departmentListChoice based on the field
    let sortedList = [...departmentList2];
    if (field === 'department_name') {
      sortedList.sort((a, b) => {
        if (newSortOrder === 'asc') {
          return a.department_name.localeCompare(b.department_name);
        } else {
          return b.department_name.localeCompare(a.department_name);
        }
      });
    } else if (field === 'cost_center') {
      sortedList.sort((a, b) => {
        if (newSortOrder === 'asc') {
          return (a.cost_center || '').localeCompare(b.cost_center || '');
        } else {
          return (b.cost_center || '').localeCompare(a.cost_center || '');
        }
      });
    }
    setDepartmentList2(sortedList);
  };

  const handleSort = (field) => {
    // Determine the sorting order for the specified field
    let newSortOrder = field === 'department_name' ? sortOrderName : sortOrderCostCenter;
    if (!newSortOrder || newSortOrder === 'desc') {
      newSortOrder = 'asc';
    } else {
      newSortOrder = 'desc';
    }

    // Set the sorting order for the specified field
    if (field === 'department_name') {
      setSortOrderName(newSortOrder);
    } else {
      setSortOrderCostCenter(newSortOrder);
    }

    // Implement sorting logic for departmentListChoice based on the field
    let sortedList = [...departmentListChoice];
    if (field === 'department_name') {
      sortedList.sort((a, b) => {
        if (newSortOrder === 'asc') {
          return a.department_name.localeCompare(b.department_name);
        } else {
          return b.department_name.localeCompare(a.department_name);
        }
      });
    } else if (field === 'cost_center') {
      sortedList.sort((a, b) => {
        if (newSortOrder === 'asc') {
          return (a.cost_center || '').localeCompare(b.cost_center || '');
        } else {
          return (b.cost_center || '').localeCompare(a.cost_center || '');
        }
      });
    }
    setDepartmentListChoice(sortedList);
  };

  const onSearchAdmin = (val) => {
    setSearchAdminKey(val);
    let filteredData = companyDetails[0]?.users.Admin;
  
    if (val) {
      filteredData = filteredData.filter((item) =>
        item.useremail.toLowerCase().includes(val.toLowerCase()) ||
        item.metadata.name.toLowerCase().includes(val.toLowerCase())
      );
    }
  
    setAdminList(filteredData);
  };
  
  

  const onSearchInstitution = (val) => {
    setSearchInstitutionKey(val);

    let filteredSubCompList = subCompList;
    let filteredDepartmentList = departmentList;

    if (val) {
        const searchTerm = val.toLowerCase();
        filteredDepartmentList = departmentList.filter(dept =>
            dept.department_name.toLowerCase().includes(searchTerm) ||
            (dept.cost_center && dept.cost_center.toLowerCase().includes(searchTerm))
        );

        filteredSubCompList = subCompList.filter(subComp =>
            subComp.name.toLowerCase().includes(searchTerm) ||
            subComp.departments.some(deptId =>
                filteredDepartmentList.some(item => item.id === deptId)
            )
        );
    }

    setSubCompListFilter(filteredSubCompList);
    setDepartmentListFilter(filteredDepartmentList);
  }

  return (
    <>
      <Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)}>
        <Header pageTitle="Settings">
          <Snackbar
            ContentProps={{
              sx: {
                background: alertAttr.color
              }
            }}
            open={alertAttr.visible}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
            anchorOrigin={anchorOrigin}
            message={alertAttr.message}
            action={action}
          />
          <TabList className="tablist">
            <Tab className="tab-1-settings" onClick={() => joyrideSteps(0) }><span id="step1company">Company</span></Tab>
            <Tab className="tab-2-settings" onClick={() => joyrideSteps(1) }><span id="step1plan">Plan & Billing</span></Tab>
            <Tab className="tab-3-settings" onClick={() => joyrideSteps(2) }><span id="step1account">Account</span></Tab>
            { !hideSSO ? <Tab className="tab-4-settings" onClick={() => joyrideSteps(3) }><span id="step1SSO">SSO</span></Tab> : null }
            <Tab className="tab-5-settings" onClick={() => joyrideSteps(4) }><span id="step1others">Others</span></Tab>
          </TabList>
        </Header>
        <TabPanel className="tabpanel">

          {
           isLoading &&
            <div style={{marginTop: 50}}>
              <div>
                  <Skeleton sx={{ height: 190, width: 600 }} animation="wave" variant="rounded" />
              </div>

             <div style={{ marginTop : 10 }}>
                     <Skeleton sx={{ height: 100, width: 600 }} animation="wave" variant="rounded" />
              </div>
            </div>
          }

          {
            !isLoading && 
            <>
              <div className="company_details" id="step2company">
                <div className="company_details_header">
                  <p className="company_details_tag">Company Details</p>
                  <p className="company_details_tagline">
                    <a onClick={() => setContactUsModal(true)} style={{textDecoration: 'none', color: '#2667FF', cursor: 'pointer'}}>Contact us</a> to make changes to your company details
                  </p>
                </div>
                <div className="company_details_content">
                  {companyDetails.length > 0 && 
                    <>
                    <CompanyDetails
                      keyValue="Name"
                      value={companyDetails[0]?.metadata?.name}
                    />
                    <CompanyDetails
                      keyValue="UEN"
                      value={companyDetails[0]?.metadata?.uen}
                    />
                    <CompanyDetails
                      keyValue="Website"
                      value={companyDetails[0]?.metadata?.website}
                    />
                    <CompanyDetails
                      keyValue="About Us"
                      value={ 
                        isEditable ? 
                          <>
                            <IconButton onClick={() => handleInsertTable() } style={{marginTop: 2}}><TableViewIcon/></IconButton>
                            <Editor value={aboutUs} onChange={handleContentChange} html={true} />
                            <IconButton onClick={() => handleSaveAboutUs() } style={{marginTop: 2}}><CheckIcon/></IconButton>
                            <IconButton onClick={() => setIsEditable(false) }  style={{marginTop: 2}}><CloseButton/></IconButton>
                          </> 
                          : <div dangerouslySetInnerHTML={{ __html: companyDetails[0]?.metadata?.aboutUs }} />
                        }
                      icon={
                        <>
                          {/* {isEditable ? 
                            <>
                              <TextField multiline onChange={(event) => setAboutUs(event.target.value) } size="small" defaultValue={companyDetails[0]?.metadata?.aboutUs ? companyDetails[0]?.metadata?.aboutUs : aboutUs}></TextField>
                              <IconButton onClick={() => handleSaveAboutUs() } ><CheckIcon/></IconButton>
                              <IconButton onClick={() => setIsEditable(false) } ><CloseButton/></IconButton>
                            </>
                        : null } */}
                        {!isEditable ? 
                          <IconButton visible={hideEdit} onClick={() => handleEditButtonDisplay() } style={{marginLeft: 5}}><EditIcon/></IconButton>
                        : null}
                        </>
                      }
                    />
                    </>
                  }
                </div>
              </div>
              <div className="admin_main_container" id="step3company" style={{marginBottom: 50}}>
                <AdminDetails
                  label="Admin Users"
                  value={`You may add up to ${userData[0]?.privilege?.adminCount ? userData[0]?.privilege?.adminCount : 2 } admin users`}
                  style={{fontWeight: 'bold'}}
                  icon={
                    <div id="step4company">
                        {
                          userData[0].role === 'Owner' || (userData[0].role === 'Admin' && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess && userData[0]?.metadata?.permission?.manageAdmin === undefined || userData[0]?.metadata?.permission?.manageAdmin)) ?
                            <AppButton
                              buttonText="Add Admin"
                              onClick={() => {
                                setShowModal(true)
                                setSelectedDepartments([])
                                setSelectAll(false)
                              }}
                              style={{
                                margin: 0,
                                opacity:
                                userData[0].privilege.adminCount === undefined ? companyDetails[0]?.users?.Admin?.length === 2 : userData[0].privilege.adminCount ===  companyDetails[0]?.users?.Admin?.length,
                                color: '#FFF'
                              }}
                              disabled={
                                userData[0].privilege.adminCount === undefined ? companyDetails[0]?.users?.Admin?.length === 2 : userData[0].privilege.adminCount ===  companyDetails[0]?.users?.Admin?.length
                              }
                            /> : null
                        }
                    </div>
                  }
                />
                { companyDetails && companyDetails.length > 0 && companyDetails[0].users && companyDetails[0].users.Owner && companyDetails[0].users.Owner[0] && companyDetails[0].users.Owner[0].metadata &&
                  <AdminDetails
                    label={ companyDetails[0].users.Owner[0].metadata.name}
                    value={ companyDetails[0].users.Owner[0].metadata.email}
                    icon={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <>
                          {
                            userData[0].role ==='Owner' ? 
                              <IconButton
                                style={{
                                  marginRight: companyDetails[0].users.Owner[0].id !== userData[0].id ? "0px" : "12px",
                                }}
                                onClick={() => {
                                  current(companyDetails[0].users.Owner[0], setShowEdit(!showEdit));
                                  setFormData({
                                    ...formData,
                                    email: companyDetails[0].users.Owner[0].metadata.name,
                                    name: companyDetails[0].users.Owner[0].metadata.name,
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            : null
                          }
                          <div>
                            <button className="owner_btn">Super Admin</button>
                          </div>
                        </>
                      </div>
                    }
                  /> 
                }

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20, paddingRight: 20}}>
                  <AppInput  
                    style={{width: '100%'}}
                    inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF', marginTop: 15 }} 
                    defaultValue={searchAdminKey} 
                    onChange={(val) => onSearchAdmin(val)}
                    icon={<SearchIcon/>}
                  />
                </div>

                <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden'}}>
                {companyDetails.length > 0 &&
                  adminList &&
                  adminList
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => {
                      // Logic for sorting by access
                      const accessOrder = {
                        "Admin": 1,
                        "Institutional Admin": 2,
                        "Department Admin": 3,
                      };

                      return accessOrder[b?.metadata?.permission?.institutionAccess && b?.metadata?.permission?.departmentAccess ? "Department Admin" : (b?.metadata?.permission?.    institutionAccess ? "Institutional Admin" : "Admin")] - accessOrder[a?.metadata?.permission?.institutionAccess && a?.metadata?.permission?.departmentAccess ? "Department Admin" : (a?.metadata?.permission?.institutionAccess ? "Institutional Admin" : "Admin")];
                    })
                    .map((data) => (
                      <AdminDetails
                        label={data.metadata.name}
                        value={data.metadata.email}
                        icon={
                          <div style={{ display: "flex", alignItems: "center" }}>
                              <>
                                {
                                  userData[0].role ==='Owner' || (userData[0].role === 'Admin' && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess && userData[0]?.metadata?.permission?.manageAdmin === undefined || userData[0]?.metadata?.permission?.manageAdmin)) || data.id === userData[0].id ?
                                    <IconButton
                                      style={{
                                        marginRight: data.id !== userData[0].id ? "0px" : "12px",
                                      }}
                                      onClick={() => {
                                        current(data, setShowEdit(!showEdit));
                                        setFormData({
                                          ...formData,
                                          email: data.metadata.email,
                                          name: data.metadata.name,
                                          filters: data.metadata.filters
                                        });
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  : ''
                                }
                                {
                                  userData[0].role ==='Owner' || data.id !== userData[0].id && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess && userData[0]?.metadata?.permission?.manageAdmin === undefined || userData[0]?.metadata?.permission?.manageAdmin) ?
                                    <IconButton
                                      style={{
                                        marginRight: "12px",
                                      }}
                                      onClick={() => {
                                        current(data, setShowDelete(!showDelete))
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  : ''
                                }
                                
                              </>
                            <button className="admin_btn" style={{backgroundColor: '#E2E2E2', color: '#000'}}>
                              {
                                !data?.metadata?.permission?.institutionAccess && data?.metadata?.permission?.departmentAccess ? 
                                'Department Admin' :
                                data?.metadata?.permission?.institutionAccess && data?.metadata?.permission?.departmentAccess ? 
                                  'Institutional Admin' : 'Admin'
                              }
                            </button>
                          </div>
                        }
                      />
                      ))
                    }
                </div>
              </div>
            </>
          }

        </TabPanel>

        <TabPanel className="tabpanel">
          <div className="planning">
            <div style={{ width: "600px" }} id="step2plan">
              <BillingDetails
                label={`Plan: ${plan}`}
                action="Change Plan"
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "14px" }}>
                      <p>Annual billing</p>
                      <p>
                        Renews on{" "}
                        {formatAnnualBillingYear(
                          subscriptionDetails[0]?.subscription_exp
                        )}
                      </p>
                    </div>
                    <div>
                      <p
                        style={{ fontWeight: "bold" }}
                      >{subscriptionDetails[0]?.metadata ? `S${subscriptionDetails[0]?.metadata?.currencyCode}${subscriptionDetails[0]?.metadata?.amount}` : null}</p>
                    </div>
                  </div>
                }
              />

              <BillingDetails
                label={"Payment Method"}
                action={"Change Payment Method"}
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PaymentOutlinedIcon style={{ padding: 0 }} />
                    </div>

                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "5px",
                      }}
                    >
                      {billingDetails?.metadata?.cardNumber &&
                        hideCardNumber(billingDetails?.metadata?.cardNumber)}
                    </p>
                  </div>
                }
              />

              <BillingDetails
                label={"Billing Details"}
                action={"Edit Details"}
                content={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <label style={{ width: "150px" }}>Email</label>
                      <div>{billingDetails?.metadata?.email}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", fontSize: 14 }}>
                      <label style={{ width: "150px", padding: "10px 0px" }}>
                        Billing Address
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {billingDetails?.metadata?.billing_address1}
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            {
              <AppInvoices data={invoiceDetails} billingData={billingDetails} subscriptionDetails={subscriptionDetails} companyDetails={companyDetails} />
            }
          </div>
        </TabPanel>

        <TabPanel className="tabpanel">
          <div className="company_details" id="step2company">
            <div className="company_details_header">
              <p className="company_details_tag">Two Factor Authentication</p>
            </div>
            <div className="company_details_content">
              <div className="switch-container">
                  <Typography variant="subtitle1" style={{fontSize: 16, fontFamily: 'Inter'}}>{isTwoFactorEnabled !== null ? isTwoFactorEnabled === true ? 'Two factor authentication is enabled' : 'Two factor authentication is disabled' : 'Two factor authentication is disabled'}</Typography>
                  <label className="switch" id="step2account">
                    <input type="checkbox" checked={isTwoFactorEnabled !== null ? isTwoFactorEnabled : false} onChange={(event) => {handleToggle(event.target.checked); setRegeneratedQr(false)}} />
                    <span className="slider round"></span>
                  </label>
                </div>
                {
                  runTour ? <div style={{margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#58608a', padding: 15, marginBottom: 10, flexDirection: 'column'}}>
                    <div style={{color: '#FFF', marginBottom: 5}}>
                      <BootstrapTooltip title="Scan this QR Code using your Google Authenticator App" placement="top">
                        <InfoOutlinedIcon />
                      </BootstrapTooltip> 
                    </div>
                    <div style={{height: 180, width: 180, backgroundColor: '#FFF', display: 'flex', justifyContent: 'center', alignItems: 'center'}} id="step3account">
                      <QRCode value=""/>
                    </div>
                    <span id="step4account">
                      <AppButton
                        buttonText={"Regenerate QR Code"}
                        onClick={() => {getQrCode(true); setRegeneratedQr(true)}}
                      />
                    </span>
                  </div> : null
                }
                {
                  isTwoFactorEnabled !== null && runTour === false ? isTwoFactorEnabled === true ? <div style={{margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#58608a', padding: 15, marginBottom: 10, flexDirection: 'column'}}>
                    <div style={{color: '#FFF', marginBottom: 5}}>
                      <BootstrapTooltip title="Scan this QR Code using your Google Authenticator App" placement="top">
                        <InfoOutlinedIcon />
                      </BootstrapTooltip> 
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: qrImg }} />
                    <AppButton
                      buttonText={"Regenerate QR Code"}
                      onClick={() => {getQrCode(true); setRegeneratedQr(true)}}
                    />
                  </div> : null : null
                }
            </div>
          </div>
        </TabPanel>

        {
          !hideSSO ?
          <TabPanel className="tabpanel">
            <div className="planning">
              {
                isSSOEnabled1 && !isEditSSO ?
                <div style={{ width: "700px", backgroundColor: '#FFF', padding: 16, marginTop: 50, borderRadius: 5}}>
                  <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 10, paddingBottom: 10, alignItems: 'center'}}>
                      <span className="sso-title">Sync Active Directory Users</span>
                      <AppButton
                        buttonText="Sync Users"
                        onClick={() => onSyncUsersAD()}
                        style={{
                          margin: 0,
                          color: '#FFF'
                        }}
                      />
                    </div>
                </div>
                : null
              }


              <div style={{ width: "550px", backgroundColor: '#FFF', padding: 16, marginTop: isSSOEnabled1 && !isEditSSO ? 15 : 20, borderRadius: 5, marginBottom: 50 }}>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 10, alignItems: 'center'}}>
                  <span className="sso-title">Single Sign-On Settings</span>
                  {
                    isEditSSO ?
                    <div style={{display: 'flex'}}>
                      <AppButton
                        buttonText="Cancel"
                        variant='text'
                        onClick={() => onCancelEditSSO()}
                        className='cancel-btn'
                        style={{
                          margin: 0,
                          color: '#FF0000',
                          background: null,
                        }}
                      />
                      <AppButton
                        buttonText="Save"
                        onClick={() => onSaveSSOConfig()}
                        style={{
                          margin: 0,
                          color: '#FFF',
                          background: '#01082e'
                        }}
                      />
                    </div>
                    :
                    <AppButton
                      buttonText="Edit"
                      onClick={() => setIsEditSSO(true)}
                      style={{
                        margin: 0,
                        color: '#FFF'
                      }}
                    />
                  }
                </div>
                <div className="panel-border"></div>
              
                <span className="sso-panel-head">Sync by Department</span>
                {
                  defaultDeptSearch && defaultDeptSearch?.length > 0 ?
                  <>
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      multiple
                      options={defaultDeptSearch?.map((option) => ({label: option, val: option}))}
                      style={{
                        padding: 0,
                        marginBottom: 10,
                        marginTop: 20
                      }}
                      value={selectedDept}
                      onChange={(event, values) => {
                        updateDeptVal(values);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search'
                          }}
                          size="small"
                          onChange={(e) => addToDeptSync(e.target.value)}
                        />
                      )}
                      readOnly={isEditSSO ? false : true}
                      inputValue={deptInput}
                      // defaultValue={selectedDept}
                    />
                  </>
                  :
                  <AppInput 
                    label='' 
                    style={{ width: '100%', marginBottom: 20, marginTop: 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF' }} 
                    defaultValue={deptInput}
                    onChange={(val) => addToDeptSync(val) }
                    disabled={isEditSSO ? false : true}
                  />
                }
                <div className="panel-border"></div>
                
                <span className="sso-panel-head">SSO Toggle</span>
                <div className="sso-switch-container">
                  <span className="sso-field-title">SSO</span>
                  <label className="switch" id="step2account" style={{marginLeft: 50}}>
                    <input type="checkbox" disabled={!isEditSSO} checked={isSSOEnabled ? isSSOEnabled : false} onChange={(event) => {handleSSOToggle(event.target.checked);}} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="panel-border"></div>

                <span className="sso-panel-head">Configuration Required IDs</span>

                <div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <label>
                      Application (client) ID
                    </label>
                    <BootstrapTooltip title={
                      <span>
                          Kindly enter the Application ID specific to the LexisCard application in your directory for configuring your Active Directory. To add or find the Application ID in Microsoft Entra, follow the instructions in <a href="https://learn.microsoft.com/en-us/azure/healthcare-apis/register-application" target="_blank" rel="noopener noreferrer" style={{ color: '#8bb9e3' }}>Microsoft Entra's documentation</a>.
                      </span>
                    } placement="top" style={{ marginLeft: 5, color: '#4a4a4a' }}>
                        <HelpOutlineIcon fontSize="small"/>
                    </BootstrapTooltip>
                  </div>
                  <AppInput
                    label=''
                    style={{ width: '100%', marginBottom: 20 }}
                    inputStyle={{ backgroundColor: '#FFFFFF' }}
                    defaultValue={clientId}
                    onChange={(val) => setClientId(val)}
                    disabled={!isEditSSO}
                  />
                </div>

                <div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <label>
                      Tenant ID
                    </label>
                    <BootstrapTooltip title={
                      <span>
                          Please provide your organization's distinct identifier within Microsoft services. It ensures secure access to your organization's resources and data. To find your Tenant ID, visit <a href="https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant" target="_blank" rel="noopener noreferrer" style={{ color: '#8bb9e3' }}>Microsoft's official documentation</a> for step-by-step instructions.
                      </span>
                    } placement="top" style={{ marginLeft: 5, color: '#4a4a4a'  }}>
                        <HelpOutlineIcon fontSize="small"/>
                    </BootstrapTooltip>
                  </div>
                  <AppInput 
                    label='' 
                    style={{ width: '100%', marginBottom: 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF' }} 
                    defaultValue={tenantId} 
                    onChange={(val) => setTenantId(val) }
                    disabled={!isEditSSO}
                  />
                </div>

                <div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <label>
                      Client Secret
                    </label>
                    <BootstrapTooltip title={
                      <div>
                          <p>
                          Please enter your client secret here. This secret key is crucial for securing your application's access to protected resources. If you need to find or add a client secret on Azure AD, you can check the <a href="https://learn.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app#add-credentials" target="_blank" rel="noopener noreferrer" style={{ color: '#8bb9e3' }}>Certificates & secrets documentation</a>.
                          </p>
                      </div>
                    } placement="top" style={{ marginLeft: 5, color: '#4a4a4a'  }}>
                      <HelpOutlineIcon fontSize="small"/>
                    </BootstrapTooltip>
                  </div>
                  <AppInput 
                    label='' 
                    style={{ width: '100%', marginBottom: 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF' }} 
                    defaultValue={secretKey} 
                    onChange={(val) => setSecretKey(val) }
                    disabled={!isEditSSO}
                  />
                </div>

                <div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <label>
                      Application Manifest JSON (Paste or Upload)
                    </label>
                    <BootstrapTooltip title={
                      <div>
                          <p>
                          Please paste or upload the application manifest here. The application manifest contains a definition of all the attributes of an application object in the Microsoft identity platform. It also serves as a mechanism for updating the application object. For more information on the Application entity and its schema, you can <a href="https://learn.microsoft.com/en-us/entra/identity-platform/reference-app-manifest" target="_blank" rel="noopener noreferrer" style={{ color: '#8bb9e3' }}>reference this guide.</a>.
                          </p>
                      </div>
                    } placement="top" style={{ marginLeft: 5, color: '#4a4a4a'  }}>
                      <HelpOutlineIcon fontSize="small"/>
                    </BootstrapTooltip>
                  </div>
                  <AppInput 
                    label='' 
                    multiline={true}
                    style={{ width: '100%', marginBottom: isEditSSO ? null : 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF'}} 
                    rows={10}
                    defaultValue={manifest} 
                    onChange={(val) => setManifest(val) }
                    disabled={!isEditSSO}
                  />
                  {
                    isEditSSO ?
                    <>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={manifestUpload}
                    />
                    <AppButton
                      buttonText="Upload Manifest"
                      onClick={() => fileInputRef.current.click()}
                      style={{
                        color: '#FFF',
                        height: 37,
                        marginLeft: '77%'
                      }}
                    />
                    </>
                    : null
                  }
                  
                </div>

                <div className="panel-border"></div>

                <span className="sso-panel-head">Send your identity provider these URLs</span>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AppInput 
                    id="redirectURL"
                    label='Redirect URL (Android)' 
                    style={{ width: '80%', marginTop: 20, marginBottom: 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF' }} 
                    defaultValue={redirectURL} 
                    readonly={true}
                  />
                  <AppButton
                    buttonText="Copy"
                    onClick={() => handleCopyClick(redirectURL)}
                    style={{
                      color: '#FFF',
                      width: '18%',
                      height: 37,
                      marginTop: 35,
                      marginLeft: 10
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AppInput 
                    id="redirectURL"
                    label='Redirect URL (IOS)' 
                    style={{ width: '80%', marginTop: 20, marginBottom: 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF' }} 
                    defaultValue={redirectURLIos} 
                    readonly={true}
                  />
                  <AppButton
                    buttonText="Copy"
                    onClick={() => handleCopyClick(redirectURLIos)}
                    style={{
                      color: '#FFF',
                      width: '18%',
                      height: 37,
                      marginTop: 35,
                      marginLeft: 10
                    }}
                  />
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AppInput 
                    label='Audience URL (Entity ID)' 
                    style={{ width: '80%', marginTop: 20, marginBottom: 20 }} 
                    inputStyle={{backgroundColor: '#FFFFFF' }} 
                    defaultValue={''} 
                    onChange={(val) => console.log('TEST :',val)}
                  />
                  <AppButton
                    buttonText="Copy"
                    onClick={() => console.log('click')}
                    style={{
                      color: '#FFF',
                      width: '18%',
                      height: 37,
                      marginTop: 35,
                      marginLeft: 10
                    }}
                  />
                </div>
                <div className="panel-border"></div> */}

              </div>
              
            </div>
          </TabPanel>
          : null

        }

        <TabPanel className="tabpanel_others">

          <div className="tabpanel-container">
            <div className="tabpanel-navigation">
              <div className="tab_others tab_others_active" onClick={(e) => scrollToRef(e, firstRef)}>Walkthrough</div>
              <div className="tab_others" onClick={(e) => scrollToRef(e, secondRef)}>User Password Settings</div>
              <div className="tab_others" onClick={(e) => scrollToRef(e, thirdRef)}>Institution/Organization List</div>
              <div className="tab_others" onClick={(e) => scrollToRef(e, fourthRef)}>Department List</div>
            </div>
            
            <div ref={firstRef} className="company_details_others">
              <div className="company_details_header">
                <div className="company_details_tag" style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 1 }}>Walkthrough</span>
                  <AppButton
                    buttonText="Start"
                    onClick={() => reRunWalkthrough(true)}
                    style={{
                      margin: 0,
                      color: '#FFF'
                    }}
                  />
                </div>
              </div>
            </div>

            <div ref={secondRef} className="company_details_others" id="step2others">
              <div className="company_details_header">
              <div className="company_details_tag" style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ flex: 1 }}>User Password Settings</span>
                <BootstrapTooltip title="Determine the order in which the last 4-digit numbers will be positioned at the end of the user's password." placement="top">
                  <HelpOutlineIcon />
                </BootstrapTooltip>
              </div>
              </div>
              <div className="company_details_content">
                <div style={{ height: '250px', padding: 20}}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {passwordOptions.map((item, index) => (
                          onSortPassword ? (
                            <Draggable key={item.count} draggableId={item.count} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'grab',
                                    background: '#01082e',
                                    padding: 15,
                                    color: '#FFFFFF',
                                    borderRadius: 5,
                                    marginBottom: 10,
                                  }}
                                >
                                  <div style={{ flex: 1, textAlign: 'left' }}>{item.name}</div>
                                  <div style={{ textAlign: 'right' }}>{parseInt(item.count) + 1}</div>
                                </div>
                              )}
                            </Draggable>
                          ) : (
                            <div
                              key={item.count}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                background: 'linear-gradient(90deg, #AB66FF, #8BB9E3)',
                                padding: 15,
                                color: '#FFFFFF',
                                borderRadius: 5,
                                marginBottom: 10,
                              }}
                            >
                              <div style={{ flex: 1, textAlign: 'left' }}>{item.name}</div>
                              <div style={{ textAlign: 'right' }}>{parseInt(item.count) + 1}</div>
                            </div>
                          )
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {
                  onSortPassword ? 
                  <div style={{float: 'right'}}>
                    <AppButton
                      variant='text'
                      buttonText="Cancel"
                      onClick={()=> {setOnSortPassword(false); setPasswordOptions(passwordDefault)} }
                      className='cancel-btn'
                      style={{
                        color: '#FF0000',
                        background: null,
                        marginLeft: 10,
                      }}
                    />
                    <AppButton
                      buttonText="Save Order"
                      onClick={() => onSaveSort()}
                      style={{
                        margin: 0,
                        color: '#FFF',
                      }}
                    />
                  </div>
                  :
                  <AppButton
                    buttonText="Sort Order"
                    onClick={() => setOnSortPassword(true)}
                    style={{
                      marginTop: 10,
                      color: '#FFF',
                      float: 'right'
                    }}
                  />
                }
                </div> 
              </div>
            </div>

            {
              userData[0].role === 'Owner' || (userData[0].role === 'Admin' && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess)) ?
              <div ref={thirdRef} className="company_details_others_dept" id="step3others" style={{marginBottom: 0}}>
                <div className="company_details_header">
                  <div className="company_details_tag" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Institution/Organization List</span>
                    <BootstrapTooltip title="This is a comprehensive list of subsidiary companies operating under your main corporate entity. It facilitates user management within your corporate account by enabling the administration to filter and assign specific departments to each Institution or Organization." placement="top">
                      <HelpOutlineIcon />
                    </BootstrapTooltip>
                  </div>
                </div>
                <div className="company_details_content">
                  <div style={{ display: 'block',}}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20}}> 
                      <AppInput 
                        label='Institution Name' 
                        style={{ width: '80%', marginRight: 14}} 
                        inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF' }} 
                        defaultValue={subCompVal} 
                        onChange={(val) => setSubCompVal(val)}
                      />
                      <AppButton
                        buttonText="Add"
                        onClick={() => onAddSubCompany()}
                        style={{
                          color: '#FFF',
                          width: '20%',
                          height: 40,
                          marginTop: 20,
                        }}
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20, paddingRight: 20}}>
                      <AppInput  
                        style={{width: '100%'}}
                        inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF', marginTop: 15 }} 
                        defaultValue={searchInstitutionKey} 
                        onChange={(val) => onSearchInstitution(val)}
                        icon={<SearchIcon/>}
                      />
                    </div>
                    
                    <div style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden'}}>
                      {subCompPanes}
                    </div>
                  </div>
                </div>
              </div> :  null
            }
            
            {
              userData[0].role === 'Owner' || (userData[0].role === 'Admin' && (userData[0]?.metadata?.permission?.departmentAccess === undefined || userData[0]?.metadata?.permission?.departmentAccess)) ? 
              <div ref={fourthRef} className="company_details_others_dept" id="step4others">
                <div className="company_details_header">
                  <div className="company_details_tag" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Department List</span>
                    <BootstrapTooltip title="This is the list of department in your company. It helps your corp account manage users thru filtering the department by admin." placement="top">
                      <HelpOutlineIcon />
                    </BootstrapTooltip>
                  </div>
                </div>
                <div className="company_details_content">
                  <div style={{ display: 'block',}}>

                  {
                    userData[0].role === 'Owner' || (userData[0].role === 'Admin' && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess)) ?
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 20}}> 
                      <AppInput 
                        label='Department Name' 
                        style={{ width: '50%', marginRight: 14}} 
                        inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF' }} 
                        defaultValue={departmentVal} 
                        onChange={(val) => setDepartmentVal(val)}
                      />
                      <AppInput 
                        label='Cost Center' 
                        style={{ width: '30%', marginRight: 14}} 
                        inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF' }} 
                        defaultValue={costCenterVal} 
                        onChange={(val) => setCostCenterVal(val)}
                      />
                      <AppButton
                        buttonText="Add"
                        onClick={() => onAddDepartment()}
                        style={{
                          color: '#FFF',
                          width: '20%',
                          height: 40,
                          marginTop: 20,
                        }}
                      />
                    </div>
                    : null
                  }

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20, paddingRight: 20}}>
                      <AppInput  
                        style={{width: '100%'}}
                        inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF', marginTop: 15 }} 
                        defaultValue={searchDeptVal2} 
                        onChange={(val) => onSearchDept2(val)}
                        icon={<SearchIcon/>}
                      />
                    </div>

                    <div className="admin_container">
                      <div className="admin_header" style={{paddingRight: 25, paddingLeft: 10}}>
                        <table style={{width: '80%'}}>
                          <tbody>
                          <tr>
                            <td className="admin_labels" style={{ textAlign: 'left', border: 'none', width: '50%', fontWeight: 700, cursor: 'pointer' }} onClick={() => handleSortList('department_name')}>Department Name {sortOrderNameList && (sortOrderNameList === 'asc' ? '▲' : '▼')}</td>
                            <td className="admin_labels" style={{ textAlign: 'left', border: 'none', fontWeight: 700, cursor: 'pointer' }} onClick={() => handleSortList('cost_center')}>Cost Center {sortOrderCostCenterList && (sortOrderCostCenterList === 'asc' ? '▲' : '▼')}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden'}}>
                      {departmentPanes}
                    </div>
                  </div>
                </div>
              </div> : null
            }
          </div>
        </TabPanel>
      </Tabs>

      {/* add admin modal */}
      <AppModal
        showModal={showModal}
        toggleModal={() => {
          setShowPassword(false);
          setIsNoPassword(false)
          setAdminPermissions(defaultAdminpermission)
          setShowModal(!showModal);
          setFormData({
            email: "",
            name: "",
            department: [],
            permission: defaultAdminpermission
          })
        }}
        onClick={() => addAdmin()}
        header="Add Admin"
        functionText={"Add"}
        content={
          <div>
            <div>
              {
                isFromUsers ?
                  <>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Typography variant="subtitle1" style={{marginBottom: 0, fontSize: 14, fontWeight: 'bold'}}>Name</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          onChange={handleSelectToggle}
                          defaultChecked={isFromUsers}
                        />
                        Select From Userlist
                      </Typography>
                    </div>
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable={true}
                      options={listData.map((option) => ({label: option.name, val: {name: option.name, email: option.email}}))}
                      style={{
                        padding: 0,
                        marginBottom: 10
                      }}
                      onChange={(event, value) => {
                        selectUser(value.val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search'
                          }}
                          size="small"
                          onChange={(e) => searchMainUser(e, e.target.value)}
                        />
                      )}
                    />
                  </>
                  :
                  <AppInput
                    label="Name"
                    inputStyle={{ marginBottom: 12 }}
                    onChange={(val) => setFormData({ ...formData, name: val })}
                    checkBoxToggle={handleSelectToggle}
                    checkBoxStatus={isFromUsers}
                    showSaveAs={true}
                    checkBoxLabel={'Select From Userlist'}
                  />
                }
            </div>
            <div>
              <AppInput
                label="Company Email"
                disabled={isFromUsers ? true : false}
                defaultValue={formData?.email}
                inputStyle={{ marginBottom: 12 }}
                onChange={(val) => setFormData({ ...formData, email: val })}
              />
            </div>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <AppInput
                label="Password"
                inputStyle={{ marginBottom: 12, fontSize: '17px', width: '95%', marginRight: 230 }}
                type={showPassword ? "text" : "password"}
                isErrorName={isNoPassword}
                icon={
                    <>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label="toggle password visibility"
                        position="end"
                        style={{marginLeft: 'auto', position: 'absolute', left: '100%'}}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      <BootstrapTooltip style={{color: 'gray', position: 'absolute', left: '110%'}} title="Password must contain upper case, number and a special character." placement="top">
                        <InfoOutlinedIcon />
                      </BootstrapTooltip>
                    </>
                }
                onChange={(val) => setFormData({ ...formData, password: val })}
              />
            </div>
            { userData[0].id !== currentItem?.id && !isChecked || (userData[0]?.metadata?.permission !== undefined || (userData[0]?.metadata?.permission?.manageAdmin === undefined || userData[0].metadata.permission.manageAdmin) || (userData[0]?.metadata?.permission?.institutionAccess)) && userData[0].id !== currentItem?.id ?
            <div>
              {
                role === 'Owner' || (role === 'Admin' && userFilter.length > 0) && subCompList.length > 0 && subCompList.some(subComp => subComp?.departments?.some(deptId => departmentList.some(item => item.id === deptId))) ?
                  <>
                    <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 14, fontWeight: 'bold' }}>Admin Designation</Typography>
                    <Accordion expanded={isAccordionExpanded} onChange={handleAccordClick}>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAllToggle}
                            />
                          }
                        />

                        <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto'}}>Select All</Typography>
                      </AccordionSummary>
                    </Accordion>
                  </>
                  : null
              }
              <div style={{ maxHeight: '250px', overflowY: 'auto'}}>
                {subCompPanesModal}
              </div>
              <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700, marginTop: 20}}>Permissions</Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap', padding: '5px', maxHeight: '120px', overflowY: 'auto' }}>

                <Typography
                  variant="subtitle1"
                  style={{
                    flexBasis: '50%',
                    boxSizing: 'border-box',
                    fontSize: '14px'
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      marginRight: '5px'
                    }}
                    checked={adminPermissions.importUsers !== undefined ? adminPermissions.importUsers : true}
                    onChange={() => onTogglePermission(adminPermissions.importUsers, 'importUsers')}
                  />
                  Import Users
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    flexBasis: '50%',
                    boxSizing: 'border-box',
                    fontSize: '14px'
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      marginRight: '5px'
                    }}
                    checked={adminPermissions.editTemplate !== undefined ? adminPermissions.editTemplate : true}
                    onChange={() => onTogglePermission(adminPermissions.editTemplate, 'editTemplate')}
                  />
                  Edit Template
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    flexBasis: '50%',
                    boxSizing: 'border-box',
                    fontSize: '14px'
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      marginRight: '5px'
                    }}
                    checked={adminPermissions.viewTemplate !== undefined ? adminPermissions.viewTemplate : true}
                    onChange={() => onTogglePermission(adminPermissions.viewTemplate, 'viewTemplate')}
                  />
                  View Template
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    flexBasis: '50%',
                    boxSizing: 'border-box',
                    fontSize: '14px'
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      marginRight: '5px'
                    }}
                    checked={adminPermissions.institutionAccess !== undefined ? adminPermissions.institutionAccess : true}
                    onChange={() => onTogglePermission(adminPermissions.institutionAccess, 'institutionAccess')}
                  />
                  Institution Access
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    flexBasis: '50%',
                    boxSizing: 'border-box',
                    fontSize: '14px'
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      marginRight: '5px'
                    }}
                    checked={adminPermissions.departmentAccess !== undefined ? adminPermissions.departmentAccess : true}
                    onChange={() => onTogglePermission(adminPermissions.departmentAccess, 'departmentAccess')}
                  />
                  Department Access
                </Typography>

                {
                  adminPermissions.institutionAccess ? 
                  <Typography
                    variant="subtitle1"
                    style={{
                      flexBasis: '50%',
                      boxSizing: 'border-box',
                      fontSize: '14px'
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        marginRight: '5px'
                      }}
                      checked={adminPermissions.manageAdmin !== undefined ? adminPermissions.manageAdmin : true}
                      onChange={() => onTogglePermission(adminPermissions.manageAdmin, 'manageAdmin')}
                    />
                    Manage Department Admin
                  </Typography> : null
                }
              </div>
            </div>
            : null
            }
          </div>
        }
      />

      {/* add department to sub-company modal */}
      <AppModal
        showModal={addSubCompProps.visible}
        toggleModal={() => onCloseAddDepartment()}
        onClick={() => addDepartmentToSubCompany(addSubCompProps.data)}
        header="Add Department to Institution/Organization"
        functionText={"Add"}
        content={
          <>
            <AppInput 
              label='Filter Search' 
              inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF' }} 
              defaultValue={searchDeptVal} 
              onChange={(val) => onSearchDept(val)}
            />
            <div className="add_dept_container_modal">
              <div className="add_dept_header_modal">
                <table style={{width: '80%'}}>
                  <tbody>
                  <tr>
                    <td className="add_dept_label_modal" style={{ textAlign: 'left', border: 'none', width: '50%', fontWeight: 700, cursor: 'pointer' }} onClick={() => handleSort('department_name')}>Department Name {sortOrderName && (sortOrderName === 'asc' ? '▲' : '▼')}</td>
                    <td className="add_dept_label_modal" style={{ textAlign: 'left', border: 'none', fontWeight: 700, cursor: 'pointer' }} onClick={() => handleSort('cost_center')}>Cost Center {sortOrderCostCenter && (sortOrderCostCenter === 'asc' ? '▲' : '▼')}</td>
                  </tr>
                  </tbody>
                </table>
                <div className="add_dept_actions_modal">

                </div>
              </div>
            </div>
            <div style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden'}}>
              {departmentListChoice.map((deptItem, index) => (
                <div className="add_dept_container_modal" key={index} style={{ borderBottom: index === departmentListChoice.length - 1 ? 'none' : '1px solid #ccc' }}>
                  <div className="add_dept_header_modal">
                    {/* <div className="add_dept_text_modal">
                      <p className="add_dept_label_modal">{deptItem.department_name} {deptItem?.cost_center ? `- ${deptItem?.cost_center}` : ''}</p>
                    </div> */}
                    <table style={{width: '80%'}}>
                      <tbody>
                        <tr>
                          <td className="add_dept_label_modal" style={{textAlign: 'left', border: 'none', width: '50%'}}>{deptItem.department_name}</td>
                          <td className="add_dept_label_modal" style={{textAlign: 'left', border: 'none'}}>{deptItem.cost_center && `${deptItem.cost_center}`}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="add_dept_actions_modal">
                      <input
                        checked={addSubCompProps?.data?.departments ? addSubCompProps?.data?.departments.includes(deptItem?.id) : false}
                        type="checkbox"
                        onChange={() => onSelectToAddDepartment(deptItem.id, addSubCompProps.data.id, addSubCompProps.data.departments) }
                        style={{ width: '24px', height: '20px' }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        }
      />

      {/* remove admin modal */}
      <AppModal
        showModal={showDelete}
        toggleModal={() => {
          setAdminPermissions(defaultAdminpermission)
          setShowDelete(!showDelete)
        }}
        header="Remove Admin"
        functionText={"Remove"}
        onClick={() => deleteAdmin()}
        content={
          <div>
            <p style={{ fontSize: "12px" }}>Remove the following admin?</p>
            <div className="delete-modal-content">
              <p>{currentItem?.metadata.name}</p>
              <p>{currentItem?.metadata.email}</p>
            </div>
          </div>
        }
      />

      {/* remove sub-company */}
      <AppModal
        showModal={deleteSubCompProps.visible}
        toggleModal={() => setDeleteSubCompProps({visible: false, data:{}})}
        header="Delete Institution/Organization"
        functionText={"Delete"}
        onClick={() => onDeleteSubCompany(deleteSubCompProps.data.id)}
        content={
          <div>
            <p style={{ fontSize: "12px" }}>Delete the following Institution?</p>
            <div className="delete-modal-content">
              <p>{deleteSubCompProps.data.name}</p>
            </div>
          </div>
        }
      />

      {/* remove department */}
      <AppModal
        showModal={deleteDeptProps.visible}
        toggleModal={() => setDeleteDeptProps({visible: false, data:{}})}
        header="Delete Department"
        functionText={"Delete"}
        onClick={() => deleteDepartment(deleteDeptProps.data)}
        content={
          <div>
            <p style={{ fontSize: "12px" }}>Delete the following department?</p>
            <div className="delete-modal-content">
              <p>{deleteDeptProps.data.department_name}</p>
            </div>
          </div>
        }
      />

      {/* remove sub-company department */}
      <AppModal
        showModal={deleteSubCompDeptProps.visible}
        toggleModal={() => setDeleteSubCompDeptProps({visible: false, id: null, departments: [], data:{}})}
        header="Delete added department to Sub-company"
        functionText={"Delete"}
        onClick={() => onDeleteSubCompanyDept(deleteSubCompDeptProps.data.id, deleteSubCompDeptProps.id, deleteSubCompDeptProps.departments)}
        content={
          <div>
            <p style={{ fontSize: "12px" }}>Delete the following department added to a sub-company?</p>
            <div className="delete-modal-content">
              <p>{deleteSubCompDeptProps.data.department_name}</p>
            </div>
          </div>
        }
      />

      {/* edit department */}
      <AppModal
        showModal={editDeptProps.visible}
        toggleModal={() => setEditDeptProps({visible: false, data:{}})}
        header="Edit Department"
        functionText={"Edit"}
        onClick={() => editDepartment(editDeptProps)}
        content={
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <AppInput
              defaultValue={editDeptProps.data.department_name}
              inputStyle={{ marginBottom: 12 }}
              label='Department Name' 
              style={{ width: '60%', marginRight: 14}}
              onChange={handleInputEditChange}
            />
            <AppInput
              defaultValue={editDeptProps?.data?.cost_center}
              inputStyle={{ marginBottom: 12 }}
              label='Cost Center' 
              style={{ width: '40%'}} 
              onChange={handleInputEditChangeCost}
            />
          </div>
        }
      />

      {/* edit sub-company */}
      <AppModal
        showModal={editSubCompProps.visible}
        toggleModal={() => setEditSubCompProps({visible: false, data:{}})}
        header="Edit Institution/Organization"
        functionText={"Edit"}
        onClick={() => editSubCompany(editSubCompProps)}
        content={
          <div>
            <AppInput
              defaultValue={editSubCompProps.data.name}
              inputStyle={{ marginBottom: 12 }}
              onChange={handleSubCompInputEditChange}
            />
          </div>
        }
      />

      {/* edit modal */}
      <AppModal
        showModal={showEdit}
        toggleModal={() => {toggleCloseEditModal()}}
        header="Edit Admin"
        functionText={"Save"}
        onClick={() => editFormSave()}
        content={
          <div>
            <AppInput
              label="Name"
              defaultValue={currentItem?.metadata.name}
              inputStyle={{ marginBottom: 12 }}
              onChange={(val)=> handleEditAdmin(val, 'name')}
            />
            <AppInput
              label="Company Email"
              defaultValue={currentItem?.metadata.email}
              inputStyle={{ marginBottom: 12 }}
              //onChange={(val)=> handleEditAdmin(val, 'email')}
              disabled={true}
            />
            {
              !isChecked ? 
              <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <AppInput
                  label="Password"
                  inputStyle={{marginBottom: 12, fontSize: '17px', width: isEditPassword ? '85%' : '95%', marginRight: 230, backgroundColor: isEditPassword ? '#FFFFFF' : '#ded4d4'}}
                  onChange={(val)=> setUserPassword(val)}
                  isErrorName={isNoPassword}
                  type={showPassword ? "text" : "password"}
                  disabled={!isEditPassword}
                  icon={
                    <>
                      <IconButton
                        onClick={() => isEditPassword ? setIsEditPassword(false) : setIsEditPassword(true) }
                        style={{marginLeft: 'auto', position: 'absolute', left: isEditPassword ? '101%' : '100%'}}
                      >
                        {isEditPassword ?  <EditOffIcon />  : <EditIcon />}
                      </IconButton>
                      {
                        isEditPassword  ?
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                          position="end"
                          style={{marginLeft: 'auto', position: 'absolute', left: '109%'}}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton> :null
                      }
                      
                      <BootstrapTooltip style={{color: 'gray', position: 'absolute', left: isEditPassword ? '120%' : '110%'}} title="Password must contain upper case, number and a special character." placement="top">
                        <InfoOutlinedIcon />
                      </BootstrapTooltip>
                    </>
                  }
                />
              </div>
              : null
            }
            {  (role === 'Owner' && currentItem?.role === 'Admin'  && !isChecked) || (role === 'Admin' && (userData[0]?.metadata?.permission?.institutionAccess === undefined || userData[0]?.metadata?.permission?.institutionAccess)) ?
            <>
              {
                userData[0].id !== currentItem?.id && !isChecked || (userData[0]?.metadata?.permission !== undefined || (userData[0]?.metadata?.permission?.manageAdmin === undefined || userData[0].metadata.permission.manageAdmin) || (userData[0]?.metadata?.permission?.institutionAccess)) && userData[0].id !== currentItem?.id ?
                  <div>
                    {
                      role === 'Owner' || (role === 'Admin' && userFilter.length > 0) && subCompList.length > 0 && subCompList.some(subComp => subComp?.departments?.some(deptId => departmentList.some(item => item.id === deptId))) ?
                        <>
                          <Typography variant="subtitle1" style={{ marginTop: 10, fontSize: 14, fontWeight: 'bold' }}>Admin Designation</Typography>
                          <Accordion expanded={isAccordionExpanded} onChange={handleAccordClick}>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                      checked={selectAll}
                                      onChange={handleSelectAllToggleEdit}
                                  />
                                }
                              />

                              <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto'}}>Select All</Typography>
                            </AccordionSummary>
                          </Accordion>
                        </>
                        : null
                    }
                    <div style={{ maxHeight: '250px', overflowY: 'auto'}}>
                      {subCompPanesModal}
                    </div>
                    <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700}}>Permissions</Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '5px', maxHeight: '120px', overflowY: 'auto' }}>

                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          checked={adminPermissions.importUsers !== undefined ? adminPermissions.importUsers : true}
                          onChange={() => onTogglePermission(adminPermissions.importUsers, 'importUsers')}
                        />
                        Import Users
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          checked={adminPermissions.editTemplate !== undefined ? adminPermissions.editTemplate : true}
                          onChange={() => onTogglePermission(adminPermissions.editTemplate, 'editTemplate')}
                        />
                        Edit Template
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          checked={adminPermissions.viewTemplate !== undefined ? adminPermissions.viewTemplate : true}
                          onChange={() => onTogglePermission(adminPermissions.viewTemplate, 'viewTemplate')}
                        />
                        View Template
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          checked={adminPermissions.institutionAccess !== undefined ? adminPermissions.institutionAccess : true}
                          onChange={() => onTogglePermission(adminPermissions.institutionAccess, 'institutionAccess')}
                        />
                        Institution Access
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          checked={adminPermissions.departmentAccess !== undefined ? adminPermissions.departmentAccess : true}
                          onChange={() => onTogglePermission(adminPermissions.departmentAccess, 'departmentAccess')}
                        />
                        Department Access
                      </Typography>

                      {
                        adminPermissions.institutionAccess ? 
                        <Typography
                          variant="subtitle1"
                          style={{
                            flexBasis: '50%',
                            boxSizing: 'border-box',
                            fontSize: '14px'
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              marginRight: '5px'
                            }}
                            checked={adminPermissions.manageAdmin !== undefined ? adminPermissions.manageAdmin : true}
                            onChange={() => onTogglePermission(adminPermissions.manageAdmin, 'manageAdmin')}
                          />
                          Manage Department Admin
                        </Typography> : null
                      }
                    </div>
                  </div>
                  : null
              }               
            </> : null
            }

            {
              role === 'Owner' && userData[0].id !== currentItem?.id ?
              <div className="checkbox_footer">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    // setSelectedDepartments([]);
                    setUserPassword("")
                    setPassword("")
                  }}
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Transfer Ownership
                </p>
              </div> : null
            }
            {role === 'Owner' && isChecked && (
              <>
                <div>
                  <p style={{ fontSize: "14px", margin: "10px 0" }}>
                    You will be transferring your ownership to this admin. There
                    can only be one owner
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    For security purposes, please re-enter your password below.
                  </p>
                </div>

                <div style={{ marginTop: 15 }}>
                  <AppInput
                    label="Password"
                    inputStyle={{marginBottom: 12}}
                    onChange={(val)=> setPassword(val)}
                    isErrorName={isPassWordValid}
                    type={"password"}
                  />
                  {/* {!isPassWordValid && <p style={{color:'red',fontSize:"12px"}}>wrong password</p> } */}
                </div>
              </>
            )}
          </div>
        }
      />

      {/* regenerate QR Code modal confirmation*/}
      <AppModal
        showModal={showConfirmation}
        toggleModal={() => setShowConfirmation(false)}
        header="Confirmation"
        functionText={"Confirm"}
        onClick={() => saveTwoFactorAuthSettings(authData, qrImg, true)}
        content={
          <div>
            <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 10}}>Are you sure you want to generate a new QR Code?</Typography>
            <Typography variant="subtitle1" style={{fontSize: 12}}>It will replace your old QR Code and you need to delete the old one in your Google Authenticator App and scan this new one.</Typography>
          </div>
        }
        style={{
          width: '40%'
        }}
      />

      <AppContactUs
        showModal={contactUsModal}
        toggleModal={() => setContactUsModal(false)}
        file={file}
        setFile={(val) => setFile(val)}
        setMessage={(value) => setMessage(value)}
        setSubject={(val) => setSubject(val)}
        contactUs={() => contactUs()}
      />

      <AppLoader loader={showLoader} />

      {runTour && (
        <Joyride
          steps={tourSteps}
          continuous={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          showProgress={true}
          run={runTour}
          disableBeacon={false}
          showSkipButton={true}
          hideCloseButton={true}
          callback={(item) => switchToTab(item)}
          styles={{
            options: {
              arrowColor: '#00072d',
              backgroundColor: '#00072d',
              overlayColor: 'rgba(77, 74, 95, 0.4)',
              primaryColor: '#4d4a5f',
              textColor: '#FFFFFF',
              width: 500,
              zIndex: 99999,
            }
          }}
        />
      )}
      
    </>
  );
}

const BillingDetails = ({ label, action, content }) => {
  return (
    <div className="billing_details_container">
      <div className="biling_details_header">
        <p>{label}</p>
        {/* <p style={{ color: "blue", cursor: "pointer", fontSize: "14px", fontWeight: 100 }} onClick={() => console.log(action)}>
          {action}
        </p> */}
      </div>
      <div className="billing_details_content"> {content} </div>
    </div>
  );
};

const CompanyDetails = ({ keyValue, value, icon  }) => {
  return (
    <div className="companyDetails">
      <p className={keyValue === 'About Us' ? 'keys' : 'key'}>{keyValue}</p>
      <div className="company_actions">{icon}</div>
      <p className="value">{value}</p>
      
    </div>
    
  );
};

const AdminDetails = ({ label, value, adminType, icon, style }) => {
  return (
    <div className="admin_container">
      <div className="admin_header">
        <div className="admin_text">
          <p className="admin_labels" style={style}>{label}</p>
          <p className="admin_value">{value}</p>
          <p className="admin_value">{adminType}</p>
        </div>
        <div className="admin_actions">{icon && icon}</div>
      </div>
    </div>
  );
};
