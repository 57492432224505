import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: null,
  userCredits:0,
  disableIndicator:false,
  corpCreds:0,
  alertCreds:false,
  onSyncUsers:false
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setUseCredits: (state,action) =>{
      state.userCredits = action.payload
    },
    setDisableIndicator : (state,action) =>{
      state.disableIndicator = action.payload
    },
    setCorpCreds : (state,action) =>{
      state.corpCreds = action.payload
    },
    setAlertCreds : (state,action) =>{
      state.alertCreds = action.payload
    },
    setOnSyncUsers : (state,action) =>{
      state.onSyncUsers = action.payload
    }
  },
})

export const { setUserData,setUseCredits,setDisableIndicator,setCorpCreds,setAlertCreds,setOnSyncUsers } = slice.actions

export default slice.reducer