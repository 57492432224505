import * as React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reduxStore from './redux/store';
import AppRoutes from './routes';
import AppProvider from './routes/AppProvider';
import "./assets/styles/main.scss";
import { Chart as ChartJS, registerables } from 'chart.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import googleFonts from 'google-fonts';
ChartJS.register(...registerables);

export default function App() {
  const { store, persistor } = reduxStore();
  
  googleFonts.add({
    Montserrat: true,
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router>
            <AppProvider>
              <AppRoutes />
            </AppProvider>
          </Router>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  )
}