import * as React from 'react';
import "./styles.scss";
import { PulseLoader } from 'react-spinners';

const AppLoader = ({
  loader,
  closeLoader,
}) => {
  
  setTimeout(()=>{
      if(loader){
        // closeLoader();
      }
  },[loader])

  return (
    <>
      {
        loader && (
          <div className="modal-overlay">
            <div className="loader-container">
                <PulseLoader color="#AB66FF" margin={8}/>
            </div>
          </div>
        )
      }
    </>
  );
  
}

export default AppLoader;