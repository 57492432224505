import React from 'react'
import "./styles.scss";

const AppLegend = (props) => {

 const {icon,label} = props

  return (
    <div className='legend-container'>
        {icon}
      <p className='legend-label'>{label}</p>
    </div>
  )
}

export default AppLegend
