import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  licenseUserData: null
}

const slice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setLicenseUserData: (state, action) => {
      state.licenseUserData = action.payload
    },
  },
})

export const { setLicenseUserData } = slice.actions

export default slice.reducer