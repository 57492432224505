import * as React from 'react';
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import { Typography, Toolbar, IconButton } from "@mui/material";
import { KeyboardArrowDown, AccountCircle } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { AppDropDownMenu, AppAlertDialog } from '../../components';
import { SignOut } from '../../utilities';

const Header = ({
  pageTitle,
  children
}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const userData = useSelector((state) => state.user.userData);
  const name = userData[0].metadata.name;
  const email = userData[0].metadata.email;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [dialogAlert, setDialogAlert] = React.useState({visible: false, title: 'Alert', message: 'Are you sure want to logout?'});

  const handleSelect = (selected) => {
    setOpen(false);
    
    if(selected === 'Logout'){
      setDialogAlert({...dialogAlert, visible: true});
    }
  }

  const onDropDown = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }
  
  const handleClose = () => {
    setOpen(false);
  }

  const onPositivePress = () => {
    SignOut(dispatch, navigate);
    setDialogAlert({...dialogAlert, visible: false});
  }

  const onNegativePress = () => {
    setDialogAlert({...dialogAlert, visible: false});
  }

  const onDialogAlertClose = () => {
    setDialogAlert({...dialogAlert, visible: false});
  }

  return (
    <div className='headerBody'>
      <Toolbar>
        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
          <Typography variant="h7" noWrap component="div" color="#000000" sx={{fontWeight: 'bold', flex: 1, borderBottomWidth: 1, borderColor: 'red'}}>
            {pageTitle}
          </Typography>
          <AccountCircle style={{marginRight: 5, color: "#666666"}} />
          <Typography variant="h7" noWrap component="div" color="#000000" style={{fontSize: 15}}>
            
            {name?name:email}
          </Typography>
          <IconButton
            onClick={onDropDown}
          >
            <KeyboardArrowDown />
          </IconButton>
          <AppDropDownMenu 
            anchorEl={anchorEl} 
            open={open} 
            handleClose={handleClose} 
            handleSelect={handleSelect}
            DataList={[{menu: 'Logout'}]}
          />
          <AppAlertDialog 
            visible={dialogAlert.visible}  
            title={dialogAlert.title}
            message={dialogAlert.message}
            onPositivePress={onPositivePress}
            onNegativePress={onNegativePress}
            onClose={onDialogAlertClose}
          />
        </div>
      </Toolbar>
      {children}
    </div>
  )
}

export default Header;