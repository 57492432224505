import axios from 'axios';
import { getEnvVars } from './Environment';
import CryptoJS from 'crypto-js';
import { AccessToken } from '../utilities';
const defaultToken = '';

const { apiUrl } = getEnvVars();
const { saveAccessToken, fetchAccessToken } = AccessToken;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'X-Frame-Options': 'DENY',
  },
})

export default axiosInstance;

export function ApiGet(apiEndpoint, timeout=20000){
  apiEndpoint = apiUrl + apiEndpoint;

  const localAccessToken = fetchAccessToken();
  const accessToken = localAccessToken ? localAccessToken : defaultToken;

  return axiosInstance({
    method: 'GET',
    url: apiEndpoint,
    baseUrl: apiUrl,
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export function ApiPost(apiEndpoint, apiData, timeout=20000, otherport){

  if(otherport){
    apiEndpoint = apiEndpoint
  }else{
    apiEndpoint = apiUrl + apiEndpoint;
  }

  const hashedApiData = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(apiData), 'g82us8tgf4cge8c8642qthwaun1zy4of').toString());

  const localAccessToken = fetchAccessToken();
  const accessToken = localAccessToken ? localAccessToken : defaultToken;

  return axiosInstance({
    method: 'POST',
    url: apiEndpoint,
    baseUrl: apiUrl,
    data: {lexc: hashedApiData},
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
