import React from "react";
import Dropzone from "react-dropzone";
import {AppInput, AppModal} from "../";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';



const AppContactUs = (props) => {
    const {contactUs, setSubject, setMessage, setFile, file, showModal, toggleModal } = props;
    return (
        <AppModal 
        showModal={showModal} 
        header="Contact Us" 
        toggleModal={toggleModal}
        onClick={()=>contactUs()}
        content={
          <div className='content-container'> 
            <AppInput label="Subject" inputStyle={{marginBottom: 15}} onChange={(val)=>setSubject(val)}/>  
            <AppInput label="Message" multiline rows={15} onChange={(value)=>setMessage(value)}/>
            
            <div style={{paddingTop:"10px"}}>
              <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload Files(s)</label>
              <Dropzone onDrop={acceptedFiles => {setFile(acceptedFiles);console.log(acceptedFiles[0].name)}}>
                {({getRootProps, getInputProps}) => (
                  <section className='drag_drop_container'>
                    <div {...getRootProps()} className='files_container'>
                      <input {...getInputProps()}/>
                      <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                      <p style={{color:'rgb(20, 110, 228)'}}>{file ? file[0]?.name.substring(0,12) + "..." : "Drag and drop files here or browse" } </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div style={{paddingTop:"10px",color:'gray',fontSize:'12px'}}>
              <p>Supported files: jpg,jpeg,png,pdf</p>
              <p>Max. file size: 5mb</p>
              </div>
            </div>
          </div>
        }
      />
    )
}

export default AppContactUs;