import * as React from 'react';
import "./styles.scss";
import { Header } from '../../../layouts';
import { AppButton, AppContactUs, AppInput, AppModal } from '../../../components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {AppDropdown, AppLegend, AppLineChart, AppDashBoardOptions, AppLoader} from '../../../components';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Dropzone from 'react-dropzone'
import { useSelector, useDispatch } from 'react-redux';
import { setUserData } from '../../../redux/reducer/User';
import { ApiPost } from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import Joyride from 'react-joyride';
import { steps } from './walkthroughSteps';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { setLicenseUserData } from '../../../redux/reducer/LicenseUserData';
import { PropagateLoader } from 'react-spinners';

export default function DashboardPage() {
  
  const userData = useSelector((state)=> state.user.userData);
  const corp_id = userData[0].corp_id;
  const login_id = userData[0].id
  const userLimit = userData[0].privilege.number_users;
  const role = userData[0].role;
  const userFilter = userData[0]?.metadata?.filters ? userData[0]?.metadata?.filters :  []

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false)
  const [showLoader, setShowLoader] = React.useState(false)
  const [dashboardDetails, setDashboardDetails] = React.useState();
  const [subject,setSubject] = React.useState("")
  const [message,setMessage] = React.useState("")
  const [file,setFile] = React.useState('')
  const [lineData, setLineData] = React.useState([]);
  const [lineDataUsers, setLineDataUsers] = React.useState([]);
  const [lineDataTopUsers, setLineDataTopUsers] = React.useState([]);
  const [defaultYear, setDefaultYear] = React.useState((new Date()).getFullYear());
  const [filterUser, setFilterUser] = React.useState();
  const [defaultYearUser, setDefaultYearUser] = React.useState((new Date()).getFullYear());
  const [listData, setListData] = React.useState([]);
  const [allCorpUsers, setAllCorpUsers] = React.useState([]);
  const [filterId, setFilterId] = React.useState();
  const [topUsersToShow, setTopUsersToShow] = React.useState("scan");
  const [tourSteps, setTourSteps] = React.useState([]);
  const [runTour, setRunTour] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [pastQuarters, setPastQuarters] = React.useState([]);
  const [initialSubCompany, setInitialSubCompany] = React.useState([]);
  const [subCompanyList, setSubCompanyList] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const getCurrentYear = () => new Date().getFullYear();

  const generateYearOptions = (numOfYears) => {
    const currentYear = getCurrentYear();
    const options = [];

    for (let i = 0; i <= numOfYears; i++) {  // Change here
      const year = currentYear - i;
      options.push({ value: year, label: year.toString() });
    }

    return options;
  };

  const dashboardCreationYear = new Date(dashboardDetails?.createdon).getFullYear();
  const yearsPassed = getCurrentYear() - dashboardCreationYear;

  const dropdownOptionsOnLoad = generateYearOptions(5);
  const dropdownOptions = generateYearOptions(yearsPassed);

  React.useEffect(()=>{
     getDashBoardDetails();
     getAllCorpUser();
     getQuarters();
     getSubCompanyList();
     getDepartmentList();
  },[])

  React.useEffect(()=>{
    getListData();
    getStatistics(defaultYear);
  },[allCorpUsers])

  React.useEffect(() => {
    setTourSteps(steps);
    if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.dashboard){
      setRunTour(true);
    }
  }, []);

  React.useEffect(() => {
    let filteredSubCompanyList = subCompanyList
    let filteredDepartmentList = departmentList
    if(role === 'Admin'){
      if(userFilter){
        filteredSubCompanyList = subCompanyList.filter(subComp => 
          userFilter.some(filterItem => filterItem.subCompId === subComp.id)
        );
      
        const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];

        filteredDepartmentList = departmentList.filter(dept => 
          uniqueDeptIds.includes(dept.id)
        );
      }

      setSubCompanyList(filteredSubCompanyList);
      setDepartmentList(filteredDepartmentList);
    }
  }, [initialSubCompany])

  const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
        },
  }));

  const getStatistics = async(year) => {
    const response = await ApiPost("/corps/get-share-scan-status",{corp_id:corp_id, user_id:login_id});
  
    const datas = response.data.data.corp_stats
  
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    //Overview 
    const filteredData = datas.filter(event => (new Date(event.createdon)).getFullYear() === year);
  
    const result = filteredData.reduce((acc, event) => {
      const monthIndex = new Date(event.createdon).getMonth();
      const monthName = months[monthIndex];
  
      if (!acc[monthName]) {
        acc[monthName] = {
          name: monthName,
          Share: 0,
          Scan: 0
        };
      }
      acc[monthName][event.action]++;
  
      return acc;
    }, {});
  
    const data = [];
    for (const month of months) {
      if (result[month]) {
        data.push({
          name: month,
          uv: result[month].Scan,
          pv: result[month].Share,
          amt: 200,
        });
      } else {
        data.push({
          name: month,
          uv: 0,
          pv: 0,
          amt: 200,
        });
      }
    }

    let topUsersPerMonth = {}
    let monthlyArrays = Array.from({length: 12}, () => []);

    filteredData.forEach(item => {
      let date = new Date(item.createdon);
      if (date.getFullYear() === year) {
        monthlyArrays[date.getMonth()].push(item);
      }
    });

    monthlyArrays.forEach((monthArray, index) => {
      let counts = {};

      monthArray.forEach(item => {
        let key = `${item.user_id}_${item.action}`;
        counts[key] = (counts[key] || 0) + 1;
      });

      let usersData = {};
      for (let key in counts) {
        let [user_id, action] = key.split('_');
        if (!usersData[user_id]) {
          usersData[user_id] = { name: months[index], user_id, 'Share': 0, 'Scan': 0 };
        }
        usersData[user_id][action] += counts[key];
      }

      let usersArray = Object.values(usersData);
  
      // Sort the array in descending order and slice the first 5 elements
      let topScanners = usersArray.sort((a, b) => b.Scan - a.Scan).slice(0, 5);
      let topSharers = usersArray.sort((a, b) => b.Share - a.Share).slice(0, 5);

      // Add the top scanners and sharers to the topUserPerMonth object
      topUsersPerMonth[months[index]] = { 
        name: months[index], 
        Share: topSharers.reduce((total, user) => total + user.Share, 0),
        Scan: topScanners.reduce((total, user) => total + user.Scan, 0),
        top_scanners: topScanners.filter(user => user.Scan > 0).map(user => ({name: allCorpUsers.find(u => u.user_id === user.user_id)?.name || '', scans: user.Scan})),
        top_sharers: topSharers.filter(user => user.Share > 0).map(user => ({name: allCorpUsers.find(u => u.user_id === user.user_id)?.name || '', shares: user.Share}))
      };
    });

    const dataTopUsers = [];
    for (const month of months) {
      if (topUsersPerMonth[month]) {
        dataTopUsers.push({
          name: month,
          uv: topUsersPerMonth[month].Scan,
          pv: topUsersPerMonth[month].Share,
          amt: 200,
          top_scanner: topUsersPerMonth[month].top_scanners,
          top_sharer: topUsersPerMonth[month].top_sharers
        });
      } else {
        dataTopUsers.push({
          name: month,
          uv: 0,
          pv: 0,
          amt: 200,
          top_scanner: '',
          top_sharer: ''
        });
      }
    }

    setLineDataTopUsers(dataTopUsers);
    setLineData(data);
  }

  const getListData = async() =>{
    const data = await ApiPost("/corps/user-lists",{
      corp_id: corp_id,
      limit: 10,
      status: 'Main',
      keyValue: 'namecard_status',
    })
    if(data?.data?.data){

      const newListUsers = data?.data.data.users.map(item => {

        const rowData = {
          user_id: item.id,
          name: item.metadata[0].front[0].name,
        };
        return rowData;
      });

      setListData(newListUsers)
    }
  }

  const getAllCorpUser = async() =>{
    const data = await ApiPost("/corps/user-lists",{
      corp_id: corp_id,
      limit: 999,
      status: 'Main',
      keyValue: 'namecard_status',
    })
    if(data?.data?.data){

      const newListUsers = data?.data.data.users.map(item => {

        const rowData = {
          user_id: item.id,
          name: item.metadata[0].front[0].name,
        };
        return rowData;
      });
      setAllCorpUsers(newListUsers);
    }
  }

  const searchMainUser = async (event, value) => {

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      const result = await ApiPost("/corps/user-lists-search", {
        corp_id: corp_id,
        page: 1,
        search: value
      });
  
      if (result?.data?.data?.users) {
        const newListUsers = result?.data.data.users.map(item => {

          const rowData = {
            user_id: item.id,
            name: item.metadata[0].front[0].name,
          };
          return rowData;
        });
  
        setListData(newListUsers)
      }
    }, 1500);

    setTimeoutId(newTimeoutId);
  }

  const getStatisticsUser = async(name, year) => {

    setFilterId(name)

    const response = await ApiPost("/corps/get-share-scan-status",{corp_id:corp_id, user_id:name});
  
    const datas = response.data.data.corp_userStats
  
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const filteredData = datas.filter(event => (new Date(event.createdon)).getFullYear() === year);
  
    const result = filteredData.reduce((acc, event) => {
      const monthIndex = new Date(event.createdon).getMonth();
      const monthName = months[monthIndex];
  
      if (!acc[monthName]) {
        acc[monthName] = {
          name: monthName,
          Share: 0,
          Scan: 0
        };
      }
      acc[monthName][event.action]++;
  
      return acc;
    }, {});
  
    const data = [];
    for (const month of months) {
      if (result[month]) {
        data.push({
          name: month,
          uv: result[month].Scan,
          pv: result[month].Share,
          amt: 200,
        });
      } else {
        data.push({
          name: month,
          uv: 0,
          pv: 0,
          amt: 200,
        });
      }
    }

    setLineDataUsers(data);
  }

  const getStatsCorp = async(year) => {
    setDefaultYear(year);
    getStatistics(year);
  } 

  const getStatsCorpUser = async(name, year) => {
    setFilterUser(name)
    setDefaultYearUser(year)
    getStatisticsUser(name, year)
  }

  const getDashBoardDetails = async() =>{
      const response = await ApiPost("/corps/dashboard-details",{corp_id:corp_id})
      setDashboardDetails(response.data.data)
      setShowLoader(true);
      setTimeout(()=>{
        setShowLoader(false);
      },800)
  }
  
  const percentage = (amount) =>{
      const total = amount  / dashboardDetails?.Total * 100;
      return total.toFixed();
  }

  const contactUs = async() =>{
    const formData = new FormData();
    formData.append('email', 'contact@lexiscard.asia');
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('file', file[0]);
    const response =await ApiPost('/corps/contact-us',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setShowModal(false)    
  
  }

  const navigateTouserList = () =>{
    navigate('/userlist')
  }

  const goToUserListWalkThrough = async (step) => {
    const userMeta = userData[0].metadata;
  
    if (step === 'reset') {
      if (!userMeta.walkthrough || !userMeta.walkthrough.dashboard) {
        const updatedMeta = {
          ...userMeta,
          walkthrough: {
            ...(userMeta.walkthrough || {}),
            dashboard: true,
          },
        };
  
        const data = await ApiPost("/corps/update-corps-user", {
          id: userData[0].id,
          metadata: updatedMeta,
          walkthrough: true
        });

        if(data.status === 200){
          const updatedArray = userData.map(obj => ({
            ...obj,
            metadata: updatedMeta
          }));

          dispatch(setUserData(updatedArray));
        }
      }
    }
  }

  const getQuarters = () => {
    const currentDate = new Date();
    // currentDate.setMonth(currentDate.getMonth() + 3); // for simulation only
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const pastQuartersArray = [];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    for (let i = 0; i < 4; i++) {
      let pastMonth = currentMonth - i * 3;
      let pastYear = currentYear;
      if (pastMonth <= 0) {
        pastMonth += 12;
        pastYear -= 1;
      }

      const pastQuarterNumber = Math.ceil(pastMonth / 3);
      const startMonthIndex = (pastQuarterNumber - 1) * 3;
      const correspondingMonths = `${monthNames[startMonthIndex].slice(0, 3)} - ${monthNames[startMonthIndex + 2].slice(0, 3)}`;
      const label = i === 0 ? `Current\n${correspondingMonths} (${pastYear})` : `${correspondingMonths} (${pastYear})`;
      
      const startDate = new Date(pastYear, (pastQuarterNumber - 1) * 3, 1).toISOString();
      const endDate = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + 3, 0).toISOString();
      const dateRange = {start: startDate, end: endDate};

      pastQuartersArray.push({ label, dateRange });
    }

    pastQuartersArray.sort((a, b) => {
      if (a.label === 'Current Quarter') return -1;
      if (b.label === 'Current Quarter') return 1;
      const [quarterA, yearA] = a.label.split(' - ');
      const [quarterB, yearB] = b.label.split(' - ');
      if (yearA === yearB) {
        return parseInt(quarterB.substring(1)) - parseInt(quarterA.substring(1));
      }
      return parseInt(yearB) - parseInt(yearA);
    });

    setPastQuarters(pastQuartersArray);
  }

  const getSubCompanyList = async () => {
    const response = await ApiPost("/corps/get-sub-company", {
      corp_id: corp_id,
      isDashboard: true
    });

    setInitialSubCompany(response.data.data)
    setSubCompanyList(response.data.data)
  }

  const getDepartmentList = async () => {
    const response = await ApiPost("/corps/get-department", {
      corp_id: corp_id,
    });

    setDepartmentList(response.data.data)
  }

  const handleCellClick = (license_user) => {
    dispatch(setLicenseUserData(license_user));
    navigate('/userlist');
  }

  return (
    <>
      <Header pageTitle="Dashboard" />
     
      <div className="dashboard-content-container">
        <div className='dashboard-active-user-by-quarter'>
          <div className='dashboard-top-header'>
            <h3 style={{ fontWeight: 'bold'}}>Active User By Quarter</h3>
          </div>
          <TableContainer component={Paper} className='active-users-table'>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Institution / Department</TableCell>
                  {pastQuarters.map((quarter, index) => (
                    <TableCell key={index} align="center">{quarter.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {
                (subCompanyList.length === 0 || departmentList.length === 0) ?
                (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {subCompanyList.length === 0 && departmentList.length === 0 ? (
                          <div>No data available</div>
                        ) : (
                          <PropagateLoader color="#AB66FF" style={{width: '100%', margin: 12}}/>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                :
                (<TableBody>
                  {
                    subCompanyList.map((subCompany) => {
                      return (
                        <>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{subCompany.name}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                          {
                            departmentList.map((department, index) => {
                              const filteredDepartments = subCompany.departments.filter(departments => departments.departmentId === department.id);

                              let currentQuarterCount = 0;
                              let currentQuarterUser = [];
                              let quarterSecondColumnCount = 0;
                              let quarterSecondUser = [];
                              let quarterThirdColumnCount = 0;
                              let quarterThirdUser = [];
                              let quarterFourthColumnCount = 0;
                              let quarterFourthUser = [];

                              if (filteredDepartments.length === 0) {
                                return null;
                              }
                              
                              if (filteredDepartments[0].licenseUsers.length !== 0) {
                                filteredDepartments[0].licenseUsers.forEach(user => {
                                  const { createdon } = user;
                                  const checkDate = new Date(createdon);
                                  if (checkDate >= new Date(pastQuarters[0].dateRange.start) && checkDate <= new Date(pastQuarters[0].dateRange.end)) {
                                    currentQuarterCount++
                                    user.subCompId = subCompany.id
                                    user.deptId = filteredDepartments[0].departmentId
                                    currentQuarterUser.push(user);
                                  } else if (checkDate >= new Date(pastQuarters[1].dateRange.start) && checkDate <= new Date(pastQuarters[1].dateRange.end)) {
                                    quarterSecondColumnCount++
                                    user.subCompId = subCompany.id
                                    user.deptId = filteredDepartments[0].departmentId
                                    quarterSecondUser.push(user);
                                  } else if (checkDate >= new Date(pastQuarters[2].dateRange.start) && checkDate <= new Date(pastQuarters[2].dateRange.end)) {
                                    quarterThirdColumnCount++
                                    user.subCompId = subCompany.id
                                    user.deptId = filteredDepartments[0].departmentId
                                    quarterThirdUser.push(user);
                                  } else if (checkDate >= new Date(pastQuarters[3].dateRange.start) && checkDate <= new Date(pastQuarters[3].dateRange.end)) {
                                    quarterFourthColumnCount++
                                    user.subCompId = subCompany.id
                                    user.deptId = filteredDepartments[0].departmentId
                                    quarterFourthUser.push(user);
                                  }
                                });
                              }

                              return (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row" style={{paddingLeft: 64}}>{`${department.department_name} ${department?.cost_center ? ' - ' + department?.cost_center : ''}`}</TableCell>
                                  <TableCell align="center" className={currentQuarterCount === 0 ? 'table-cell-no-value' : 'table-cell-with-value'} onClick={currentQuarterCount !== 0 ? () => handleCellClick(currentQuarterUser) : null}>{currentQuarterCount}</TableCell>
                                  <TableCell align="center" className={quarterSecondColumnCount === 0 ? 'table-cell-no-value' : 'table-cell-with-value'} onClick={quarterSecondColumnCount !== 0 ? () => handleCellClick(currentQuarterUser) : null}>{quarterSecondColumnCount}</TableCell>
                                  <TableCell align="center" className={quarterThirdColumnCount === 0 ? 'table-cell-no-value' : 'table-cell-with-value'} onClick={quarterThirdColumnCount !== 0 ? () => handleCellClick(currentQuarterUser) : null}>{quarterThirdColumnCount}</TableCell>
                                  <TableCell align="center" className={quarterFourthColumnCount === 0 ? 'table-cell-no-value' : 'table-cell-with-value'} onClick={quarterFourthColumnCount !== 0 ? () => handleCellClick(currentQuarterUser) : null}>{quarterFourthColumnCount}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                        </>
                      )
                    })
                  }
                </TableBody>)
              }
              
            </Table>
          </TableContainer>
        </div>

        <div className='dashboard-top-content-container'>
          
          <div className='dashboard-top-header'>
              <h3>Plan: {dashboardDetails?.Plan}</h3>
              <p style={{color:"blue",fontSize:"14px",cursor:"pointer"}} onClick={()=>navigateTouserList()}>View User List</p>
          </div>
          
          <div className='dashboard-top-options'>
              {
                dashboardDetails?.Total ?
                <AppDashBoardOptions
                  label="Registered Users"
                  step="step1"
                  value={dashboardDetails?`${dashboardDetails?.Total}/${dashboardDetails?.NumberOfUsers}`:`-/-`}
                  icon={  
                    <BootstrapTooltip title="Number of users that your corporate plan entitles you to add."  placement="top">
                      <InfoOutlinedIcon />
                    </BootstrapTooltip> 
                  }
                  style={{border:"1px solid skyblue"}}
                />
                : 
                <AppDashBoardOptions
                  label="Registered Users"
                  step="step1"
                  value={dashboardDetails?`${dashboardDetails?.Total}/${dashboardDetails?.NumberOfUsers}`:`-/-`}
                  icon={
                    <BootstrapTooltip title="Number of users that your corporate plan entitles you to add."  placement="top">
                      <InfoOutlinedIcon />
                    </BootstrapTooltip>
                  }
                  style={{border:"1px solid skyblue"}}
                />
              }
              {
                dashboardDetails?.Active ?
                <AppDashBoardOptions
                  label="Active Users"
                  step="step2"
                  value={dashboardDetails?.Active}
                  percent={`(${percentage(dashboardDetails?.Active)}%)`}
                  style={{border:"1px solid violet"}}
                />
                :
                <AppDashBoardOptions
                  label="Active Users"
                  step="step2"
                  value={`0`}
                  percent={`(0%)`}
                  style={{border:"1px solid violet"}}
                />
              }
              {
                dashboardDetails?.Suspended ?
                <AppDashBoardOptions
                  label="Suspended Users"
                  step="step3"
                  value={dashboardDetails?.Suspended}
                  percent={`(${percentage(dashboardDetails?.Suspended)}%)` }
                  style={{border:"1px solid #666666"}}
                />
                :
                <AppDashBoardOptions
                  label="Suspended Users"
                  step="step3"
                  value={`0`}
                  percent={`(0%)`}
                  style={{border:"1px solid #666666"}}
                />
              }
          </div>
        </div>

        <div className='tabs_container'>
        
          <p className='performance'>Performance</p>
          
          <Tabs>
          <TabList className="tablist">
            <Tab className="tab-1" onClick={() => setFilterId('')}><span id="step5" >Overview</span></Tab>
            <Tab className="tab-2"><span id="step6">View by User</span></Tab>
            <Tab className="tab-2"><span id="">View Top User</span></Tab>
          </TabList>
          <TabPanel>
            <div className='overview-container'>
              <div style={{paddingLeft:"16px"}}>
                <AppDropdown 
                  data={dashboardDetails?.createdon ? dropdownOptions : dropdownOptionsOnLoad} 
                  onSelect={ (val) => getStatsCorp(val)}
                  style={{width:'200px',paddingTop:"10px"}}
                />
              </div>
              <div className='legend-container'>
                <AppLegend icon={<HorizontalRuleIcon style={{color:"violet"}}/>} label="No.of Scans"/>
                <AppLegend icon={<HorizontalRuleIcon style={{color:"skyblue"}}/>} label="No.of Shares"/>
              </div>
            </div>
            
            <AppLineChart step="step4" data={lineData}/>
          </TabPanel>

          <TabPanel className="viewby_users">
              <div className='viewby_users_container' style={{marginBottom: 20}}>
                {/* <AppInput placeHolderText="Search user" style={{width:'230px'}} icon={<SearchIcon/>} onChange={(val)=> getStatsCorpUser(val, defaultYearUser)}/> */}
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  options={listData.map((option) => ({label: option.name, val: option.user_id}))}
                  style={{
                    width:'230px'
                  }}
                  onChange={(event, value) => {
                    getStatisticsUser(value.val, defaultYearUser);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: '37px'
                        }
                      }}
                      size="small"
                      xs={{}}
                      onChange={(e) => searchMainUser(e, e.target.value)}
                    />
                  )}
                />
                <AppDropdown 
                  data={dropdownOptions} 
                  onSelect={(val) => getStatsCorpUser(filterUser, val)}
                  style={{width:"150px" }}
                />
                <div style={{display:'flex',alingItems:'center'}}>
                <AppLegend icon={<HorizontalRuleIcon style={{color:"violet"}}/>} label="No.of Scans"/>
                <AppLegend icon={<HorizontalRuleIcon style={{color:"skyblue"}}/>} label="No.of Shares"/>
                </div>
              </div>

              {
                filterId ? 
                <AppLineChart data={lineDataUsers}/>
                :
                <div className='viewby_users_content_container'>
                <div><AnalyticsIcon style={{ backgroundColor: 'white', color: 'gray', fontSize:"50px"}} /></div>
                <p style={{fontSize:'12px'}}>Search staff to view his/her performance data</p>
              </div>
              }
          </TabPanel>

          <TabPanel className="viewtop_users">
              <div className='viewtop_users_container' style={{marginBottom: 20}}>
                
                <div className='overview-container'>
                  <div style={{paddingLeft:"16px"}}>
                    <AppDropdown 
                      data={dropdownOptions} 
                      onSelect={ (val) => getStatsCorp(val)}
                      style={{width:'200px',paddingTop:"10px", marginRight: 0}}
                    />
                  </div>
                  <div>
                    <AppDropdown
                      data={[
                        // {value: 'all', label: 'All'},
                        {value: 'scan', label: 'Top Scanner'},
                        {value: 'share', label: 'Top Sharer'},
                      ]} 
                      onSelect={ (val) => setTopUsersToShow(val)}
                      style={{width:'150px',paddingTop:"10px", marginLeft: 0}}
                    />
                  </div>
                  <div className='legend-container'>
                    <AppLegend icon={<HorizontalRuleIcon style={{color:"violet"}}/>} label="Top Scanner"/>
                    <AppLegend icon={<HorizontalRuleIcon style={{color:"skyblue"}}/>} label="Top Sharer"/>
                  </div>
                </div>
                
                <AppLineChart variant="top_users" step="step4" data={lineDataTopUsers} show={topUsersToShow}/>
              </div>
          </TabPanel>
        
        </Tabs>

      
      </div>
      <div className='dashboard-footer'>
          <div className='guide'>
              <p style={{fontWeight:"bold"}}>Need Help?</p>
          </div>
          <div className='footer-text'>
              <p style={{fontSize: 15}}>Want to increase your user limit or refresh your company name card design? Feel free to contact us and we will reach you out in 1 - 3 business days.</p>
              <div style={{width:"100%", display: "flex", justifyContent: "flex-end"}}>
                <AppButton buttonText="Contact Us" onClick={()=>toggleModal()} />
              </div>
          </div>
      </div>

      </div>
      <AppContactUs 
        file={file}
        showModal={showModal}
        toggleModal={toggleModal}
        setFile={(val) => setFile(val)}
        setMessage={(value) => setMessage(value)}
        setSubject={(val) => setSubject(val)}
        contactUs={() => contactUs()}
      />
      {/* <AppModal
        showModal={showModal} 
        header="Contact Us" 
        toggleModal={toggleModal}
        onClick={()=>contactUs()}
        content={
          <div className='content-container'> 
            <AppInput label="Subject" inputStyle={{marginBottom: 15}} onChange={(val)=>setSubject(val)}/>  
            <AppInput label="Message" multiline rows={15} onChange={(value)=>setMessage(value)}/>
            
            <div style={{paddingTop:"10px"}}>
              <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload Files(s)</label>
              <Dropzone onDrop={acceptedFiles => {setFile(acceptedFiles);console.log(acceptedFiles[0].name)}}>
                {({getRootProps, getInputProps}) => (
                  <section className='drag_drop_container'>
                    <div {...getRootProps()} className='files_container'>
                      <input {...getInputProps()}/>
                      <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                      <p style={{color:'rgb(20, 110, 228)'}}>{file ? file[0]?.name.substring(0,12) + "..." : "Drag and drop files here or browse" } </p>
                    </div>
                    
                  </section>
                )}
              </Dropzone>
              <div style={{paddingTop:"10px",color:'gray',fontSize:'12px'}}>
              <p>Supported files: jpg,jpeg,png,pdf</p>
              <p>Max. file size: 5mb</p>
              </div>
            </div>
          </div>
        }
      /> */}
      <AppLoader loader={showLoader} />

      {runTour && (
        <Joyride
          steps={tourSteps}
          continuous={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          showProgress={true}
          run={runTour}
          disableBeacon={false}
          showSkipButton={true}
          hideCloseButton={true}
          callback={(item) => goToUserListWalkThrough(item.action)}
          styles={{
            options: {
              arrowColor: '#00072d',
              backgroundColor: '#00072d',
              overlayColor: 'rgba(77, 74, 95, 0.4)',
              primaryColor: '#4d4a5f',
              textColor: '#FFFFFF',
              width: 500,
              zIndex: 99999,
            }
          }}
        />
      )}
    </>
  )
}
