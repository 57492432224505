const qrJson = {
    width: 300,
    height: 300,
    type: "svg",
    data: "https://www.lexiscard.asia/",
    margin: 0,
    image: "https://yt3.googleusercontent.com/LhrdqVSL-hYBPPy8dUACZ0iaoUIdDlmsgulvoThNSZkLTLm2pU23bbAQStLoqj11jTsDj2tEMg=s900-c-k-c0x00ffffff-no-rj",
    dotsOptions: {
        type: "square",
        color: "#811aff",
        gradient: {
            type: "linear",
            rotation: -1,
            colorStops: [{
                offset: 0,
                color: "#811aff"
            },
            {
                offset: 1,
                color: "#0e80f1"
            }]
        }
    },
    backgroundOptions: {
        color: "#e9ebee",
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 0,
        imageSize: 0.1
    },
    qrOptions: {
        // typeNumber: 0,
        // mode: "Byte",
        errorCorrectionLevel: "M"
    }
}

const qrJsonDefault = {
    width: 300,
    height: 300,
    type: "svg",
    data: "https://www.lexiscard.asia/",
    margin: 0,
    image: "https://yt3.googleusercontent.com/LhrdqVSL-hYBPPy8dUACZ0iaoUIdDlmsgulvoThNSZkLTLm2pU23bbAQStLoqj11jTsDj2tEMg=s900-c-k-c0x00ffffff-no-rj",
    dotsOptions: {
        type: "square",
        color: "#811aff",
        gradient: {
            type: "linear",
            rotation: -1,
            colorStops: [{
                offset: 0,
                color: "#811aff"
            },
            {
                offset: 1,
                color: "#0e80f1"
            }]
        }
    },
    backgroundOptions: {
        color: "#e9ebee",
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 0,
        imageSize: 0.1
    },
    qrOptions: {
        // typeNumber: 0,
        // mode: "Byte",
        errorCorrectionLevel: "M"
    }
}



export {qrJson, qrJsonDefault}
