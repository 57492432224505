import * as React from 'react';
import Box from '@mui/material/Box';
import { MenuItem, FormControl, Select, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function AppDropdown({
  style,
  data,
  onSelect,
  error,
  label,
  showSaveAs = false,
  checkBoxToggle,
  defaultValue,
  customItem,
  disableIndicator,
  isMoveUp = false
}) {

  const [item, setItem] = React.useState(isMoveUp ? defaultValue : defaultValue || data[0]?.value);
  
  const handleChange = (event) => {
    setItem(event.target.value);
    onSelect(event.target.value);
  };

  React.useEffect(() => {

  }, [item])

  return (
    <Box style={{ minWidth: 120,marginRight:"20px", ...style}}>
      <FormControl disabled={disableIndicator} fullWidth style={{padding:"0"}} size="small">
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {label && <Typography variant="subtitle1" style={{marginBottom: 3, fontSize: 14, fontWeight: 700}}>{label}</Typography>}
          {showSaveAs && <div>
            <Typography
              variant="subtitle1"
              style={{
                flexBasis: '50%',
                boxSizing: 'border-box',
                fontSize: '14px',
                display: 'flex'
              }}
            >
              <input
                type="checkbox"
                style={{
                  marginRight: '5px'
                }}
                onChange={checkBoxToggle}
              />
              Designate as Main Card
            </Typography>
          </div>}
        </div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          MenuProps={MenuProps}
          onChange={handleChange}
          error={error}
          style={{fontSize: 14, backgroundColor: '#FFFFFF'}}
        >
          {customItem && <MenuItem key={-0} value={customItem.value} style={{fontSize: 14,padding:10}}>{customItem.label}</MenuItem>}
          {data && data.map((item, index)=>{
              return <MenuItem key={index} value={item.value} style={{fontSize: 14,padding:10}}>{item.label}</MenuItem>
          })}
        </Select>
      </FormControl>
    </Box>
  );
}	
