import React, { useEffect, useRef, useState } from 'react'
import "./styles.scss";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from "@mui/material";
import AppInput from '../AppInput';
import AppColorPicker from '../AppColorPicker';
import AppDropdown from '../AppDropdown';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AppLoader from '../AppLoader';
import AppButton from '../AppButton';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const AppQRAttributes = ({ jsonData, originalQrData, onChange }) => {

    const [expandedMain, setExpandedMain] = useState(true);
    const [color, setColor] = useState("#aabbcc");
    const [qrJson,  setQrJson] = useState(jsonData)
    const [insertImage, setInsertImage] = useState('url');
    const [showLoader, setShowLoader] = useState(false);
    const [toSaveQr, setToSaveQr] = useState(originalQrData);
    const [errorImageSize, setErrorImageSize] = useState(false);
    const [errorImageSizeMin, setErrorImageSizeMin] = useState(false);
    const [errorMargin, setErrorMargin] = useState(false);

    const handleExpandMainChange = (event, expandedMain) => {
        if (expandedMain) {
            setExpandedMain(true);
        }
    };

    const handleColorTypeChange = (event) => {
        const updatedQrJson = { ...qrJson };
        const updatedToSaveQr = { ...toSaveQr };
    
        if (event.target.value === 'single') {
            updatedToSaveQr.enableLinearGradient = false;
            updatedQrJson.dotsOptions.gradient.colorStops[0].color = updatedToSaveQr.color;
            updatedQrJson.dotsOptions.gradient.colorStops[1].color = updatedToSaveQr.color;
        } else {
            if (!updatedQrJson.dotsOptions.gradient) {
                updatedQrJson.dotsOptions.gradient = {
                    type: 'linear',
                    rotation: -1,
                    colorStops: [
                        {
                            offset: 0,
                            color: updatedQrJson.dotsOptions.color,
                        },
                        {
                            offset: 1,
                            color: updatedQrJson.dotsOptions.color,
                        },
                    ],
                };
            }
    
            updatedQrJson.dotsOptions.type = 'linear';
            updatedQrJson.dotsOptions.rotation = -1;
            updatedQrJson.dotsOptions.gradient.colorStops[0].color = updatedQrJson.dotsOptions.color;
            updatedQrJson.dotsOptions.gradient.colorStops[1].color = updatedQrJson.dotsOptions.color;
    
            updatedToSaveQr.enableLinearGradient = true;
            updatedToSaveQr.linearGradient = [updatedToSaveQr.color, updatedToSaveQr.color];
        }
    
        setQrJson(updatedQrJson);
        setToSaveQr(updatedToSaveQr);
        onChange(updatedQrJson, updatedToSaveQr);
    };
    

    const onChangeColorPick = (value) => {
        const updatedQrJson = { ...qrJson };
        const updatedToSaveQr = { ...toSaveQr };

        updatedQrJson.dotsOptions.color = value;
        updatedToSaveQr.color = value;
        updatedQrJson.dotsOptions.gradient.colorStops[0].color = updatedToSaveQr.color;
        updatedQrJson.dotsOptions.gradient.colorStops[1].color = updatedToSaveQr.color;
        
        setQrJson(updatedQrJson);
        setToSaveQr(updatedToSaveQr);
        onChange(updatedQrJson, updatedToSaveQr);
    }

    const onChangeBackgroundColor = (value) => {
        const updatedQrJson = { ...qrJson };

        updatedQrJson.backgroundColor = value;
        
        setQrJson(updatedQrJson);
        onChange(updatedQrJson)
    }

    const onChangeColorPickGradient = (value, colorNumber) => {
        const updatedQrJson = { ...qrJson };
        const updatedToSaveQr = { ...toSaveQr };

        if(colorNumber === 'color1'){
            updatedQrJson.dotsOptions.color = value;
            updatedToSaveQr.color = value;
            updatedQrJson.dotsOptions.gradient.colorStops[0].color = value;
            updatedToSaveQr.linearGradient[0] = value;
        }else{
            updatedQrJson.dotsOptions.gradient.colorStops[1].color = value;
            updatedToSaveQr.linearGradient[1] = value
        }
        
        setQrJson(updatedQrJson);
        setToSaveQr(updatedToSaveQr);
        onChange(updatedQrJson, updatedToSaveQr);
    }

    const onClearLogo = () => {
        const updatedQrJson = { ...qrJson }
        const updatedToSaveQr = { ...toSaveQr };

        updatedQrJson.image = ''
        updatedQrJson.imageName = ''
        updatedToSaveQr.logo = ''
        updatedToSaveQr.imageName = ''

        setQrJson(updatedQrJson);
        setToSaveQr(updatedToSaveQr);
        onChange(updatedQrJson, updatedToSaveQr)
    }

    const handleInsertImage = (acceptedFiles) => {
        setShowLoader(true);
        const updatedQrJson = { ...qrJson }
        const updatedToSaveQr = { ...toSaveQr };

        const uploadedFile = acceptedFiles[0]

        if (uploadedFile) {
            const reader = new FileReader();
      
            reader.onload = async (e) => {
                const base64Data = `data:image/png;base64,${e.target.result.split(',')[1]}`;

                updatedQrJson.image = base64Data
                updatedQrJson.imageName = acceptedFiles[0].name
                updatedToSaveQr.logo = base64Data
                updatedToSaveQr.imageName = acceptedFiles[0].name

                setShowLoader(false);
                setQrJson(updatedQrJson);
                setToSaveQr(updatedToSaveQr);
                onChange(updatedQrJson, updatedToSaveQr)
            }

            reader.readAsDataURL(uploadedFile);
        }
        
    }

    const onSetImageSize = (val) => {
        const updatedQrJson = { ...qrJson };
        const updatedToSaveQr = { ...toSaveQr };

        if(val >= 51){
            setErrorImageSize(true);

            updatedQrJson.imageOptions.imageSize = 50 / 100;
            updatedToSaveQr.logoSize = 50;
            
        }else if(val < 1){
            setErrorImageSizeMin(true);

            updatedQrJson.imageOptions.imageSize = 1 / 100;
            updatedToSaveQr.logoSize = 1;
        }else{
            let decimalRepresentation = val / 100;

            updatedQrJson.imageOptions.imageSize = decimalRepresentation;
            updatedToSaveQr.logoSize = val;
        }

        setQrJson(updatedQrJson);
        setToSaveQr(updatedToSaveQr);
        onChange(updatedQrJson, updatedToSaveQr);
    }

    const onSetImageMargin = (val) => {
        const updatedQrJson = { ...qrJson };
        const updatedToSaveQr = { ...toSaveQr };

        if(val <= 10){
            updatedQrJson.imageOptions.margin = val
            updatedToSaveQr.logoMargin = val;
        }else if(val === ''){
            updatedQrJson.imageOptions.margin = 0
            updatedToSaveQr.logoMargin = 0;
        }else{
            setErrorMargin(true)
            updatedQrJson.imageOptions.margin = 5
            updatedToSaveQr.logoMargin = 5;
        }
        

        setQrJson(updatedQrJson);
        setToSaveQr(updatedToSaveQr);
        onChange(updatedQrJson, updatedToSaveQr);
    }

    return (
        <>
            {/* MAIN PANEL */}
            <Accordion expanded={expandedMain} onChange={handleExpandMainChange}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto', fontWeight: 700}}>Main Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Image File</label>
                    <Dropzone onDrop={acceptedFiles => handleInsertImage(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                        <section className={'drag_drop_container'}>
                            <div {...getRootProps()} className='files_container'>
                            <input {...getInputProps()}/>
                            <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                            <p style={{color:'rgb(20, 110, 228)'}}>{toSaveQr.logo ? toSaveQr.imageName.substring(0,12)+"..." : 'Drag and drop files here or browse'}</p>
                            </div>
                        </section>
                        )}
                    </Dropzone>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <AppButton
                            variant='text'
                            buttonText="Clear Logo"
                            onClick={() => onClearLogo()}
                            className='cancel-btn'
                            style={{ color: '#FF0000', background: null, marginLeft: 10 }}
                        />
                    </div>
                </AccordionDetails>            
            </Accordion>

            {/* COLOR OPTION */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expanded={true}
                >
                    <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto', fontWeight: 700}}>Color Option</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label" style={{fontWeight: 700, color: 'black', fontSize: 15}}>Color Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                style={{marginLeft: 5}}
                                defaultValue={toSaveQr.enableLinearGradient ? 'gradient' : 'single'}
                                onChange={(val) => handleColorTypeChange(val)}
                            >
                                <FormControlLabel value="single" control={<Radio />} label="Single Color" />
                                <FormControlLabel value="gradient" control={<Radio />} label="Color Gradient" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {
                        !toSaveQr.enableLinearGradient ? 
                        <AppColorPicker label="Dots Color" color={toSaveQr.color} onChange={onChangeColorPick} options={'dotsOptions'} optionHelper={'dotsOptionsHelper'}/>
                        :
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                <div style={{flexDirection: 'column', width: '30%'}}>
                                    <AppColorPicker label="Gradient Colors" color={toSaveQr?.linearGradient[0] ? toSaveQr?.linearGradient[0] : qrJson.color} onChangeGradient={onChangeColorPickGradient} isGradient={true} colorNumber={'color1'} options={'dotsOptions'} optionHelper={'dotsOptionsHelper'}/>

                                    <AppColorPicker color={toSaveQr?.linearGradient[1] ? toSaveQr?.linearGradient[1] : toSaveQr.color} onChangeGradient={onChangeColorPickGradient} isGradient={true} colorNumber={'color2'} options={'dotsOptions'} optionHelper={'dotsOptionsHelper'}/>
                                </div>
                                
                                {/* <AppInput label="Rotation" defaultValue={qrJson.dotsOptionsHelper.gradient.rotation ? qrJson.dotsOptionsHelper.gradient.rotation : 0} isNumber={true} pattern={"^[0-9]"} inputStyle={{marginBottom: 15}} onChange={(val)=> handleRotationChange(val, 'dotsOptions', 'dotsOptionsHelper')} style={{width: '50%'}}/> */}
                            </div>
                        </>
                    }
                </AccordionDetails>            
            </Accordion>
            
            {/* BACKGROUND OPTION */}
            {/* <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expanded={true}
                >
                    <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto', fontWeight: 700}}>Background Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AppColorPicker label="Background Color" color={qrJson.backgroundColor} onChange={onChangeBackgroundColor} options={'backgroundOptions'} optionHelper={'backgroundOptionsHelper'}/>
                </AccordionDetails>            
            </Accordion> */}

            {/* LOGO OPTION */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expanded={true}
                >
                    <Typography style={{backgroundColor: '#FFF', display: 'flex', alignItems: 'center', marginRight: 'auto', fontWeight: 700}}>Logo Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <AppInput label="Image Size" defaultValue={parseInt(toSaveQr?.logoSize)} inputStyle={{marginBottom: 15, marginRight: 15}} onChange={(val)=> onSetImageSize(val)} pattern='^[0-9]*$' isNumber={true}/>
                            <div style={{color:errorImageSize ? 'red':'gray',fontSize:'12px',fontWeight:'bold'}}>
                                <p>Logo size must not exceed 50</p>
                            </div>
                            <div style={{color:errorImageSizeMin ? 'red':'gray',fontSize:'12px',fontWeight:'bold'}}>
                                <p>Logo size minimum is 1</p>
                            </div>
                        </div>
                        
                        <div>
                            <AppInput label="Margin" defaultValue={toSaveQr?.logoMargin} inputStyle={{marginBottom: 15, marginRight: 15}} onChange={(val)=> onSetImageMargin(val)} pattern='^[0-9]*$' isNumber={true}/>
                            <div style={{color:errorMargin ? 'red':'gray',fontSize:'12px',fontWeight:'bold'}}>
                                <p>Margin size must not exceed 10</p>
                            </div>
                        </div>
                        
                    </div>
                </AccordionDetails>            
            </Accordion>

            <AppLoader loader={showLoader} />
        </>
    );
}

export default AppQRAttributes