import React, {useReducer, useEffect, useState} from 'react';
import './styles.scss';
import {Search, CalendarToday, AccessTime, Alarm, Snooze } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Radio, RadioGroup, TextField, Select } from '@mui/material';
import moment from 'moment';
import { AppDropdown, AppModal } from '../../../../../components';
import { ApiPost } from '../../../../../services/AxiosInstance';
import { useSelector, useDispatch } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import LinkComponent from './LinkComponent';
import ReciepientsComponent from './ReciepientsComponent';

// const formattedDate = moment().format();
// const currentTime = moment().format();
const initialTodos = {
  title: '',
  message: '',
  link: {
    status: 'URL',
    data: ''
  },
  recipients: {
    status: 'Everyone',
    data: []
  },
  dateTime:{
    date: '',
    time: ''
  },
  creds:0
};

const appPages = ["My Profile","Contacts","Notification"];

const reducer = (state, action) => {
  switch (action.type) {
    case "setTitle":
      return {...state, title: action.value}
    case "setMessage":
      return {...state, message: action.value}
    case "setLink":
      return {
        ...state,
        link: {
          status: action.value.status,
          data: action.value.data
        }}
    case "setRecipients":
      return {
        ...state,
        recipients: {
          status: action.value.status,
          data: action.value.data
        }}
    case "setDate":
      return {
        ...state,
        dateTime: {
          ...state.dateTime,
          date: action.value
        }}
    case "setTime":
      return {
        ...state,
        dateTime: {
          ...state.dateTime,
          time: action.value
        }}
     case "setCreds":
          return{
            ...state,
            creds:action.value
         }     
    default:
      return initialTodos;
  }
};

const NewPushNotification = (props) => {
    const { data, onSubmit, onClose, display, notif, setErrorAlert } = props;
    const [form, dispatch] = useReducer(reducer, initialTodos)
    const [link, setLink] = useState('URL');
    const [selectedRecip, setSelectedRecip] = useState([]);
    const [selectedRecipGroup, setSelectedRecipGroup] = useState([]);
    const [corpContacts,setCorpContacts] = useState([]);
    const [tempCorpContacts,setTempCorpContacts] = useState([]);
    const [titleErr, setTitleErr] = useState(false);
    const [messageErr, setMessageErr] = useState(false);
    const [dateErr, setDateErr] = useState(false);
    const [timeErr, setTimeErr] = useState(false);
    const [linkErr, setLinkErr] = useState("");
    const [recipientErr, setRecipientErr] = useState(false);
    const [editData, setEditData] = useState({})
    const [alertAttr, setAlertAttr] = useState({visible: false, message: null, color: null});
 
    const userData = useSelector((state)=> state.user.userData);
    const corp_id = userData[0].corp_id;
    const disableIndicator = useSelector((state)=> state.user.disableIndicator);
    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState([]);
    const role = userData[0]?.role;
    
    const [recipients, setRecipients] = useState(role === 'Owner' ? 'Everyone' : 'Company/Department');

    useEffect(() => {
      if(Object.keys(data).length > 0){
        const splitData = data?.content?.props?.metadata;

        if(splitData && splitData?.content){
          dispatch({type: 'setTitle', value: splitData?.title})
        }

        if(splitData && splitData?.title){
          dispatch({type: 'setMessage', value: splitData?.content})
        }
      
        if(splitData && splitData.url && splitData.url.hasOwnProperty('link')){

          if(!splitData?.url?.link?.includes("http")){
            setLink("AppPage");
            dispatch({type: 'setLink', value: {
              status: 'AppPage',
              data: splitData.url?.name
            }})
          }
        }else{
          dispatch({type: 'setLink', value: {
            status: 'URL',
            data: splitData.url
          }})
        }

        if(splitData && splitData?.no_recipients && splitData?.no_recipients.length > 0){
          setRecipients('Specific');
          getCorpContacts();
        }
        if(splitData && splitData?.pushed_time_date){
          const pushTime = splitData?.pushed_time_date;
          dispatch({type: 'setDate', value: pushTime.date });
          dispatch({type: 'setTime', value: pushTime.time });
          // dispatch({type: 'setTime', value: moment(pushTime.time,'h:mm A') });
        }

        setEditData(splitData)
      }else{
        dispatch({type: ''});
      }
    }, [JSON.stringify(data)]) 
    
    useEffect(() => {
      getCorpContacts()
    }, [editData])

    const getCorpContacts = async() =>{
      const data = await ApiPost("/corps/getAll-corpContacts",{corp_id:corp_id, useremail: userData[0]?.useremail, role: role})
       if(data.data.data){
        const result = [];
        const selected = [];
        const new_data = data?.data.data;
        for await (const contactData of new_data) {
          const status = await corpSelected(contactData);
          if(status){
            selected.push({...contactData, status: status})
          }
          result.push({...contactData, status: status})
        }
        if(selected.length > 0){
          setRecipients('Specific')
        }

        setSelectedRecip(selected)
        setTempCorpContacts(result)
        setCorpContacts(result)
       }
    }
    
    const corpSelected = async (item) => {
      if(editData && editData?.no_recipients && editData?.no_recipients.length > 0){
        const recipients = editData?.no_recipients;
        const find = recipients.find((data) => data === item.id);
        if(find){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    }
    

    useEffect(() => {
      if (selectedRecip.length === 0 && selectedRecipGroup.length === 0) {
        dispatch({type: 'setRecipients', value: {
          status: 'Everyone',
          data: []
        }})
        dispatch({type:'setCreds',value:corpContacts.length})
      } else if(selectedRecip.length === 0 && selectedRecipGroup.length > 0){
        dispatch({type: 'setRecipients', value: {
          status: 'Group',
          data: selectedRecipGroup
        }})
        dispatch({type:'setCreds',value:selectedRecipGroup.length});
      } else {
        dispatch({type: 'setRecipients', value: {
          status: 'Specific',
          data: selectedRecip
        }})
        dispatch({type:'setCreds',value:selectedRecip.length})
      }
    }, [JSON.stringify(selectedRecip),JSON.stringify(selectedRecipGroup), notif])

    const handleLinkRadioButton = (value) => {
      if(value === 'URL'){
        dispatch({type: 'setLink', value: {
          status: link,
          data: ''
        }})
      }
      setLink(value);
    }

    const handleRecipient = (value) => {
      if(value === "Everyone"){
        dispatch({type: 'setRecipients', value: {
          status: value,
          data: null
        }})
      }
      setRecipients(value)
    }

    const handleSelectedGroup = async(data) => {
      let tempDaata = []
      for(const res of data){
          const children = res?.children
          for(const resChildren of children){
            try {
              const data = await ApiPost("/corp/get-department-users",{corp_id: corp_id, deptId: resChildren?.deptId, subCompId: resChildren?.subComp})

              const resres = data.data.data
              tempDaata.push(resres)

            } catch (error) {
              console.log("error----------------",error)
            }
          }
      }

      const newArray = tempDaata.flat()

      const uniqueArray = newArray.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      );
  
      setSelectedRecipGroup(uniqueArray)
    }


    const handleSelectRecipt = (index,id) => {
      // Toggle the status property of the current item
      const user = corpContacts.find(item => item.id === id);

      user.status = !user.status;
      user.id = id;
      
      const check = selectedRecip.filter((item) => item.id === user.id);
      
      if (user.status === true && check.length === 0) {
        setSelectedRecip([...selectedRecip, user]);
      } else {
        setSelectedRecip(selectedRecip.filter((item) => item.id !== id));
      }
    };

    const handleChangeDate = (value) => {
      dispatch({ type: "setDate", value });
    };
    
    const handleChangeTime = (value) => {
      dispatch({type: 'setTime', value})
      setTimeErr(false);
    }

    const handleClose = () => {
      // if(!disableIndicator){
        getCorpCompany();
        getCorpContacts();
        setLink("URL");
        setRecipients("Everyone");
        setSelectedRecip([])
        setSelectedRecipGroup([])
        setEditData({})
        setTitleErr(false);
        setMessageErr(false);
        setDateErr(false);
        setTimeErr(false);
        setRecipientErr(false);
        setLinkErr("");
        // clearcheckedContacts();
        recipientsClose();
        dispatch({type: ''});
      // }
      onClose();
    }

    const recipientsClose = () => {
      const new_arr = [];
      corpContacts.forEach(element => {
        new_arr.push({...element, status: 0})
      });
      setCorpContacts(new_arr);
    }

    const clearcheckedContacts = () =>{
      selectedRecip.map((item)=>{
        return {...item,status:false}
      })
    }
   
    const handleSearchReciept = (value) => {
      const new_reciept = tempCorpContacts.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
      setCorpContacts(new_reciept);
    }

    const notifValidate = async () => {
      let status = true;
      const linkURL = form.link?.data;
      if(form.link.status === "URL" && linkURL !== ""){
        var validRegex = /^https?:\/\/[0-9A-Za-z]/g;

        if(!linkURL.match(validRegex)){
          setLinkErr("Please enter a valid URL.");
          setAlertAttr({visible: true, message: 'Please enter a valid URL.', color: '#b2102f'});
          status = false;
        }else{
          setLinkErr("");
          status = true;
        }
      }

      if(form.title === ""){
        setTitleErr(true);
        setAlertAttr({visible: true, message: 'Please enter a notificatioon title.', color: '#b2102f'});
        status = false
      }else{
        setTitleErr(false);
      }
    
      if(form.message === ""){
        setAlertAttr({visible: true, message: 'Please enter a message.', color: '#b2102f'});
        setMessageErr(true);
        status = false
      }else{
        setMessageErr(false);
      }

      if(form.dateTime.date === ""){
        setDateErr(true);
        setAlertAttr({visible: true, message: 'Please enter valid date.', color: '#b2102f'});
        status = false
      }else{
        setDateErr(false);
      }

      if(form.dateTime.time === ""){
        setTimeErr(true);
        setAlertAttr({visible: true, message: 'Please enter valid time.', color: '#b2102f'});
        status = false
      }else{
        setTimeErr(false);
      }

      if(recipients === "Specific" && selectedRecip.length === 0){
        setRecipientErr(true);
        setAlertAttr({visible: true, message: 'Please enter select recipient(s).', color: '#b2102f'});
        status = false
      }else{
        setRecipientErr(false);
      }

      if(recipients === "Group" && selectedRecipGroup.length === 0){
        setRecipientErr(true);
        setAlertAttr({visible: true, message: 'Please check sub-company(s)/department(s).', color: '#b2102f'});
        status = false
      }else{
        setRecipientErr(false);
      }

      //CHECK PAST TIME
      const pushTime = moment(form.dateTime.time).format('LT');
      const pushDate = moment(form.dateTime.date).format('L');
      const pushDateTime = pushDate+" "+pushTime;

      const formattedPushDateTime = moment(pushDateTime).format("YYYY-MM-DDTHH:mm")
      const dateNow = moment().format("YYYY-MM-DDTHH:mm")
      if(formattedPushDateTime < dateNow){
        console.log("past date");
        setTimeErr(true);
        setAlertAttr({visible: true, message: 'The time and date entered is already passed.', color: '#b2102f'});
        status = false
      }else{
        setTimeErr(false);
      }
    
      return status;
    }

    const getCorpCompany = async () => {
       const data = await ApiPost("/corp/get-corp-company",{corp_id: corp_id, useremail: userData[0]?.useremail, role: role })
       if(data.data.data){
        const result = [];
        const selected = [];
        const new_data = data?.data.data;
        setCompanyList(new_data);
       }
    }

    
    useEffect(()=>{
      getCorpCompany();
    }, [])
     
    
    console.log("specify", selectedRecip, "group", selectedRecipGroup)
    return (
        <AppModal 
          showModal={display}
          contentStyle={{overflowY:"auto", maxHeight: '60vh', paddingBottom: 50}}
          style={{
            marginBottom: 20,
            width: 600,
            // height:"87%"
          }}
          header={disableIndicator ? "View Push Notification" : "New Push Notification"}
          disableIndicator={disableIndicator}
          toggleModal={() => handleClose()}
          onClick={async () => {
            const validate = await notifValidate();
            if(recipients === 'Company/Department'){
              if(selectedRecipGroup.length === 0 || !selectedRecipGroup || selectedRecipGroup.length <= 0){
                setErrorAlert({visible: true, message: 'Please select department', color: '#b2102f'})
                return
              }
            }
            if(validate){
              onSubmit(form);
              handleClose();
            }else{
              setErrorAlert(alertAttr)
            }
          }}
          content={
            <div id="notifPagePushNotificationModal" className='content-container'> 
              <div className="inputHolder">
                <label className='input-text'>Title</label>
                <TextField
                  className='input'
                  type="text"
                  value={form.title}
                  size="small"
                  fullWidth
                  disabled={disableIndicator}
                  onChange={(e) => {
                    dispatch({type: 'setTitle', value: e.target.value})
                  }}
                  required
                  error={titleErr}
                />
              </div>            
              <div className="inputHolder">
                <label className='input-text'>Message</label>
                  <TextField
                    className='input'
                    type="text"
                    value={form.message}
                    multiline
                    maxRows={10}
                    minRows={10}
                    fullWidth
                    disabled={disableIndicator}
                    onChange={(e) => {
                      dispatch({type: 'setMessage', value: e.target.value})
                    }}
                    required
                    error={messageErr}
                  />
              </div>
              <div className="inputHolder">
                <LinkComponent
                  handleLinkRadioButton={(val) => handleLinkRadioButton(val)}
                  dispatch={dispatch}
                  appPages={appPages}
                  form={form}
                  link={link}
                  linkErr={linkErr}
                  disableIndicator={disableIndicator}
                />
              </div>          
              <div className='divider'></div>
              <div className="inputHolder">
                <ReciepientsComponent
                  disableIndicator={disableIndicator}
                  handleRecipient={(val) => handleRecipient(val)}
                  handleSearchReciept={(val) => handleSearchReciept(val)}
                  handleSelectRecipt={(index,id) => handleSelectRecipt(index,id)}
                  corpContacts={corpContacts}
                  selectedRecip={selectedRecip}
                  recipients={recipients}
                  recipientErr={recipientErr}
                  companyList={companyList}
                  corpId={corp_id}
                  role={role}
                  handleSelectedGroup={(index) => handleSelectedGroup(index)}
                />
              </div>
              <div className="inputHolder">
                <label className='input-text'>Push Date & Time</label>
                <div className='pushDateTime'>
                    <div className='date'>
                        <DesktopDatePicker
                          className='dateTimeInput'
                          sx={{
                            "& fieldset": { border: 'none',padding:0 }
                          }}
                          inputFormat="DD-MM-YYYY"
                          disabled={disableIndicator}
                          value={form.dateTime.date}
                          onChange={(value) => handleChangeDate(moment(value).format())}
                          renderInput={(params) => <TextField {...params} error={dateErr} />}
                          disablePast={true}
                        />
                    </div>
                    <div className='time'>
                      <TimePicker
                        className='dateTimeInput'
                        disabled={disableIndicator}
                        value={form.dateTime.time}
                        onChange={(value) => handleChangeTime(moment(value).format())}
                        renderInput={(params) => <TextField {...params} error={timeErr}/>}
                      />
                    </div>
                </div>
                    <div style={{ textAlign: 'right' }}>
                      {timeErr ? ( <small style={{ color: 'red', marginRight: 50}}>Please choose a valid time or date.</small> ) : null}
                    </div>
              </div>
            </div>
          }
        />
    )

}

export default NewPushNotification;