import React from "react";
import './styles.scss';
import {AppModal} from '../../../../../components';


const DeleteModal = (props) => {
    const {onClose, display, onSubmit } = props
    return (
        <AppModal 
          showModal={display} 
          header="Delete Push Notification" 
          toggleModal={() => onClose()}
          functionText="Delete"
          buttonStyle={{background:'red',color:"white"}}
          onClick={() => onSubmit()}
          content={
            <div id="deleteModal"> 
                <p>Delete this push notification permanently?</p>
            </div>
          }
        />
    )

}

export default DeleteModal;