import { 
    faEnvelope, 
    faAddressBook, 
    faUser, 
    faBriefcase, 
    faMobile, 
    faPhone, 
    faAddressCard, 
    faLocationDot, 
    faBuilding, 
    faLink, 
    faFax,
    faPrint,
    faGlobe,
    faPhoneFlip
} from '@fortawesome/free-solid-svg-icons';
import { 
    faEnvelope as farEnvelope, 
    faAddressBook as farAddressBook, 
    faUser as farUser,
    faAddressCard as farAddressCard, 
    faBuilding as farBuilding,
 } from '@fortawesome/free-regular-svg-icons';
 import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

    const iconsArray = [
        { label: 'Email', icon: faEnvelope, value: 'faEnvelope' },
        { label: 'Email Regular', icon: farEnvelope, value: 'farEnvelope' },
        { label: 'Person', icon: faUser, value: 'faUser' },
        { label: 'Person Regular', icon: farUser, value: 'farUser' },
        { label: 'Address Book', icon: faAddressBook, value: 'faAddressBook' },
        { label: 'Address Book Regular', icon: farAddressBook, value: 'farAddressBook' },
        { label: 'Work Position', icon: faBriefcase, value: 'faBriefcase' },
        { label: 'Mobile', icon: faMobile, value: 'faMobile' },
        { label: 'Phone', icon: faPhone, value: 'faPhone' },
        { label: 'Phone Flip', icon: faPhoneFlip, value: 'faPhoneFlip' },
        { label: 'Fax', icon: faFax, value: 'faFax' },
        { label: 'Address Card', icon: faAddressCard, value: 'faAddressCard' },
        { label: 'Address Card Regular', icon: farAddressCard, value: 'farAddressCard' },
        { label: 'Location', icon: faLocationDot, value: 'faLocationDot' },
        { label: 'Building', icon: faBuilding, value: 'faBuilding' },
        { label: 'Building Regular', icon: farBuilding, value: 'farBuilding' },
        { label: 'Link', icon: faLink, value: 'faLink' },
        { label: 'Print', icon: faPrint, value: 'faPrint' },
        { label: 'Globe', icon: faGlobe, value: 'faGlobe' },
        { label: `What's App`, icon: faWhatsapp, value: 'faWhatsapp' }
    ];

    const getIconByName = (name) => {
        switch (name) {
            case 'faEnvelope':
            return faEnvelope;
            case 'farEnvelope':
            return farEnvelope;
            case 'faAddressBook':
            return faAddressBook;
            case 'farAddressBook':
            return farAddressBook;
            case 'faUser':
            return faUser;
            case 'farUser':
            return farUser;
            case 'faBriefcase':
            return faBriefcase;
            case 'faMobile':
            return faMobile;
            case 'faPhone':
            return faPhone;
            case 'faPhoneFlip':
            return faPhoneFlip;
            case 'faFax':
            return faFax;
            case 'faAddressCard':
            return faAddressCard;
            case 'farAddressCard':
            return farAddressCard;
            case 'faLocationDot':
            return faLocationDot;
            case 'faBuilding':
            return faBuilding;
            case 'farBuilding':
            return farBuilding;
            case 'faLink':
            return faLink;
            case 'faPrint':
            return faPrint;
            case 'faGlobe':
            return faGlobe;
            case 'faWhatsapp':
            return faWhatsapp;
            default:
            return null;
        }
    };

export { iconsArray, getIconByName }