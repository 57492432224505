import { useEffect, useState } from "react";
import { AppTable } from "../../../../components"
import { headCells,rows } from "./dummy"
import { useSelector, useDispatch } from 'react-redux';
import { ApiPost } from "../../../../services/AxiosInstance";
import moment from "moment";


const NotificationHistory = () =>{
const [itemsPerPage, setItemPerPage] = useState(10);
const [page,setPage] = useState(0);
const [notificationList,setNotificationList] = useState([])
const [totalRows,setTotalRows] = useState(0)
const [loading,setLoading] = useState(false)

const userData = useSelector((state)=> state.user.userData);
const corp_id = userData[0].corp_id;

useEffect(()=>{
    getNotification()
},[])


const restructure = (result) =>{

    const restuctureList =  result?.map((item)=>{
    
        const ContentItem = ({ metadata }) => {
            console.log(metadata)
            return (
              <>
                <b>{metadata?.title}</b><br></br>
                <p>{metadata?.content}</p>
              </>
            );
          };
            const date = moment(item?.metadata.pushed_time_date.date).format("YYYY-MM-DD");
            const time = moment(item?.metadata.pushed_time_date.time).format("LT");
            const newList = {
                date: date + ", " + time,
                content:  <ContentItem metadata={item?.metadata} />,
                no_receipient: item?.metadata.isEveryone ? "Everyone": item?.metadata.no_recipients.length,
                credit_used: item?.metadata.credits_used,
                created_by: item?.created_by,
                id: item?.id
                }              
        return newList
        })
    
        setNotificationList(restuctureList)
    
    }

const getNotification = async() =>{
    setLoading(true)
    const data = await ApiPost("/corps/get-notification",{corp_id:corp_id,limit:itemsPerPage,page:page,isHistory:true})
    const result = data.data.data
    if(result){
     setTotalRows(result.totalRow)
     restructure(result.result)   
     setLoading(false) 
    }
    setLoading(false)
   
}


const handleUserListsPerPage = async(val) =>{
    setItemPerPage(val)
    setLoading(true)
    const data = await ApiPost("/corps/get-notification",{corp_id:corp_id,limit:val,page:page,isHistory:true})
    const result = data.data.data
    setTotalRows(result.totalRow)
    restructure(result.result)
    setLoading(false)

}

const handleUserListsPage = async (val) => {
    console.log("per page", val)
    setLoading(true)
    setPage(val)
    const data = await ApiPost("/corps/get-notification",{corp_id:corp_id,limit:itemsPerPage,page:val,isHistory:true})
    const result =data.data.data
    if(result){
        setTotalRows(result.totalRow)
        restructure(result.result)
        setLoading(false)
    }
}



    return (
        <div style={{width:"100%"}}>
            <AppTable 
                rows={notificationList} 
                headCells={headCells}
                isAction={false}
                hasFilterHeader={false}
                isLoading={loading}
                setPropsItemPerPage={(val) => handleUserListsPerPage(val)}
                setPropsPage={val => handleUserListsPage(val)}
                isCheckbox ={false}
                totalRowsPerPage={itemsPerPage}
                totalCounts={totalRows}
                currentPage={page}
            />
        </div>
    )
}

export default NotificationHistory