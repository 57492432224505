const headCells3 = [
  {
    id: 'contact_of',
    numeric: false,
    disablePadding: false,
    label: 'Username', 
  },
  {
    id: 'logo',
    numeric: false,
    disablePadding: false,
    label: 'Namecard',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'office_number',
    numeric: true,
    disablePadding: false,
    label: 'Office Number',
  },
  {
    id: 'mobile_number',
    numeric: true,
    disablePadding: false,
    label: 'Mobile Number',
  },
  {
    id: 'fax_number',
    numeric: true,
    disablePadding: false,
    label: 'Fax Number',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'date_added',
    numeric: true,
    disablePadding: false,
    label: 'Date Added',
  }
 
];

const headCells4 = [
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
  {
    id: 'license_number',
    numeric: false,
    disablePadding: false,
    label: 'License Number',
  },
  {
    id: 'createdon',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
    
  },
  {
    id: 'license_status',
    numeric: false,
    disablePadding: false,
    label: 'License Status',
  },
  {
    id: 'useremail',
    numeric: false,
    disablePadding: false,
    label: 'Useremail',
  },
  {
    id: 'institution',
    numeric: false,
    disablePadding: false,
    label: 'Institution',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: false,
    label: 'Department',
  },
  {
    id: 'user_status',
    numeric: true,
    disablePadding: false,
    label: 'User Status',
  }, 
];
  
export {headCells3, headCells4};