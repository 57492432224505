import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import InvoiceContent from './InvoiceContent';
import './styles.scss';
import AppButton from '../AppButton';

const AppInvoicePdf = ({ selectedInvoice,billingData,companyDetails,subscriptionDetails }) => {

  const componentRef = useRef();

  return (
    <>
        <ReactToPrint
          trigger={() => <a href="javascript:void(0)" className='pdf-invoice-btn'>Print</a>}
          content={() => componentRef.current}
        />
        <div style={{ display: 'none' }}>
            <div ref={componentRef}>
                <InvoiceContent selectedInvoice={selectedInvoice} companyDetails={companyDetails} subscriptionDetails={subscriptionDetails} billingData={billingData} />
            </div>
        </div>
    </>
  );
}

export default AppInvoicePdf;
