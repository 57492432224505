import React, { useState, useEffect, useRef } from 'react';
// import "./styles.scss";
import Draggable from 'react-draggable';
import { AppButton, AppLoader, AppInput, AppDropdown } from "../../components"
import { useSelector ,useDispatch } from 'react-redux';
import { fonts } from './fonts';
import { fontSize } from './fontSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIcons, fas } from '@fortawesome/free-solid-svg-icons';
import { IconButton, Typography } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Select from 'react-select';
import { iconsArray, getIconByName } from '../../utilities/fontAwesomeIcons';
import { setAddressCheckBox } from '../../redux/reducer/AddressCheckBox';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ApiPost } from '../../services/AxiosInstance';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

const AppFieldsAttribute = (props) => {

    const { currentFields, setNewlabel, setTemplateVal, setNewStyleVal, others, setMultiLineAddress, setNewAddressTemplateVal, cardOrientation, setLogoType, template_id, setAutoAlign, fieldToFocus } = props

    const otherFields = currentFields.filter(field => field.hasOwnProperty('isOtherField') && field.isOtherField === true);
    const defaulFields = currentFields.filter(field => !field.hasOwnProperty('isOtherField') || field.isOtherField === false);
    const userData = useSelector((state)=> state.user.userData);
    const corp_id = userData[0].corp_id;

    const [selectedIcon, setSelectedIcon] = useState(iconsArray[0]);
    const [newIcon, setNewIcon] = useState(false);
    const [iconField, setIconField] = useState("");
    const [errorUpload, setErrorUpload] = useState(false);
    const [uploadFile, setUploadFile] = useState();
    const [iconUploadFile, setIconUploadFile] = useState();
    const [alertAttr, setAlertAttr] = useState({visible: false, message: null, color: null});
    const anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
      };
    const [iconUploadLoader, setIconUploadLoader] = useState(false)
    const [fontList, setFontList] = useState(
        [
            {'label':'Inter','value':'inter'}, 
            {'label':'Arial','value':'arial'}, 
            {'label':'Montserrat','value':'Montserrat'}, 
            {'label':'Monospace','value':'monospace'},
            {'label':'Sans-serif','value':'sans-serif'}
        ]
    )

    const handleIconChange = (icon) => {
        setSelectedIcon(icon);
        setNewIcon(true);
    };

    const filtdata = currentFields.filter((filt)=>filt.field == "address")

    const [checked, setChecked] = React.useState({
        single:true,
        multi:false
    });

    const dispatch = useDispatch()
    dispatch(setAddressCheckBox({
        single:true,
        multi:false
    }))
    
    const handleChange = (event,data) => {
        if(checked.single === false && data == 1) {
            setChecked({...checked, single:true, multi:false});
            dispatch(setAddressCheckBox({single:true, multi:false}))
            setMultiLineAddress(true)
        }else if(checked.multi === false && data == 2){
            setChecked({...checked, single:false, multi:true});
            dispatch(setAddressCheckBox({single:false, multi:true}))
            setMultiLineAddress(false)
        }
    };

    const multiLineChecker = () => {
        const fieldKeys = defaulFields.map(obj => obj.field);
        const filterValues = ["building", "room", "street", "country"];
        const filteredKeys = fieldKeys.filter(key => filterValues.includes(key));
        if(filteredKeys.length != 0){
            setChecked({single:false, multi:true});
        }
    };

    useEffect(()=>{
        multiLineChecker()
        fetchS3Fonts();
    },[]);

    useEffect(() => {
        const inputElement = document.getElementById(fieldToFocus);
        if (inputElement) {
            inputElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            inputElement.focus();
        }
    }, [fieldToFocus])

    const handleNewIcon = (field) => {
        setNewIcon((prevNewIcon) => !prevNewIcon);
        setIconField(field)
    }

    const handleLogoImageUpload = async (file) => {
        setUploadFile(file)

        const formData = new FormData();
        formData.append('file', file[0]);
        formData.append('template_id', template_id);
        formData.append('corp_id', corp_id);

        await ApiPost("/upload-logo-namecard-template",formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        }).then((response)=>{
            if(response.data.message === 'success'){
                setTemplateVal(response.data.data, 'logo')
            }else{
                setAlertAttr({visible: true, message: `Error uploading attaching your logo.`, color: '#b2102f'});
            }
        }).catch((error)=>{
            console.log("error", error);
            setAlertAttr({visible: true, message: `Error uploading attaching your logo.`, color: '#b2102f'});
        })
    };

    const handleCloseAlert = () =>{
        setAlertAttr({visible: false, message: null, color: null});
    }

    const fetchS3Fonts = async () => {
        try {
            const response = await fetch('https://lexicard-public.s3.ap-southeast-1.amazonaws.com/fonts/fonts.txt');
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.text();
            const fontsArray = data.split('\n').map((font, index) => {
                const fontName = font.trim();
                return {
                    label: fontName.replace(/\.ttf$/, ''),
                    value: fontName.replace(/\.ttf$/, '')
                };
            });

            const mergedFontList = [...fontList, ...fontsArray];
    
            setFontList(mergedFontList);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseAlert}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            {
                currentFields.map((field, index) => {

                    let fieldName = field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    if(fieldName.includes('_o')){
                        const words = field?.field.split('_');

                        fieldName = 'Other '+words[0].charAt(0).toUpperCase() + words[0].slice(1)+' '+words[1]
                    }else if(fieldName.includes(' o')){
                        const words = field?.field.split('_');
                        
                        fieldName = 'Other '+words[0].charAt(0).toUpperCase() + words[0].slice(1)
                    }

                    let axisX = field.style?.x
                    let axisY = field.style?.y
                    let font = field.style?.font
                    let alignment = field.style?.alignmentX
                    let orientation = field.style?.orientation
                    let sizes = field.style?.size
                    let colors = field.style?.color
                    let weight = field.style?.weight
                    let transform = field.style?.textTransform
                    let width = field.style?.width
                    let height = field.style?.height
                    let maxLetter = field.maxLetterPerLine
                    
                    let icon = field?.iconLabel ? getIconByName(field?.iconLabel) : faIcons;

                    let defaultIconIndex

                    iconsArray.find((icon, index) => {
                        if (icon.value === field?.iconLabel) {
                            defaultIconIndex = index;
                            return true; 
                        }
                        return false;
                    });

                    const handleIconImageUpload = async (file) => {
                        setIconUploadFile(file)
                        setIconUploadLoader(true)

                        const uploadedFile = file[0]

                        if (uploadedFile) {
                            const reader = new FileReader();

                            reader.onload = async (e) => {
                                const base64Data = e.target.result.split(',')[1];

                                // const formData = new FormData();
                                // formData.append('file', file[0]);
                                // formData.append('template_id', template_id);
                                // formData.append('corp_id', corp_id);
                                // formData.append('field', fieldName)

                                await ApiPost("/upload-icon-namecard-template", {
                                    file: base64Data,
                                    fileType: file[0].name.split('.').pop(),
                                    template_id: template_id,
                                    corp_id: corp_id,
                                    field: fieldName
                                }).then((response)=>{
                                    if(response.data.message === 'success'){
                                        setNewlabel(field?.label, field?.labelStyle?.color, field?.labelStyle?.size, field?.field, response.data.data, true, field?.iconType)
                                        setAlertAttr({visible: true, message: `Success uploading your attach icon.`, color: 'green'});
                                        setIconUploadLoader(false)
                                    }else{
                                        setAlertAttr({visible: true, message: `Error uploading your attach icon.`, color: '#b2102f'});
                                        setIconUploadLoader(false)
                                    }
                                }).catch((error)=>{
                                    console.log("error", error);
                                    setAlertAttr({visible: true, message: `Error uploading your attach icon. Please try again`, color: '#b2102f'});
                                    setIconUploadLoader(false)
                                })
                            }

                            reader.readAsDataURL(uploadedFile);
                        }
                    };

                    return (
                        field.field === 'building' || field.field === 'room' || field.field === 'street' || field.field === 'country' ?
                            <></>
                        : 
                            <div style={{display: fieldToFocus === field.field ? 'block' : 'none'}}>
                            <div style={{
                                background: index % 2 === 0 ? '#f0f0f0' : '#ffffff',
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingTop: 30,
                                paddingBottom: 10,
                                }} id={field.field}>
                                {
                                    field?.field !== "logo" ?
                                    <p className='fields_label' key={index}>{fieldName}</p>
                                    :
                                    <p className='fields_label' key={index}>{field.title}</p>
                                }
                                
                                {
                                    field?.field !== "logo" ? <div className='input_fields_container'>
                                        {
                                            !field?.isIcon ? <AppInput
                                                label='Label' 
                                                style={{width: '80%', marginRight: 10}} 
                                                inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}  
                                                defaultValue={field?.label} 
                                                onChange={(val)=> setNewlabel(val, field?.labelStyle?.color, field?.labelStyle?.size, field?.field, field?.iconLabel, false, field?.iconType)} 
                                            /> : <>
                                                {/* <div style={{marginRight: 5, width: '33%'}}> */}
                                                <div style={{width: '85%', marginRight: 10}}>
                                                    <div>
                                                        <AppDropdown 
                                                            label='Label Selection Option'
                                                            defaultValue={field?.iconType}
                                                            onSelect={(val)=> {
                                                                setNewlabel(field?.label, field?.labelStyle?.color, field?.labelStyle?.size, field?.field, '', true, val);
                                                                setIconUploadFile()
                                                            }}
                                                            data={[{'label':'Select from list','value':'select'},{'label':'Logo URL','value':'url'},{'label':'Upload Logo','value':'upload'}]} 
                                                            style={{width: '100%', marginRight: 14, marginBottom: 10}}
                                                        />
                                                    </div>
                                                    {/* <div style={{display: 'flex', height: '47%', marginTop: -3, height: 37.13, width: 200.13}}> */}
                                                    {
                                                        field?.iconType === 'select' || field?.iconType === undefined ? <>
                                                            <Typography variant="subtitle1" style={{fontSize: 14}}>Label</Typography>
                                                            <div style={{display: 'flex'}}>
                                                                <div style={{width: '100%', marginBottom: 10}}>
                                                                    <Select
                                                                        defaultValue={field?.iconLabel !== '' ? iconsArray[defaultIconIndex] : selectedIcon.label}
                                                                        onChange={(selectedOption) => {
                                                                            const selectedValue = iconsArray.find((icon) => icon.label === selectedOption.label)?.value;
                                                                            setNewlabel(field?.label, field?.labelStyle?.color, field?.labelStyle?.size, field?.field, selectedValue, true, field?.iconType)
                                                                            handleIconChange(selectedOption);
                                                                        }}
                                                                        options={iconsArray}
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                            ...provided,
                                                                            borderRadius: 4,
                                                                            // height: '100%',
                                                                            height: 37.13,
                                                                            minHeight: 37.13,
                                                                            paddingLeft: 5,
                                                                            paddingRight: 5,
                                                                            fontSize: 14
                                                                            }),
                                                                            option: (provided) => ({
                                                                            ...provided,
                                                                            padding: '10px',
                                                                            width: '100%',
                                                                            }),
                                                                        }}
                                                                        isSearchable={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </> : field.iconType === 'url' ? <div style={{display: 'flex'}}>
                                                            <div style={{width: '100%'}}>
                                                                <AppInput 
                                                                    label='Logo URL' 
                                                                    style={{width: '116%', marginRight: 14}} 
                                                                    inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                                    defaultValue={field?.iconLabel} 
                                                                    onChange={(val)=> setNewlabel(field?.label, field?.labelStyle?.color, field?.labelStyle?.size, field?.field, val, true, field?.iconType)} 
                                                                />
                                                            </div>
                                                        </div> : <div style={{display: 'flex'}}>
                                                            <div style={{width: '100%', marginBottom: 20}}>
                                                                <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload Files(s)</label>
                                                                <Dropzone onDrop={acceptedFiles => handleIconImageUpload(acceptedFiles)}>
                                                                    {({getRootProps, getInputProps}) => (
                                                                    <section style={{width: '111%'}} className={errorUpload ? 'drag_drop_container_err' : 'drag_drop_container'}>
                                                                        <div {...getRootProps()} className='files_container'>
                                                                        <input {...getInputProps()}/>
                                                                        <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                                                                        <p style={{color:'rgb(20, 110, 228)'}}>{field?.iconLabel ? field?.iconLabel.substring(0,12)+"..." : 'Drag and drop files here or browse'}</p>
                                                                        </div>
                                                                    </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </div>
                                                    } 
                                                </div>
                                            </>
                                        }
                                        <IconButton
                                            onClick={() => {
                                                setNewlabel(field?.label, field?.labelStyle?.color, field?.labelStyle?.size, field.field, field?.iconLabel, !field?.isIcon, field?.iconType)
                                                handleNewIcon(field?.field);
                                            }}
                                            style={{
                                                marginRight: 10,
                                                padding: 0,
                                                borderRadius: '50%',
                                                width: 48,
                                                height: 48,
                                                top: (field?.iconType === 'select' || field?.iconType === undefined) && field?.isIcon  ? 95 : 22,
                                                // top: 22,
                                                left: -10,
                                                color: field?.isIcon === true ? "#01082E" : "rgba(0, 0, 0, 0.54)"
                                            }}
                                        >
                                            {/* <FontAwesomeIcon icon={icon !== null ? icon : newIcon !== false  ? faIcons : faIcons}/> */}
                                            <FontAwesomeIcon icon={field?.isIcon === true && field?.iconType === 'select' ? icon : faIcons}/>
                                        </IconButton>
                                        <AppInput 
                                            label='Label Width' 
                                            style={{width: '31%', marginRight: 14}} 
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                            defaultValue={field?.labelStyle?.width} 
                                            onChange={(val)=> setNewlabel(field?.label, field?.labelStyle?.color, field?.labelStyle?.size, field?.field, field?.iconLabel, field?.isIcon, field?.iconType, val)} 
                                        />
                                        <AppInput 
                                            label='Label Color' 
                                            style={{width: '31%', marginRight: 14}} 
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                            defaultValue={field?.labelStyle?.color} 
                                            onChange={(val)=> setNewlabel(field?.label, val, field?.labelStyle?.size, field?.field, field?.iconLabel, field?.isIcon, field?.iconType, field?.labelStyle?.width)} 
                                        />
                                        <AppInput 
                                            label='Label Size' 
                                            style={{width: '21%', marginRight: 14}} 
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                            defaultValue={field?.labelStyle?.size} 
                                            isNumber={true} 
                                            onChange={(val)=> setNewlabel(field?.label, field?.labelStyle?.color, val, field?.field, field?.iconLabel, field?.isIcon, field?.iconType, field?.labelStyle?.width)}
                                        />

                                        <div className='logo-border'></div>
                                        {
                                            field.field === 'address' ? 
                                                checked.single ? 
                                                <>
                                                    <AppInput 
                                                        checkBox={field.field} 
                                                        checkBoxState={checked}
                                                        checkBoxhandleChange={(event,data)=>handleChange(event,data)}
                                                        label='Template Values' 
                                                        style={{width: '100%', marginRight: 14}} 
                                                        inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                        defaultValue={field?.template_val} 
                                                        onChange={(val)=> setTemplateVal(val, field.field)} 
                                                    />
                                                    <AppInput 
                                                        checkBox={"autoAlign"} 
                                                        checkBoxState={field?.autoAlign?.isAutoAlign}
                                                        checkBoxhandleAutoAlign={(data)=> setAutoAlign(data, 0, field.field, 'check')}
                                                        label='Row #' 
                                                        pattern='^[0-9]*$'
                                                        isNumber={true}
                                                        style={{width: '100%', marginRight: 14}} 
                                                        inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                        defaultValue={[field?.autoAlign?.row, field?.autoAlign?.group, field?.autoAlign?.moveUp, field?.autoAlign?.isMoveHorizontal, field?.autoAlign?.column]} 
                                                        onChange={(val, attr)=> setAutoAlign(field?.autoAlign?.isAutoAlign, val, field.field, attr)} 
                                                    />
                                                </>
                                                :
                                                <>
                                                    <AppInput 
                                                        checkBox={field.field} 
                                                        checkBoxState={checked}
                                                        checkBoxhandleChange={(event,data)=>handleChange(event,data)}
                                                        label='Building' 
                                                        style={{width: '47.5%', marginRight: 14}} 
                                                        inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                        defaultValue={defaulFields
                                                            .filter(obj => obj.field === "building")
                                                            .map(obj => obj.template_val)} 
                                                        onChange={(val)=> setNewAddressTemplateVal(val, 'building')} 
                                                        onChangeRow={(val)=> setNewAddressTemplateVal(parseInt(val), 'building', 2)}
                                                        onChangeRowMove={(val)=> setNewAddressTemplateVal(val, 'building', 3)}
                                                        rowVal={
                                                            defaulFields
                                                            .filter(obj => obj.field === "building")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "building")
                                                            .map(obj => obj.row)
                                                            : 1 
                                                        }
                                                        alignValue={
                                                            defaulFields
                                                            .filter(obj => obj.field === "building")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "building")
                                                            .map(obj => obj.moveUp)
                                                            : true
                                                        }
                                                        fromDesigner={true}
                                                    />
                                                    <AppInput 
                                                        label='Room #' 
                                                        style={{width: '47.5%', marginRight: 14, display:"flex",alignSelf:"center", paddingTop:"6%"}} 
                                                        inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                        defaultValue={defaulFields
                                                            .filter(obj => obj.field === "room")
                                                            .map(obj => obj.template_val)} 
                                                        onChange={(val)=> setNewAddressTemplateVal(val, 'room')}
                                                        onChangeRow={(val)=> setNewAddressTemplateVal(parseInt(val), 'room', 2)}
                                                        onChangeRowMove={(val)=> setNewAddressTemplateVal(val, 'room', 3)}
                                                        rowVal={
                                                            defaulFields
                                                            .filter(obj => obj.field === "room")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "room")
                                                            .map(obj => obj.row)
                                                            : 2 
                                                        }
                                                        alignValue={
                                                            defaulFields
                                                            .filter(obj => obj.field === "room")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "room")
                                                            .map(obj => obj.moveUp)
                                                            : true
                                                        }
                                                        fromDesigner={true}
                                                            
                                                    />
                                                    <AppInput 
                                                        label='Street' 
                                                        style={{width: '47.5%', marginRight: 14}} 
                                                        inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                        defaultValue={defaulFields
                                                            .filter(obj => obj.field === "street")
                                                            .map(obj => obj.template_val)} 
                                                        onChange={(val)=> setNewAddressTemplateVal(val, 'street')} 
                                                        onChangeRow={(val)=> setNewAddressTemplateVal(parseInt(val), 'street', 2)}
                                                        onChangeRowMove={(val)=> setNewAddressTemplateVal(val, 'street', 3)}
                                                        rowVal={
                                                            defaulFields
                                                            .filter(obj => obj.field === "street")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "street")
                                                            .map(obj => obj.row)
                                                            : 3 
                                                        }
                                                        alignValue={
                                                            defaulFields
                                                            .filter(obj => obj.field === "street")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "street")
                                                            .map(obj => obj.moveUp)
                                                            : true
                                                        }
                                                        fromDesigner={true}
                                                    />
                                                    <AppInput 
                                                        label='Country' 
                                                        style={{width: '47.5%', marginRight: 14}} 
                                                        inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                        defaultValue={defaulFields
                                                            .filter(obj => obj.field === "country")
                                                            .map(obj => obj.template_val)} 
                                                        onChange={(val)=> setNewAddressTemplateVal(val, 'country')} 
                                                        onChangeRow={(val)=> setNewAddressTemplateVal(parseInt(val), 'country', 2)}
                                                        onChangeRowMove={(val)=> setNewAddressTemplateVal(val, 'country', 3)}
                                                        rowVal={
                                                            defaulFields
                                                            .filter(obj => obj.field === "country")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "country")
                                                            .map(obj => obj.row)
                                                            : 4
                                                        }
                                                        alignValue={
                                                            defaulFields
                                                            .filter(obj => obj.field === "country")
                                                            .map(obj => obj.row) !== ''
                                                            ? defaulFields
                                                            .filter(obj => obj.field === "country")
                                                            .map(obj => obj.moveUp)
                                                            : true
                                                        }
                                                        fromDesigner={true}
                                                    />
                                                </>
                                            :
                                            <>
                                                <AppInput 
                                                    checkBox={field.field} 
                                                    label='Template Values' 
                                                    style={{width: '100%', marginRight: 14}} 
                                                    inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                    defaultValue={field?.template_val} 
                                                    onChange={(val)=> setTemplateVal(val, field.field)} 
                                                />
                                                <AppInput 
                                                    checkBox={"autoAlign"} 
                                                    checkBoxState={field?.autoAlign?.isAutoAlign}
                                                    checkBoxhandleAutoAlign={(data)=> setAutoAlign(data, 0, field.field, 'check')}
                                                    label='Row #' 
                                                    pattern='^[0-9]*$'
                                                    isNumber={true}
                                                    style={{width: '100%', marginRight: 14}} 
                                                    inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                    defaultValue={[field?.autoAlign?.row, field?.autoAlign?.group, field?.autoAlign?.moveUp, field?.autoAlign?.isMoveHorizontal, field?.autoAlign?.column]} 
                                                    onChange={(val, attr)=> setAutoAlign(field?.autoAlign?.isAutoAlign, val, field.field, attr)} 
                                                />
                                            </>
                                        
                                        }

                                        <div className='logo-border'></div>
                                        <AppInput 
                                            label='Field Width (%)'
                                            defaultValue={width}
                                            onChange={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, colors, weight, transform, field.field, val, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            style={field.field == "address" ? {width: '48%', marginRight: 14, display:"flex",alignSelf:"center"} :{width: '47.5%', marginRight: 14}}
                                            isNumber={true}
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}
                                        />
                                        <AppInput 
                                            label='Field Height (px)'
                                            defaultValue={height}
                                            onChange={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, colors, weight, transform, field.field, width, val, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            style={{width: '47.5%', marginRight: 14}}
                                            isNumber={true}
                                            heightLimit={cardOrientation ? 630 : 344}
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}
                                        />
                                        <AppInput 
                                            label='Size'
                                            defaultValue={sizes}
                                            isNumber={true}
                                            onChange={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, val, colors, weight, transform, field.field, width, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            style={{width: '31%', marginRight: 14}}
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}
                                        />
                                        <AppInput 
                                            label='Color'
                                            defaultValue={colors}
                                            onChange={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, val, weight, transform, field.field, width, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            style={{width: '31%', marginRight: 14}}
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}
                                        />
                                        <AppDropdown 
                                            label='Weight'
                                            defaultValue={weight}
                                            onSelect={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, colors, val, transform, field.field, width, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            data={[{'label':'Normal','value':'normal'},{'label':'Bold','value':'bold'}]} 
                                            style={{width: '31%', marginRight: 14, marginBottom: 15}}
                                        />
                                        <AppDropdown 
                                            label='Orientation'
                                            defaultValue={orientation}
                                            onSelect={(val)=> setNewStyleVal(axisX, axisY, font, alignment, val, sizes, colors, weight, transform, field.field, width, val === 'vertical' ? 344 : '', field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            data={[{'label':'Horizontal','value':'horizontal'},{'label':'Vertical','value':'vertical'}]} 
                                            style={{width: '47.5%', marginRight: 14, marginBottom: 15}}
                                        />
                                        <AppDropdown 
                                            label='Text Transform'
                                            defaultValue={transform}
                                            onSelect={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, colors, weight, val, field.field, width, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            data={[{'label':'None','value':'none'},{'label':'Uppercase','value':'uppercase'},{'label':'Lower Case','value':'lowercase'}]} 
                                            style={{width: '47.5%', marginRight: 14, marginBottom: 15}}
                                        />
                                        <AppDropdown 
                                            label='Text Alignment'
                                            defaultValue={alignment}
                                            onSelect={(val)=> setNewStyleVal(axisX, axisY, font, val, orientation, sizes, colors, weight, transform, field.field, width, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            data={[{'label':'Default','value':'default'},{'label':'Left','value':'left'},{'label':'Center','value':'center'},{'label':'Right','value':'right'}]} 
                                            style={{width: '47.5%', marginRight: 14, marginBottom: 15}}
                                        />
                                        <AppDropdown 
                                            label='Font'
                                            defaultValue={font}
                                            onSelect={(val)=> setNewStyleVal(axisX, axisY, val, alignment, orientation, sizes, colors, weight, transform, field.field, width, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            data={fontList} 
                                            style={{width: '47.5%', marginRight: 14, marginBottom: 15}}
                                        />
                                    </div> : <div className='input_fields_container'>
                                        
                                        {/* <AppDropdown 
                                            label='Image Attachment Option'
                                            defaultValue={field.logoType}
                                            onSelect={(val)=> setLogoType(val, field.field) } 
                                            data={[{'label':'Image URL','value':'url'},{'label':'Upload Image','value':'upload'}]} 
                                            style={{width: '100%', marginRight: 14, marginBottom: 15}}
                                        />
                                        {
                                            field.logoType === 'upload' ?
                                                <div style={{paddingBottom:"20px", width: '98%'}}>
                                                    <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload Files(s)</label>
                                                    <Dropzone onDrop={acceptedFiles => handleLogoImageUpload(acceptedFiles)}>
                                                        {({getRootProps, getInputProps}) => (
                                                        <section className={errorUpload ? 'drag_drop_container_err' : 'drag_drop_container'}>
                                                            <div {...getRootProps()} className='files_container'>
                                                            <input {...getInputProps()}/>
                                                            <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                                                            <p style={{color:'rgb(20, 110, 228)'}}>{uploadFile ? uploadFile[0]?.name.substring(0,12)+"..." : 'Drag and drop files here or browse'}</p>
                                                            </div>
                                                        </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            : <AppInput 
                                                checkBox={field.field} 
                                                checkBoxState={checked}
                                                checkBoxhandleChange={(event,data)=>handleChange(event,data)}
                                                label='Template Value' 
                                                style={{width: '100%', marginRight: 14}} 
                                                inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}} 
                                                defaultValue={field?.template_val} 
                                                onChange={(val)=> setTemplateVal(val, field.field)} 
                                            />
                                        } */}
                                        <AppInput 
                                            label='Field Width (%)'
                                            defaultValue={width}
                                            onChange={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, colors, weight, transform, field.field, val, height, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            style={field.field == "address" ? {width: '48%', marginRight: 14, display:"flex",alignSelf:"center"} :{width: '47.5%', marginRight: 14}}
                                            isNumber={true}
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}
                                        />
                                        <AppInput 
                                            label='Field Height (px)'
                                            defaultValue={height}
                                            onChange={(val)=> setNewStyleVal(axisX, axisY, font, alignment, orientation, sizes, colors, weight, transform, field.field, width, val, field?.labelStyle?.size, field?.labelStyle?.color)} 
                                            style={{width: '47.5%', marginRight: 14}}
                                            isNumber={true}
                                            heightLimit={cardOrientation ? 630 : 344}
                                            inputStyle={{marginBottom: 15, backgroundColor: '#FFFFFF'}}
                                        />
                                    </div>
                                }
                            </div>
                            <div className='field-border'></div>
                            </div>
                    )
                })
            }
            <Snackbar
                ContentProps={{
                sx: {
                    background: alertAttr.color
                }
                }}
                open={alertAttr.visible}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={anchorOrigin}
                message={alertAttr.message}
                action={action}
            />

            <AppLoader loader={iconUploadLoader} /> 
        </>
    )
}

export default AppFieldsAttribute;