import { Menu, MenuItem, Fade } from "@mui/material";
// import { makeStyles } from "@material-ui/core/styles";

const AppDropDownMenu = ({
    anchorEl,
    handleClose,
    handleSelect,
    open,
    DataList=[],
    style={}
}) => {
    // const useStyles = makeStyles({
    //     root: style
    // });

    // const classes = useStyles();
    return (
        <Menu
            id="fade-menu"
            MenuListProps={{
                'aria-labelledby' : 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            elevation={2}
            onClose={handleClose}
            TransitionComponent={Fade}
            // className={classes.root}
        >
            {
                DataList.map((data, index) => {
                    const colorText = data.menu === 'Delete Account' || data.menu === 'Delete License' ? '#E5383B' : null;
                    return (
                        <MenuItem 
                            key={index} 
                            disabled={data.disabled} 
                            onClick={() => handleSelect(data.menu)} 
                            style={{ color: colorText, fontSize: 14 }}
                        >
                            {data.menu}
                        </MenuItem>
                    )
                })
            }
        </Menu>
    )
}

export default AppDropDownMenu;