import React,{useEffect, useState}  from 'react';
import "./styles.scss";
import { Header } from '../../../layouts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AppModal, AppTable, AppInput, AppButton, AppAlertDialog, AppDropdown, AppLoader, AppUploadPhotoForm, AppMultiAddressFields, AppImportUserDialog, AppQRAttributes, AppQRGenerator, AppImageCropper } from '../../../components';
import { headCells3, headCells4 } from './defaultContactListTableHead';
import { headCells, headCellsWithPhoto } from './defaultNameCardData';
import { headCells2, headCells2WithPhoto } from './otherNameCardsData';
import { useSelector, useDispatch } from 'react-redux';
import { ApiPost, ApiGet } from '../../../services/AxiosInstance';
import { exportToExcel, exportToExcelContacts } from '../../../utilities';
import Dropzone from 'react-dropzone';

/* MUI */
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FormHelperText from '@mui/material/FormHelperText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, Typography } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Joyride from 'react-joyride';
import { steps } from './walkthroughStepsUser';
import { setUserData } from '../../../redux/reducer/User';

import blankTemplate from "../../../assets/image/template_blank.png"
import DataEntryFields from './DataEntryFields';
import zIndex from '@mui/material/styles/zIndex';
import QRCode from 'qrcode.react';
import { getEnvVars } from '../../../services/Environment';
import { qrJson, qrJsonDefault } from '../../../components/AppQRAttributes/qrJson';
import useCacheBuster from '../../../components/AppTables/useCacheBuster';

export default function UserListPage(navigate) {

  const userData = useSelector((state)=> state.user.userData);
  const corp_id = userData[0].corp_id;
  const adminDept = userData[0].metadata?.filters;
  const isInstitutionAdmin = userData[0].metadata?.permission?.institutionAccess
  const canImport = userData[0].metadata?.permission?.importUsers
  const userFilter = userData[0]?.metadata?.filters ? userData[0]?.metadata?.filters :  []
  const role = userData[0].role;
  const dispatch = useDispatch();
  
  const [listData, setListData] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [listDataSub, setListDataSub] = useState([]);
  const [originalListSub, setOriginalListSub] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [isEdit, setIsEdit ] = useState(false);
  const [isEditSub, setIsEditSub] = useState(false);
  const [isSuspend, setisSuspend] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isRemove, setIsRemove ] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isAddSub, setIsAddSub] = useState(false);
  const [isDeleteSub, setIsDeleteSub] = useState(false);
  const [isActiveId, setIsActiveId] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [suspendRow, setSuspendRow] = useState(null);
  const [success, setSuccess] = useState(false)
  const [templates, setTemplates] = useState([])
  const [allTemplates, setAllTemplates] = useState([])
  const [isSelected, setIsSelected] = useState();
  const [isSelectedTemplate, setIsSelectedTemplate] = useState()
  const [templateData, setTemplateData] = useState([]);
  const [templateDataBack, setTemplateDataBack] = useState([]);
  const [templateDataOthers, setTemplateDataOther] = useState([]);
  const [templateDataOthersBack, setTemplateDataOtherBack] = useState([]);
  const [templateDataEdit, setTemplateDataEdit] = useState([]);
  const [templateDataBackEdit, setTemplateDataBackEdit] = useState([]);
  const [selectedValue, setSelectedValue] = useState('item0');
  const [selectedName, setSelectedName] = useState('item0');
  const [selectedItems,setSelectedItems] = useState([])
  const [contactSelectedItems,setContactSelectedItems] = useState([])
  const [deleteSelected,setDeleteSelected] = useState(false);
  const [deleteContactSelected,setDeleteContactSelected] = useState(false);
  const [deleteIds,setDeleteIds] = useState([]);
  const [activationEmails,setActivationEmails] = useState([]);
  const [activationModals,setActivationModals] = useState(false);

  const [isDeleteMultipleSub, setIsDeleteMultipleSub] = useState(false);
  const [trackSelected,setIsTrackSelected] = useState();
  const [trackContactSelected,setIsContactTrackSelected] = useState();
  const [isLoading,setIsloading] = useState(false);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [importFile, setImportFile] = useState();
  const [alertDialog, setAlertDialog] = useState({visible: false, title: null, message: null});
  const [importDialog, setImportDialog] = useState({visible: false, title: null, subTitle: null, message: null});
  const [duplicateEmail, setDuplicateEmail] = useState([]);
  const [importAlert, setImportAlert] = useState(false);
  const [contactSearch,setContactSearch] = useState("");

  const licenseUserData = useSelector((state) => state.license.licenseUserData);

  const [languages, setLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguages] = useState('English');
  const [allMainListData, setAllMainListData] = useState([]);
  const [allSubListData, setAllSubListData] = useState([]);
  const [mainListWithoutSub, setMainListWithoutSub] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [contactItemsPerPage, setContactItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [contactPage, setContactPage] = useState(0);
  const [filterStatus, setFilterStatus] = useState("All");
  const [searchFilterKey, setSearchFilterKey] = useState('All');
  const [searchFilterKeyWord, setSearchFilterKeyword] = useState('');
  const [searchFilterType, setSearchFilterType] = useState('status');
  const [userTableConfig, setUserTableConfig] = useState({
    totalCount: 0,
    totalPages: 1
  });
  const [userContactTableConfig, setUserContactTableConfig] = useState({
    totalCount: 0,
    totalPages: 1
  });

  const [tabIndicator,setTabIndicator] = useState("default");
  const [metaData,setMetadata] = useState({
    name:"",
    position:"",
    office_number:"",
    mobile_number:"",
    fax_number:"",
    email:"",
    status:"",
  })
  const [metaDataBack,setMetadataBack] = useState({
    name:"",
    position:"",
    office_number:{
      number:"",
      extension:""
    },
    mobile_number:{
      number:""
    },
    fax_number:{
      number:"",
      extension:""
    },
    email:"",
    status:"",
  })
  const [switchButton, setSwitchButton] = useState(true)
  const [isMultiAddress, setIsMultiAddress] = useState(false);
  const [isMultiAddressBack, setIsMultiAddressBack] = useState(false);
  const [isMultiAddressOthers, setIsMultiAddressOthers] = useState(false);
  const [isMultiAddressOthersBack, setIsMultiAddressOthersBack] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [alertAttr, setAlertAttr] = useState({visible: false, message: null, color: null});
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const [passwordOptions, setPasswordOptions] = useState([
    {
      count: "0",
      field: "fax_number",
      name: "Fax Number"
    },
    {
      count: "1",
      field: "mobile_number",
      name: "Mobile Number"
    },
    {
      count: "2",
      field: "office_number",
      name: "Office Number"
    }
  ])

  const [errorUpload, setErrorUpload] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [uploadType, setUploadType] = useState("upload");
  const [hasLogo, setHasLogo] = useState("");
  const [hasLogoOthers, setHasLogoOthers] = useState("upload");
  const [isUploadPhoto, setIsUploadPhoto] = useState(false);
  const [defaultLogoVal, setDefaultLogoVal] = useState("");
  const [saveAsMain, setSaveAsMain] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null);
  const [defaultTempId, setDefaultTempId] = useState();
  const [totalTableCount, setTotalTableCount] = useState(null);
  const [totalTableCountSub, setTotalTableCountSub] = useState();
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [numberUsersPlan, setNumberUsersPlan] = useState(0)

  const [tourSteps, setTourSteps] = useState([]);
  const [runTour, setRunTour] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [subCompList, setSubCompList] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);

  const [previewModal, setPreviewModal] = React.useState({visible: false, img1: null, img2: null, isQRCode: false});
  const [previewFront, setPreviewFront] = React.useState(true);
  const [side, setSide] = useState('front');
  const [frontOrientation, setFrontOrientation] = useState('horizontal');
  const [backOrientation, setBackOrientation] = useState('horizontal');
  const [templateBackgroundFront, setTemplateBackgroundFront] = useState(blankTemplate);
  const [templateBackgroundBack, setTemplateBackgroundBack] = useState(blankTemplate)
  const [templateBackgroundFrontOthers, setTemplateBackgroundFrontOthers] = useState(blankTemplate);
  const [templateBackgroundBackOthers, setTemplateBackgroundBackOthers] = useState(blankTemplate);
  const [adminList, setAdminList] = useState([]);
  const [subCompOption, setSubCompOption] = useState([]);
  const [deptFilterOption, setDeptFilterOption] = useState([]);
  const [toAssignSubComp, setToAssignSubComp] = useState(licenseUserData ? licenseUserData[0].subCompId : '1');
  const [toAssignDepartment, setToAssignDepartment] = useState(licenseUserData ? licenseUserData[0].deptId : '1');
  const [checkMainEmail, setCheckMainEmail] = useState(0)

  const [isLoadingLicenseTable ,setIsLoadingLicenseTable] = useState(true);
  const [licensesList, setLicensesList] = useState([]);
  const [licenseTableConfig, setLicenseTableConfig] = useState({
    totalCount: 0,
    totalPages: 1
  });
  const [licensePage, setLicensePage] = useState(0);
  const [licenseItemsPerPage, setLicenseItemsPerPage] = useState(10);
  const [licenseSearch, setLicenseSearch] = useState("");
  const [licenseDropDown, setLicenseDropDown] = useState("All");
  const [isDeleteLicense, setIsDeleteLicense] = useState(false);
  const [defaultDepartment, setDefaultDepartment] = useState();

  const [alertDialogQR, setAlertDialogQR] = useState(false);
  const [editQRTemplate, setEditQRTemplate] = useState(false);
  const [qrMeta, setQRMeta] = useState(qrJson)
  const [originalQRData, setOriginalQRData] = useState({
    "color": '#811aff',
    "backgroundColor": '#FFFFFF',
    "enableLinearGradient": true,
    "linearGradient": ['#811aff', '#0e80f1'],
    "gradientDirection": ["0%", "100%"],
    "logo": '',
    "logoSize": 30,
    "logoBackgroundColor": 'transparent',
    "logoMargin": 2,
    "logoBorderRadius": 0,
  })

  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [imgFile, setImgFile] = useState([])
  const { cacheBuster, updateCacheBuster } = useCacheBuster();

  // const [userContactTableConfig, setUserContactTableConfig] = useState({
  //   totalCount: 0,
  //   totalPages: 1
  // });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCorpDetails();
        await getSubCompany();
        await getDepartments();
        await getQRTemplate();
        await fetchLanguage();
        await joyrideSteps();
        if(licenseUserData){
          await getLicenseUsers();
        }else{
          await getTemplate();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    getUserCount();
  },[deptFilterOption])

  useEffect(() => {
    getFilterOptionsOrg();
  }, [subCompList, departmentList]);

  useEffect(()=>{
    
    getContactListData();
    
  },[JSON.stringify(contactPage), JSON.stringify(contactItemsPerPage), contactSearch])

  useEffect(()=>{
    
    getLicenses();
    
  },[JSON.stringify(licensePage), JSON.stringify(licenseItemsPerPage), licenseSearch, licenseDropDown])

  useEffect(()=>{
    getAllMainWithoutSub();
  },[listDataSub])

  const getFilterOptionsOrg = async () => {

    let filteredSubCompList = subCompList
    let filteredDepartmentList = departmentList

    if(role === 'Admin'){
      if(userFilter){
        filteredSubCompList = subCompList.filter(subComp => 
          userFilter.some(filterItem => filterItem.subCompId === subComp.id)
        );
    
        const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];
      
        filteredDepartmentList = departmentList.filter(dept => 
          uniqueDeptIds.includes(dept.id)
        );
      }
    }

    const subCompOptions = filteredSubCompList.map((item, parentIndex) => {

      const matchingDepartmentIds = item?.departments?.filter((deptId) => filteredDepartmentList.some((dept) => dept.id === deptId)).map((deptId) => deptId) || [];
        
      return { label: item.name, value: item.id };
    });

    subCompOptions.unshift({ label: '-- Select Institution --', value: '1' });
    setSubCompOption(subCompOptions)
    getFilterOptionsDept();
  }

  const getFilterOptionsDept = async (selectedId) => {

    let filteredSubCompList = subCompList;
    let filteredDepartmentList = departmentList;

    if (role === 'Admin') {
        if (userFilter) {
            filteredSubCompList = subCompList.filter(subComp => 
                userFilter.some(filterItem => filterItem.subCompId === subComp.id)
            );
        
            // Extract unique department IDs from userFilter
            const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];
        
            // Filter departmentList based on uniqueDeptIds
            filteredDepartmentList = departmentList.filter(dept => 
                uniqueDeptIds.includes(dept.id)
            );
        }
    }

    
    let deptFilterOption;
    if (selectedId) {
      const filterSubComp = subCompList.filter(subComp => selectedId === subComp.id);

      const matchingDepartmentIds = filterSubComp[0]?.departments?.filter(deptId => 
          filteredDepartmentList.some(dept => dept.id === deptId)
      ).map(deptId => deptId) || [];

      deptFilterOption = matchingDepartmentIds.map(deptItem => {
          const getDept = filteredDepartmentList.find(item => item.id === deptItem);
          
          return { label: `${getDept.department_name} ${getDept?.cost_center ? ' - ' + getDept?.cost_center : ''}`, value: getDept.id };
      });
    } else {
        deptFilterOption = filteredDepartmentList.map(item => ({
            label: `${item.department_name} ${item?.cost_center ? ' - ' + item?.cost_center : ''}`,
            value: item.id
        }));
    }

    deptFilterOption.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    
    deptFilterOption.unshift({ label: '-- Select Department --', value: '1' });
    setDeptFilterOption(deptFilterOption);
  }


  const joyrideSteps = async () => {
    setTourSteps(steps);
    if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.userlist){
      setRunTour(true);
    }
  }

  const getCorpDetails = async () => {
    try {
      const response = await ApiPost("/corps/details", { corp_id: corp_id });
      if (response.data.data.length > 0) {

        const users = response.data.data[0].users

        const mergedUsers = users?.Admin ? users.Owner.concat(users?.Admin) : users.Owner;
        setAdminList(mergedUsers)

        if(response.data.data[0]?.metadata?.passwordSetting){
          setPasswordOptions(response.data.data[0]?.metadata?.passwordSetting)
        }
      } else {
        getCorpDetails();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const switchToTab = async (item) => {
    const userMeta = userData[0].metadata;

    if(item.action === 'reset'){
      if (!userMeta.walkthrough || !userMeta.walkthrough.userlist) {
        const updatedMeta = {
          ...userMeta,
          walkthrough: {
            ...(userMeta.walkthrough || {}),
            userlist: true,
          },
        };
  
        const data = await ApiPost("/corps/update-corps-user", {
          id: userData[0].id,
          metadata: updatedMeta,
          walkthrough: true
        });

        if(data.status === 200){
          const updatedArray = userData.map(obj => ({
            ...obj,
            metadata: updatedMeta
          }));

          dispatch(setUserData(updatedArray));
        }
      }
    }else{
      if(item.step.target === '#step1' || item.step.target === '#step2'){
        setSelectedIndex(0);
      }else if(item.step.target === '#step3' || item.step.target === '#step4'){
        setSelectedIndex(1);
      }else if(item.step.target === '#step5'){
        setSelectedIndex(2);
      }
    }
    
  };

  const getListData = async (templates) => {
    setIsloading(true);

    console.log('1')
    if(adminDept && adminDept?.length > 0){
      const result = await ApiPost("/corps/user-listsv2", {
        corp_id: corp_id,
        page: page + 1,
        limit: itemsPerPage,
        template_id: isSelectedTemplate,
        userFilter: role === 'Admin' ? userFilter : []
      });


      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminList.filter(user => user?.id === item?.created_by);
          
          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            status: item.status,
            role: item.namecard_status,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail
          };
          return rowData;
        });
  
        setUserTableConfig({
          totalCount: result?.data?.data?.totalCount,
          numberOfUsers: result?.data?.data?.numberOfUsers,
        });
  
        setListData(newListUsers);
        setOriginalList(newListUsers)
        setIsloading(false);
      }
    }else{
      console.log('2')
      const result = await ApiPost("/corps/user-listsv2", {
        corp_id: corp_id,
        page: page + 1,
        limit: itemsPerPage,
        template_id:  defaultTempId,
        userFilter: role === 'Admin' ? userFilter : []
      });

      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminList.filter(user => user?.id === item?.created_by);

          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            assignDept: item?.metadata[3],
            status: item.status,
            role: item.namecard_status,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail
          };
          return rowData;
        });
  
        setUserTableConfig({
          totalCount: result?.data?.data?.totalCount,
          numberOfUsers: result?.data?.data?.numberOfUsers,
        });
  
        setListData(newListUsers);
        setOriginalList(newListUsers)
        setIsloading(false);
      }
    }

  };

  const getUserCount = async () => {

    let toAssignThis = { role: role, department: null };
    if(subCompOption.length > 0 && deptFilterOption.length > 0){
      if (toAssignDepartment !== '1' && toAssignSubComp !== '1') {
        toAssignThis.department = [{
            subCompId: toAssignSubComp,
            deptId: toAssignDepartment
        }];
      }else{
        toAssignThis.department = [{
            subCompId: subCompOption[1]?.value,
            deptId: deptFilterOption[1]?.value
        }];
      }
    }

    //if(!toAssignThis.department[0]?.deptId) return;

    const result = await ApiPost("/corps/user-lists-count", { corp_id: corp_id, department: toAssignThis });

    setTotalUserCount(result?.data?.data?.totalCount);
    setNumberUsersPlan(result?.data?.data?.number_users);
  }

  const getSubCompany = async () => {
    const response = await ApiPost("/corps/get-sub-company", {
      corp_id: corp_id,
    });

    setSubCompList(response?.data?.data)
  }

  const getDepartments = async () => {
    const response = await ApiPost("/corps/get-department", {
      corp_id: corp_id,
    });

    setDepartmentList(response?.data?.data)
  }

  const deleteLicense = async () => {
    setShowLoader(true);
    const data = await ApiPost("/corps/delete-license",{id: currentRow.id})

    if(data.data.message == "success"){
      getLicenses();
      setAlertAttr({visible: true, message: 'License deleted successfully.', color: '#4caf50'});
    }else{
      setAlertAttr({visible: true, message: 'Error deleting license.', color: '#b2102f'});
    }
    setTimeout(()=>{setShowLoader(false);});
    setIsDeleteLicense(false)
  }

  const handleDeleteLicense = (row) =>{
    setCurrentRow(row)
    setIsDeleteLicense(!isDelete)
  }

  const handleLicenseDropDown = (value) => {
    setLicenseDropDown(value)
  }

  const handleLicenseSearch = (value) => {
    setIsLoadingLicenseTable(true)
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
  
    setSearchTimeout(setTimeout(() => {
      setIsLoadingLicenseTable(false)

      if(value !== ''){
        setLicensePage(0)
      }
      setLicenseSearch(value);
    }, 2000));
  }

  const handleLicenseListPage = async (val) => {
    setLicensePage(val)
  }

  const handleLicenseItemPerPage = async (val) => {
    let offset = ((contactPage + 1) - 1) * val;
    let ppage = contactPage;
    setLicenseItemsPerPage(val);
    if(offset >= userTableConfig.totalCount) {
      ppage =  contactPage - (offset / val) + 1;
    } else {
      ppage =  contactPage + 1;
    }


    setLicensePage(ppage - 1)
  }

  const getLicenses = async () => {
    setIsLoadingLicenseTable(true)

    let params = {
      corp_id: corp_id,
      page: licensePage + 1,
      limit: licenseItemsPerPage,
      search: '',
      filter: licenseDropDown.toLowerCase(),
      userFilter: role === 'Admin' ? userFilter : []
    }

    if(licenseSearch !== ""){
      params.search = licenseSearch.toLowerCase();
    }

    const data = await ApiPost("/corps/corp-license-list", params);

    const list = data.data.data.list
    const totalCount = data.data.data.totalCount;
    const totalPages = data.data.data.totalPages;

    if(data.data.message === 'success'){
      setLicensesList(list)
      setLicenseTableConfig({
        totalCount: totalCount,
        totalPages: totalPages
      })
      setIsLoadingLicenseTable(false)
    }
  }

  const getTemplate = async () => {

    const data = await ApiPost("/corps/corp-template", { corp_id: corp_id });
    let filteredArray = []

    let adminLists = adminList
    if(adminLists.length === 0){
      const getAdmins = await ApiPost("/corps/details", { corp_id: corp_id });
      const users = getAdmins.data.data[0].users

      const mergedUsers = users?.Admin ? users.Owner.concat(users.Admin) : users.Owner;
      setAdminList(mergedUsers)
      adminLists = mergedUsers
    }
    
    
    const default_fields = data.data;
    filteredArray = default_fields.filter(obj => {
      return obj.template.every(templateObj => !('isDefault' in templateObj) || templateObj.isDefault === false);
    });
  
    const defaultTemp = default_fields.filter(obj => {
      return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
    });
  
    const hasBuilding = defaultTemp[0].template[1].front.some(obj => obj.field === 'building');
    const hasBuildingBack = defaultTemp[0].template[2].back.some(obj => obj.field === 'building');
  
    const hasLogoField = defaultTemp[0].template[1].front.some(item => item.field === "logo");

    // if(role === 'Admin' && !isInstitutionAdmin){
    //   filteredArray = filteredArray.filter(item => {
    //     if (!adminDept || adminDept.length === 0) {
    //       return true;
    //     }
      
    //     console.log('item.department  : ',item.department)
    //     const hasMatchingDepartment = item?.department?.some(department => {
    //       return item.department && item.department.some(dept => {
    //         return adminDept.some(adminDeptItem => adminDeptItem.subCompId === department.subCompId && adminDeptItem.deptId === dept.deptId);
    //       });
    //     });
      
    //     return hasMatchingDepartment;
    //   });
    // }
    
    setIsMultiAddress(hasBuilding);
    setIsMultiAddressBack(hasBuildingBack);
    setTemplateData(defaultTemp[0].template[1].front);
    setTemplateDataBack(defaultTemp[0].template[2].back);
    setTemplateDataEdit(defaultTemp[0].template[1].front)
    setTemplateDataBackEdit(defaultTemp[0].template[2].back)
    setAllTemplates(default_fields);
    setTemplates(filteredArray);
    setHasLogo(hasLogoField);
    setDefaultTempId(defaultTemp[0].id)

    if(defaultTemp[0].template[3].template_image[0].front){
      setTemplateBackgroundFront(defaultTemp[0].template[3].template_image[0].front);
    }

    if(defaultTemp[0].template[3].template_image[1].back){
      setTemplateBackgroundBack(defaultTemp[0].template[3].template_image[1].back);
    }

    // if(!adminDept || adminDept?.length === 0){
      console.log('3')
      const result = await ApiPost("/corps/user-listsv2", {
        corp_id: corp_id,
        page: page,
        limit: itemsPerPage,
        template_id:  defaultTemp[0].id,
        userFilter: role === 'Admin' ? userFilter : []
      });


      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminLists.filter(user => user?.id === item?.created_by);

          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            assignDept: item?.metadata[3],
            status: item.status,
            role: item.namecard_status,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail
          };
          return rowData;
        });

        setUserTableConfig({
          totalCount: result?.data?.data?.totalCount,
          numberOfUsers: result?.data?.data?.numberOfUsers,
        });

        setTotalTableCount(result?.data?.data?.totalCount)
        setListData(newListUsers);
        setOriginalList(newListUsers)
        setIsloading(false);
      }
    // }
  
    if (filteredArray[0]?.template) {
      const hasLogoFieldOthers = filteredArray[0].template[1].front.some(item => item.field === "logo");
      const userFilters = filteredArray[0].department
  
      setTemplateDataOther(filteredArray[0].template[1].front);
      setTemplateDataOtherBack(filteredArray[0].template[2].back);
      setIsSelected(filteredArray[0].template[0].template_name);
      setIsSelectedTemplate(filteredArray[0].id);
      setHasLogoOthers(hasLogoFieldOthers);
  
      const hasBuildingOthers = filteredArray[0].template[1].front.some(obj => obj.field === 'building');
      const hasBuildingOthersBack = filteredArray[0].template[2].back.some(obj => obj.field === 'building');
      setIsMultiAddressOthers(hasBuildingOthers);
      setIsMultiAddressOthersBack(hasBuildingOthersBack);

      if(filteredArray[0].template[3].template_image[0].front){
        setTemplateBackgroundFrontOthers(filteredArray[0].template[3].template_image[0].front);
      }

      if(filteredArray[0].template[3].template_image[1].back){
        setTemplateBackgroundBackOthers(filteredArray[0].template[3].template_image[1].back);
      }

      console.log('4')
      const result = await ApiPost("/corps/user-listsv2", {
        corp_id: corp_id,
        page: page,
        limit: itemsPerPage,
        template_id: filteredArray[0].id,
        userFilter: role === 'Admin' ? userFilter : []
      });

      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminLists.filter(user => user?.id === item?.created_by);

          let defualtInstitution
          let defaultDepartment
          const deptAssigned = item?.metadata[3]?.department

          defualtInstitution = subCompList.find((subComp) => subComp.id === deptAssigned?.subCompId)
          defaultDepartment = departmentList.find((dept) => dept.id === deptAssigned?.deptId)

          let institution = []
          let department = []

          if(deptAssigned){
            if (userFilters) {
              institution = userFilters
                .flatMap((filter) => {
                  const matchingSubComp = subCompList.find((subComp) =>
                    subComp.id === filter.subCompId
                  ); 
            
                  if (matchingSubComp) {
                    return [matchingSubComp.name];
                  } else {
                    return [];
                  }
                });
            }
  
            if (userFilters) {
              department = userFilters
                .flatMap((filter) => {
                  const matchingDept = departmentList.find((dept) =>
                    dept.id === filter.deptId
                  );
            
                  if (matchingDept) {
                    return [matchingDept.department_name];
                  } else {
                    return [];
                  }
                });
            }
  
            institution = institution.length > 0 ? institution[0] : ''
            department = department.length > 0 !== '' ? department[0] : ''
          }

          const deptName = `${defaultDepartment?.department_name}${defaultDepartment?.cost_center ? ` - ${defaultDepartment?.cost_center}` : ''}`

          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            assignDept: item?.metadata[3],
            institutions: defualtInstitution ? defualtInstitution.name : institution,
            departments:  defaultDepartment ? deptName : department,
            status: item.status,
            role: item.namecard_status,
            parent_id: item.namecard_parent_id,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail
          };
          return rowData;
        });
  
        setUserTableConfig({
          totalCount: result?.data?.data?.totalCount,
          numberOfUsers: result?.data?.data?.numberOfUsers,
        });
  
        setTotalTableCountSub(result?.data?.data?.totalCount)
        setListDataSub(newListUsers);
        setOriginalListSub(newListUsers)
        setIsloading(false);
      }
    }
  
  };

  const getAllMainWithoutSub = async () => {

    const filteredArrayMainWithSub = allMainListData.filter(obj => !listDataSub.map(mObj => mObj.name).includes(obj.name));

    setMainListWithoutSub(filteredArrayMainWithSub)
  }

  const getContactListData = async () => {
    setIsloading(true)
    let params = {
      corp_id: corp_id,
      page: contactPage + 1,
      limit: contactItemsPerPage
    }
    if(contactSearch !== ""){
      params.search = contactSearch
    }

    const contactList = await ApiPost("/corps/contact-list", params);
    const listData = contactList.data.data.contactList;
    const totalCount = contactList.data.data.totalCount;
    const totalPages = contactList.data.data.totalPages;
    if(listData.length > 0){
      const new_data = listData.map(item => {

        const createdon = new Date(item.createdon);
        const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

        const meta = item.metadata != null ? item.metadata[0].front[0] : []

        let actual
        actual = item.metadata[0]?.actualCard_url ? item.metadata[0]?.actualCard_url[0]?.img1 !== '' ?  item.metadata[0]?.actualCard_url[0]?.img1 : item.metadata[0]?.captured_img_url[0]?.img1 : Array.isArray(item.metadata[4]?.actualCard_url) ? item.metadata[4]?.actualCard_url[0]?.img1 !== '' ? item.metadata[4]?.actualCard_url[0]?.img1 : item.metadata[5]?.captured_img_url[0]?.img1 : item.metadata[4]?.actualCard_url

        const rowData = {
          user_id: item.user_id,
          corp_id: item.id,
          logo: actual,
          name: meta?.name,
          position: meta?.position,
          office_number: meta?.office_number,
          mobile_number: meta?.mobile_number,
          fax_number: meta?.fax_number,
          email: meta?.email,
          contact_of: item.useremail,
          date_added: formattedDate,
          parent_id: item.namecard_parent_id
        };
        return rowData;
      });
      setContactList(new_data);
    }else{
      setContactList([])
    }
    setUserContactTableConfig({
      totalCount: totalCount,
      totalPages: totalPages
    })
    setIsloading(false)
  }

  const handleEdit = (row) => {
    setDefaultLanguages(row?.language ? row.language : 'English');
    setIsEditSub(false);
    setIsActiveId(row.corp_id);
    setMetadata(row);
    setMetadataBack(typeof row.back[0] === 'object' ? row.back[0] : {});
    setDefaultDepartment(row?.assignDept?.department)
    let filterTemplate = allTemplates.filter((item)=> item.id === defaultTempId)

    if(tabIndicator ===  'others'){
      filterTemplate = allTemplates.filter((item)=> item.id === isSelectedTemplate)
    }

    const frontOrientation = filterTemplate[0]?.template[5]?.cardOrientation[0]?.front ? filterTemplate[0]?.template[5]?.cardOrientation[0]?.front : 'horizontal'
    const backOrientation = filterTemplate[0]?.template[5]?.cardOrientation[1]?.back ? filterTemplate[0]?.template[5]?.cardOrientation[1]?.back : 'horizontal'

    setFrontOrientation(frontOrientation)
    setBackOrientation(backOrientation)
  }

  const processSubmitUserEdit = async(isActiveId, metaData, metaDataBack, isPasswordUpdated=false) => {
    // delete metaData.corp_id

    let department = {subCompId: '', deptId: ''}
    if(role === 'Admin'){
      if(userFilter){
        if(subCompOption.length > 0 && deptFilterOption.length > 0){
          if (toAssignDepartment !== '1'  && toAssignSubComp !== '1' ) {
            department.subCompId = toAssignSubComp
            department.deptId = toAssignDepartment
          }else{
            department.subCompId = subCompOption[1]?.value
            department.deptId = deptFilterOption[1]?.value
          }
        }
      }
    }else{
      if (toAssignDepartment !== '1'  && toAssignSubComp !== '1' ) {
        department.subCompId = toAssignSubComp
        department.deptId = toAssignDepartment
      }
    }

    const isObjectEmpty = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const payloadformat = [
      {
        "front":[{...metaData}],
      },
      {
        "back":[{...metaDataBack}]
      },
      {
        "card_status" : {
          "status": "main",
          "parent_id": ""
        }
      },
      ...(defaultDepartment && !isObjectEmpty(defaultDepartment)
        ? [{ "department": defaultDepartment }]
        : (department.subCompId && department.deptId ? [{ "department": department }] : [])
      )
    ]

    const data = await ApiPost("/corps/update-user",{
      user_id: userData[0].id,
      id:isActiveId,
      metadata:JSON.stringify(payloadformat),
      passwordOptions:passwordOptions,
      isPasswordUpdated
    })
    
    if(data.data.message == "success"){

      if(tabIndicator === 'others'){
        const updateList = listDataSub.map((item)=>{
          if(item.corp_id == isActiveId){
            return {...item, ...metaData}
          }else{
            return item
          }
        })
        
        setListDataSub(updateList);
      }else{
        const updateList = listData.map((item)=>{
          if(item.corp_id == isActiveId){
            return {...item, ...metaData}
          }else{
            return item
          }
        })
        
        setListData(updateList);
      }
      
      setIsEdit(false)
      setSwitchButton(true)
      setShowLoader(false);
      setUploadFile("");
      setAlertAttr({visible: true, message: 'User details updated successfully.', color: '#4caf50'});
      setAlertDialog({visible: false, title: null, message: null});
    }else{
      setIsEdit(false)
      setSwitchButton(true)
      setShowLoader(false);
      setUploadFile("");
      setAlertAttr({visible: true, message: 'Error updating user details.', color: '#b2102f'});
      setAlertDialog({visible: false, title: null, message: null});
    }
  }

  const validateUserEdit = async (isActiveId, metaData, metaDataBack, isPasswordUpdated=false, toAssignSubComp, toAssignDepartment, isIgnore) => {

    let filteredSubCompList = subCompList;
    let filteredDepartmentList = departmentList;

    if (role === 'Admin') {
        if (userFilter) {
            filteredSubCompList = subCompList.filter(subComp =>
                userFilter.some(filterItem => filterItem.subCompId === subComp.id)
            );

            const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];

            filteredDepartmentList = departmentList.filter(dept =>
                uniqueDeptIds.includes(dept.id)
            );
        }
    }

    const assignedDepartments = await areDepartmentsAssigned(filteredSubCompList, filteredDepartmentList);

    const isObjectEmpty = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if(defaultDepartment && !isObjectEmpty(defaultDepartment)){
      await processSubmitUserEdit(isActiveId, metaData, metaDataBack, isPasswordUpdated)
    }else{
      if(isIgnore){
        await processSubmitUserEdit(isActiveId, metaData, metaDataBack, isPasswordUpdated)
      }else{
        if (filteredSubCompList.length > 0 && filteredDepartmentList.length > 0 && assignedDepartments) {
          if (toAssignSubComp === '1' || toAssignDepartment === '1') {
  
            deptFilterOption.sort((a, b) => {
              if (a.label < b.label) return -1;
              if (a.label > b.label) return 1;
              return 0;
            });
            setShowLoader(false);
            setAlertDialog({
                visible: true,
                title: 'Designate Department',
                message: (
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', width: '500px', marginBottom: 20}}>
                    <AppDropdown 
                      onSelect={(val)=> {
                        filterOptions(val, 'subCompId', toAssignDepartment)
                      }} 
                      defaultValue={toAssignSubComp}
                      data={subCompOption} 
                      style={{ width: '50%', fontSize:"13px", marginRight:"15px"}}
                    />
                    <AppDropdown
                      onSelect={(val)=> {
                        filterOptions(val, 'deptId', toAssignDepartment)
                      }} 
                      defaultValue={toAssignDepartment}
                      data={deptFilterOption} 
                      style={{ width: '50%', marginRight: 0, fontSize:"13px"}}
                    /> 
                  </div>
                )
            });
          } else {
            await processSubmitUserEdit(isActiveId, metaData, metaDataBack, isPasswordUpdated)
          }
        } else {
          await processSubmitUserEdit(isActiveId, metaData, metaDataBack, isPasswordUpdated)
        }
      }
    }
    
    
  }

  const submitEdit = async(isIgnore=false) => {
    delete metaData.back
    setShowLoader(true);
    if(!metaData?.name || metaData?.name === ''){
      setIsErrorName(true);
      setShowLoader(false);
    }else if(!metaData?.email || metaData?.email === ''){
      setIsErrorEmail(true);
      setShowLoader(false);
    }else{
      if (validateEmail(metaData.email)){
        if(uploadFile[0]){
          const reader = new FileReader();

          reader.onload = async (e) => {
            const base64Data = e.target.result.split(',')[1];

            // const formData = new FormData();
            // formData.append('file', uploadFile[0]);
            // formData.append('corp_id', corp_id);
            // formData.append('userID', isActiveId);

            await ApiPost("/upload-user-logo",{
              file: base64Data,
              fileType: uploadFile[0].name.split('.').pop(),
              corp_id: corp_id,
              userID: isActiveId
            }).then(async (response)=>{
              if(response.data.message === 'success'){
                const permanentImage = response.data.data.permanent_image;

                if(switchButton){
                  const updatedMeta = {...metaData, logo:permanentImage, logo_base64:base64Data}
                  // await processSubmitUserEdit(isActiveId, updatedMeta, metaDataBack, metaData.isPasswordUpdated)
                  await validateUserEdit(isActiveId, updatedMeta, metaDataBack, metaData.isPasswordUpdated, toAssignSubComp, toAssignDepartment, isIgnore)
                }else{
                  const updatedMeta = {...metaDataBack, logo:permanentImage, logo_base64:base64Data}
                  // await processSubmitUserEdit(isActiveId, metaData, updatedMeta, metaData.isPasswordUpdated)
                  await validateUserEdit(isActiveId, metaData, updatedMeta, metaData.isPasswordUpdated, toAssignSubComp, toAssignDepartment, isIgnore)
                }
              }else{
                setIsEdit(false)
                setSwitchButton(true);
                setShowLoader(false);
                setUploadFile("")
                setAlertDialog({...alertDialog, title:"Error Uploading Logo", message: "There is an error in uploading your logo, please try again",  visible: true});
              }
            }).catch((error)=>{
              console.log("error", error);
              setIsEdit(false)
              setSwitchButton(true);
              setShowLoader(false);
              setUploadFile("")
              setAlertDialog({...alertDialog, title:"Error Uploading Logo", message: "There is an error in uploading your logo, please try again",  visible: true})
            })
          }

          reader.readAsDataURL(uploadFile[0]);
        }else{
          // processSubmitUserEdit(isActiveId, metaData, metaDataBack, metaData.isPasswordUpdated);
          validateUserEdit(isActiveId, metaData, metaDataBack, metaData.isPasswordUpdated, toAssignSubComp, toAssignDepartment, isIgnore)
        }
      }else{
        setIsErrorEmail(true)
        setShowLoader(false);
      }
    }
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const proceedSubmitUser = async(uniqueID=null, metaData, metaDataBack) => {
    let filteredTemplate = "";
  
    if(isAddSub){
      filteredTemplate = templates.filter(item => item.id === isSelectedTemplate);
    }else{
      filteredTemplate = allTemplates.filter(obj => {
        return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
      });
    }

    let department = {subCompId: '', deptId: ''}
    if(role === 'Admin'){
      if(userFilter){
        if(subCompOption.length > 0 && deptFilterOption.length > 0){
          if (toAssignDepartment !== '1'  && toAssignSubComp !== '1' ) {
            department.subCompId = toAssignSubComp
            department.deptId = toAssignDepartment
          }else{
            department.subCompId = subCompOption[1]?.value
            department.deptId = deptFilterOption[1]?.value
          }
        }
      }
    }else{
      if (toAssignDepartment !== '1'  && toAssignSubComp !== '1' ) {
        department.subCompId = toAssignSubComp
        department.deptId = toAssignDepartment
      }
    }

    if(filteredTemplate[0]){
      delete metaData.corp_id
      const payloadformat = [
        {
          "front":[{...metaData}],
        },
        {
          "back":[{...metaDataBack}]
        },
        isAddSub ?
        {
          "card_status" : {
            "status": "sub",
            "parent_id": selectedValue
          }
        }
        :
        {
          "card_status" : {
            "status": "main",
            "parent_id": ""
          }
        },
        ...(department.subCompId && department.deptId ? [{ "department": department }] : [])
      ]

      const namecard_status = isAddSub ? saveAsMain ? 'main' :'sub' : 'main';
      const namecard_parent_id = isAddSub ? saveAsMain ? '' : selectedValue : '';

      let passwordNumber = '1234';

      if (passwordOptions) {
        const count1Field = passwordOptions.find(item => item.count === "0")?.field;
        const count2Field = passwordOptions.find(item => item.count === "1")?.field;
        const count3Field = passwordOptions.find(item => item.count === "2")?.field;

        const getField1 = isFieldObject(metaData?.[count1Field])
        const getField2 = isFieldObject(metaData?.[count2Field])
        const getField3 = isFieldObject(metaData?.[count3Field])
      
        if(metaData?.[count1Field]){
          const faxNumber = getField1.replace(/\s/g, "");
          passwordNumber = faxNumber.substring(faxNumber.length -4)
        }else if(metaData?.[count2Field]){
          const mobileNumber = getField2.replace(/\s/g, "");
          passwordNumber = mobileNumber.substring(mobileNumber.length -4)
        }else if(metaData?.[count3Field]){
          const officeNumber = getField3.replace(/\s/g, "");
          passwordNumber = officeNumber.substring(officeNumber.length -4)
        }
      }else{
        if(metaData?.fax_number){
          const faxNumber = metaData.fax_number.replace(/\s/g, "");
          passwordNumber = faxNumber.substring(faxNumber.length -4)
        }else if(metaData?.mobile_number){
          const mobileNumber = metaData.mobile_number.replace(/\s/g, "");
          passwordNumber = mobileNumber.substring(mobileNumber.length -4)
        }else if(metaData?.office_number){
          const officeNumber = metaData.office_number.replace(/\s/g, "");
          passwordNumber = officeNumber.substring(officeNumber.length -4)
        }
      }

      const password = metaData.email.substring(0, 5)+''+passwordNumber

      let toAssignThis = { role: role, department: null };

      if(subCompOption.length > 0 && deptFilterOption.length > 0){
        if (toAssignDepartment !== '1' && toAssignSubComp !== '1' ) {
          toAssignThis.department = [{
              subCompId: toAssignSubComp,
              deptId: toAssignDepartment
          }];
        }else{
          toAssignThis.department = [{
              subCompId: subCompOption[1]?.value,
              deptId: deptFilterOption[1]?.value
          }];
        }
      }

      //####################################
      const data = await ApiPost("/corps/create-user", {
        uniqueID: uniqueID,
        user_id: userData[0].id,
        corp_id: corp_id,
        useremail: metaData.email,
        password: password,
        metadata: JSON.stringify(payloadformat),
        template_id: filteredTemplate[0].id,
        namecard_status: namecard_status,
        namecard_parent_id: namecard_parent_id,
        isStatus: saveAsMain ? true : false,
        department: toAssignThis
      })  

      if(data.data.message == 'Error') {
        if (data.data.status === 204) {
          setIsAdd(false);
          setIsAddSub(false);
          setAlertDialog({...alertDialog, title:"Email Already Used", message: data.data.data.message, visible: true});
          setShowLoader(false);
          setAlertDialog({visible: false, title: null, message: null});
        } else {
          setIsAdd(false);
          setIsAddSub(false);
          setAlertDialog({...alertDialog, title:"Reach Limit", message: "You have reached the limit of user",  visible: true});
          setShowLoader(false);
          setAlertDialog({visible: false, title: null, message: null});
        }
      }else {
        if(data.data){
          if(!saveAsMain){
              setSuccess(!success);
              setIsAdd(false);
              setIsAddSub(false);
              setSelectedValue(0);
              setFrontOrientation('horizontal')
              setBackOrientation('horizontal')
              setAlertAttr({visible: true, message: 'Corp sub-user added successfully.', color: '#4caf50'});
          }else{
            if(tabIndicator === 'others' && isAddSub && !saveAsMain){
              await filterTemplate(filteredTemplate)
            }
            setSuccess(!success)
            setIsAdd(false);
            setIsAddSub(false);
            setSelectedValue(0);
            setFrontOrientation('horizontal')
            setBackOrientation('horizontal')
            setAlertAttr({visible: true, message: 'Corp user added successfully.', color: '#4caf50'});
          }
        }
        setIsErrorName(false)
        setIsErrorEmail(false)
        setSwitchButton(true)
        setShowLoader(false);
        setAlertDialog({visible: false, title: null, message: null});
        updateCacheBuster();
        
        setMetadata({
          name:"",
          position:"",
          office_number:{
            number:"",
            extension:""
          },
          mobile_number:{
            number:""
          },
          fax_number:{
            number:"",
            extension:""
          },
          email:"",
          status:"",
        })
        
        await getUserCount();
        filterUsers(toAssignDepartment, 'deptId', toAssignSubComp)
      }
    }else{
      setIsAdd(false);
      setIsAddSub(false);
      setAlertDialog({...alertDialog, title: 'Template not available', visible: true});
      setShowLoader(false);
      setAlertDialog({visible: false, title: null, message: null});
    }
  }

  const areDepartmentsAssigned = async (subCompList, departmentList) => {
    for (const company of subCompList) {
      if (company?.departments) {
        for (const departmentId of company.departments) {
          if (departmentList.some(department => department?.id === departmentId)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const filterOptions = (val, keyvalue, secondVal) => {

    let deptFilterOptions = deptFilterOption
    if (val !== '1') {
      if (keyvalue === 'subCompId') {
        setToAssignSubComp(val);

        let filteredSubCompList = subCompList;
        let filteredDepartmentList = departmentList;
    
        if (role === 'Admin') {
            if (userFilter) {
                filteredSubCompList = subCompList.filter(subComp => 
                    userFilter.some(filterItem => filterItem.subCompId === subComp.id)
                );
            
                // Extract unique department IDs from userFilter
                const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];
            
                // Filter departmentList based on uniqueDeptIds
                filteredDepartmentList = departmentList.filter(dept => 
                    uniqueDeptIds.includes(dept.id)
                );
            }
        }
    
        if (val) {
          const filterSubComp = subCompList.filter(subComp => val === subComp.id);
    
          const matchingDepartmentIds = filterSubComp[0]?.departments?.filter(deptId => 
              filteredDepartmentList.some(dept => dept.id === deptId)
          ).map(deptId => deptId) || [];
    
          deptFilterOptions = matchingDepartmentIds.map(deptItem => {
              const getDept = filteredDepartmentList.find(item => item.id === deptItem);
              
              return { label: `${getDept.department_name} ${getDept?.cost_center ? ' - ' + getDept?.cost_center : ''}`, value: getDept.id };
          });
        } else {
          deptFilterOptions = filteredDepartmentList.map(item => ({
                label: `${item.department_name} ${item?.cost_center ? ' - ' + item?.cost_center : ''}`,
                value: item.id
            }));
        }
        
        deptFilterOptions.unshift({ label: '-- Select Department --', value: '1' });
      } else {
        setToAssignDepartment(val);
      }
    } else {
      setToAssignSubComp('1');
      setToAssignDepartment('1');
    }

    deptFilterOptions.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  
    setAlertDialog(prevState => ({
      ...prevState,
      message: (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '500px', marginBottom: 20 }}>
          <AppDropdown
            onSelect={(val) => {
              filterOptions(val, 'subCompId', toAssignDepartment);
            }}
            defaultValue={toAssignSubComp}
            data={subCompOption}
            style={{ width: '50%', fontSize: "13px", marginRight: "15px" }}
          />
          <AppDropdown
            onSelect={(val) => {
              filterOptions(val, 'deptId', toAssignDepartment);
            }}
            defaultValue={toAssignDepartment}
            data={deptFilterOptions}
            style={{ width: '50%', marginRight: 0, fontSize: "13px" }}
          />
        </div>
      )
    }));
  };
    
  const validateUserAdd = async (uniqueID=null, metaData, metaDataBack, toAssignSubComp, toAssignDepartment) => {

    let filteredSubCompList = subCompList;
    let filteredDepartmentList = departmentList;

    if (role === 'Admin') {
        if (userFilter) {
            filteredSubCompList = subCompList.filter(subComp =>
                userFilter.some(filterItem => filterItem.subCompId === subComp.id)
            );

            const uniqueDeptIds = [...new Set(userFilter.map(filterItem => filterItem.deptId))];

            filteredDepartmentList = departmentList.filter(dept =>
                uniqueDeptIds.includes(dept.id)
            );
        }
    }

    const assignedDepartments = await areDepartmentsAssigned(filteredSubCompList, filteredDepartmentList);

    if (filteredSubCompList.length > 0 && filteredDepartmentList.length > 0 && assignedDepartments) {
        if (toAssignSubComp === '1' || toAssignDepartment === '1') {

          deptFilterOption.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
          setShowLoader(false);
          setAlertDialog({
              visible: true,
              title: 'Designate Department',
              message: (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', width: '500px', marginBottom: 20}}>
                  <AppDropdown 
                    onSelect={(val)=> {
                      filterOptions(val, 'subCompId', toAssignDepartment)
                    }} 
                    defaultValue={toAssignSubComp}
                    data={subCompOption} 
                    style={{ width: '50%', fontSize:"13px", marginRight:"15px"}}
                  />
                  <AppDropdown
                    onSelect={(val)=> {
                      filterOptions(val, 'deptId', toAssignDepartment)
                    }} 
                    defaultValue={toAssignDepartment}
                    data={deptFilterOption} 
                    style={{ width: '50%', marginRight: 0, fontSize:"13px"}}
                  /> 
                </div>
              )
          });
        } else {
          await proceedSubmitUser(uniqueID, metaData, metaDataBack)
        }
    } else {
      await proceedSubmitUser(uniqueID, metaData, metaDataBack)
    }
  }


  const submitAddUser = async() => {
    setShowLoader(true);
    if(!metaData?.name || metaData?.name === ''){
      setIsErrorName(true);
      setShowLoader(false);
      if (!metaData?.email || metaData?.email === '') {
        setIsErrorEmail(true);
      } else {
        if (validateEmail(metaData.email)) {
          setIsErrorEmail(false);
        } else {
          setIsErrorEmail(true)
        }
      }
    }else if(!metaData?.email || metaData?.email === ''){
      setIsErrorEmail(true);
      setShowLoader(false);
      if (!metaData?.name || metaData?.name === '') {
        setIsErrorName(true);
      } else {
        setIsErrorName(false);
      }
    }else{
      setIsErrorName(false);
      setIsErrorEmail(false);
      if (validateEmail(metaData.email)) {
        if(checkMainEmail === 0){
          if(uploadFile[0]){
            const reader = new FileReader();
  
            reader.onload = async (e) => {
              const base64Data = e.target.result.split(',')[1];
  
              await ApiPost("/upload-user-logo",{
                file: base64Data,
                fileType: uploadFile[0].name.split('.').pop(),
                corp_id: corp_id
              }).then(async (response)=>{
                  if(response.data.message === 'success'){
                    const permanentImage = response.data.data.permanent_image;
  
                    if(switchButton){
                      const updatedMeta = {...metaData, logo:permanentImage, logo_base64:base64Data}
                      await validateUserAdd(response.data.data.uniqueID, updatedMeta, metaDataBack, toAssignSubComp, toAssignDepartment)
                    }else{
                      const updatedMeta = {...metaDataBack, logo:permanentImage, logo_base64:base64Data}
                      await validateUserAdd(response.data.data.uniqueID, metaData, updatedMeta, toAssignSubComp, toAssignDepartment)
                    }
                    
                  }else{
                    setIsAdd(false);
                    setIsAddSub(false);
                    setAlertDialog({...alertDialog, title:"Error Uploading Logo", message: "There is an error in uploading your logo, please try again",  visible: true});
                  }
              }).catch((error)=>{
                  console.log("error", error);
                  setIsAdd(false);
                  setIsAddSub(false);
                  setAlertDialog({...alertDialog, title:"Error Uploading Logo", message: "There is an error in uploading your logo, please try again",  visible: true})
              })
            }
  
            reader.readAsDataURL(uploadFile[0]);
  
          }else{
            validateUserAdd(null, metaData, metaDataBack, toAssignSubComp, toAssignDepartment);
          }
        }else{
          setIsErrorEmail(true)
          setShowLoader(false);
        }  
      } else {
        setIsErrorEmail(true)
        setShowLoader(false);
        setAlertDialog({visible: false, title: null, message: null});
      }
    }

    getUserCount();
  }

  const selectUser = (data) => {
    setSelectedValue(data);
    const filteredArray = allMainListData.filter(item => item.corp_id === data);
    setMetadata({...metaData, ...filteredArray[0]});
    setMetadataBack({...metaDataBack, ...filteredArray[0]});
  }

  const searchMainUser = async (event, value) => {

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(async () => {
      const result = await ApiPost("/corps/user-lists-search", {
        corp_id: corp_id,
        page: page + 1,
        search: value
      });
  
      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {
          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            status: item.status,
            role: item.namecard_status
          };
          return rowData;
        });
  
        setAllMainListData(newListUsers)
        setMainListWithoutSub(newListUsers)
      }
    }, 1500);

    setTimeoutId(newTimeoutId);
    
  }

  const handleEditSub = (row) => {

    let filterTemplate = allTemplates.filter((item)=> item.id === defaultTempId)

    if(tabIndicator ===  'others'){
      filterTemplate = allTemplates.filter((item)=> item.id === isSelectedTemplate)
    }

    const frontOrientation = filterTemplate[0]?.template[5]?.cardOrientation[0]?.front ? filterTemplate[0]?.template[5]?.cardOrientation[0]?.front : 'horizontal'
    const backOrientation = filterTemplate[0]?.template[5]?.cardOrientation[1]?.back ? filterTemplate[0]?.template[5]?.cardOrientation[1]?.back : 'horizontal'

    setFrontOrientation(frontOrientation)
    setBackOrientation(backOrientation)
    
    if(row.role === 'main'){

      const hasBuilding = templateDataOthers.some(obj => obj.field === 'building');
      const hasBuildingOthersBack = templateDataOthersBack.some(obj => obj.field === 'building');

      setIsMultiAddress(hasBuilding)
      setIsMultiAddressBack(hasBuildingOthersBack)
      setTemplateDataEdit(templateDataOthers)
      setTemplateDataBackEdit(templateDataOthersBack)
      handleEdit(row)
    }else{
      setSelectedValue(row.parent_id);
      setSelectedName(row.name);
      setIsEditSub(true);
      setIsActiveId(row.corp_id);
      setMetadata(row);
      setMetadataBack(typeof row.back[0] === 'object' ? row.back[0] : {});
    }
    
  }

  const proceedSubmitUserSub = async (uniqueID=null, metaData, metaDataBack) => {

    setMetadata({...metaData,name:selectedName});
    const filteredTemplate = templates.filter(item => item.id === isSelectedTemplate);

    // delete metaData.corp_id
    metaData.parent_id = selectedValue
    const payloadformat = [
      {
        "front":[{...metaData}],
      },
      {
        "back":[{...metaDataBack}]
      },
      {
        "card_status" : {
          "status": "sub",
          "parent_id": selectedValue
        }
      }
    ]

    const data = await ApiPost("/corps/update-user-sub",{
      user_id: userData[0].id,
      id: isActiveId,
      metadata: JSON.stringify(payloadformat),
      template_id: filteredTemplate[0].id,
      namecard_parent_id: selectedValue
    })
    
    if(data.data.message == "success"){
      const updateList = listDataSub.map((item)=>{
        if(item.corp_id == isActiveId){
          return {...item,...metaData}
        }else{
          return item
        }
      })

      setTotalTableCountSub(updateList.length)
      setListDataSub(updateList);
      setIsEdit(false)
      setIsEditSub(false)
      setSwitchButton(true)
      setShowLoader(false);
      setAlertAttr({visible: true, message: 'Sub-user details updated successfully.', color: '#4caf50'});
    }else{
      setIsEdit(false)
      setIsEditSub(false)
      setSwitchButton(true)
      setShowLoader(false);
      setAlertAttr({visible: true, message: 'Error updating sub-user details.', color: '#b2102f'});
    }
  }

  const submitEditSub = async() =>{
    setShowLoader(true);
    delete metaData.back
    if(!metaData?.name || metaData?.name === ''){
      setIsErrorName(true);
      setShowLoader(false);
    }else if(!metaData?.email || metaData?.email === ''){
      setIsErrorEmail(true);
      setShowLoader(false);
    }else{
      if (validateEmail(metaData.email)){
        if(uploadFile[0]){
          const reader = new FileReader();

            reader.onload = async (e) => {
              const base64Data = e.target.result.split(',')[1];

              // const formData = new FormData();
              // formData.append('file', uploadFile[0]);
              // formData.append('corp_id', corp_id);
              // formData.append('userID', isActiveId);

              await ApiPost("/upload-user-logo",{
                file: base64Data,
                fileType: uploadFile[0].name.split('.').pop(),
                corp_id: corp_id,
                userID: isActiveId
              }).then(async (response)=>{
                if(response.data.message === 'success'){
                  const permanentImage = response.data.data.permanent_image;

                  if(switchButton){
                    const updatedMeta = {...metaData, logo:permanentImage, logo_base64:base64Data}
                    await proceedSubmitUserSub(isActiveId, updatedMeta, metaDataBack)
                  }else{
                    const updatedMeta = {...metaDataBack, logo:permanentImage, logo_base64:base64Data}
                    await proceedSubmitUserSub(isActiveId, metaData, updatedMeta)
                  }
                }else{
                  setIsEdit(false)
                  setIsEditSub(false)
                  setSwitchButton(true)
                  setShowLoader(false);
                  setUploadFile("")
                  setAlertDialog({...alertDialog, title:"Error Uploading Logo", message: "There is an error in uploading your logo, please try again",  visible: true});
                } 
              }).catch((error)=>{
                console.log("error", error);
                setIsEdit(false)
                setIsEditSub(false)
                setSwitchButton(true)
                setShowLoader(false);
                setUploadFile("")
                setAlertDialog({...alertDialog, title:"Error Uploading Logo", message: "There is an error in uploading your logo, please try again",  visible: true})
              })
              
              setUploadFile("")
            }
            reader.readAsDataURL(uploadFile[0]);
        }else{
          proceedSubmitUserSub(null, metaData, metaDataBack);
        }
      }else{
        setIsErrorEmail(true)
        setShowLoader(false);
      }
    }
  }

  const handleDeleteSub = (val) => {
    setCurrentRow(val)
    setIsDelete(!isDelete)
    setIsDeleteSub(true);
  }

  //this is to handle the current row we should change the function name
  const handleDelete = (row) =>{
      setCurrentRow(row)
      setIsDelete(!isDelete)
  }

  const handleSuspend = (row) => {
    setSuspendRow(row)
    setisSuspend(!isSuspend)
  }

  const deleteUser = async() =>{
    setShowLoader(true);
    const data = await ApiPost("/corps/delete-user",{id: currentRow.corp_id, corp_id: corp_id})
    if(data.data.message === 'success'){
      if(isDeleteSub){
        getListData();
        setIsDeleteSub(false) 
        setAlertAttr({visible: true, message: 'Sub-user deleted successfully.', color: '#4caf50'});
      }else{
        getListData();
        setAlertAttr({visible: true, message: 'Corp user deleted successfully.', color: '#4caf50'});
      }
      setTimeout(()=>{setShowLoader(false);});
    } else {
      setAlertAttr({visible: true, message: 'Error deleting corp user.', color: '#b2102f'});
      setTimeout(()=>{setShowLoader(false);});
    }

    getUserCount();
    setIsDelete(false) 
    if(toAssignDepartment !== '1' && toAssignSubComp !== '1'){
      filterUsers(toAssignDepartment, 'deptId', toAssignSubComp)
    }
    
  }

  const deleteMultiple = (selected_items) =>{
    const filteredData = selected_items.map(row => {
      const { corp_id, ...otherColumns } = row;
      return otherColumns;
    });
    setSelectedItems(filteredData)
    
  }

  const deleteMultiple2 = (selected_items) =>{
    const filteredData = selected_items.map(row => {
      const { corp_id, ...otherColumns } = row;
      return otherColumns;
    });
    setContactSelectedItems(filteredData)
    
  }

  const exportToExcelSelected = () =>{
    exportToExcel(selectedItems)
  }

  const exportToExcelSelected2 = () =>{
    exportToExcel(contactSelectedItems)
  }

  const deleteMultipleUser = async(multiple_id) => {
    //delete user here
    setDeleteIds(multiple_id)
  } 

  const activateMultipleUser = async(multiple_id) => {
    const allEmals = multiple_id.map((item) => item.email)
    setActivationEmails(allEmals)
    setActivationModals(true)
  } 

  const deleteMultipleUserSub = async(multiple_id) => {
    setIsDeleteMultipleSub(true)
    setDeleteIds(multiple_id)
  } 

  const handleDeletaMultipleUser = async() =>{
    setShowLoader(true);
    const ids = deleteIds.map((data)=>{
      return data.corp_id
    })
    const idString = ids.join(";")
    const data = await ApiPost("/corps/delete-user",{id: idString, corp_id: corp_id})
    if(data.data.message == "success"){
        
      if(isDeleteMultipleSub){
        setIsTrackSelected(ids);
        getTemplate();
        setIsDeleteMultipleSub(false);
        setIsDelete(false);
        setSuccess(!success);
        setDeleteSelected(false);
      }else{
        setIsTrackSelected(ids)
      
        const filteredArr = listData.filter(obj => !ids.includes(obj.corp_id));

        setListData(filteredArr);
        setSuccess(!success);
        setDeleteSelected(false);
        setIsDelete(false);
      }
      setTimeout(()=>{ setShowLoader(false);},800);
    }
  }

  const handleDeletaMultipleUser2 = async() =>{
    const ids = deleteIds.map((data)=>{
      return data.corp_id
    })
    const idString = ids.join(";")
    const data = await ApiPost("/corps/delete-user",{id: idString, corp_id: corp_id})
    if(data.data.message == "success"){
        
      if(isDeleteMultipleSub){
        setIsContactTrackSelected(ids);
        getTemplate();
        setIsDeleteMultipleSub(false);
        setIsDelete(false);
        setSuccess(!success);
        setDeleteSelected(false);
      }else{
        setIsContactTrackSelected(ids)
      
        const filteredArr = listData.filter(obj => !ids.includes(obj.corp_id));

        setListData(filteredArr);
        setSuccess(!success);
        setDeleteSelected(false);
        setIsDelete(false);
      }
    }
  }

  const numerSelected = (selected) =>{
    return selected - 1
  }

  const filterItem = async(filterKey, keyWord,  type = 'status') => {

    setPage(0)
    setItemPerPage(10)
    setSearchFilterKey(filterKey)
    setSearchFilterKeyword(keyWord)
    setSearchFilterType(type)

    if(tabIndicator === 'others'){
      type = 'namecard_status'
    }

    if(type === 'status'){
      if(filterKey === 'All' || filterKey === 'Suspended' || filterKey == 'Active') {
        userListFetch(corp_id, page, itemsPerPage, filterKey, keyWord, type, null, tabIndicator)
      } else {
        userListFetch(corp_id, page, itemsPerPage, filterKey, keyWord, type, null, tabIndicator)
      }
    }else{
      if (filterKey === 'All' || filterKey === 'Main' || filterKey == 'Sub') {
        userListFetch(corp_id, page, itemsPerPage, filterKey, keyWord, type, null, tabIndicator)
      }else{
        userListFetch(corp_id, page, itemsPerPage, filterKey, keyWord, type, null, tabIndicator)
      }
    }
  }

  const filterUsers = async (val, keyvalue, secondVal, currentTab='default') => {

    if(val !== '1'){
      if(keyvalue === 'subCompId'){
        setToAssignSubComp(val)
        getFilterOptionsDept(val)
      }else{
        setToAssignDepartment(val)
      }
      
      let filters = []
      if(val && secondVal){
        if(keyvalue === 'subCompId'){
          filters = [...filters, {subCompId: val, deptId: secondVal}]
        }else{
          filters = [...filters, {subCompId: secondVal, deptId: val}]
        }

        if(searchFilterType === 'status'){
          if(searchFilterKey === 'All' || searchFilterKey === 'Suspended' || searchFilterKey == 'Active') {
            userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, filters, currentTab)
          } else {
            userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, filters, currentTab)
          }
        }else{
          if (searchFilterKey === 'All' || searchFilterKey === 'Main' || searchFilterKey == 'Sub') {
            userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, filters, currentTab)
          }else{
            userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, filters, currentTab)
          }
        }
      }else{
        if(userFilter){
          userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, userFilter, currentTab)
        }else{
          userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, null, currentTab)
        }
        
      }
    }else{
      setToAssignSubComp('1')
      setToAssignDepartment('1')
      if(userFilter){
        userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, userFilter, currentTab)
      }else{
        userListFetch(corp_id, page, itemsPerPage, searchFilterKey, searchFilterKeyWord, searchFilterType, null, currentTab)
      }
    }
  }



  const suspendUser = async() =>{
    setShowLoader(true);
    const id = suspendRow?.corp_id
    const stat = suspendRow?.status

    const statNow = stat === 'Active' || stat === 'Inactive' ? "Suspended" : "Active"
    const data = await  ApiPost("/corps/update-user-status",{
      id:id,
      user_id: userData[0].id,
      status: statNow
    })
    setSuccess(!success)
    setisSuspend(!isSuspend )

    if(tabIndicator === 'others'){
      const updateList = listDataSub.map((item)=>{
        if(item.corp_id == id){
          return {...item, status: statNow}
        }else{
          return item
        }
      })
      
      setListDataSub(updateList);
    }else{
      const updateList = listData.map((item)=>{
        if(item.corp_id == id){
          return {...item, status: statNow}
        }else{
          return item
        }
      })
      
      setListData(updateList);
    }

    setTimeout(()=>{setShowLoader(false);},800);
  }

  const formatOfficeNumber = (value) => {
    for (const key in value) {
      if (value.hasOwnProperty(key) && typeof value[key] === 'object' && value[key]?.number) {
        const { number, extension } = value[key];
        if (extension) {
          value[key] = `${number} ext ${extension}`;
        } else {
          value[key] = number;
        }
      }
    }
  
    return value;
  }

  const ExportExcel = async (data) => {
    
    setShowLoader(true)
    
    console.log('5')
    data = await ApiPost("/corps/user-listsv2",{
      corp_id: corp_id,
      page: 0,
      status: 'All',
      template_id: tabIndicator === 'others' ? isSelectedTemplate : defaultTempId,
      keyValue: 'status' ,
      userFilter: role === 'Admin' ? userFilter : []
    })

    const template = allTemplates.filter(obj => {
      return obj.id === isSelectedTemplate;
    });

    let userFilter

    if(template[0]?.department){
      userFilter = template[0].department
    }

    const newListUsers = data?.data?.data?.users
      .map(item => {

        let institution = []
        let department = []

        if(tabIndicator === 'others'){
          if (userFilter) {
            institution = userFilter
              .flatMap((filter) => {
                const matchingSubComp = subCompList.find((subComp) =>
                  subComp.id === filter.subCompId
                ); 
          
                if (matchingSubComp) {
                  return [matchingSubComp.name];
                } else {
                  return [];
                }
              });
          }
  
          if (userFilter) {
            department = userFilter
              .flatMap((filter) => {
                const matchingDept = departmentList.find((dept) =>
                  dept.id === filter.deptId
                );
          
                if (matchingDept) {
                  return [matchingDept.department_name];
                } else {
                  return [];
                }
              });
          }
        }

        institution = institution.length > 0 ? institution[0] : ''
        department = department.length > 0 !== '' ? department[0] : ''

        const rowData = {
          corp_id: item.id,
          ...item?.metadata[0]?.front[0],
          ...item?.metadata[1],
          assignDept: item?.metadata[3],
          institutions: institution,
          departments: department,
          status: item.status,
          role: item.namecard_status
        };
        return rowData;
      });

    const filteredData = newListUsers.map(row => {
      const { corp_id, ...otherColumns } = row;
      return formatOfficeNumber(otherColumns);
    });
    
    exportToExcel(filteredData)
    setShowLoader(false)
  }

  const ExportExcelContact = async (data) => {

    setShowLoader(true)

    let params = {
      corp_id: corp_id,
      page: 0,
      limit: 0,
      search: "",
      isExport: true
    }

    const contactList = await ApiPost("/corps/contact-list", params);
    const listData = contactList.data.data.contactList;

    if(listData.length > 0){
      const new_data = listData.map(item => {

        const createdon = new Date(item.createdon);
        const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

        const meta = item.metadata != null ? item.metadata[0].front[0] : []

        let actual
        actual = item.metadata[0]?.actualCard_url ? item.metadata[0]?.actualCard_url[0]?.img1 !== '' ?  item.metadata[0]?.actualCard_url[0]?.img1 : item.metadata[0]?.captured_img_url[0]?.img1 : Array.isArray(item.metadata[4]?.actualCard_url) ? item.metadata[4]?.actualCard_url[0]?.img1 !== '' ? item.metadata[4]?.actualCard_url[0]?.img1 : item.metadata[5]?.captured_img_url[0]?.img1 : item.metadata[4]?.actualCard_url

        // actual = item.metadata[0]?.actualCard_url ? item.metadata[0]?.actualCard_url[0]?.img1 !== '' ?  item.metadata[0]?.actualCard_url[0]?.img1 : item.metadata[0]?.captured_img_url[0]?.img1 : Array.isArray(item?.metadata[4]) ? item.metadata[4]?.actualCard_url[0]?.img1 !== '' ? item.metadata[4]?.actualCard_url[0]?.img1 : item.metadata[5]?.captured_img_url[0]?.img1 : item.metadata[4]?.actualCard_url

        const rowData = {
          user_id: item.user_id,
          corp_id: item.id,
          namecard: actual,
          name: meta?.name,
          position: meta?.position,
          office_number: meta?.office_number,
          mobile_number: meta?.mobile_number,
          fax_number: meta?.fax_number,
          email: meta?.email,
          contact_of: item.useremail,
          date_added: formattedDate,
          parent_id: item.namecard_parent_id
        };
        return rowData;
      });

      const filteredData = new_data.map(row => {
        const { corp_id, ...otherColumns } = row;
        return formatOfficeNumber(otherColumns);
      })
      exportToExcelContacts(filteredData)
      setShowLoader(false)
    }
  }

  const filterTemplate = async (value) => {

    const hasLogoFieldOthers = value.template[1].front.some(item => item.field === "logo");
    const userFilters = value?.department

    setPage(0)
    setIsSelectedTemplate(value.id)
    setIsSelected(value.template[0].template_name)
    setTemplateDataOther(value.template[1].front)
    setTemplateDataOtherBack(value.template[2].back)
    setHasLogoOthers(hasLogoFieldOthers)

    const hasBuilding = value.template[1].front.some(obj => obj.field === 'building');
    const hasBuildingOthersBack = value.template[2].back.some(obj => obj.field === 'building');
    

    setIsMultiAddressOthers(hasBuilding);
    setIsMultiAddressOthersBack(hasBuildingOthersBack);

    if(value.template[3].template_image[0].front){
      setTemplateBackgroundFrontOthers(value.template[3].template_image[0].front);
    }

    if(value.template[3].template_image[1].back){
      setTemplateBackgroundBackOthers(value.template[3].template_image[1].back);
    }

    setIsloading(true)

    console.log('6')
    let filters = []
    
    if(toAssignSubComp !== '1' && toAssignDepartment !== '1'){
      filters = [...filters, {subCompId: toAssignSubComp, deptId: toAssignDepartment}]
    }

    const result = await ApiPost("/corps/user-listsv2", {
      corp_id: corp_id,
      page: 0,
      limit: itemsPerPage,
      keyValue: 'namecard_status',
      template_id: value.id,
      userFilter: role === 'Admin' ? filters.length > 0 ? userFilter : filters : filters.length > 0 ? filters : []
    });

    if (result?.data?.data?.users) {
      const newListUsers = result?.data?.data?.users.map(item => {

        const createdon = new Date(item?.createdon);
        const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

        const createdBy = adminList.filter(user => user?.id === item?.created_by);

        let defualtInstitution
        let defaultDepartment
        const deptAssigned = item?.metadata[3]?.department

        defualtInstitution = subCompList.find((subComp) => subComp.id === deptAssigned?.subCompId)
        defaultDepartment = departmentList.find((dept) => dept.id === deptAssigned?.deptId)

        let institution = []
        let department = []

        if(deptAssigned){
          if (userFilters) {
            institution = userFilters
              .flatMap((filter) => {
                const matchingSubComp = subCompList.find((subComp) =>
                  subComp.id === filter.subCompId
                ); 
          
                if (matchingSubComp) {
                  return [matchingSubComp.name];
                } else {
                  return [];
                }
              });
          }

          if (userFilters) {
            department = userFilters
              .flatMap((filter) => {
                const matchingDept = departmentList.find((dept) =>
                  dept.id === filter.deptId
                );
          
                if (matchingDept) {
                  return [matchingDept.department_name];
                } else {
                  return [];
                }
              });
          }

          institution = institution.length > 0 ? institution[0] : ''
          department = department.length > 0 !== '' ? department[0] : ''
        }

        const deptName = `${defaultDepartment?.department_name}${defaultDepartment?.cost_center ? ` - ${defaultDepartment?.cost_center}` : ''}`

        const rowData = {
          corp_id: item.id,
          ...item?.metadata[0]?.front[0],
          ...item.metadata[1],
          assignDept: item?.metadata[3],
          institutions: defualtInstitution ? defualtInstitution.name : institution,
          departments:  defaultDepartment ? deptName : department,
          status: item.status,
          role: item.namecard_status,
          parent_id: item.namecard_parent_id,
          isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
          createdon: formattedDate,
          createdBy: createdBy[0]?.useremail
        };
        return rowData;
      });

      setUserTableConfig({
        totalCount: result?.data?.data?.totalCount,
        numberOfUsers: result?.data?.data?.numberOfUsers,
      });

      setTotalTableCountSub(result?.data?.data?.totalCount)
      setListDataSub(newListUsers);
      setOriginalListSub(newListUsers)
      setIsloading(false);
    }
  }

  const toggleAddModal = () => {
    setIsErrorName(false)
    setIsErrorEmail(false)
    isAdd ? setIsAdd(!isAdd) : setIsAddSub(!isAddSub)
    setSwitchButton(true)
    setUploadFile("")
    setUploadType("upload")
    setFrontOrientation('horizontal')
    setBackOrientation('horizontal')
    setCheckMainEmail(0)
  }

  const toggleEditModal = () => {
    setIsErrorName(false)
    setIsErrorEmail(false)
    setIsEdit(!isEdit)
    setSwitchButton(true)
    setUploadFile("");
    setUploadType("upload")
    if(tabIndicator === 'others'){
      const hasBuilding = templateData.some(obj => obj.field === 'building');
      const hasBuildingOthersBack = templateDataBack.some(obj => obj.field === 'building');

      setIsMultiAddress(hasBuilding)
      setIsMultiAddressBack(hasBuildingOthersBack)
      setTemplateDataEdit(templateData)
      setTemplateDataBackEdit(templateDataBack)
    }
  }

  const handleImport = () => {
    setShowImportModal(true)
  }

  const hundleActivationLink = async (currentRow) => {
    setShowLoader(true);
    await ApiPost("/corps/send-activation",{email: currentRow.email})
    .then((response) => {
      const data = response.data;
      if(data.message === 'success'){
        setAlertDialog({visible: true, title: 'Activation Link', message: `Successfully sent to ${currentRow.email}`})
      } else {
        setAlertDialog({visible: true, title: 'Activation Link', message: 'Something went wrong, please try again'})
      }
      setShowLoader(false);
    })
    .catch((error) => {
      setAlertDialog({visible: true, title: 'Activation Link', message: 'Connection error, please send activation link again'})
      setShowLoader(false);
    })
  }

  const handleSendPasswordReset = async (currentRow) => {
    setShowLoader(true);
    await ApiPost("/corps/send-reset-password",{email: currentRow.email, user_id: currentRow.corp_id, name: currentRow.name})
    .then((response) => {
      const data = response.data;
      if(data.message === 'success'){
        setAlertDialog({visible: true, title: 'Password Reset Link', message: `Successfully sent to ${currentRow.email}`})
      } else {
        setAlertDialog({visible: true, title: 'Password Reset Link', message: 'Something went wrong, please try again'})
      }
      setShowLoader(false);
    })
    .catch((error) => {
      setAlertDialog({visible: true, title: 'Password Reset Link', message: 'Connection error, please send password reset link again'})
      setShowLoader(false);
    })
  }

  const hundleMultipleActivationLink = async (currentRow) => {
    setShowLoader(true);
    await ApiPost("/corps/send-activation",{email: activationEmails.join(';')})
    .then((response) => {
      const data = response.data;
      if(data.message === 'success'){
        setActivationModals(false)
        setAlertDialog({visible: true, title: 'Activation Link', message: `Successfully sent` })
      } else {
        setActivationModals(false)
        setAlertDialog({visible: true, title: 'Activation Link', message: 'Something went wrong, please try again'})
      }
      setShowLoader(false);
    })
    .catch((error) => {
        setActivationModals(false)
      setAlertDialog({visible: true, title: 'Activation Link', message: 'Connection error, please send activation link again'})
      setShowLoader(false);
    })
  }

  const userListFetch = async (id, ppage, limit, filterKey="All", keyWord= "", type, filters, currentTab) => {

    setIsloading(true)
    let data
    
    console.log('7')
    data = await ApiPost("/corps/user-listsv2",{
      corp_id: id,
      page: ppage,
      limit: limit,
      status: filterKey,
      name:keyWord,
      keyValue: type,
      template_id: currentTab === 'others' ? isSelectedTemplate : defaultTempId,
      userFilter: filters
    })

    const template = allTemplates.filter(obj => {
      return obj.id === isSelectedTemplate;
    });

    let userFilters

    if(template[0]?.department){
      userFilters = template[0].department
    }

    if(data?.data?.data?.users){
      const newListUsers = data?.data?.data?.users
      .map(item => {

        const createdon = new Date(item?.createdon);
        const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

        const createdBy = adminList.filter(user => user?.id === item?.created_by);

        let defualtInstitution
        let defaultDepartment
        const deptAssigned = item?.metadata[3]?.department

        defualtInstitution = subCompList.find((subComp) => subComp.id === deptAssigned?.subCompId)
        defaultDepartment = departmentList.find((dept) => dept.id === deptAssigned?.deptId)

        let institution = []
        let department = []

        if(deptAssigned){
          if (userFilters) {
            institution = userFilters
              .flatMap((filter) => {
                const matchingSubComp = subCompList.find((subComp) =>
                  subComp.id === filter.subCompId
                ); 
          
                if (matchingSubComp) {
                  return [matchingSubComp.name];
                } else {
                  return [];
                }
              });
          }

          if (userFilters) {
            department = userFilters
              .flatMap((filter) => {
                const matchingDept = departmentList.find((dept) =>
                  dept.id === filter.deptId
                );
          
                if (matchingDept) {
                  return [matchingDept.department_name];
                } else {
                  return [];
                }
              });
          }

          institution = institution.length > 0 ? institution[0] : ''
          department = department.length > 0 !== '' ? department[0] : ''
        }

        const deptName = `${defaultDepartment?.department_name}${defaultDepartment?.cost_center ? ` - ${defaultDepartment?.cost_center}` : ''}`

        const rowData = {
          corp_id: item.id,
          ...item?.metadata[0]?.front[0],
          ...item.metadata[1],
          assignDept: item?.metadata[3],
          institutions: defualtInstitution ? defualtInstitution.name : institution,
          departments:  defaultDepartment ? deptName : department,
          status: item.status,
          role: item.namecard_status,
          isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
          createdon: formattedDate,
          createdBy: createdBy[0]?.useremail
        };
        return rowData;
      });

      setUserTableConfig({
        totalCount: data?.data?.data?.totalCount,
        totalPages: data?.data?.data?.totalPages,
        numberOfUsers: data?.data?.data?.numberOfUsers,
      });

      if (currentTab === 'others') {
        setTotalTableCountSub(data?.data?.data?.totalCount)
        setListDataSub(newListUsers)
        setIsloading(false)
      } else {
        setTotalTableCount(data?.data?.data?.totalCount)
        setListData(newListUsers)
        setOriginalList(newListUsers)
        setIsloading(false)
      }
    }
  }

  const importUsers = async (file) => {

    setShowImportModal(!showImportModal);
    setIsloading(true);
    setShowLoader(true);

    let toAssignThis = { role: role, department: null };
    if(subCompOption.length > 0 && deptFilterOption.length > 0){
      if (toAssignDepartment !== '1' && toAssignSubComp !== '1') {
        toAssignThis.department = [{
            subCompId: toAssignSubComp,
            deptId: toAssignDepartment
        }];
      }else{
        toAssignThis.department = [{
            subCompId: subCompOption[1]?.value,
            deptId: deptFilterOption[1]?.value
        }];
      }
    }
  
    const filteredTemplate = allTemplates.filter(obj => {
      return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
    });

    const passwordOptionsSort = JSON.stringify(passwordOptions);

    const uploadedFile = file[0]

    if(uploadedFile){
      const reader = new FileReader();

      reader.onload = async (e) => {
        const base64Data = e.target.result.split(',')[1];

        const response = await ApiPost("/csv-corps-users", {
          file: base64Data,
          fileName: file[0].name,
          fileType: file[0].name.split('.').pop(),
          corp_id: corp_id,
          template_id: tabIndicator === 'others' ? isSelectedTemplate : filteredTemplate[0].id,
          namecard_status: 'main',
          passwordOptionsSort: passwordOptionsSort,
          user_id: userData[0].id,
          department: toAssignThis
        })

        setIsloading(false)
        setShowLoader(false);

        if(response.data.status === 200){
          const success = response.data.data.success;
          const alreadyExist = response.data.data.alreadyExist;
          const failedDueToReachedLimit = response.data.data.failedDueToReachedLimit;

          if(success.length > 0){
            getTemplate();
            getListData();
            setImportFile();
            await getUserCount();
          }

          if(alreadyExist.length > 0 || failedDueToReachedLimit.length > 0){

            let exist = ''
            if(alreadyExist.length > 0){
              exist = 'Email already exist <br />'
              alreadyExist.map((item) => {
                exist +=  '<p style="color:red"> - ' + item + '</p>'
              })
            }

            let reachedLimit = ''
            if(failedDueToReachedLimit.length > 0){
              reachedLimit = '<br /> Failed due to user reached limit <br />'
              failedDueToReachedLimit.map((item) => {
                reachedLimit +=  '<p style="color:red"> - ' + item + '</p>'
              })
            }

            setImportDialog({visible: true, title: 'Error Import', subTitle: 'See import issue below.', message: exist +'<br />'+ reachedLimit })
            // setAlertDialog({visible: true, title: 'Error Import', message: exist +'<br />'+ reachedLimit });
          }else{
            setImportDialog({visible: true, title: 'Success Import', subTitle: null, message: 'All users imported successfully.' })
          }

        } else if(response.data.status === 400){
          // setAlertDialog({visible: true, title: 'Error Import', message: 'File type not supported.'})
          setImportDialog({visible: true, title: 'Error Import', subTitle: null, message: 'File type not supported.' })
        }

        // if(response.data.message === 'success'){

        //   if(response.data.data[0].rows){
        //     setDuplicateEmail(response.data.data[0].rows)
        //     setImportAlert(true)
        //   }

        //   getTemplate();
        //   getListData();
        //   setIsloading(false)
        //   setShowLoader(false);
        // }else if(response.data.status === 400){
        //   setAlertDialog({visible: true, title: 'Error Import', message: 'File type not supported.'})
        //   setIsloading(false)
        //   setShowLoader(false);
        // }else{
        //   let email = ''
        //   response?.data?.data?.data.map((item) => {
        //     email +=  '<p style="color:red"> - ' + item + '</p>'
        //   })

        //   getTemplate();
        //   setAlertDialog({visible: true, title: 'Error Import', message: 'Adding new user limit, <br /> <br /> <br />' +email })
        //   setIsloading(false)
        //   setShowLoader(false);
        // }

        // setImportFile();
        // getUserCount();

      }

      reader.readAsDataURL(uploadedFile);
    }
  }

  const handleUserListsPage = async (val) => {
    setPage(val)
    setIsloading(true)

    getListDataPagination('page', val)
  }

  const handleUserListsPerPage = async (val) => {
    setPage(0)
    setItemPerPage(val)
    setIsloading(true)

    getListDataPagination('count', val)
  }

  const getListDataPagination = async(filter, val) => {
    let pages = 0
    let itemsCounter = itemsPerPage

    if(filter === 'page'){
      pages = val
    }else{
      itemsCounter = val
    }

    const data = await ApiPost("/corps/corp-template", { corp_id: corp_id });
    let filteredArray = []
    
    const default_fields = data.data;
    filteredArray = default_fields.filter(obj => {
      return obj.template.every(templateObj => !('isDefault' in templateObj) || templateObj.isDefault === false);
    });
  
    const defaultTemp = default_fields.filter(obj => {
      return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
    });
  
    const hasBuilding = defaultTemp[0].template[1].front.some(obj => obj.field === 'building');
    const hasBuildingBack = defaultTemp[0].template[2].back.some(obj => obj.field === 'building');
  
    const hasLogoField = defaultTemp[0].template[1].front.some(item => item.field === "logo");

    filteredArray = filteredArray.filter(item => {
      if (!adminDept || adminDept?.length === 0) {
        return true;
      }
      if (item.department && item.department.some(dept => adminDept.includes(dept))) {
        return true;
      }
      return false;
    });

    filteredArray = filteredArray.filter(item => {
      if (!adminDept || adminDept.length === 0) {
        return true;
      }
    
      const hasMatchingDepartment = item.department.some(department => {
        return item.department && item.department.some(dept => {
          return adminDept.some(adminDeptItem => adminDeptItem.subCompId === department.subCompId && adminDeptItem.deptId === dept);
        });
      });
    
      return hasMatchingDepartment;
    });
    
    setIsMultiAddress(hasBuilding);
    setIsMultiAddressBack(hasBuildingBack);
    setTemplateData(defaultTemp[0].template[1].front);
    setTemplateDataBack(defaultTemp[0].template[2].back);
    setAllTemplates(default_fields);
    setTemplates(filteredArray);
    setHasLogo(hasLogoField);
    setDefaultTempId(defaultTemp[0].id)

    if(!adminDept){

      console.log('8')
      const result = await ApiPost("/corps/user-listsv2", {
        corp_id: corp_id,
        page: page,
        limit: itemsPerPage,
        status: searchFilterKey,
        name: searchFilterKeyWord,
        keyValue: searchFilterType,
        template_id:  defaultTemp[0].id,
        userFilter: role === 'Admin' ? userFilter : []
      });


      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminList.filter(user => user?.id === item?.created_by);

          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            assignDept: item?.metadata[3],
            status: item.status,
            role: item.namecard_status,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail
          };
          return rowData;
        });

        setUserTableConfig({
          totalCount: result?.data?.data?.totalCount,
          numberOfUsers: result?.data?.data?.numberOfUsers,
        });

        setTotalTableCount(result?.data?.data?.totalCount)
        setListData(newListUsers);
        setOriginalList(newListUsers)
        setIsloading(false);
      }
    }
  
    if (filteredArray[0]?.template) {
      const hasLogoFieldOthers = filteredArray[0].template[1].front.some(item => item.field === "logo");
      const userFilters = filteredArray[0].department
  
      setTemplateDataOther(filteredArray[0].template[1].front);
      setTemplateDataOtherBack(filteredArray[0].template[2].back);
      setIsSelected(filteredArray[0].template[0].template_name);
      setIsSelectedTemplate(filteredArray[0].id);
      setHasLogoOthers(hasLogoFieldOthers);
  
      const hasBuildingOthers = filteredArray[0].template[1].front.some(obj => obj.field === 'building');
      const hasBuildingOthersBack = filteredArray[0].template[2].back.some(obj => obj.field === 'building');
      setIsMultiAddressOthers(hasBuildingOthers);
      setIsMultiAddressOthersBack(hasBuildingOthersBack);

      console.log('9')
      const result = await ApiPost("/corps/user-listsv2", {
        corp_id: corp_id,
        page: page,
        limit: itemsPerPage,
        status: searchFilterKey,
        name: searchFilterKeyWord,
        keyValue: searchFilterType,
        template_id: filteredArray[0].id,
        userFilter: role === 'Admin' ? userFilter : []
      });

      if (result?.data?.data?.users) {
        const newListUsers = result?.data?.data?.users.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminList.filter(user => user?.id === item?.created_by);

          let defualtInstitution
          let defaultDepartment
          const deptAssigned = item?.metadata[3]?.department

          defualtInstitution = subCompList.find((subComp) => subComp.id === deptAssigned?.subCompId)
          defaultDepartment = departmentList.find((subComp) => subComp.id === deptAssigned?.deptId)

          let institution = []
          let department = []
          if(deptAssigned){
            if (userFilters) {
              institution = userFilters
                .flatMap((filter) => {
                  const matchingSubComp = subCompList.find((subComp) =>
                    subComp.id === filter.subCompId
                  ); 
            
                  if (matchingSubComp) {
                    return [matchingSubComp.name];
                  } else {
                    return [];
                  }
                });
            }
  
            if (userFilters) {
              department = userFilters
                .flatMap((filter) => {
                  const matchingDept = departmentList.find((dept) =>
                    dept.id === filter.deptId
                  );
            
                  if (matchingDept) {
                    return [matchingDept.department_name];
                  } else {
                    return [];
                  }
                });
            }
  
            institution = institution.length > 0 ? institution[0] : ''
            department = department.length > 0 !== '' ? department[0] : ''
          }

          const deptName = `${defaultDepartment?.department_name}${defaultDepartment?.cost_center ? ` - ${defaultDepartment?.cost_center}` : ''}`;

          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            assignDept: item?.metadata[3],
            institutions: defualtInstitution ? defualtInstitution.name : institution,
            departments:  defaultDepartment ? deptName : department,
            status: item.status,
            role: item.namecard_status,
            parent_id: item.namecard_parent_id,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail
          };
          return rowData;
        });
  
        setUserTableConfig({
          totalCount: result?.data?.data?.totalCount,
          numberOfUsers: result?.data?.data?.numberOfUsers,
        });
  
        setTotalTableCountSub(result?.data?.data?.totalCount)
        setListDataSub(newListUsers);
        setOriginalListSub(newListUsers)
        setIsloading(false);
      }
    }
  }

  const handleUserListsPage2 = async (val) => {
    // setIsloading(true)
    // setListData([])
    // getListData();
    setContactPage(val)
  }

  const handleUserListsPerPage2 = async (val) => {
     let offset = ((contactPage + 1) - 1) * val;
     let ppage = contactPage;
     setContactItemsPerPage(val);
    if(offset >= userTableConfig.totalCount) {
      ppage =  contactPage - (offset / val) + 1;
    } else {
      ppage =  contactPage + 1;
    }


    setContactPage(ppage - 1)
  }

  const getFieldData = (data) => {
    if(switchButton){
      return isFieldObject(metaData[data]) || '';
    }else{
      return isFieldObject(metaDataBack[data]) || '';
    }
    
  }

  const fetchLanguage = async() => {
    const language = await ApiGet('/getLanguage');
    setLanguages(language.data.data)
  }

  const handleContactSearch = (value) => {
    setIsloading(true)
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
  
    setSearchTimeout(setTimeout(() => {
      setIsloading(false)

      if(value !== ''){
        setContactPage(0)
      }
      setContactSearch(value);
    }, 2000));
  
    
  }

  const goFrontForm = () => {
    setSwitchButton(true)
  }

  const goBackForm = () => {
    setSwitchButton(false)
  }

  const setMultiAddress = (building, room, street, country) => {

    const build = building ? building : '';
    const roomNo = room ? room : '';
    const strt = street ? street : '';
    const cntry = country ? country : '';
    const addressFull = build+' '+roomNo+' '+strt+' '+cntry

    setMetadata({
      ...metaData,
      building: build,
      room: roomNo,
      street: strt,
      country: cntry,
      address: addressFull
    });

  }

  const setMultiAddressBack = (building, room, street, country) => {

    const build = building ? building : '';
    const roomNo = room ? room : '';
    const strt = street ? street : '';
    const cntry = country ? country : '';
    const addressFull = build+' '+roomNo+' '+strt+' '+cntry

    setMetadataBack({
      ...metaData,
      building: build,
      room: roomNo,
      street: strt,
      country: cntry,
      address: addressFull
    });

  }

  const handleCloseAlert = () =>{
    setAlertAttr({visible: false, message: null, color: null});
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const isLogoUrl = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].field === "logo") {
        if(array[i]?.logoType === 'upload'){
          return true
        }
      }
    }
    return false;
  }

  const getLogoValue = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].field === "logo") {
        if(array[i]?.logoType === 'upload'){
          return true
        }
      }
    }
    return false;
  }

  const handleRadioChange = (event) => {
    setUploadType(event.target.value);
  };

  const onClear = () => {
    onClearPhoto(switchButton ? metaData : metaDataBack)
    setUploadFile('')
  };

  const attachPhotoOption = () => {
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 20 }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={uploadType}
            onChange={handleRadioChange}fuploz
          >
            <FormControlLabel value="upload" control={<Radio />} label="Upload" />
            <FormControlLabel value="url" control={<Radio />} label="URL" />
          </RadioGroup>
        </FormControl>  
        <AppButton
          variant='text'
          buttonText="Clear Photo"
          onClick={() => onClear()}
          className='cancel-btn'
          style={{ color: '#FF0000', background: null, marginLeft: 10, marginTop: 0, float: 'right' }}
        />
        <div style={{color:'gray',fontSize:'12px'}}>
          <p style={{color: errorUpload ? 'red' : 'gray'}} >Supported files: jpg,jpeg,png</p>
        </div>
        
        
      </div>
    );
  }

  const toggleUploadPhotoForm = () => {
    setIsUploadPhoto(true)
    setDefaultLogoVal(getFieldData('logo'))
  }

  const toggleUploadPhotoModal = () => {
    setErrorUpload(false)
    setIsUploadPhoto();
    setUploadFile("")
    setImgFile([])
    setUploadType("upload");
    switchButton ? setMetadata({...metaData,logo:defaultLogoVal}) : setMetadataBack({...metaDataBack,logo:defaultLogoVal});
  }

  const handleSelectToggle = (event) => {
    setMetadata({})
    setMetadataBack({})
    setSaveAsMain(!saveAsMain)
  };

  const getSelectFromUserListData = async () => {
    const result = await ApiPost("/corps/user-lists-search", {
      corp_id: corp_id,
      page: page + 1
    });

    if (result?.data?.data?.users) {
      const newListUsers = result?.data?.data?.users.map(item => {
        const rowData = {
          corp_id: item.id,
          ...item?.metadata[0]?.front[0],
          ...item?.metadata[1],
          status: item.status,
          role: item.namecard_status
        };
        return rowData;
      });

      setAllMainListData(newListUsers)
      setMainListWithoutSub(newListUsers)
    }
  }

  const isFieldObject = (value) => {
    const isObject = typeof value === 'object' && value !== null;
        
    if (isObject) {
      return value.number;
    } else {
      return value;
    }
  }

  const CreateNamecardLink = (uuid) => {
    const { apiUrl } = getEnvVars();
    const getUniqueCode = uuid?.substring(0, 8);
    return `${apiUrl}/nameCard/${getUniqueCode}`;
  }

  const checkNumber = obj => {
    if (obj && typeof obj === "object") {
        return  obj.formatedNum;
    }else{
        return  obj;
    }
  };

  const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }

  const generateVCard = (user) => {

    const uuid = user?.corp_id
    const namecardLink = CreateNamecardLink(uuid);

    const name = user?.name ? user?.name : "";
    const local_name = user?.local_name ? user?.local_name : "";
    const company = user?.company ? user?.company : "";
    const position = user?.position ? user?.position : "";
    const department = user?.department ? user?.department : "";
    const division = user?.division ? user?.division : "";
    const address = user?.address ? user?.address : "";
    const office_number = user?.office_number ? isJson(user.office_number) ? user.office_number.extension ? `${user.office_number.number}, ${user.office_number.extension}` : checkNumber(user.office_number?.number) : checkNumber(user?.office_number) : "";
    const mobile_number = user?.mobile_number ? isJson(user.mobile_number) ? user.mobile_number.extension ? `${user.mobile_number.number}, ${user.mobile_number.extension}` : checkNumber(user.mobile_number?.number) : checkNumber(user?.mobile_number) : "";
    const fax_number = user?.fax_number ? isJson(user.fax_number) ?  user.fax_number.extension ? `${user.fax_number.number}, ${user.fax_number.extension}` : checkNumber(user.fax_number?.number) : checkNumber(user?.fax_number) : "";
    const company_number = user?.company_number ? checkNumber(user?.company_number) : "";
    const email = user?.email ? user?.email : "";
    const website = user?.website ? user?.website : "";
    const building = user?.building ? user?.building : "";
    const room = user?.room ? user?.room : "";
    const street = user?.street ? user?.street : "";
    const country = user?.country ? user?.country : "";

    return [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `CARDID:${corp_id}`,
      `N:${name}`,
      `FN:${name}`,
      `LN:${local_name}`,
      `ORG:${company}`,
      `TITLE:${position}`,
      `DEPARTMENT:${department}`,
      `DIVISION:${division}`,
      `ADR:${address}`,
      `TEL;TYPE=work:${office_number}`,
      `TEL;TYPE=cell:${mobile_number}`,
      `TEL;TYPE=fax:${fax_number}`,
      `TEL;TYPE=company:${company_number}`,
      `EMAIL;WORK;INTERNET:${email}`,
      `URL:${namecardLink}`,
      `URL:${website}`,
      `BUILDING:${building}`,
      `STREET:${street}`,
      `ROOM:${room}`,
      `COUNTRY:${country}`,
      `CORPID:${corp_id}`,
      "END:VCARD"
    ].join("\r\n");
  }

  const CreateProfileLink = (uuid, mainCardUUID) => {

    let link = 'https://dev-web.lexiscard.asia'

    if (window.location.href.includes("localhost")) {
      link = 'http://localhost:3003'
    }else{
        if(window.location.href.includes("dev-corp")){
          link = 'https://dev-web.lexiscard.asia'
        }else{
          link = 'https://www.lexiscard.asia'
        }
    }

    let uuid_key = ''
    if(mainCardUUID){
      uuid_key = mainCardUUID?.substring(0, 4) + uuid?.substring(0, 8);
    }else{
      uuid_key = uuid
    }
    return `${link}/profile?data=${uuid_key}`
  }

  const onPreviewNameCard = async (currentRow, isQRCode = false) => {
    try{
      setShowLoader(true);

      if(isQRCode){
        const frontOrientation = 'horizontal';
        const backOrientation = 'horizontal';
        const cardValue = generateVCard(currentRow)
        const profileLink = currentRow?.profileID ? CreateProfileLink(currentRow?.profileID) : CreateProfileLink(currentRow.corp_id, currentRow.corp_id)

        setFrontOrientation(frontOrientation)
        setBackOrientation(backOrientation)
        setShowLoader(false);
        setPreviewModal({visible:true, img1: cardValue, img2: profileLink, isQRCode: true})
      }else{
        let generateUrl = 'http://localhost:3000/corps/generate-namecard'

        if (window.location.href.includes("localhost")) {
          generateUrl = 'https://dev-web.lexiscard.asia/api/corps/generate-namecard'
        }else{
            if(window.location.href.includes("dev-corp")){
              generateUrl = 'https://dev-web.lexiscard.asia/api/corps/generate-namecard'
            }else{
              generateUrl = 'https://www.lexiscard.asia/api/corps/generate-namecard'
            }
        }

        let filterTemplate = allTemplates.filter((item)=> item.id === defaultTempId)

        if(tabIndicator ===  'others'){
          filterTemplate = allTemplates.filter((item)=> item.id === isSelectedTemplate)
        }

        const data = await ApiPost("/generate-namecard", {
          generateUrl: generateUrl,
          data: {
            email: currentRow.email,
            corp_id: corp_id,
            template_id: tabIndicator === 'default' ? defaultTempId : isSelectedTemplate,
            templateJson: filterTemplate[0].template
          },
        });

        const frontOrientation = filterTemplate[0]?.template[5]?.cardOrientation[0]?.front ? filterTemplate[0]?.template[5]?.cardOrientation[0]?.front : 'horizontal'
        const backOrientation = filterTemplate[0]?.template[5]?.cardOrientation[1]?.back ? filterTemplate[0]?.template[5]?.cardOrientation[1]?.back : 'horizontal'

        if(data.status === 200){
          setFrontOrientation(frontOrientation)
          setBackOrientation(backOrientation)
          setShowLoader(false);
          setPreviewModal({visible:true, img1: data.data.data[0].img1, img2: data.data.data[1].img2, isQRCode: false})
        }else{
          setShowLoader(false);
          setAlertAttr({visible: true, message: `Failed generating preview.`, color: '#b2102f'});
        }
      }
      
    } catch (error) {
      setShowLoader(false);
      setAlertAttr({visible: true, message: `Error generating preview.`, color: '#b2102f'});
    }
    
  }

  const onClearPhoto = (data) => {
    if(switchButton){
      setMetadata({ ...data, logo: '', logo_base64: '' });
      setUploadFile('')
    }else{
      setMetadataBack({ ...data, logo: '', logo_base64: '' });
      setUploadFile('')
    }
  }

  const handleAddUser =  ()  =>  {
    setMetadata({});
    setMetadataBack({});
    setIsAdd(!isAdd)
    setSaveAsMain(true)

    let filterTemplate = allTemplates.filter((item)=> item.id === defaultTempId)

    if(tabIndicator ===  'others'){
      filterTemplate = allTemplates.filter((item)=> item.id === isSelectedTemplate)
    }

    const frontOrientation = filterTemplate[0]?.template[5]?.cardOrientation[0]?.front ? filterTemplate[0]?.template[5]?.cardOrientation[0]?.front : 'horizontal'
    const backOrientation = filterTemplate[0]?.template[5]?.cardOrientation[1]?.back ? filterTemplate[0]?.template[5]?.cardOrientation[1]?.back : 'horizontal'

    setFrontOrientation(frontOrientation)
    setBackOrientation(backOrientation)
  }

  const handleAddUserSub = () => {
    setMetadata({});
    setMetadataBack({});
    setIsAddSub(true);
    setSaveAsMain(false);
    getSelectFromUserListData()

    let filterTemplate = allTemplates.filter((item)=> item.id === defaultTempId)

    if(tabIndicator ===  'others'){
      filterTemplate = allTemplates.filter((item)=> item.id === isSelectedTemplate)
    }

    const frontOrientation = filterTemplate[0]?.template[5]?.cardOrientation[0]?.front ? filterTemplate[0]?.template[5]?.cardOrientation[0]?.front : 'horizontal'
    const backOrientation = filterTemplate[0]?.template[5]?.cardOrientation[1]?.back ? filterTemplate[0]?.template[5]?.cardOrientation[1]?.back : 'horizontal'

    setFrontOrientation(frontOrientation)
    setBackOrientation(backOrientation)
  }

  const getLicenseUsers = async () => {
    setIsloading(true)
    if (licenseUserData) {
      const data = await ApiPost("/corps/corp-template", { corp_id: corp_id });

      let filteredArray = []

      let adminLists = adminList
      if(adminLists.length === 0){
        const getAdmins = await ApiPost("/corps/details", { corp_id: corp_id });
        const users = getAdmins.data.data[0].users

        const mergedUsers = users?.Admin ? users.Owner.concat(users.Admin) : users.Owner;
        setAdminList(mergedUsers)
        adminLists = mergedUsers
      }
      
      
      const default_fields = data.data;
      filteredArray = default_fields.filter(obj => {
        return obj.template.every(templateObj => !('isDefault' in templateObj) || templateObj.isDefault === false);
      });
    
      const defaultTemp = default_fields.filter(obj => {
        return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
      });
    
      const hasBuilding = defaultTemp[0].template[1].front.some(obj => obj.field === 'building');
      const hasBuildingBack = defaultTemp[0].template[2].back.some(obj => obj.field === 'building');
    
      const hasLogoField = defaultTemp[0].template[1].front.some(item => item.field === "logo");

      filteredArray = filteredArray.filter(item => {
        if (!adminDept || adminDept.length === 0) {
          return true;
        }
      
        const hasMatchingDepartment = item?.department?.some(department => {
          return item.department && item.department.some(dept => {
            return adminDept.some(adminDeptItem => adminDeptItem.subCompId === department.subCompId && adminDeptItem.deptId === dept.deptId);
          });
        });
      
        return hasMatchingDepartment;
      });
      
      setIsMultiAddress(hasBuilding);
      setIsMultiAddressBack(hasBuildingBack);
      setTemplateData(defaultTemp[0].template[1].front);
      setTemplateDataBack(defaultTemp[0].template[2].back);
      setTemplateDataEdit(defaultTemp[0].template[1].front)
      setTemplateDataBackEdit(defaultTemp[0].template[2].back)
      setAllTemplates(default_fields);
      setTemplates(filteredArray);
      setHasLogo(hasLogoField);
      setDefaultTempId(defaultTemp[0].id)

      if(defaultTemp[0].template[3].template_image[0].front){
        setTemplateBackgroundFront(defaultTemp[0].template[3].template_image[0].front);
      }

      if(defaultTemp[0].template[3].template_image[1].back){
        setTemplateBackgroundBack(defaultTemp[0].template[3].template_image[1].back);
      }

      const response = await ApiPost("/corps/get-license-user", {
        licenseUserData: licenseUserData
      });
      
      console.log('11')
      if (response.data.data.length > 0) {
        const newListUsers = response.data.data.map(item => {

          const createdon = new Date(item?.createdon);
          const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

          const createdBy = adminLists.filter(user => user?.id === item?.created_by);

          const rowData = {
            corp_id: item.id,
            ...item?.metadata[0]?.front[0],
            ...item?.metadata[1],
            assignDept: item?.metadata[3],
            status: item.status,
            role: item.namecard_status,
            isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
            createdon: formattedDate,
            createdBy: createdBy[0]?.useremail,
            template_id: item?.template_id
          };
          return rowData;
        });

        const newestUserList = newListUsers.filter((item) => item.template_id === defaultTemp[0].id)

        setTotalTableCount(newListUsers.length);
        setListData(newestUserList);
        setOriginalList(newestUserList)
        setIsloading(false);
      }

      if (filteredArray[0]?.template) {
        const hasLogoFieldOthers = filteredArray[0].template[1].front.some(item => item.field === "logo");
        const userFilters = filteredArray[0].department
    
        setTemplateDataOther(filteredArray[0].template[1].front);
        setTemplateDataOtherBack(filteredArray[0].template[2].back);
        setIsSelected(filteredArray[0].template[0].template_name);
        setIsSelectedTemplate(filteredArray[0].id);
        setHasLogoOthers(hasLogoFieldOthers);
  
        const hasBuildingOthers = filteredArray[0].template[1].front.some(obj => obj.field === 'building');
        const hasBuildingOthersBack = filteredArray[0].template[2].back.some(obj => obj.field === 'building');
        setIsMultiAddressOthers(hasBuildingOthers);
        setIsMultiAddressOthersBack(hasBuildingOthersBack);

        if(filteredArray[0].template[3].template_image[0].front){
          setTemplateBackgroundFrontOthers(filteredArray[0].template[3].template_image[0].front);
        }

        if(filteredArray[0].template[3].template_image[1].back){
          setTemplateBackgroundBackOthers(filteredArray[0].template[3].template_image[1].back);
        }

        const response = await ApiPost("/corps/get-license-user", {
          licenseUserData: licenseUserData,
        });

        console.log('10')
        if (response.data.data.length > 0) {
          const newListUsers = response.data.data.map(item => {

            const createdon = new Date(item?.createdon);
            const formattedDate = `${createdon.getFullYear()}/${(createdon.getMonth()+1).toString().padStart(2, '0')}/${createdon.getDate().toString().padStart(2, '0')} ${createdon.getHours().toString().padStart(2, '0')}:${createdon.getMinutes().toString().padStart(2, '0')}:${createdon.getSeconds().toString().padStart(2, '0')} ${createdon.getHours() >= 12 ? 'PM' : 'AM'}`;

            const createdBy = adminLists.filter(user => user?.id === item?.created_by);

            let defualtInstitution
            let defaultDepartment
            const deptAssigned = item?.metadata[3]?.department

            defualtInstitution = subCompList.find((subComp) => subComp.id === deptAssigned?.subCompId)
            defaultDepartment = departmentList.find((dept) => dept.id === deptAssigned?.deptId)

            let institution = []
            let department = []

            if(deptAssigned){
              if (userFilters) {
                institution = userFilters
                  .flatMap((filter) => {
                    const matchingSubComp = subCompList.find((subComp) =>
                      subComp.id === filter.subCompId
                    ); 
              
                    if (matchingSubComp) {
                      return [matchingSubComp.name];
                    } else {
                      return [];
                    }
                  });
              }
    
              if (userFilters) {
                department = userFilters
                  .flatMap((filter) => {
                    const matchingDept = departmentList.find((dept) =>
                      dept.id === filter.deptId
                    );
              
                    if (matchingDept) {
                      return [matchingDept.department_name];
                    } else {
                      return [];
                    }
                  });
              }
    
              institution = institution.length > 0 ? institution[0] : ''
              department = department.length > 0 !== '' ? department[0] : ''
            }

            const deptName = `${defaultDepartment?.department_name}${defaultDepartment?.cost_center ? ` - ${defaultDepartment?.cost_center}` : ''}`

            const rowData = {
              corp_id: item.id,
              ...item?.metadata[0]?.front[0],
              ...item?.metadata[1],
              institutions: defualtInstitution ? defualtInstitution.name : institution,
              departments:  defaultDepartment ? deptName : department,
              status: item.status,
              role: item.namecard_status,
              parent_id: item.namecard_parent_id,
              isPasswordUpdated: item?.password_settings?.isPasswordUpdated,
              createdon: formattedDate,
              createdBy: createdBy[0]?.useremail
            };
            return rowData;
          });
    
          setTotalTableCountSub(newListUsers.length);
          setListDataSub(newListUsers);
          setOriginalListSub(newListUsers)
          setIsloading(false);
        }
      }
    }
  }

  const checkMainEmailUser = async (value) => {

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setMetadata({...metaData,email:value})
    const newTimeoutId = setTimeout(async () => {
      const result = await ApiPost("/corps/check-user-main", {
        corp_id: corp_id,
        search: value
      });
  
      const response = result?.data?.data?.count

      setCheckMainEmail(response)
    }, 1000);

    setTimeoutId(newTimeoutId);
  }

  const toggleDesinateAdd = () => {
    setAlertDialog({visible: false, title: null, message: null})
    setToAssignDepartment('1')
    setToAssignSubComp('1')
  }


  const onLicenseTab = () => {
    setLicensePage(0);
    setLicenseItemsPerPage(10);
    setLicenseSearch('')
    setTabIndicator("licensedUsers")
  }

  const onEditQR = (val, data) => {

    const updatedQrJson = { ...qrJson, ...val };
    const updatedToSaveQrJson = { ...originalQRData, ...data };

    setQRMeta(updatedQrJson)
    setOriginalQRData(updatedToSaveQrJson)
  }

  const onEditQrDesign = () => {
    setShowLoader(true)
    setTimeout(()=>{      
      setEditQRTemplate(true)
      setShowLoader(false)
    },800)
  }

  const cancelEditQR = () =>{
    setShowLoader(true)
    getQRTemplate();
    setTimeout(()=>{      
      setEditQRTemplate(false)
      setShowLoader(false)
    },800)
  }

  const onSaveQrDesign = async () => {
    setAlertDialogQR(true);
  }

  const proceedSaveQrDesign = async (toSaveQrJson) => {
    try{
      setShowLoader(true)
      setAlertDialogQR(false)
      setEditQRTemplate(false)
      const data = await ApiPost("/corps/add-qr-template", {
        corp_id: corp_id,
        qrJson: toSaveQrJson,
        user_id: userData[0].id,
      })

      if (data.data.status === 200) {
        setAlertAttr({ visible: true, message: 'QR design updated successfully', color: '#4caf50' });
        setShowLoader(false)
      } else {
        setAlertAttr({ visible: true, message: data.data.message, color: '#b2102f' });
        setShowLoader(false)
      }
    } catch (error) {
      console.log("error on save qr template",error )
      setAlertAttr({ visible: true, message: error.message, color: '#b2102f' });
      setShowLoader(false)
    } 
  }

  const getQRTemplate = async () => {
    try {
      const response = await ApiPost("/corps/get-qr-template", { corp_id: corp_id });

      if(response?.data?.data[0]?.metadata){
        const restructureQrData = { ...qrJson }

        const qrFetchMeta = response.data.data[0].metadata;
        restructureQrData.backgroundOptions.color = qrFetchMeta.backgroundColor;
        restructureQrData.dotsOptions.color = qrFetchMeta.color;
        restructureQrData.image = qrFetchMeta.logo
        restructureQrData.imageOptions.imageSize = qrFetchMeta.logoSize / 100
        restructureQrData.imageOptions.margin  = qrFetchMeta.logoMargin

        if(qrFetchMeta.enableLinearGradient){
          restructureQrData.dotsOptions.type = 'linear';
          restructureQrData.dotsOptions.rotation =  -1
          restructureQrData.dotsOptions.gradient.colorStops[0].color = qrFetchMeta.linearGradient[0];
          restructureQrData.dotsOptions.gradient.colorStops[1].color = qrFetchMeta.linearGradient[1];
        }
        
        restructureQrData.image = qrFetchMeta.logo;

        setOriginalQRData(qrFetchMeta);
        setQRMeta(restructureQrData);
      }else{
        setOriginalQRData({
          "color": '#811aff',
          "backgroundColor": '#FFFFFF',
          "enableLinearGradient": true,
          "linearGradient": ['#811aff', '#0e80f1'],
          "gradientDirection": ["0%", "100%"],
          "logo": '',
          "logoSize": 30,
          "logoBackgroundColor": 'transparent',
          "logoMargin": 2,
          "logoBorderRadius": 0,
        });
        setQRMeta(qrJsonDefault);
      }
      
    } catch (error) {
      console.log("error on get qr template",error )
    }
  }

  const handleImageUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const maxFileSize = 1048576; // 1 MB

    const fileType = acceptedFiles[0].name.split('.').pop();
  
    if(fileType === 'PNG' || fileType === 'png' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'jpg' || fileType === 'jpeg'){
      const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              
              let { width, height } = img;
              if (width > maxWidth || height > maxHeight) {
                if (width > height) {
                  height = Math.round((height *= maxWidth / width));
                  width = maxWidth;
                } else {
                  width = Math.round((width *= maxHeight / height));
                  height = maxHeight;
                }
              }
              
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);
              canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.7);
            };
            img.src = event.target.result;
          };
          reader.readAsDataURL(file);
        });
      };
    
      resizeImage(file, 600, 600).then((resizedBlob) => {
        if (resizedBlob.size > maxFileSize) {
          setErrorUpload(true);
        } else {
          setErrorUpload(false);
          const resizedFile = new File([resizedBlob], file.name, { type: 'image/jpeg' });
  
          setUploadFile([resizedFile]);
          const reader = new FileReader();
          reader.onload = () => {
            setImgFile(reader.result);
            setIsCropperOpen(true);
          };
          reader.readAsDataURL(resizedFile);
        }
      });
    }else{
      setErrorUpload(true)
    }
    
  };
  

  const imageCropped = (data) => {
    const base64Data = data.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
    const blob = base64toBlob(base64Data, 'image/jpeg');
    const file = new File([blob], `cropped_image.jpg`, { type: 'image/jpeg' });
  
    // Create a URL representing the file object
    const fileURL = URL.createObjectURL(file);
  
    setIsCropperOpen(false);
    setUploadFile([file]);
  };
  

  function base64toBlob(base64Data, contentType = '') {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  return (
    <>
      <Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)}>
      <Header pageTitle={`User List (${totalUserCount}/${numberUsersPlan})`}>
        <Snackbar
          ContentProps={{
            sx: {
              background: alertAttr.color
            }
          }}
          open={alertAttr.visible}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={anchorOrigin}
          message={alertAttr.message}
          action={action}
        />

        <TabList className="tablist">
          <Tab className="tab-1" onClick={()=> {
            setTabIndicator("default")
            if(!licenseUserData){
              filterUsers(toAssignSubComp, 'subCompId', toAssignDepartment, 'default')
            }
          }}><span id="step1">Default Name Cards</span></Tab>

          <Tab className="tab-2" onClick={async()=> {
            setTabIndicator("others") 
            if(templates.length > 0){
              if(!licenseUserData){
                filterUsers(toAssignSubComp, 'subCompId', toAssignDepartment, 'others')
              }
              await filterTemplate(templates[0])
            }
          }}><span id="step3">Other Name Cards</span></Tab>

          <Tab className="tab-3" onClick={()=> {
            setTabIndicator("contactList")
          }}><span id="step5">Contact List</span></Tab>

          <Tab className="tab-4" onClick={()=> {
            onLicenseTab()
          }}><span id="step6">Licensed Users</span></Tab>
        </TabList>
      </Header>

        <TabPanel className="settingsTab">
          <div style={{width:'90%',marginTop:"50px"}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', width: '100%', marginBottom: 20}}>
              <AppDropdown 
                key={`subCompDropdown-${toAssignSubComp}`}
                onSelect={(val)=> {
                  filterUsers(val, 'subCompId', toAssignDepartment, tabIndicator)
                }} 
                defaultValue={toAssignSubComp}
                data={subCompOption} 
                style={{ width: '15%', fontSize:"13px", marginRight:"15px"}}
              />
              <AppDropdown 
                key={`deptDropdown-${toAssignDepartment}`}
                onSelect={(val)=> {
                  filterUsers(val, 'deptId', toAssignSubComp, tabIndicator)
                }} 
                defaultValue={toAssignDepartment}
                data={deptFilterOption} 
                style={{ width: '15%', marginRight: 0, fontSize:"13px"}}
              /> 
            </div>
            <AppTable
              step="step2"
              rows={listData} 
              totalCounts={userTableConfig.totalCount}
              headCells={ hasLogo ? headCellsWithPhoto : headCells} 
              isLoading={isLoading}  
              trackSelected={trackSelected} 
              numerSelected={(val)=>numerSelected(val)} 
              toggleSelected={()=>setDeleteSelected(!deleteSelected)}
              exportToExcelSelected={()=>exportToExcelSelected()}
              ExportToExcel={()=>ExportExcel(listData)}
              handleSuspend={(val)=>handleSuspend(val)}  
              toggleModal={()=>setIsEdit(!isEdit)} 
              toggleDelete={(val)=>handleDelete(val)}
              handleEdit={(val)=>handleEdit(val)} 
              handleAdd={()=>handleAddUser()} 
              deleteMultiple={(val)=>deleteMultiple(val)}
              deleteMultipleUser={(val)=>deleteMultipleUser(val)} 
              activateMultipleUser={(val)=>activateMultipleUser(val)}

              filterItem={(val, keyvalue, type)=>filterItem(val, keyvalue, type)} 
              handleImport={()=>handleImport()}
              hundleActivationLink={hundleActivationLink}
              component="default"
              totalTableCount={totalTableCount}
              totalRowsPerPage={itemsPerPage}
              currentPage={page}
              setPropsItemPerPage={(val) => handleUserListsPerPage(val)}
              setPropsPage={val => handleUserListsPage(val)}
              onPreviewNameCard={onPreviewNameCard}
              handleSendPasswordReset={handleSendPasswordReset}
              canImport={canImport === undefined ? true : canImport}
            />
          </div>
        </TabPanel>

        <TabPanel className="settingsTab">
          <div className='settingsTab_container'>
            <div className='settingsTab_tagline'>
              <EmojiObjectsIcon style={{color:'gray'}}/>
              <p>These name cards useful for staff who hold multiple positions or are part of a subsidiary. Head over to Name Card Design to add an unlimited number of name cards.</p>
            </div>
            <div id="step4" className='button_options'>
              <div class="content-wrapper">
              {
                templates.length > 0 ?
                  templates.map((item, index) => {
                    const selected = isSelected === item.template[0].template_name;
                    return(
                      <>
                        <AppButton 
                          key={index}
                          buttonText={item.template[0].template_name}
                          variant={selected ? 'contained' : 'outlined'}
                          style={{background: selected ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12}}
                          textStyle={{fontSize: 12, color: selected ? 'white' : '#01082E'}}
                          onClick={async()=> {
                            await filterTemplate(item);
                            // await getAllMainWithoutSub();
                          }}
                        />
                      </> 
                    )
                  })
                :
                <div className='settingsTab_tagline' style={{marginBottom: 20}}>
                  <p>There are no other name cards are available right now.</p>
                </div>
              }   
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', width: '100%', marginBottom: 20}}>
              <AppDropdown 
                key={`subCompDropdownOther-${toAssignSubComp}`}
                onSelect={(val)=> {
                  filterUsers(val, 'subCompId', toAssignDepartment, tabIndicator)
                }} 
                defaultValue={toAssignSubComp}
                data={subCompOption} 
                style={{ width: '15%', fontSize:"13px", marginRight:"15px"}}
              />
              <AppDropdown 
                key={`deptDropdownOther-${toAssignDepartment}`}
                onSelect={(val)=> {
                  filterUsers(val, 'deptId', toAssignSubComp, tabIndicator)
                }} 
                defaultValue={toAssignDepartment}
                data={deptFilterOption} 
                style={{ width: '15%', marginRight: 0, fontSize:"13px"}}
              /> 
            </div>  
            <div>
              <AppTable 
                rows={listDataSub} 
                isForSub={true} 
                isLoading={isLoading} 
                numerSelected={(val)=>numerSelected(val)} 
                ExportToExcel={()=>ExportExcel(listDataSub)} 
                exportToExcelSelected={()=>exportToExcelSelected()} 
                headCells={ hasLogoOthers ? headCells2WithPhoto : headCells2} 
                handleAdd={()=> handleAddUserSub()} 
                toggleDelete={(val)=>handleDeleteSub(val)} 
                handleEdit={(val)=>handleEditSub(val)} 
                handleSuspend={(val)=>handleSuspend(val)}  
                toggleModal={()=>setIsEdit(!isEdit)} 
                deleteMultiple={(val)=>deleteMultiple(val)} 
                deleteMultipleUser={(val)=>deleteMultipleUserSub(val)} 
                toggleSelected={()=>setDeleteContactSelected(!deleteContactSelected)} 
                trackSelected={trackSelected}
                filterItem={(val, keyvalue, type)=>filterItem(val, keyvalue, type)}
                handleImport={()=>handleImport()}
                otherTemplateCount={templates.length}
                component="others"
                totalTableCount={totalTableCountSub}
                totalRowsPerPage={itemsPerPage}
                setPropsPage={val => handleUserListsPage(val)}
                setPropsItemPerPage={(val) => handleUserListsPerPage(val)}
                hundleActivationLink={hundleActivationLink}
                onPreviewNameCard={onPreviewNameCard}
                handleSendPasswordReset={handleSendPasswordReset}
                canImport={canImport === undefined ? true : canImport}
              />
            </div>
          </div>
        </TabPanel>
          
        {/* this is tab 3 */}
        <TabPanel className="settingsTab">
        <div style={{width:'90%',marginTop:"50px"}}>
          <AppTable
            rows={contactList} 
            totalCounts={userContactTableConfig.totalCount}
            headCells={headCells3} 
            isLoading={isLoading}
            trackSelected={trackContactSelected}
            toggleDelete={(val)=>handleDeleteSub(val)} 
            exportToExcelSelected={()=>exportToExcelSelected2()} 
            ExportToExcel={()=>ExportExcelContact(contactList)} 
            // handleAdd={()=>setIsAdd(!isAdd)}
            deleteMultiple={(val)=>deleteMultiple2(val)}
            activateMultipleUser={(val)=>deleteMultipleUserSub(val)} 
            hundleActivationLink={hundleActivationLink}
            totalRowsPerPage={contactItemsPerPage}
            filterItem={(val, keyvalue)=> handleContactSearch(keyvalue)} 
            isAction={false}
            component="contacts"
            currentPage={contactPage}
            setPropsItemPerPage={(val) => handleUserListsPerPage2(val)}
            setPropsPage={val => handleUserListsPage2(val)}
          />
        </div>
        </TabPanel>

        {/* this is tab 4 */}
        <TabPanel className="settingsTab">
        <div style={{width:'90%',marginTop:"50px"}}>
          <AppTable
            rows={licensesList} 
            totalCounts={licenseTableConfig.totalCount}
            headCells={headCells4} 
            isLoading={isLoadingLicenseTable}
            toggleDelete={(val)=> handleDeleteLicense(val)}
            // ExportToExcel={()=>ExportExcelContact(contactList)} 
            totalRowsPerPage={licenseItemsPerPage}
            filterItem={(val, keyvalue)=> handleLicenseSearch(keyvalue)} 
            filterItemDropDown={(val, keyvalue)=> handleLicenseDropDown(val)} 
            // isAction={false}
            component="licenses"
            currentPage={licensePage}
            setPropsItemPerPage={(val) => handleLicenseItemPerPage(val)}
            setPropsPage={val => handleLicenseListPage(val)}
          />
        </div>
        </TabPanel>
          
      </Tabs>

      {/* for tab 1 edit modals --------------------- */}
      <AppModal
        showModal={isEdit}
        header="Edit Details"
        functionText={"Save"}
        toggleModal={()=> toggleEditModal()}
        onClick={()=> isEditSub ? submitEditSub() : submitEdit(false)}
        style={{ width: '60%' }}
        onClear={() => toggleUploadPhotoForm()}
        dataEntry={true}
        content={
          <div> 
            <div style={{ padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <IconButton onClick={() => goFrontForm()} style={{ display: switchButton ? 'none' : '' }}>
                <ArrowBackIosNewIcon style={{ fontSize: "15px" }} />
              </IconButton>
              <p style={{ marginRight: "5px", marginLeft: "5px", color: 'black', fontWeight: 'bold' }}>{switchButton ? 'Front' : 'Back'}</p>
              <IconButton onClick={() => goBackForm()} style={{ display: switchButton ? '' : 'none' }}>
                <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
              </IconButton>
            </div>

            {
              isAddSub ?
              <>
                <AppInput
                  label="Name Card Template"
                  defaultValue={isSelected}
                  disabled={true}
                  style={{ width:"96%",fontSize:"13px",marginBottom: 12,marginLeft: 10}}
                /> 
                {
                  saveAsMain ? 
                    <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10}}>
                      <Typography variant="subtitle1" style={{marginBottom: 3, fontSize: 14, fontWeight: 700}}> </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          onChange={handleSelectToggle}
                          checked={saveAsMain}
                        />
                        Designate as Main Card
                      </Typography>
                    </div> : <>
                      <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10}}>
                        <Typography variant="subtitle1" style={{marginBottom: 3, fontSize: 14, fontWeight: 700}}>Name</Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            flexBasis: '50%',
                            boxSizing: 'border-box',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              marginRight: '5px'
                            }}
                            onChange={handleSelectToggle}
                          />
                          Designate as Main Card
                        </Typography>
                      </div>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={mainListWithoutSub.map((option) => ({label: option.name, val: option.corp_id}))}
                        style={{
                          padding: 0,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10
                        }}
                        onChange={(event, value) => {
                          selectUser(value.val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search'
                            }}
                            size="small"
                            onChange={(e) => searchMainUser(e, e.target.value)}
                          />
                        )}
                      />
                    </>
                }
              </>
              : null
            }

            <div style={{ padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <div
                id="step4Edit"
                className="image_container"
                style={{
                  height: side === 'front' ? frontOrientation === 'horizontal' ? '516px' : '630px' : backOrientation === 'horizontal' ? '516px' : '630px',
                  width: side === 'front' ? frontOrientation === 'horizontal' ? '945px' : '344px' : backOrientation === 'horizontal' ? '945px' : '344px',
                  backgroundImage: `url(${switchButton ? (tabIndicator === 'others' ? templateBackgroundFrontOthers : templateBackgroundFront) : (tabIndicator === 'others' ? templateBackgroundBackOthers : templateBackgroundBack)})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 1)',
                  borderRadius: '6px',
                }}
              >
                {
                  switchButton ? (tabIndicator === 'others' ? templateDataOthers.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateDataOthers.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "front_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaData?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadata({...metaData,[field.field]:{number:val,extension:""}}) : field.field === 'email' ? checkMainEmailUser(val) : setMetadata({...metaData,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaData}
                          onClearPhoto={() => onClearPhoto(metaData)}
                          orientation={frontOrientation}
                        />
                      </div>
                    );
                  }) : templateData.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateData.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "front_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaData?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadata({...metaData,[field.field]:{number:val,extension:""}}) : field.field === 'email' ? checkMainEmailUser(val) : setMetadata({...metaData,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaData}
                          onClearPhoto={() => onClearPhoto(metaData)}
                          orientation={frontOrientation}
                        />
                      </div>
                    );
                  })) : (tabIndicator === 'others' ? templateDataOthersBack.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateDataOthersBack.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "back_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaDataBack?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadataBack({...metaDataBack,[field.field]:{number:val,extension:""}}) : setMetadataBack({...metaDataBack,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaDataBack}
                          onClearPhoto={() => onClearPhoto(metaDataBack)}
                          orientation={backOrientation}
                        />
                      </div>
                    );
                  }) : templateDataBack.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateDataBack.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "back_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaDataBack?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadataBack({...metaDataBack,[field.field]:{number:val,extension:""}}) : setMetadataBack({...metaDataBack,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaDataBack}
                          onClearPhoto={() => onClearPhoto(metaDataBack)}
                          orientation={backOrientation}
                        />
                      </div>
                    );
                  }))
                }
              </div>
            </div>
          </div>
        }
      />

      {/* -----------add modal---------- */}
      <AppModal
        showModal={isAdd || isAddSub ? true : false}
        toggleModal={() => toggleAddModal()}
        functionText={"Add"}
        onClick={submitAddUser}
        header="Add User"
        style={{ width: '60%' }}
        onClear={() => toggleUploadPhotoForm()}
        dataEntry={true}
        content={
          <div>
            <div style={{ padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <IconButton onClick={() => goFrontForm()} style={{ display: switchButton ? 'none' : '' }}>
                <ArrowBackIosNewIcon style={{ fontSize: "15px" }} />
              </IconButton>
              <p style={{ marginRight: "5px", marginLeft: "5px", color: 'black', fontWeight: 'bold' }}>{switchButton ? 'Front' : 'Back'}</p>
              <IconButton onClick={() => goBackForm()} style={{ display: switchButton ? '' : 'none' }}>
                <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
              </IconButton>
            </div>

            {
              isAddSub ?
              <>
                <AppInput
                  label="Name Card Template"
                  defaultValue={isSelected}
                  disabled={true}
                  style={{ width:"96%",fontSize:"13px",marginBottom: 12,marginLeft: 10}}
                /> 
                {
                  saveAsMain ? 
                    <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10}}>
                      <Typography variant="subtitle1" style={{marginBottom: 3, fontSize: 14, fontWeight: 700}}> </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          flexBasis: '50%',
                          boxSizing: 'border-box',
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: '5px'
                          }}
                          onChange={handleSelectToggle}
                          checked={saveAsMain}
                        />
                        Designate as Main Card
                      </Typography>
                    </div> : <>
                      <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: 10, marginRight: 10}}>
                        <Typography variant="subtitle1" style={{marginBottom: 3, fontSize: 14, fontWeight: 700}}>Name</Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            flexBasis: '50%',
                            boxSizing: 'border-box',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              marginRight: '5px'
                            }}
                            onChange={handleSelectToggle}
                          />
                          Designate as Main Card
                        </Typography>
                      </div>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={mainListWithoutSub.map((option) => ({label: option.name, val: option.corp_id}))}
                        style={{
                          padding: 0,
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: 10
                        }}
                        onChange={(event, value) => {
                          selectUser(value.val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search'
                            }}
                            size="small"
                            onChange={(e) => searchMainUser(e, e.target.value)}
                          />
                        )}
                      />
                    </>
                }
              </>
              : null
            }

            <div style={{ padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <div
                id="step4Edit"
                className="image_container"
                style={{
                  height: side === 'front' ? frontOrientation === 'horizontal' ? '516px' : '630px' : backOrientation === 'horizontal' ? '516px' : '630px',
                  width: side === 'front' ? frontOrientation === 'horizontal' ? '945px' : '344px' : backOrientation === 'horizontal' ? '945px' : '344px',
                  backgroundImage: `url(${switchButton ? (tabIndicator === 'others' ? templateBackgroundFrontOthers : templateBackgroundFront) : (tabIndicator === 'others' ? templateBackgroundBackOthers : templateBackgroundBack)})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 1)',
                  borderRadius: '6px',
                }}
              >
                {
                  switchButton ? (tabIndicator === 'others' ? templateDataOthers.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateDataOthers.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "front_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaData?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadata({...metaData,[field.field]:{number:val,extension:""}}) : field.field === 'email' ? checkMainEmailUser(val) : setMetadata({...metaData,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaData}
                          onClearPhoto={() => onClearPhoto(metaData)}
                          orientation={frontOrientation}
                          disabled={selectedValue !== 'item0' ? false : saveAsMain ? false : true}
                        />
                      </div>
                    );
                  }) : templateData.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateData.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }
                    // const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) + ' ' + field?.field.split('_')[1] : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "front_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaData?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadata({...metaData,[field.field]:{number:val,extension:""}}) : field.field === 'email' ? checkMainEmailUser(val) : setMetadata({...metaData,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaData}
                          onClearPhoto={() => onClearPhoto(metaData)}
                          orientation={frontOrientation}
                        />
                      </div>
                    );
                  })) : (tabIndicator === 'others' ? templateDataOthersBack.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateDataOthersBack.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }

                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "back_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaDataBack?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadataBack({...metaDataBack,[field.field]:{number:val,extension:""}}) : setMetadataBack({...metaDataBack,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaDataBack}
                          onClearPhoto={() => onClearPhoto(metaDataBack)}
                          orientation={backOrientation}
                          disabled={selectedValue !== 'item0' ? false : saveAsMain ? false : true}
                        />
                      </div>
                    );
                  }) : templateDataBack.map((field, index) => {

                    if (field?.field === 'address') {
                      const hasStreetField = templateDataBack.some(item => item?.field === 'street');
                      if (hasStreetField) {
                        return null;
                      }
                    }
                    
                    const fieldName = field?.field.includes('_other') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes('_o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.includes(' o') ? 'Other ' + field?.field.split('_')[0].charAt(0).toUpperCase() + field?.field.split('_')[0].slice(1) : field?.field.charAt(0).toUpperCase() + field?.field.slice(1).replace('_', ' ');

                    const uniqueKey = "back_" + field.field + "_" + index;
                    return (
                      <div key={uniqueKey} style={{position: "relative"}}>
                        <DataEntryFields
                          field={field}
                          label={field?.label}
                          value={field?.template_val ? field.template_val : ''}
                          style={field?.style}
                          isIcon={field?.isIcon}
                          iconLabel={field?.iconLabel}
                          iconType={field?.iconType}
                          type={field?.type}
                          defaultValue={isFieldObject(metaDataBack?.[field.field])}
                          onChange={(val)=> field.type === 'number' ? setMetadataBack({...metaDataBack,[field.field]:{number:val,extension:""}}) : setMetadataBack({...metaDataBack,[field.field]:val})}
                          isErrorEmail={isErrorEmail}
                          isErrorName={isErrorName}
                          fieldName={fieldName}
                          togglePhotoModal={()=> toggleUploadPhotoForm()}
                          uploadFile={uploadFile}
                          metaData={metaDataBack}
                          onClearPhoto={() => onClearPhoto(metaDataBack)}
                          orientation={backOrientation}
                        />
                      </div>
                    );
                  }))
                }
              </div>
            </div>
            {
              checkMainEmail === 1 ? <p style={{marginLeft: '8%', color: 'red', fontWeight: 700}}>The email provided is already associated with the another primary namecard.</p> : null
            }
          </div>
        }
      />

      
      {/* suspend */}
      <AppModal
        showModal={isSuspend}
        header={suspendRow?.status === 'Active' || suspendRow?.status === 'Inactive' ? 'Suspend Account' : 'Unsuspend Account' }
        toggleModal={()=> setisSuspend(!isSuspend)}
        functionText={suspendRow?.status === 'Active' || suspendRow?.status === 'Inactive' ? "Suspend" : "Unsuspend"}
        onClick={()=>suspendUser()}
        content={
          <div>
            <p style={{marginBottom:"10px"}}>{suspendRow?.status === 'Active' || suspendRow?.status === 'Inactive' ? 'Suspend the following account?' : 'Unsuspend the following account?'}</p>
            <div style={{padding:"10px",backgroundColor:"lightgray",borderRadius:"5px"}}>
              <p>{suspendRow?.name}</p>
              <p>{suspendRow?.email}</p>
            </div>

            {
              suspendRow?.status === 'Active' || suspendRow?.status === 'Inactive' ?
              <p style={{color:"red",marginTop:"10px"}}>
                User will not have access to their account until you unsuspend it.
              </p>
              :
              <p style={{color:"red",marginTop:"10px"}}>
                User will have access to their account if you unsuspend it.
              </p>
            }
          </div>
        }
      />

      {/* Delete License */}
      <AppModal
        showModal={isDeleteLicense}
        header="Delete License"
        toggleModal={()=> setIsDeleteLicense(false)}
        functionText={"Delete"}
        onClick={()=>deleteLicense()}
        content={
          <div style={{fontSize:"12px"}}>
            <p style={{marginBottom:"10px"}}>Delete the following license?</p>
            <div style={{padding:"10px",backgroundColor:"lightgray",borderRadius:"5px"}}>
              <p style={{fontSize:"16px"}}>{currentRow && currentRow?.license_number}</p>
              <p>{currentRow && currentRow?.email}</p>
            </div>

            <p style={{color:"red",marginTop:"10px",fontSize:"12px"}}>
              This license will be deleted permanently and will also removed in your billing details.
            </p>
          </div>
        }
      />

      {/* Delete */}
      <AppModal
        showModal={isDelete}
        header="Delete Account"
        toggleModal={()=> {isDeleteSub ? setIsDelete(!isDelete) : setIsDelete(!isDelete); setIsDeleteSub(!isDeleteSub) }}
        functionText={"Delete"}
        onClick={()=>deleteUser()}
        content={
          isDeleteSub ? 
          <div>
            <p style={{marginBottom:"20px"}}>Remove the following user from {isSelected} name card?</p>
            <p style={{marginBottom:"10px"}}>This user will still have access to his/her primary name card.</p>
            <div style={{padding:"10px",backgroundColor:"lightgray",borderRadius:"5px"}}>
              <p>{currentRow && currentRow?.name}</p>
              <p>{currentRow && currentRow?.email}</p>
            </div>
          </div>
          :
          <div style={{fontSize:"12px"}}>
            <p style={{marginBottom:"10px"}}>Delete the following account?</p>
            <div style={{padding:"10px",backgroundColor:"lightgray",borderRadius:"5px"}}>
              <p>{currentRow && currentRow?.name}</p>
              <p>{currentRow && currentRow?.email}</p>
            </div>

            <p style={{color:"red",marginTop:"10px",fontSize:"12px"}}>
              All contacts stored in this user's account will be erased permanently.
            </p>
          </div>
        }
      /> 

         {/* acvtivation all modal warning */}
      <AppModal
        showModal={activationModals}
        functionText={"Activation"}
        toggleModal={()=>setActivationModals(!activationModals)}
        onClick={()=>hundleMultipleActivationLink()}
        header="Activiation Selected"
        content={
            <div>
              <p>Send activation on {activationEmails?.length} accounts?</p>
              <p style={{marginTop:"9px"}}>All selected email's will be send an activation link.</p>
            </div>
        }
      /> 

      {/* detele all modal warning */}
      <AppModal
        showModal={deleteSelected}
        functionText={"Delete"}
        toggleModal={()=>setDeleteSelected(!deleteSelected)}
        onClick={()=>handleDeletaMultipleUser()}
        header="Delete Selected"
        buttonStyle={{background:'red',color:"white"}}
        content={
            <div>
              <p>Delete {selectedItems?.length} accounts?</p>
              <p style={{color:'red',marginTop:"9px"}}>All contacts stored in user's account will be erased permanently.</p>
            </div>
        }
     
      /> 

      {/* Import CSV File */}
      <AppModal 
        showModal={showImportModal} 
        header="Import XLS/CSV" 
        toggleModal={()=>setShowImportModal(!showImportModal)}
        onClick={() => importUsers(importFile)}
        content={
          <div className='content-container'>
           {
            tabIndicator === "others" ?  <AppInput
              label="Name Card Template"
              defaultValue={isSelected}
              disabled={true}
              style={{ width:"100%",fontSize:"13px",marginBottom: 10}}
            /> : null
           }             
            <div style={{paddingTop:"10px"}}>
              <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload File</label>
                <Dropzone onDrop={acceptedFiles => setImportFile(acceptedFiles)}>
                  {({getRootProps, getInputProps}) => (
                    <section className='drag_drop_container'>
                      <div {...getRootProps()} className='files_container'>
                        <input {...getInputProps()}/>
                        <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                        <p style={{color:'rgb(20, 110, 228)'}}>{importFile ? importFile[0]?.name.substring(0,12)+"..." : 'Drag and drop files here or browse'}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              <div style={{paddingTop:"10px",color:'gray',fontSize:'12px'}}>
                <p>Supported files: xls, csv</p>
                <p style={{marginTop: 15}}>Note: These imports will make use of the specified template above and will use it as their main card upon submitting.</p>
              </div>
            </div>
          </div>
        }
      />

      <AppModal
        showModal={importAlert}
        header="Import Successful"
        toggleModal={()=> setImportAlert(false)}
        onClick={() => setImportAlert(false)}
        functionText={"Close"}
        cancelButton={false}
        content={
          <div>
            {
              duplicateEmail.length > 0 ?
                <>
                  <p style={{marginBottom:"20px"}}>Here are the list of existing emails</p>
                  <div style={{padding:"10px",backgroundColor:"lightgray",borderRadius:"5px"}}>
                    {
                      duplicateEmail.map((data, index) => (
                        <p style={{color:'#FF0000'}}>{data.useremail}</p>
                      ))
                    }
                  </div>
                </>
                
                :
                <p style={{marginBottom:"20px"}}>All emails imported successfully</p>
            }
          </div>
        }
      />


      {/* Upload Photo Modal */}
      <AppModal
        showModal={isUploadPhoto}
        header="Upload Photo"
        toggleModal={toggleUploadPhotoModal}
        onClick={() => setIsUploadPhoto(false)}
        functionText="Set"
        cancelButton={true}
        disableIndicator={errorUpload}
        content={
          <>
            {uploadType === 'upload' ? (
              <div style={{ paddingTop: "20px", marginTop: "10px", width: '95%', marginLeft: 10, textAlign: 'center' }}>
                {uploadFile && (
                  <div style={{ paddingTop: "10px", display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                    <img src={URL.createObjectURL(uploadFile[0])} alt="Cropped" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                  </div>
                )}
                <Dropzone onDrop={acceptedFiles => handleImageUpload(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section className={errorUpload ? 'drag_drop_container_err' : 'drag_drop_container'}>
                      <div {...getRootProps()} className='files_container'>
                        <input {...getInputProps()} />
                        <CloudUploadIcon style={{ marginRight: "10px", color: 'rgb(20, 110, 228)' }} />
                        <p style={{ color: 'rgb(20, 110, 228)' }}>
                          {uploadFile ? uploadFile[0]?.name.substring(0, 12) + "..." : 'Drag and drop files here or browse'}
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            ) : (
              <>
                {metaData?.logo && (
                  <div style={{ paddingTop: "10px", display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                    <img src={metaData.logo} alt="Cropped" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                  </div>
                )}
                <AppInput
                  label="Photo URL"
                  defaultValue={getFieldData('logo')}
                  onChange={(val) => switchButton ? setMetadata({ ...metaData, logo: val }) : setMetadataBack({ ...metaDataBack, logo: val })}
                  style={{ width: "96%", fontSize: "13px", marginBottom: 12, marginLeft: 10 }}
                />
              </>
            )}
            {attachPhotoOption()}
          </>
        }
      />

      <AppImportUserDialog 
        visible={importDialog.visible}
        title={importDialog.title}
        subTitle={importDialog.subTitle}
        message={importDialog.message}
        positiveButtonName='Continue'
        onPositivePress={()=> setImportDialog({...importDialog, visible: false})}
        onClose={()=> setImportDialog({...importDialog, visible: false})}
      />

      <AppAlertDialog 
        visible={alertDialog.visible}  
        title={alertDialog.title}
        message={alertDialog.message}
        positiveButtonName={isEdit || isAdd ? 'Proceed' : 'Ok'}
        onPositivePress={()=> isEdit ? submitEdit(false) : isAdd ? submitAddUser() : setAlertDialog({...alertDialog, visible: false})}
        onNegativePress={()=> isAdd ? toggleDesinateAdd() : setAlertDialog({...alertDialog, visible: false})}
        onIgnorePress={isEdit ? () =>  submitEdit(true) : null}
        cancelText='Cancel'
      />

      {/* NAMECARD PREVIEW MODAL */}
      <AppModal
        showModal={previewModal.visible}
        header={previewModal.isQRCode ? "QR Preview (NFC Print)" : "Namecard Preview"}
        toggleModal={()=> {setPreviewModal({visible: false, img1: null, img2: null, isQRCode: false}); setFrontOrientation('horizontal'); setBackOrientation('horizontal')}}
        cancelButton={false}
        disableIndicator={true}
        functionText={"Close"}
        onClick={()=> setPreviewModal({visible: false, img1: null, img2: null, isQRCode: false})}
        style={{width: '50%'}}
        content={
          <>
            {
              previewModal?.isQRCode ? 
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className="user_qr_container_user" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
                      <AppQRGenerator data={previewFront ? previewModal.img2 : previewModal.img1} qrJsonMeta={qrMeta ? qrMeta : qrJson}/>
                    </div>
                    {
                      previewModal.img2 && previewModal.isQRCode && 
                      <div className='pagination-card' style={{marginBottom: 50, marginTop: 0}}>
                        <IconButton onClick={() => setPreviewFront(true)} style={{ display: !previewFront ? 'block' : 'none'}}>
                          <ArrowBackIosNewIcon style={{fontSize:"15px",}} />
                        </IconButton>
                        <p id="step4" style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>{previewFront ? 'Profile' : 'Connection'}</p>
                        <IconButton onClick={() => setPreviewFront(false)} style={{ display: previewFront ? 'block' : 'none'}}>
                          <ArrowForwardIosIcon style={{fontSize:"15px",}} />
                        </IconButton>
                      </div>
                    }
                  </div>
                  {
                    editQRTemplate ? 
                    <div style={{ maxHeight: '400px', overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                      <AppQRAttributes 
                        jsonData={qrMeta ? qrMeta : qrJson}
                        originalQrData={originalQRData}
                        onChange={onEditQR}
                      />
                    </div> : null
                  }
                  
                </div>
                {
                  editQRTemplate ? 
                  <div className='button_right' style={{width: '100%', display: 'flex', justifyContent: 'flex-end',  marginRight: 15, marginBottom: 30}}>
                    <AppButton
                      variant='text'
                      buttonText="Cancel"
                      onClick={()=> cancelEditQR() }
                      className='cancel-btn'
                      style={{color: '#FF0000', background: null, marginLeft: 10}}
                    />
                    <AppButton buttonText="Save QR Design" onClick={()=> onSaveQrDesign()}/>
                  </div>
                  :
                  <div className='button_right' style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: 15, marginBottom: 30}}>
                    <AppButton buttonText="Edit QR Design" onClick={()=> onEditQrDesign() }/>
                  </div>
                }
              </>
              :
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.5)',
                  width: side === 'front' ? frontOrientation === 'horizontal' ? 634 : 344 : backOrientation === 'horizontal' ? 634 : 344,
                  height: side === 'front' ? frontOrientation === 'horizontal' ? 344 : 634 : backOrientation === 'horizontal' ? 344 : 634,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url('data:image/png;base64,${previewFront ? previewModal.img1 : previewModal.img2}')`,
                  marginTop: '25px',
                  marginBottom: '150px',
                  marginRight: 'auto',
                  marginLeft: 'auto'
                }}
              ></div>
            }
            {
              previewModal.img2 && !previewModal.isQRCode && 
              <div className='pagination-card' style={{marginBottom: 50}}>
                <IconButton onClick={() => setPreviewFront(true)} style={{ display: !previewFront ? 'block' : 'none'}}>
                  <ArrowBackIosNewIcon style={{fontSize:"15px",}} />
                </IconButton>
                <p id="step4" style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>{previewFront ? 'Front' : 'Back'}</p>
                <IconButton onClick={() => setPreviewFront(false)} style={{ display: previewFront ? 'block' : 'none'}}>
                  <ArrowForwardIosIcon style={{fontSize:"15px",}} />
                </IconButton>
              </div>
            }
            
          </>
        }
      />

      <AppModal
        showModal={alertDialogQR}
        toggleModal={()=> setAlertDialogQR(!alertDialogQR)}
        onClick={()=> proceedSaveQrDesign(originalQRData)}
        header='Confirm Save QR Design'
        functionText="Proceed"
        content={
            <div>
              <p>Are you sure to save your update on QR design?</p>
            </div>
        }
      />

      <AppImageCropper 
        image={imgFile}
        modalOpen={isCropperOpen}
        modalClose={()=> setIsCropperOpen(false)}
        imageCropped={(data)=>{  
          imageCropped(data)
        }}
      />

      <AppLoader loader={showLoader} />

      {runTour && (
        <Joyride
          steps={tourSteps}
          continuous={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          showProgress={true}
          run={runTour}
          disableBeacon={false}
          showSkipButton={true}
          hideCloseButton={true}
          callback={(item) => switchToTab(item)}
          styles={{
            options: {
              arrowColor: '#00072d',
              backgroundColor: '#00072d',
              overlayColor: 'rgba(77, 74, 95, 0.4)',
              primaryColor: '#4d4a5f',
              textColor: '#FFFFFF',
              width: 500,
              zIndex: 99999,
            }
          }}
        />
      )}
    </>
  )
}