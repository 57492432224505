import React, { useEffect, useRef, useState, useCallback } from 'react'
import "./styles.scss";
import { HexColorPicker } from "react-colorful";
import useClickOutside from './useClickOutside';
import { Typography } from '@mui/material';

const AppColorPicker = ({ label, color, onChange, isGradient=false, colorNumber, onChangeGradient, options, optionHelper }) => {

    const popover = useRef();
    const [isOpen, toggle] = useState(false);
    const [currentColor, setCurrentColor] = useState(color)

    const handleClick = () => toggle(!isOpen);

    const setPickColor = (val) => {
        setCurrentColor(val)
        
        if(isGradient){
            onChangeGradient(val, colorNumber, options, optionHelper)
        }else{
            onChange(val, options, optionHelper)
        }
        
    }

    return (
        <div className="picker" id={options}>
            {label && <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700}}>{label}</Typography>}
            <div
                className="swatch"
                style={{ backgroundColor: currentColor }}
                onClick={handleClick}
            />

            {isOpen && (
                <div className='popover' ref={popover}>
                    <HexColorPicker color={color} onChange={(val) => setPickColor(val)} />
                </div>
                
            )}
        </div>
    );
}

export default AppColorPicker