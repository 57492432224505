import React from 'react'
import "./styles.scss";

const AppDashBoardOptions = (props) => {

    const {label, value, icon, percent, style, step} = props

  return (
    <div className="cards-container" id={step} style={style}>
            <div className="label">   
             <p>{label}</p>
             {icon && icon}
             
            </div>
            <div className="value">
            <h3 >{value}</h3>
             <p className="percent">{percent && percent}</p>
            </div>
           
    </div>
  )
}

export default AppDashBoardOptions
