import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ children, isLanding }) => {
    const location = useLocation();
    const userData = useSelector((state) => state.user.userData);

    if (userData == null) {
        if(isLanding){
            return children;
        }
        return <Navigate to={{ pathname: "/", state: { from: location } }} />;
    } else {
        if(isLanding){
            return <Navigate to={{ pathname: "/dashboard", state: { from: location } }} />;
        }
        return children;
    }
};