import { setAlertCreds, setDisableIndicator, setUseCredits, setUserData, setOnSyncUsers } from '../redux/reducer/User';
import { AccessToken } from '../utilities';
import constants from '../constants';

const { removeAccessToken } = AccessToken;
const { GLOBAL_VARIABLES } = constants;

export default function SignOut(dispatch, navigate){
    dispatch(setUserData(null));
    dispatch(setUseCredits(0));
    dispatch(setDisableIndicator(false));
    dispatch(setAlertCreds(false));
    dispatch(setOnSyncUsers(false));
    navigate("/");
    removeAccessToken();
}