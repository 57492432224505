export const steps = [
    {
        target: '#step1',
        title: 'Filter Notifications Table',
        content: 'This buttons can be toggle to filter what list will be shown on the table.',
        disableBeacon: true
    },
    {
        target: '#step2',
        title: 'New Notification Button',
        content: 'Button for adding new notification.',
        disableBeacon: true
    },
    {
        target: '#step3',
        title: 'Notification Credits',
        content: 'This is the credit you can use to send notification to your users. You can also see here the remaining amount of your credits and can also request for more credits.',
        disableBeacon: true
    }
];