export const steps = [
    {
      target: '#step1',
      title: "Registered Users",
      content: 'Here you can see the current number of registered users.',
      disableBeacon: true
    },
    {
      target: '#step2',
      title: "Active Users",
      content: 'Here you can see the count of registered users whose account is active. The percentage of the total number of active users in the current registered users can also be seen here.',
      disableBeacon: true
    },
    {
      target: '#step3',
      title: "Suspended Users",
      content: 'Here you can see the number of registered users who have been suspended. You can also see what percentage it is in the total number of registered users.',
      disableBeacon: true
    },
    {
      target: '#step4',
      title: "Share/Scan Graph",
      content: 'Here you can see the current total of the number of shares and scans of registered users using the app for the year you choose to filter',
      disableBeacon: true,
      placement: 'left'
    },
    {
      target: '#step5',
      title: "Overview",
      content: 'This tab is the general number of scans and shares of all registered users using the app.',
      disableBeacon: true 
    },
    {
      target: '#step6',
      title: "View By User",
      content: 'Here on this tab, you can filter which registered user you want to view the activity on his app.',
      disableBeacon: true
    }
  ]