import * as React from 'react';
import "./styles.scss";
import { NavLink, Outlet } from 'react-router-dom';
import { Drawer, Box, ListItemButton, List } from "@mui/material";
import logo from '../../assets/image/logo.png';
import useMenuData from './menuData';
import { Header } from '../../layouts';
import { getEnvVars } from '../../services/Environment';
import { useDispatch } from 'react-redux';
import { setLicenseUserData } from '../../redux/reducer/LicenseUserData';

const Sidebar = ({children}) => {
  
  const menuData = useMenuData();
  const { appVersion } = getEnvVars();
  const dispatch = useDispatch();
  
  const drawerWidth = 240;
  const [openDrawer, setOpenDrawer] = React.useState(true);
  
  const onDrawerClose = () => {

  }

  return (
    <Box className='container-sidebar'>
      <Drawer
          className='drawer'
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              paddingTop: 3,
            },
          }}
          BackdropProps={{ invisible: true }}
          anchor={"left"}
          open={openDrawer}
          onClose={onDrawerClose}
          variant="permanent"
        > 
          <img className="logo" src={logo} />

          <List
            style={{marginTop:"15px"}}
            sx={{
              width: '100%',
              flex: 1
            }}
          >
            {
              menuData.map((data, index) => (
                data ? (
                  <NavLink to={data.route} key={index} className={({ isActive }) => isActive ? "active" : 'navLink'} onClick={() => data.name !== 'User List' ? dispatch(setLicenseUserData(null)) : {}}>
                    <ListItemButton sx={{ paddingLeft: 3 }} key={index}>
                      {data?.icon}
                      <div className='menuName'>{data.name}</div>
                    </ListItemButton>
                  </NavLink>
                ) : null
              ))
            }

          </List>

          <ListItemButton
            sx={{paddingLeft: 3, flex: 0}}
          >
            <div style={{fontSize: 14}}>{appVersion}</div>
          </ListItemButton>

      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }} >
        <Outlet />
      </Box>
    </Box>
  )
}

export default Sidebar;