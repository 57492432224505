const steps = [
    {
      target: '#step1',
      title: 'Default Template',
      content: "This is your company's default template tab.",
      disableBeacon: true
    },
    {
      target: '#step2',
      title: 'Other Templates',
      content: 'In this tab you can see the other templates under your company (if any).',
      disableBeacon: true
    },
    {
      target: '#step3',
      title: 'Namecard Design',
      content: 'Here you can see the current design and appearance of your namecard template that will be used or used in the app.',
      disableBeacon: true
    },
    {
      target: '#step4',
      title: 'Toggle Front or Back View',
      content: 'Here you can toggle to see the appearance or the design of the front and back of your namecard template.',
      disableBeacon: true
    },
    {
      target: '#step5',
      title: 'Edit Template',
      content: 'This is the button where you can start designing your namecard template.',
      disableBeacon: true,
      placement: 'left'
    }
  ]

  const editSteps = [
    {
      target: '#step1Edit',
      title: 'Upload Template',
      content: 'This is the button where you can upload an image that can be used as the background of your namecard template.',
      disableBeacon: true
    },
    {
      target: '#step2Edit',
      title: 'Fields Option Buttons',
      content: "These are the options fields. Just click on the fields you want to include in your template and you can also remove them if you don't want to include them.",
      disableBeacon: true
    },
    {
      target: '#step3Edit',
      title: 'Fields Attribute',
      content: 'Here you can edit the appearance of the fields that will be used on your namecard. Here you can change the color, size, width, and more.',
      disableBeacon: true
    },
    {
      target: '#step4Edit',
      title: 'Drag and Drop Fields',
      content: 'This is the design edit mode of your namecard template. It can be dragged and dropped where you want to place your chosen fields.',
      disableBeacon: true
    },
    {
      target: '#step5Edit',
      title: 'Save Template',
      content: "This is the button where you can save your update to your namecard template if you think you've finished making it better.",
      disableBeacon: true,
      placement: 'left'
    },
    {
      target: '#step6Edit',
      title: 'Save Template',
      content: "This is the button where you can preview an actual namecard.",
      disableBeacon: true,
      placement: 'left'
    }
  ]

  export {steps, editSteps};