const headCells = [
  {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'office_number',
    numeric: true,
    disablePadding: false,
    label: 'Office Number',
  },
  {
    id: 'mobile_number',
    numeric: true,
    disablePadding: false,
    label: 'Mobile Number',
  },
  {
    id: 'fax_number',
    numeric: true,
    disablePadding: false,
    label: 'Fax Number',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'company',
    numeric: true,
    disablePadding: false,
    label: 'Institution',
  },
  {
    id: 'department',
    numeric: true,
    disablePadding: false,
    label: 'Department',
  },
  {
    id: 'createdon',
    numeric: true,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: false,
    label: 'Created By',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Namecard Status',
  },
];


const headCellsWithPhoto = [
  {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'logo',
    numeric: false,
    disablePadding: false,
    label: 'Photo',
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'office_number',
    numeric: true,
    disablePadding: false,
    label: 'Office Number',
  },
  {
    id: 'mobile_number',
    numeric: true,
    disablePadding: false,
    label: 'Mobile Number',
  },
  {
    id: 'fax_number',
    numeric: true,
    disablePadding: false,
    label: 'Fax Number',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'company',
    numeric: true,
    disablePadding: false,
    label: 'Institution',
  },
  {
    id: 'department',
    numeric: true,
    disablePadding: false,
    label: 'Department',
  },
  {
    id: 'createdon',
    numeric: true,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: false,
    label: 'Created By',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
];



  
export {headCells,headCellsWithPhoto};