import React,{isValidElement, useEffect, useState}  from 'react';
import "./styles.scss";
import { Header } from '../../../layouts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {AppButton, AppLoader} from '../../../components';
import {NewPushNotification, DeleteModal, ContactUsModal, ConfirmationModal} from './modals';
/* MUI */
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import NotificationHistory from './History';
import NotificationScheduled from './NotificationScheduled';
import NotificationDraft from './NotificationDraft';
import { ApiPost } from '../../../services/AxiosInstance';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import moment from "moment";
import io from "socket.io-client";
import { getEnvVars } from '../../../services/Environment';
import { setAlertCreds, setDisableIndicator, setUserData } from '../../../redux/reducer/User';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Joyride from 'react-joyride';
import { steps } from './walkthroughStepsNotification';

export default function NotificationPage(navigate) {
  const [notif, setNotif] = useState(false);
  const [deleteView, setDeleteView] = useState(false);
  const [confirmPush, setConfirmPush] = useState(false);
  const [contactUsView, setContactUsView] = useState(false);
  const [saveData, setSaveData] = useState({})
  const [editData, setEditData] = useState({})
  const [currentRow,setCurrentRow] = useState()
  const [success,setSuccess] = useState(null)
  const [isEdit,setIsEdit] = useState(false)
  const [isView,setIsView] = useState(false)
  const [total,setTotal] = useState(0)
  const [draftTotal,setDraftTotal] = useState(0)
  const [trigger,setTrigger] = useState(false)
  const [notifIndicator,setnotifIndicator] = useState(false)
  const [socketId,setSocket_id] = useState();
  const { apiUrl } = getEnvVars();
  const [showLoader, setShowLoader] = useState(false);
  const [alertAttr, setAlertAttr] = useState({visible: false, message: null, color: null});

  const userData = useSelector((state)=> state.user.userData);
  const corpCreds = useSelector((state)=> state.user.corpCreds);
 
  const corp_id = userData[0].corp_id;
  const user_id = userData[0].id;
  
  const dispatch = useDispatch();
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const [selectedTab, setSelectedTab] = useState(0);
  //const socket = io(apiUrl);

  const [tourSteps, setTourSteps] = useState([]);
  const [runTour, setRunTour] = useState(false);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("Connected to server", socket.id);
  //     // socket.emit('client_id', socket.id);
  //   });

  //   socket.on(corp_id, (data) => {
  //    if(data.status){
  //     setTrigger(!trigger)
  //    }
  //   });

  //   socket.on("connect_error", (err) => {
  //     console.log("Connection error:", err);
  //   });
  //   socket.on("error", (err) => {
  //     console.log("Socket error:", err);
  //   });
    
  // }, [socket]);

  useEffect(() => {
    getTotalData()
  }, [success,trigger])

  useEffect(() => {
    if(!isEdit && !isView){
      setEditData({})
    }
  },[isEdit, isView]) 

  useEffect(() => {
    setTourSteps(steps);
    if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.notification){
      setRunTour(true);
    }
  }, []);

  const doneWalkthrough = async (step) => {
    const userMeta = userData[0].metadata;
  
    if (step === 'reset') {
      if (!userMeta.walkthrough || !userMeta.walkthrough.notification) {
        const updatedMeta = {
          ...userMeta,
          walkthrough: {
            ...(userMeta.walkthrough || {}),
            notification: true,
          },
        };
  
        const data = await ApiPost("/corps/update-corps-user", {
          id: userData[0].id,
          metadata: updatedMeta,
          walkthrough: true
        });

        if(data.status === 200){
          const updatedArray = userData.map(obj => ({
            ...obj,
            metadata: updatedMeta
          }));

          dispatch(setUserData(updatedArray));
        }
      }
    }
  }
  
  const getTotalData = async () => {
    try {
      const resp = await ApiPost("/corps/total_notification",{corp_id:corp_id});
      if(resp.data.data){
        const data = resp.data.data;
        data.forEach(item => {
          if(item.status === 'queuing'){
            setTotal(item.count)
          }
          if(item.status === 'draft'){
            setDraftTotal(item.count)
          }
        });
        
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const userCredits = useSelector((state)=> state.user.userCredits);

  const credits = userData[0].privilege.credits
  const subs_expiry =  userData[0].subscription_exp

  const dateObj = new Date(subs_expiry);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear().toString().slice(-2);
  const dateOnly = `${day}/${month}/${year}`

  const getCredsPercent = (usedCredits,credits) =>{
    const percent = usedCredits/credits * 100
    return percent.toFixed(2)
  }

  const handleDelete = (data,getNotification) => {
    setCurrentRow(data)
    setDeleteView(true);
  }

  const handleEdit = (val) =>{
    setIsEdit(true)
    setCurrentRow(val)
    setEditData(val)
    setNotif(true);
  }

  const handleView = (val) =>{
    setIsEdit(false)
    setIsView(true)
    setCurrentRow(val)
    setEditData(val)
    setNotif(true);
  }

  const handleMoreCredit = () => {
    setContactUsView(true);
  }
 
  const deleteNotification = async() =>{
    const data = await ApiPost("/corps/delete-notification",{id:currentRow.id})
    setSuccess(!success)
    setDeleteView(false)
    setShowLoader(true);
    setTimeout(()=>{setShowLoader(false)},800);
  }

  const confitmNotification = async() =>{
    const data = await ApiPost("/corps/delete-notification",{id:currentRow.id})
    setDeleteView(false)
    setSuccess(!success)
  }
  
  const closeModal = () =>{
    setIsView(false);
    dispatch(setDisableIndicator(false))
    setNotif(false);
  }

  const triggernotification = async(corp_id,user_id,metadata) => {
    try{
      const data = await ApiPost("/trigger-pushnotification",{corp_id:corp_id,user_id:user_id,metadata:metadata})
      
      setTrigger(!trigger)
      setTimeout(()=>{
        setnotifIndicator(!notifIndicator)
      },2000)
      setTimeout(()=>{
        setnotifIndicator(false)
      },3500)
  
    }catch(err){
      console.log(err)
    }
  }

  const handleConfirm = (data) => {
    setSaveData(data);
    setConfirmPush(true);
  }

  const handleConfirmClose = () => {
    setConfirmPush(false);
    setIsEdit(false);
  }

  const push_notif_submit = async (status) => {
    setShowLoader(true);
    
    const data = saveData;
    // const dateValue = moment(data?.dateTime.date).format("YYYY-MM-DD");
    // const timeValue = moment(data?.dateTime.time).format("LT");  
    // console.log(timeValue,dateValue)
    let link;
    if(data?.link.data == "Contacts"){    
      link =  {
          "name": "Contacts",
          "link": "/contactNav/contacts",
          "type": data?.link?.status
      }
    }else if(data?.link.data  == "My Profile"){
      link =  {
          "name": "My Profile",
          "link": "/myProfile",
          "type": data?.link?.status
      }
    }else if(data?.link.data == "Notification"){
      link =  {
          "name": "Notification",
          "link": "/notifNav/MainNotif",
          "type": data?.link?.status
      }
    }else{
      link = data?.link.data
    }
    const metadata = {
      title: data?.title,
      content:data?.message,
      isEveryone:data?.recipients.status == "Everyone" ? true : false,
      no_recipients:data.recipients.data && data?.recipients.data.map(recipient => recipient.id),
      credits_used:data?.creds,
      url:link,
      pushed_time_date:{date:data?.dateTime.date,time:data?.dateTime.time}
      // pushed_time_date:{date:dateValue,time:timeValue}
    }

    console.log("metadata", metadata);
    
    try {
      if (!isEdit) {
        const response = await ApiPost("/corps/save-notification", {corp_id: corp_id, metadata: metadata, createdBy: user_id, status: status,corpCreds:userCredits});
        if(response.data.data){
          setSuccess(!success)
          setNotif(false)
          setAlertAttr({visible: true, message: 'Notification saved successfully', color: '#4caf50'});
          // await triggernotification(corp_id,false,metadata)
        }else{
          setAlertAttr({visible: true, message: 'Not enough credits', color: '#b2102f'});
          // setnotifIndicator(true)
          // setTimeout(()=>{
          //   setnotifIndicator(false)
          // },3000)
        }
      } else {
        const response =  await ApiPost("/corps/update-notification",{metadata: metadata,user_id:currentRow.id, status: status})
        if(response.data.data){
          setSuccess(!success)
          setNotif(false)
          setIsEdit(false)
          setAlertAttr({visible: true, message: 'Notification updated successfully', color: '#4caf50'});
         // await triggernotification(corp_id,currentRow.id,metadata)
        }
      }
    } catch (error) {
      setAlertAttr({visible: true, message: 'Something went wrong. Please try again.', color: '#b2102f'});
      console.log("error", error)
    }
    
    setSaveData({})
    setEditData({})
    setConfirmPush(false)
    setShowLoader(true);
    setTimeout(()=>{setShowLoader(false)},800);
  }

  const handleClose = () =>{
    setAlertAttr({visible: false, message: null, color: null});
    // setnotifIndicator(!notifIndicator)
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleNewNotif = () => {
    setIsEdit(false)
    setIsView(false)
    setNotif(true);
  }

  return (
    <div style={{
      position: 'relative'
      }}>
      <Tabs>
        <Header pageTitle={`Send Push Notification`}>
        </Header>
        <Snackbar
          ContentProps={{
            sx: {
              background: alertAttr.color
            }
          }}
          open={alertAttr.visible}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          message={alertAttr.message}
          action={action}
        />
        {/* <Snackbar
          ContentProps={{
            sx: {
              background: "orange"
            }
          }}
          open={notifIndicator}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          message={'Not enough credits'}
          action={action}
        /> */}
        <div style={{padding:"20px 60px"}}>
        <div id="step3">
          <Box sx={{ width: '100%', marginBottom: 5 }}>
            <p style={{marginBottom:10,fontSize:"14px",display:'flex',flexDirection:"row",alignItems:'center'}}> <CircleNotificationsIcon style={{marginLeft:"-35px",marginRight:"10px"}} size={15}/>  Your plan entities you to send {credits} credits per billing year (expires on {dateOnly}). <a href='#' onClick={() => handleMoreCredit()} style={{color:'blue', marginLeft: 5}}>Request for more credits</a></p>
              <LinearProgress variant="determinate" style={{color: "black"}} value={getCredsPercent(userCredits,corpCreds)} />
            <p style={{marginTop:10,fontSize:"14px"}}>{userCredits} / {corpCreds} credits used</p>
          </Box>
        </div>
      
        <Box style={{ backgroundColor: '#fff', display: 'flex', alignItems:'center', justifyContent: 'space-between', padding: 5}}>
           
        <div id="step1">
          <TabList className="tablist">
          <Tab>
            <AppButton 
                 buttonText={`Draft (${draftTotal})`}
                 variant={selectedTab === 0 ? 'contained' : 'outlined'}
                 style={{background: selectedTab === 0 ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12,padding:'4px 12px', textTransform: 'capitalize'}}
                 textStyle={{fontSize: 14, color: selectedTab === 0 ? 'white' : '#01082E'}}
                 onClick={()=> {
                    handleTabChange(0)
                    setTrigger(!trigger)
                }}
            />
          </Tab>
          <Tab>
            <AppButton 
                 buttonText={`Scheduled (${total})`}
                 variant={selectedTab === 1 ? 'contained' : 'outlined'}
                 style={{background: selectedTab === 1 ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12,padding:'4px 12px', textTransform: 'capitalize'}}
                 textStyle={{fontSize: 14, color: selectedTab === 1 ? 'white' : '#01082E'}}
                 onClick={()=> {
                    handleTabChange(1)
                    setTrigger(!trigger)
                }}
            />
          </Tab>
          <Tab > 
            <AppButton 
                 buttonText={"History"}
                 variant={selectedTab === 2 ? 'contained' : 'outlined'}
                 style={{background: selectedTab === 2 ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12,padding:'4px 12px', textTransform: 'capitalize'}}
                 textStyle={{fontSize: 14, color: selectedTab === 2 ? 'white' : '#01082E'}}
                 onClick={()=> {
                    handleTabChange(2)
                    setTrigger(!trigger)
                    setEditData({})
                }}
            />
          </Tab>
          </TabList>
        </div>
        <div id="step2">
          <AppButton onClick={() => handleNewNotif()} buttonText="New Notification" style={{marginRight:"10px"}}/>
        </div>
        </Box>
        <TabPanel className="settingsTab">
        
          <NotificationDraft onDelete={(data) => handleDelete(data)} trigger={trigger} onEdit={(data) => handleEdit(data)} setIsDisabled={(val)=>console.log("this is the callback val", val)} isSuccess={success}/>
        
        </TabPanel>

        <TabPanel className="settingsTab">
        
          <NotificationScheduled onDelete={(data) => handleDelete(data)} trigger={trigger} onView={(data) => handleView(data)} onEdit={(data) => handleEdit(data)} setIsDisabled={(val)=>console.log("this is the callback val",val)} isSuccess={success}/>
        
        </TabPanel>

        <TabPanel className="settingsTab" >

          <NotificationHistory/>  

        </TabPanel>
       
        </div>
        </Tabs>
        <ContactUsModal display={contactUsView} onClose={() => setContactUsView(false)}/>
        <DeleteModal display={deleteView} onSubmit={() => {deleteNotification()}} onClose={() => setDeleteView(false)} />
        <NewPushNotification notif={notif} data={editData} display={notif} onSubmit={(data) => handleConfirm(data)} onClose={() => closeModal()} setErrorAlert={(data) => setAlertAttr(data)}/>
        <ConfirmationModal display={confirmPush} onSubmit={(val) => push_notif_submit(val)} onDraft={(val) => push_notif_submit(val)} onClose={() => handleConfirmClose()} />
        <AppLoader loader={showLoader}/>

        {runTour && (
          <Joyride
            steps={tourSteps}
            continuous={true}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            showProgress={true}
            run={runTour}
            disableBeacon={false}
            showSkipButton={true}
            hideCloseButton={true}
            callback={(item) => doneWalkthrough(item.action)}
            styles={{
              options: {
                arrowColor: '#00072d',
                backgroundColor: '#00072d',
                overlayColor: 'rgba(77, 74, 95, 0.4)',
                primaryColor: '#4d4a5f',
                textColor: '#FFFFFF',
                width: 500,
                zIndex: 99999,
              }
            }}
          />
        )}
    </div>
  )
}