import { useState, useEffect, useCallback } from 'react';

const useCacheBuster = () => {
  const [cacheBuster, setCacheBuster] = useState(new Date().getTime());

  const updateCacheBuster = useCallback(() => {
    setCacheBuster(new Date().getTime());
  }, []);

  useEffect(() => {
    // Update cacheBuster every 5 minutes (300000 milliseconds)
    const intervalId = setInterval(updateCacheBuster, 5 * 60 * 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [updateCacheBuster]);

  return { cacheBuster, updateCacheBuster };
};

export default useCacheBuster;
