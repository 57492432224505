import React from "react";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { AppDropdown } from "../../../../../../components";



const LinkComponent = (props) => {
    const {
        handleLinkRadioButton,
        dispatch,
        appPages,
        form,
        link,
        linkErr,
        disableIndicator
    } = props;

    return (
        <>
            <label className='input-text'>Link</label>
            <RadioGroup
                disabled={disableIndicator}
                value={link}
                onChange={(e) => handleLinkRadioButton(e.target.value)}
            >
                <FormControlLabel style={{fontSize:"15px"}} className="flabel" value="URL" control={<Radio />} label="URL" />
                { link === "URL" ? (
                <TextField
                    disabled={disableIndicator}
                    className='input'
                    type="text"
                    size="small"
                    value={form.link.data}
                    onChange={(e) => {
                    dispatch({type: 'setLink', value: {
                        status: link,
                        data: e.target.value
                    }})
                    }}
                    fullWidth
                    error={linkErr !== "" ? true : false}
                    helperText={linkErr}
                />
                ) : null}
                <FormControlLabel style={{fontSize:"15px"}} onClick={()=> dispatch({type: 'setLink', value: {
                        status: "AppPage",
                        data: "My Profile"
                    }})}  className="flabel" value="AppPage" control={<Radio disabled={disableIndicator} />} label="App Page" />
                { link === "AppPage" ? (
                <AppDropdown
                    onSelect={(val) => {
                    console.log("val",val);
                    dispatch({type: 'setLink', value: {
                        status: link,
                        data: val
                    }})
                    }} 
                    disableIndicator={ disableIndicator} 
                    data={appPages.map(item => ({label: item, value: item}))}
                    style={{ width:"100%",fontSize:"13px",marginBottom: 12}}
                    error={false}
                    defaultValue={form.link.data}
                />
                ) : null}
            </RadioGroup>
        </>
    );

}

export default LinkComponent;