import React,{useState} from 'react';
import "./styles.scss";
import { Button, Typography, InputAdornment, FormControl, TextField, Box, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import AppDropdown from "../AppDropdown"
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';

const AppInput = ({
  icon,
  placeHolderText,
  onChange,
  style,
  type,
  label,
  showSaveAs = false,
  checkBoxToggle,
  checkBoxStatus,
  inputStyle,
  defaultValue,
  disabled,
  isNumber,
  pattern="",
  isErrorName,
  multiline,
  rows,
  checkBox="",
  checkBoxState,
  checkBoxhandleChange,
  checkBoxhandleAutoAlign,
  heightLimit,
  rowVal,
  alignValue,
  onChangeRow,
  onChangeRowMove,
  fromDesigner=false,
  checkBoxLabel='Designate as Main Card'
}) => {

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
        ))(({ theme }) => ({
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
          },
  }));

  return (
    <Box
        component="form"
        sx={{
          '& > :not(style)': { width: '100%' },
        }}
        onSubmit={(event) => {
          event.preventDefault();
        }}
        noValidate
        autoComplete="off"
        style={style}
      >
        <FormControl fullWidth>
          {
            checkBox === "address" ? 
            <FormGroup sx={{display:"flex", flexDirection:"row"}}>
              <FormControlLabel control={<Checkbox checked={checkBoxState.single}  onChange={(event)=>checkBoxhandleChange(event,1)}  size="small" inputProps={{ 'aria-label': 'controlled' }}/> } label="Single Line" />
              <FormControlLabel control={<Checkbox checked={checkBoxState.multi}  onChange={(event)=>checkBoxhandleChange(event,2)}  size="small" inputProps={{ 'aria-label': 'controlled' }}/>} label="Multi Line"  />
              <BootstrapTooltip style={{marginTop: 6, marginLeft: -10}} title="Please note that the multi-line address adheres to the Singapore format." placement="top">
                <HelpOutlineIcon />
              </BootstrapTooltip>
            </FormGroup>
            : null
          }
          {
            checkBox === "autoAlign" ?
            <> 
                <FormGroup sx={{display:"flex", flexDirection:"row", width: '100%'}}>
                  <FormControlLabel control={<Checkbox checked={checkBoxState}  onChange={(event)=> checkBoxState ? checkBoxhandleAutoAlign(false) : checkBoxhandleAutoAlign(true)}  size="small" inputProps={{ 'aria-label': 'controlled' }}/> } label="Enable Auto Alignment for this field" />
                </FormGroup>

                {
                  checkBoxState ?
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10}}>
                      <AppDropdown 
                        label='Vertical Position to: '
                        defaultValue={defaultValue[2] ? true : false}
                        onSelect={(val)=> onChange(val, 'moveUp')} 
                        data={[{'label':'Top','value':true},{'label':'Bottom','value':false}]} 
                        style={{width: '31%', marginRight: 14, marginBottom: 15}}
                        isMoveUp={true}
                      />
                      <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'space-between', width: '31%'}}>
                        <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700}}>Group #</Typography>
                        <TextField
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          multiline={multiline}
                          error={isErrorName}
                          rows={rows}
                          value={defaultValue[1] ? defaultValue[1] : ''} 
                          onChange={(e)=> {
                            if(isNumber){
                              if(e.target.value !== "") {
                                let value = e.target.value.replace(/[^0-9]/g, "");
                                if (value !== "" && heightLimit) {
                                  value = Math.min(parseInt(value, 10), heightLimit);
                                }
                                onChange(value, 'group');
                              } else {
                                onChange(e.target.value, 'group')
                              }
                            } else {
                              onChange(e.target.value, 'group')
                            }
                          }}
                          disabled={disabled}
                          pattern={pattern}
                          style={{borderRadius: 4, ...inputStyle}}
                          type={type}
                          InputProps={ 
                            {
                              startAdornment: (
                                <InputAdornment position="start">
                                  {icon}
                                </InputAdornment>
                              ),
                              style: {fontSize: 14}
                            }
                          }
                        />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'space-between', width: '31%'}}>
                      <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700}}>{label}</Typography>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        multiline={multiline}
                        error={isErrorName}
                        rows={rows}
                        value={defaultValue[0] ? defaultValue[0] : ''} 
                        onChange={(e)=> {
                          if(isNumber){
                            if(e.target.value !== "") {
                              let value = e.target.value.replace(/[^0-9]/g, "");
                              if (value !== "" && heightLimit) {
                                value = Math.min(parseInt(value, 10), heightLimit);
                              }
                              onChange(value, 'row');
                            } else {
                              onChange(e.target.value, 'row')
                            }
                          } else {
                            onChange(e.target.value, 'row')
                          }
                        }}
                        disabled={disabled}
                        pattern={pattern}
                        style={{borderRadius: 4, ...inputStyle}}
                        type={type}
                        InputProps={ 
                          {
                            startAdornment: (
                              <InputAdornment position="start">
                                {icon}
                              </InputAdornment>
                            ),
                            style: {fontSize: 14}
                          }
                        }
                      />
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                      <AppDropdown 
                        label='Auto-Horizontal Adjust: '
                        defaultValue={defaultValue[3] ? true : false}
                        onSelect={(val)=> onChange(val, 'moveHorizontal')} 
                        data={[{'label':'Yes','value':true},{'label':'No','value':false}]} 
                        style={{width: '48%', marginRight: 14, marginBottom: 15}}
                        isMoveUp={true}
                      />
                      {
                        defaultValue[3] ?
                          <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'space-between', width: '48%'}}>
                            <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700}}>Column #</Typography>
                            <TextField
                              size="small"
                              id="outlined-basic"
                              variant="outlined"
                              multiline={multiline}
                              error={isErrorName}
                              rows={rows}
                              value={defaultValue[4] ? defaultValue[4] : ''} 
                              onChange={(e)=> {
                                if(isNumber){
                                  if(e.target.value !== "") {
                                    let value = e.target.value.replace(/[^0-9]/g, "");
                                    if (value !== "" && heightLimit) {
                                      value = Math.min(parseInt(value, 10), heightLimit);
                                    }
                                    onChange(value, 'column');
                                  } else {
                                    onChange(e.target.value, 'column')
                                  }
                                } else {
                                  onChange(e.target.value, 'column')
                                }
                              }}
                              disabled={disabled}
                              pattern={pattern}
                              style={{borderRadius: 4, ...inputStyle}}
                              type={type}
                              InputProps={ 
                                {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {icon}
                                    </InputAdornment>
                                  ),
                                  style: {fontSize: 14}
                                }
                              }
                            />
                          </div>:null
                      }
                      
                    </div>
                  </> : null
                }
            </>
            : null
          }

          {
            checkBox !== "autoAlign"  ?
            <>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {label && <Typography variant="subtitle1" style={{fontSize: 14, fontWeight: 700}}>{label}</Typography>}
                {showSaveAs && <div>
                  <Typography
                    variant="subtitle1"
                    style={{
                      flexBasis: '50%',
                      boxSizing: 'border-box',
                      fontSize: '14px',
                      display: 'flex'
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        marginRight: '5px'
                      }}
                      checked={checkBoxStatus}
                      onChange={checkBoxToggle}
                    />
                    {checkBoxLabel}
                  </Typography>
                </div>}
              </div>
              {
                fromDesigner && (label === 'Building' || label === 'Room #' || label === 'Street' || label === 'Country') ?
                <div style={{ borderBottomWidth: 2, borderBottomColor: 'black', borderBottomStyle: 'solid', marginBottom: 5 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        placeholder={placeHolderText}
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        multiline={multiline}
                        error={isErrorName}
                        rows={rows}
                        value={defaultValue}
                        onChange={(e) => {
                          if(isNumber){
                            if(e.target.value !== "") {
                              let value = e.target.value.replace(/[^0-9\s!@#$%^&*()-_=+{}\[\]:;<>,.?~\\/]/g, "");
                              if (value !== "" && heightLimit) {
                                value = Math.min(parseInt(value, 10), heightLimit);
                              }
                              onChange(value);
                            } else {
                              onChange(e.target.value)
                            }
                          } else {
                            onChange(e.target.value)
                          }
                        }}
                        disabled={disabled}
                        pattern={pattern}
                        style={{ borderRadius: 4, ...inputStyle }}
                        type={type}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {icon}
                            </InputAdornment>
                          ),
                          style: { fontSize: 14 },
                        }}
                      />
                    </div>
                    <div style={{ width: '30%', marginTop: -25 }}>
                      <Typography variant="subtitle1" style={{ fontSize: 14, fontWeight: 700 }}>Row</Typography>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        variant="outlined"
                        multiline={multiline}
                        error={isErrorName}
                        rows={rows}
                        value={rowVal}
                        onChange={(e) => {
                          if(e.target.value !== "") {
                            let value = e.target.value.replace(/[^0-9\s!@#$%^&*()-_=+{}\[\]:;<>,.?~\\/]/g, "");
                            if (value !== "" && heightLimit) {
                              value = Math.min(parseInt(value, 10), heightLimit);
                            }
                            onChangeRow(value);
                          } else {
                            onChangeRow(e.target.value)
                          }
                        }}
                        disabled={disabled}
                        pattern={pattern}
                        style={{ borderRadius: 4, ...inputStyle}}
                        type={type}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {icon}
                            </InputAdornment>
                          ),
                          style: { fontSize: 14 },
                        }}
                      />
                    </div>
                  </div>
                  <AppDropdown 
                    label='Adjust Position to: '
                    defaultValue={alignValue}
                    onSelect={(val)=> onChangeRowMove(val)} 
                    data={[{'label':'Top','value':true},{'label':'Bottom','value':false}]} 
                    style={{width: '100%', marginRight: 14, marginBottom: 15,}}
                    isMoveUp={true}
                  />
                </div>
                :
                <TextField
                  placeholder={placeHolderText}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  multiline={multiline}
                  error={isErrorName}
                  rows={rows}
                  value={defaultValue} 
                  onChange={(e)=> {
                    if(isNumber){
                      if(e.target.value !== "") {
                        let value = e.target.value.replace(/[^0-9\s!@#$%^&*()-_=+{}\[\]:;<>,.?~\\/]/g, "");
                        if (value !== "" && heightLimit) {
                          value = Math.min(parseInt(value, 10), heightLimit);
                        }
                        onChange(value);
                      } else {
                        onChange(e.target.value)
                      }
                    } else {
                      onChange(e.target.value)
                    }
                  }}
                  disabled={disabled}
                  pattern={pattern}
                  style={{borderRadius: 4, ...inputStyle}}
                  type={type}
                  InputProps={ 
                    {
                      startAdornment: (
                        <InputAdornment position="start">
                          {icon}
                        </InputAdornment>
                      ),
                      style: {fontSize: 14}
                    }
                  }
                />  
              }
              
            </>
            : null
          }
        </FormControl>
    </Box>
  );
}

export default AppInput;