import React from "react";
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

export default function ErrorPage(){
    return (
        <Box style={{ textAlign: "center", marginTop: "100px" }}>
            <Typography style={{ fontSize: "100px", color: "red" }}>404</Typography>
            <Typography style={{ fontSize: "20px" }}>
                The page you’re looking for doesn’t exist.
            </Typography>
            <Link to="/" style={{ fontSize: "20px", marginTop: 100 }}>
                <Typography>Back Home</Typography>
            </Link>
        </Box>
    )
}