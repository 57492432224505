import React,{ useState, useRef, useEffect } from 'react';
import "./styles.scss";
import { Header } from '../../../layouts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import logo from "../../../assets/image/chuGo-logo.png"
import imageChallenger from "../../../assets/image/challenger_img.jpg"
import blankTemplate from "../../../assets/image/template_blank.png"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import GridLines from 'react-gridlines';
import { useSelector, useDispatch } from 'react-redux';
import { ApiPost } from '../../../services/AxiosInstance';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { AppButton, AppInput, AppModal, AppEditorFields, AppLoader, AppAddFieldsButton, AppFieldsAttribute, AppAlertDialog, AppContactUs, AppQRGenerator, AppQRAttributes } from '../../../components';
import { IconButton } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import Joyride from 'react-joyride';
import { steps, editSteps } from './walkthroughStepsDesigner';
import { setUserData } from '../../../redux/reducer/User';
import { qrJson, qrJsonDefault } from '../../../components/AppQRAttributes/qrJson';
import SearchIcon from '@mui/icons-material/Search';
import useCacheBuster from '../../../components/AppTables/useCacheBuster';

export default function NameCardDesignPage(navigate) {

  const userData = useSelector((state)=> state.user.userData);
  const corp_id = userData[0].corp_id;
  const userDepartment = userData[0].metadata.department
  const { cacheBuster, updateCacheBuster } = useCacheBuster();
  const dispatch = useDispatch();

  const [subject,setSubject] = React.useState("")
  const [message,setMessage] = React.useState("")
  const [file,setFile] = React.useState('')

  const [showModal,setShowModal] = useState(false);
  const [showLoader,setShowLoader] = useState(false);
  const [currentIndex,setCurrentIndex] = useState(1);
  const [editTemplate, setEditTemplate] = useState(false);
  const [fields, setFields] = useState([]);
  const [side, setSide] = useState('front');
  const [frontFields, setFrontFields] = useState([]);
  const [backFields, setBackFields] = useState([]);
  const [frontFieldsOrg, setFrontFieldsOrg] = useState([]);
  const [backFieldsOrg, setBackFieldsOrg] = useState([]);
  const [templateId, setTemplateId] = useState();
  const [templateCount, setTemplateCount] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [isSelected, setIsSelected] = useState();
  const [isSelectedTemplate, setIsSelectedTemplate] = useState();
  const [tabValue, setTabValue] = useState('Default');
  const [isLoading, setIsloading] = useState(false);
  const [uploadTemplateModal, setUploadTemplateModal] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const [errorUpload, setErrorUpload] = useState(false);
  const [multi, setMulti] = useState(false);
  const [newFields, setNewFields] = useState(fields)
  const swiperRef = useRef();

  /* FOR OTHER NAME CARDS */
  const [otherTemplates , setOtherTemplates] = useState([])
  const [otherCount, setOtherCount] = useState(0);
  const [templateName, setTemplateName] = useState();
  const [templateOtherId, setTemplateOtherId] = useState();
  const [fieldsOthers, setFieldsOthers] = useState([]);
  const [frontFieldsOthers, setFrontFieldsOthers] = useState([]);
  const [backFieldsOthers, setBackFieldsOthers] = useState([]);
  const [frontFieldsOthersOrg, setFrontFieldsOthersOrg] = useState([]);
  const [backFieldsOthersOrg, setBackFieldsOthersOrg] = useState([]);
  const [templateBackgroundFront, setTemplateBackgroundFront] = useState(blankTemplate);
  const [templateBackgroundBack, setTemplateBackgroundBack] = useState(blankTemplate)
  const [templateBackgroundFrontOthers, setTemplateBackgroundFrontOthers] = useState(blankTemplate);
  const [templateBackgroundBackOthers, setTemplateBackgroundBackOthers] = useState(blankTemplate);
  const [orientation, setOrientation] = useState(false);
  const [uploadOrientation, setUploadOrientation] = useState('horizontal');
  const [frontOrientation, setFrontOrientation] = useState('horizontal');
  const [backOrientation, setBackOrientation] = useState('horizontal');
  const [frontOrientationOthers, setFrontOrientationOthers] = useState('horizontal');
  const [backOrientationOthers, setBackOrientationOthers] = useState('horizontal');
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  const [alertAttr, setAlertAttr] = useState({visible: false, message: null, color: null});
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogQR, setAlertDialogQR] = useState(false);
  const [toSaveFeilds, setToSaveFields] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [currentDepartment, setCurrentDepartment] = useState([])
  const [updatedDepartment, setUpdatedDepartment] = useState([])
  const [currentDepartmentOthers, setCurrentDepartmentOthers] = useState([])
  const [updatedDepartmentOthers, setUpdatedDepartmentOthers] = useState([])
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllOthers, setSelectAllOthers] = useState(false);
  const [focusField, setFocusField] = useState();

  const [tourStepsEdit, setTourStepsEdit] = useState([]);
  const [tourSteps, setTourSteps] = useState([]);
  const [runTour, setRunTour] = useState(false);

  /* FOR SWITCHING FIELDS */
  const [others, setOthers] = useState(true);

  const [subCompList, setSubCompList] = React.useState([]);
  const [previewModal, setPreviewModal] = React.useState({visible: false, img1: null, img2: null});
  const [previewFront, setPreviewFront] = React.useState(true);

  /* QR GENERATOR STATES */
  const [editQRTemplate, setEditQRTemplate] = React.useState(false)
  const [qrMeta, setQRMeta] = React.useState(qrJson)
  const [originalQRData, setOriginalQRData] = React.useState({
    "color": '#811aff',
    "backgroundColor": '#FFFFFF',
    "enableLinearGradient": true,
    "linearGradient": ['#811aff', '#0e80f1'],
    "gradientDirection": ["0%", "100%"],
    "logo": '',
    "logoSize": 30,
    "logoBackgroundColor": 'transparent',
    "logoMargin": 2,
    "logoBorderRadius": 0,
  })
  const [qrDefault, setQRDefault] = React.useState(qrJson)

  const [searchInstitutionKey, setSearchInstitutionKey]= React.useState('');
  const [departmentListFilter, setDepartmentListFilter] = React.useState([]);
  const [subCompListFiilter, setSubCompListFilter] = React.useState([]);

  useEffect(()=>{
    getSubCompany()
    getDepartments()
    getTemplate()
    getQRTemplate()
    joyrideSteps();
  },[])

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const contactUs = async() =>{
    const formData = new FormData();
    formData.append('email', 'contact@lexiscard.asia');
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('file', file[0]);
    const response =await ApiPost('/corps/contact-us',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setFile('')
    setShowModal(false)    
  
  }

  const hasMatchingItem = () => {
    return (
      userData[0]?.metadata?.filters &&
      userData[0].metadata?.filters.filter((filterItem) =>
        currentDepartmentOthers.some(
          (item) =>
            item.subCompId === filterItem.subCompId &&
            item.deptId === filterItem.deptId
        )
      ).length > 0
    );
  };

  const joyrideSteps = async () => {
    setTourStepsEdit(editSteps)
    setTourSteps(steps);
    if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.namecard){
      setRunTour(true);
    }
  }

  const stopWalkthrough = async (item) => {
    const userMeta = userData[0].metadata;

    if(item.action === 'reset'){
      setRunTour(false)
      if (!userMeta.walkthrough || !userMeta.walkthrough.namecard) {
        const updatedMeta = {
          ...userMeta,
          walkthrough: {
            ...(userMeta.walkthrough || {}),
            namecard: true,
          },
        };
  
        const data = await ApiPost("/corps/update-corps-user", {
          id: userData[0].id,
          metadata: updatedMeta,
          walkthrough: true
        });

        if(data.status === 200){
          const updatedArray = userData.map(obj => ({
            ...obj,
            metadata: updatedMeta
          }));

          dispatch(setUserData(updatedArray));
        }
      }
    }
  }

  const stopWalkthroughEdit = async (item) => {
    const userMeta = userData[0].metadata;

    if(item.action === 'reset'){
      setRunTour(false)
      if(item.action === 'reset'){
        setRunTour(false)
        if (!userMeta.walkthrough || !userMeta.walkthrough.namecard_edit) {
          const updatedMeta = {
            ...userMeta,
            walkthrough: {
              ...(userMeta.walkthrough || {}),
              namecard_edit: true,
            },
          };
    
          const data = await ApiPost("/corps/update-corps-user", {
            id: userData[0].id,
            metadata: updatedMeta,
            walkthrough: true
          });
  
          if(data.status === 200){
            const updatedArray = userData.map(obj => ({
              ...obj,
              metadata: updatedMeta
            }));
  
            dispatch(setUserData(updatedArray));
          }
        }
      }
    }
  }

  /* FUNCTIONS FOR DEPARTMENT FILTER START */

  const getSubCompany = async () => {
    const response = await ApiPost("/corps/get-sub-company", {
      corp_id: corp_id,
    });

    setSubCompList(response.data.data)
    setSubCompListFilter(response.data.data)
  }

  const getDepartments = async () => {
    const response = await ApiPost("/corps/get-department", {
      corp_id: corp_id,
    });

    setDepartmentList(response.data.data)
    setDepartmentListFilter(response.data.data)
  }

  const onSearchInstitution = (val) => {
    setSearchInstitutionKey(val);

    let filteredSubCompList = subCompList;
    let filteredDepartmentList = departmentList;

    if (val) {
        const searchTerm = val.toLowerCase();
        filteredDepartmentList = departmentList.filter(dept =>
            dept.department_name.toLowerCase().includes(searchTerm) ||
            (dept.cost_center && dept.cost_center.toLowerCase().includes(searchTerm))
        );

        filteredSubCompList = subCompList.filter(subComp =>
            subComp.name.toLowerCase().includes(searchTerm) ||
            subComp.departments.some(deptId =>
                filteredDepartmentList.some(item => item.id === deptId)
            )
        );
    }

    setSubCompListFilter(filteredSubCompList);
    setDepartmentListFilter(filteredDepartmentList);
  }


  const onSetDepartment = (subCompId, departmentId) => {

    if(tabValue === 'Others'){

      const hasMatchingPair = updatedDepartmentOthers.some(item => item.subCompId === subCompId && item.deptId === departmentId);

      if(hasMatchingPair){
        const updatedDepartmentOthersNew = updatedDepartmentOthers.filter(item => !(item.subCompId === subCompId && item.deptId === departmentId));

        setUpdatedDepartmentOthers(updatedDepartmentOthersNew);
      }else{
        setUpdatedDepartmentOthers([...updatedDepartmentOthers, {subCompId: subCompId, deptId: departmentId}]);
      }

      // console.log(updatedDepartmentOthers+' hasMatchingPair '+hasMatchingPair)

      // const isTemplateDeptMatched = otherTemplates.some(template => {
      //   if (template.id === templateOtherId) {
      //     return false;
      //   }
        
      //   if (template.department && template.department.some(dept => dept === item)) {
      //     return true;
      //   }
        
      //   return false;
      // });

      // if(isTemplateDeptMatched){
      //   setUpdatedDepartmentOthers(updatedDepartmentOthers);
      // }else{
      //   const departmentIndex = updatedDepartmentOthers.indexOf(item);

      //   if (departmentIndex === -1) {
      //     newDepartmentList = [item];
      //     setUpdatedDepartmentOthers(newDepartmentList);
      //   } else {
      //     newDepartmentList = updatedDepartmentOthers.filter(id => id !== item);
      //     setUpdatedDepartmentOthers(newDepartmentList); 
      //   }
      // } 
    }
    
    // else{
    //   const departmentIndex = updatedDepartment.indexOf(item);

    //   if (departmentIndex === -1) {
    //     newDepartmentList = [...updatedDepartment, item];
    //     setUpdatedDepartment(newDepartmentList); 
    //   } else {
    //     newDepartmentList = updatedDepartment.filter(id => id !== item);
    //     setUpdatedDepartment(newDepartmentList); 
    //   }

    //   if(newDepartmentList.length === departmentList.length){
    //     setSelectAll(true)
    //   }else{
    //     setSelectAll(false)
    //   }
    // }
    
  }

  /* FUNCTIONS FOR DEPARTMENT FILTER END */

  const getTemplate = async() =>{

    setIsloading(true)
    const data = await ApiPost("/corps/corp-template",{corp_id:corp_id})

    const default_fields = data.data;

    const otherTemplates = default_fields.filter(obj => {
      return obj.template.every(templateObj => !('isDefault' in templateObj) || templateObj.isDefault === false);
    });

    if(otherTemplates.length > 0){
      setTemplateCount(otherTemplates.length)
      setTemplateOtherId(otherTemplates[0].id)
      setTemplateName(otherTemplates[0].template[0].template_name)
      setFieldsOthers(otherTemplates[0].template);
      setFrontFieldsOthers(otherTemplates[0].template[1].front);
      setBackFieldsOthers(otherTemplates[0].template[2].back);
      setFrontFieldsOthersOrg(otherTemplates[0].template[1].front)
      setBackFieldsOthersOrg(otherTemplates[0].template[2].back)
      setOtherTemplates(otherTemplates)

      if(otherTemplates[0].template[3].template_image[0].front){
        setTemplateBackgroundFrontOthers(otherTemplates[0].template[3].template_image[0].front);
      }else{
        console.log('2')
      }

      if(otherTemplates[0].template[3].template_image[1].back){
        setTemplateBackgroundBackOthers(otherTemplates[0].template[3].template_image[1].back);
      }

      if(otherTemplates[0]?.department){
        if (typeof otherTemplates[0].department[0] === 'object') {
          setCurrentDepartmentOthers(otherTemplates[0].department)
          setUpdatedDepartmentOthers(otherTemplates[0].department)
        }
        // setCurrentDepartmentOthers(otherTemplates[0].department)
        // setUpdatedDepartmentOthers(otherTemplates[0].department)

        if (otherTemplates[0].department.length === departmentList.length) {
          setSelectAllOthers(true);
        } else {
          setSelectAllOthers(false);
        }
      }
    }
    
    if(default_fields.length > 0){
      const filteredArray = default_fields.filter(obj => {
        return obj.template.some(templateObj => templateObj.hasOwnProperty('isDefault') && templateObj.isDefault);
      });
      
      setTemplateId(filteredArray[0].id)
      setFields(filteredArray[0].template);
      setFrontFields(filteredArray[0].template[1].front);
      multiLineChecker(filteredArray[0].template[1].front);
      setBackFields(filteredArray[0].template[2].back);
      setFrontFieldsOrg(filteredArray[0].template[1].front);
      setBackFieldsOrg(filteredArray[0].template[2].back);
      

      if(filteredArray[0].template[3].template_image[0].front){
        setTemplateBackgroundFront(filteredArray[0].template[3].template_image[0].front);
      }

      if(filteredArray[0].template[3].template_image[1].back){
        setTemplateBackgroundBack(filteredArray[0].template[3].template_image[1].back);
      }

      if(filteredArray[0]?.template[5]){
        if(filteredArray[0].template[5].cardOrientation[0]?.front){
          setFrontOrientation(filteredArray[0].template[5]?.cardOrientation[0]?.front)
          setBackOrientation(filteredArray[0].template[5]?.cardOrientation[1]?.back)
          if(side === 'front'){
            if (filteredArray[0].template[5] && filteredArray[0].template[5].cardOrientation[0].front === 'vertical') {
              setOrientation(true)
            }else{
              setOrientation(false)
            }
          }else{
            if (filteredArray[0].template[5] && filteredArray[0].template[5].cardOrientation[1].back === 'vertical') {
              setOrientation(true)
            }else{
              setOrientation(false)
            }
          }
        }else{
          setFrontOrientation(filteredArray[0].template[5]?.cardOrientation)
          setBackOrientation(filteredArray[0].template[5]?.cardOrientation)
          if(filteredArray[0].template[5].cardOrientation === 'vertical'){
            setOrientation(true)
          }else{
            setOrientation(false)
          }
        }
      }
      
      if(filteredArray[0]?.department){
        setCurrentDepartment(filteredArray[0].department)
        setUpdatedDepartment(filteredArray[0].department)

        if (filteredArray[0].department.length === departmentList.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }

    }else{
      setTemplateId(default_fields[0].id)
      setFields(default_fields[0].template);
      setFrontFields(default_fields[0].template[1].front);
      setBackFields(default_fields[0].template[2].back);
      setFrontOrientation(default_fields[0].template[5]?.cardOrientation[0]?.front)
      setBackOrientation(default_fields[0].template[5]?.cardOrientation[1]?.back)

      if(default_fields[0].template[3].template_image[0].front){
        setTemplateBackgroundFront(default_fields[0].template[3].template_image[0].front);
      }

      if(default_fields[0].template[3].template_image[1].back){
        setTemplateBackgroundBack(default_fields[0].template[3].template_image[1].back);
      }

      if(side === 'front'){
        if (default_fields[0].template[5] && default_fields[0].template[5].cardOrientation[0].front === 'vertical') {
          setOrientation(true)
        }else{
          setOrientation(false)
        }
      }else{
        if (default_fields[0].template[5] && default_fields[0].template[5].cardOrientation[1].back === 'vertical') {
          setOrientation(true)
        }else{
          setOrientation(false)
        }
      }

      if(default_fields[0]?.department){
        setCurrentDepartment(default_fields[0].department)
        setUpdatedDepartment(default_fields[0].department)

        if (default_fields[0].department.length === departmentList.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }
    }

    setIsloading(false)
    
  }

  const hasBuildingField = (dataArray) => {
    for (const obj of dataArray) {
      if (obj.field === "building") {
         return true;
      }
   }
   return false;
  }

  const getTemplateOthers = async(count) => {

    const data = await ApiPost("/corps/corp-template",{corp_id:corp_id})

    const default_fields = data.data;

    const filteredArray = default_fields.filter(obj => {
      return obj.template.every(templateObj => !('isDefault' in templateObj) || templateObj.isDefault === false);
    });
    
    setTemplateName(filteredArray[count].template[0].template_name)
    setTemplateOtherId(filteredArray[count].id)
    setFieldsOthers(filteredArray[count].template);
    setFrontFieldsOthers(filteredArray[count].template[1].front);
    setBackFieldsOthers(filteredArray[count].template[2].back);
    setFrontFieldsOthersOrg(filteredArray[count].template[1].front);
    setBackFieldsOthersOrg(filteredArray[count].template[2].back);

    if(filteredArray[count].template[3].template_image[0].front){
      setTemplateBackgroundFrontOthers(filteredArray[count].template[3].template_image[0].front);
    }

    if(filteredArray[count].template[3].template_image[1].back){
      setTemplateBackgroundBackOthers(filteredArray[count].template[3].template_image[1].back);
    }

    if(filteredArray[count]?.template[5]){
      if(filteredArray[count].template[5].cardOrientation[0]?.front){
        setFrontOrientationOthers(filteredArray[count].template[5]?.cardOrientation[0]?.front);
        setBackOrientationOthers(filteredArray[count].template[5]?.cardOrientation[1]?.back);

        const isMultiLine = hasBuildingField(filteredArray[count].template[1].front);
        setMulti(isMultiLine)
        if(side === 'front'){
          if (filteredArray[count].template[5] && filteredArray[count].template[5].cardOrientation[0].front === 'vertical') {
            setOrientation(true)
          }else{
            setOrientation(false)
          }
        }else{
          if (filteredArray[count].template[5] && filteredArray[count].template[5].cardOrientation[1].back === 'vertical') {
            setOrientation(true)
          }else{
            setOrientation(false)
          }
        }
      }else{
        setFrontOrientationOthers(filteredArray[count].template[5]?.cardOrientation);
        setBackOrientationOthers(filteredArray[count].template[5]?.cardOrientation);
        if(filteredArray[count].template[5].cardOrientation === 'vertical'){
          setOrientation(true)
        }else{
          setOrientation(false)
        }
      }
    }

    if(filteredArray[count]?.department){

      if (typeof filteredArray[count].department[0] === 'object') {
        setCurrentDepartmentOthers(filteredArray[count].department)
        setUpdatedDepartmentOthers(filteredArray[count].department)
      }else{
        setCurrentDepartmentOthers([])
        setUpdatedDepartmentOthers([])
      }
      // setCurrentDepartmentOthers(filteredArray[count].department)
      // setUpdatedDepartmentOthers(filteredArray[count].department)
    }else{
      setCurrentDepartmentOthers([])
      setUpdatedDepartmentOthers([])
    }

    // if (filteredArray[count].department.length === departmentList.length) {
    //   setSelectAllOthers(true);
    // } else {
    //   setSelectAllOthers(false);
    // }
    
  }

  const image = [
    {
      image:"image1",
      imageTitle:"Challenger x ChopeShift"
    },
    {
      image:"image2",
      imageTitle:"Challenger"
    },
  ];

  const handleSlideChange = (index) => {
    setCurrentIndex(index.realIndex + 1);
  } 

  const goBackCard = () => {
    if(tabValue === 'Default'){
      if(backOrientation === 'vertical'){
        setOrientation(true)
      }else{
        setOrientation(false)
      }
    }else{
      if(backOrientationOthers === 'vertical'){
        setOrientation(true)
      }else{
        setOrientation(false)
      }
    }
    
    setSide('back')
  }

  const goFrontCard = () => {
    if(tabValue === 'Default'){
      if(frontOrientation === 'vertical'){
        setOrientation(true)
      }else{
        setOrientation(false)
      }
    }else{
      if(frontOrientationOthers === 'vertical'){
        setOrientation(true)
      }else{
        setOrientation(false)
      }
    }
    setSide('front')
  }

  const editTemplates = () => {
    setShowLoader(true)
    setTimeout(()=>{
      setShowLoader(false)
      setEditTemplate(true)

      if(!userData[0].metadata.walkthrough || !userData[0].metadata.walkthrough.namecard_edit){
        setRunTour(true);
      }
    },800)
  }

  const saveTemplate = async (newFields) => {
    setAlertDialog(false)
    setIsloading(true)
    setEditTemplate(false)

    if(side === 'front'){
      fields[1].front = newFields
    }else{
      fields[2].back = newFields
    }

    const data = await ApiPost("/corps/update-template",{id:templateId, template:fields, department: updatedDepartment})
    
    updateCacheBuster();
    if(data.data.message === 'success'){
      setFrontFields(fields[1].front);
      setBackFields(fields[2].back);
      setFrontFieldsOrg(fields[1].front);
      setBackFieldsOrg(fields[2].back);
      setFields(fields);
      setIsloading(false);
      setAlertAttr({visible: true, message: 'Template design saved successfully.', color: '#4caf50'});
    }else{
      setAlertAttr({visible: true, message: 'Error saving template design.', color: '#b2102f'});
      getTemplate();
    }
  }

  const cancelEdit = (value) => {
    setShowLoader(true)
    setTimeout(()=>{
      if(tabValue === 'Default'){
        if(side === 'front'){
          multiLineChecker(frontFieldsOrg)
          setFrontFields(frontFieldsOrg)
        }else{
          multiLineChecker(backFieldsOrg)
          setBackFields(backFieldsOrg)
        }

        setCurrentDepartment(currentDepartment)
        setUpdatedDepartment(currentDepartment)
      }else{
        if(side === 'front'){
          multiLineChecker(frontFieldsOthersOrg)
          setFrontFieldsOthers(frontFieldsOthersOrg)
        }else{
          multiLineChecker(backFieldsOthersOrg)
          setBackFieldsOthers(backFieldsOthersOrg)
        }

        setCurrentDepartmentOthers(currentDepartmentOthers)
        setUpdatedDepartmentOthers(currentDepartmentOthers)
      }
      
      setShowLoader(false)
      setEditTemplate(value)
    },800)
  }

  const onDefault = () => {
    cancelEditQR()
    getTemplate();
    multiLineChecker(frontFieldsOrg)
    setEditTemplate(false)
    setTabValue('Default');
  }

  const onOthers = () => {
    cancelEditQR()
    if(templateCount > 0){
      setEditTemplate(false)
      multiLineChecker(frontFieldsOthersOrg)
      setTabValue('Others');
      setOrientation(false)
    }
  }

  const onQrCode = () => {
    getTemplate();
    setEditTemplate(false);
  }

  const saveTemplateOthers = async (newFields) => {
    setAlertDialog(false)
    setIsloading(true)
    setEditTemplate(false)

    if(side === 'front'){
      fieldsOthers[1].front = newFields
    }else{
      fieldsOthers[2].back = newFields
    }

    const data = await ApiPost("/corps/update-template",{id:templateOtherId, template:fieldsOthers, department: updatedDepartmentOthers})

    if(data.data.message === 'success'){
      setFieldsOthers(fieldsOthers);
      setFrontFieldsOthers(fieldsOthers[1].front);
      setBackFieldsOthers(fieldsOthers[2].back);
      setFrontFieldsOthersOrg(fieldsOthers[1].front);
      setBackFieldsOthersOrg(fieldsOthers[2].back);
      setCurrentDepartmentOthers(updatedDepartmentOthers)
      setUpdatedDepartmentOthers(updatedDepartmentOthers)
      setIsloading(false);
      setAlertAttr({visible: true, message: 'Template design saved successfully.', color: '#4caf50'});
    }else{
      setAlertAttr({visible: true, message: 'Error saving template design.', color: '#b2102f'});
      getTemplateOthers(otherCount);
    }
    
  }

  const onNextOther = async() => {

    await getTemplateOthers(otherCount+1)
    setOtherCount(otherCount+1)
  }

  const onPrevOther = async() => {

    await getTemplateOthers(otherCount-1)
    setOtherCount(otherCount-1)
  }

  const availFields = [
    { "field": "name", "title": "Name" },
    { "field": "local_name", "title": "Local Name" },
    { "field": "qualifications", "title": "Qualifications" },
    { "field": "entity", "title": "Entity" },
    { "field": "company", "title": "Company" },
    { "field": "department", "title": "Department" },
    { "field": "position", "title": "Position" },
    { "field": "division", "title": "Division" },
    { "field": "company_other", "title": "Other Company" },
    { "field": "department_other", "title": "Other Department" },
    { "field": "position_other", "title": "Other Position" },
    { "field": "division_other", "title": "Other Division" },
    { "field": "address", "title": "Address" },
    { "field": "office_number", "title": "Office Number" },
    { "field": "mobile_number", "title": "Mobile Number" },
    { "field": "company_number", "title": "Company Number" },
    { "field": "fax_number", "title": "Fax Number" },
    { "field": "website", "title": "Website" },
    { "field": "email", "title": "Email" },
    { "field": "logo", "title": "Photo" },
    { "field": "qualifications_2", "title": "Qualifications 2" },
    { "field": "entity_1", "title": "Entity 1 Additional" },
    { "field": "entity_2", "title": "Entity 2" },
    { "field": "company_2", "title": "Company 2" },
    { "field": "department_2", "title": "Department 2" },
    { "field": "position_2", "title": "Position 2" },
    { "field": "division_2", "title": "Division 2" },
  ]

  const otherFields = [
    { "field": "qualifications_3", "title": "Qualifications 3" },
    { "field": "entity_3", "title": "Entity 3" },
    { "field": "company_3", "title": "Company 3" },
    { "field": "department_3", "title": "Department 3" },
    { "field": "position_3", "title": "Position 3" },
    { "field": "division_3", "title": "Division 3" },
    { "field": "website_2", "title": "Website 2" },
    { "field": "company_o", "title": "Company" },
    { "field": "department_o", "title": "Department" },
    { "field": "position_o", "title": "Position" },
    { "field": "division_o", "title": "Division" },
    { "field": "mobile_number_o_ch", "title": "Mobile Number China" },
    { "field": "phone_number", "title": "Phone Number" },
    { "field": "mobile_number_o", "title": "Mobile Number" },
    { "field": "fax_number_o", "title": "Fax Number" },
    { "field": "email_o", "title": "Email" },
    { "field": "website_o", "title": "Website" },
    { "field": "linkedin", "title": "LinkedIn" },
    { "field": "facebook", "title": "Facebook" },
    { "field": "instagram", "title": "Instagram" },
    { "field": "line", "title": "Line" },
    { "field": "address_line_1", "title": "Address Line 1" },
    { "field": "address_line_2", "title": "Address Line 2" },
    { "field": "address_line_3", "title": "Address Line 3" },
    { "field": "address_line_4", "title": "Address Line 4" },
    { "field": "clinic", "title": "Clinic" },
  ]

  const addNewField = (val) => {

    const data = newFields.filter(item => item.field === val);

    let type = 'text'
    if(val === 'office_number' || val === 'company_number' || val === 'mobile_number' || val === 'fax_number'){
      type = 'number'
    }else if(val === 'logo'){
      type = 'logo'
    }

    const newField = {
      field:val,
      type:type,
      template_val: type === 'number' ? '+65 XXXX XXXX' : 'Sample Text',
      label: '',
      iconLabel: '',
      isIcon: false,
      iconType: 'select',
      labelStyle: {
        fontFamily: 'inter',
        size: '16',
        color: 'black',
        width: 0
      },
      style:{
        x: 0,
        y: 0,
        font: 'inter, sans-serif',
        alignmentX: 'default',
        orientation: 'horizontal',
        color: 'black',
        size: '16',
        weight: 'normal',
        textTransform: 'none',
        width:'50',
        height: '',
      },
      isOtherField: !others,
      autoAlign:{
        isAutoAlign:false,
        row:1,
        group:1,
        moveUp:true,
        isMoveHorizontal: false,
        column: 0
      }
    }

    let addNewField = []

    if(data.length > 0){
      addNewField = newFields.filter(item => item.field !== val);
    }else{
      addNewField = [...newFields, newField];
    }

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(addNewField);
      }else{
        setBackFields(addNewField);
      }
    }else{
      if(side === 'front'){
        setFrontFieldsOthers(addNewField);
      }else{
        setBackFieldsOthers(addNewField);
      }
    }
    
  }

  const addNewLogo = (val) => {

    const data = newFields.filter(item => item.field === val);

    const newField = {
      title:'Photo',
      field:'logo',
      type:'logo',
      template_val:'https://lexicard-public.s3.amazonaws.com/namecards/logo/user_photo_holder.png',
      logoType:'url',
      style:{
        x: 0,
        y: 0,
        width:'14',
        height: '100'
      },
      isOtherField: !others
    }

    let addNewField = []

    if(data.length > 0){
      addNewField = newFields.filter(item => item.field !== val);
    }else{
      addNewField = [...newFields, newField];
    }

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(addNewField);
      }else{
        setBackFields(addNewField);
      }
    }else{
      if(side === 'front'){
        setFrontFieldsOthers(addNewField);
      }else{
        setBackFieldsOthers(addNewField);
      }
    }
    
  }

  const setLogoType = (val, fieldVal) => {


    const updatedFields = newFields.map(field => {
      if (field.field === fieldVal) {
        return {...field, logoType: val};
      } else {
        return field;
      }
    });

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(updatedFields);
      }else{
        setBackFields(updatedFields);
      }
    }else{
      if(side === 'front'){
        setFrontFieldsOthers(updatedFields);
      }else{
        setBackFieldsOthers(updatedFields);
      }
    }
  }

  const setNewlabel = (val, color, size, fieldVal, iconLabel, isIcon, iconType, labelWidth) => {

    const updatedFields = newFields.map(field => {

      if (field.field === fieldVal) {
        return {
          ...field,
          label: val,
          iconLabel: iconLabel,
          isIcon: isIcon,
          iconType: iconType,
          labelStyle:{
            fontFamily:'',
            size:size,
            color:color,
            width: labelWidth
          }
        };
      } else {
        return field;
      }
    });

    if (fieldVal === 'address' && multi) {
      const addressField = updatedFields.find(field => field.field === 'address');
      const replicatedStyleFields = ['building', 'room', 'street', 'country'];
    
      const replicatedFields = replicatedStyleFields.map(repField => {
        if (repField === 'address') {
          return addressField;
        }

        const fieldToUpdate = updatedFields.find(field => field.field === repField);
    
        if (repField === 'building') {
          return {
            ...fieldToUpdate,
            style: {
              ...fieldToUpdate.style,
              ...addressField.style
            },
            labelStyle: {
              ...fieldToUpdate.labelStyle,
              ...addressField.labelStyle
            },
            label: val,
            iconLabel: iconLabel,
            isIcon: isIcon
          };
        } else {
          return {
            ...fieldToUpdate,
            style: {
              ...fieldToUpdate.style,
              ...addressField.style
            },
            labelStyle: {
              ...fieldToUpdate.labelStyle,
              ...addressField.labelStyle
            },
            iconLabel: '',
            isIcon: false,
            label: ''
          };
        }
      });
    
      // Add the replicated fields to the original fields
      const updatedFieldIndices = replicatedStyleFields.map(field => updatedFields.findIndex(f => f.field === field));
      updatedFieldIndices.forEach((index, i) => {
        updatedFields[index] = replicatedFields[i];
      });

      if(tabValue === 'Default'){
        if(side === 'front'){
          setFrontFields(updatedFields);
        }else{
          setBackFields(updatedFields);
        }
      }else{
        if(side === 'front'){
          setFrontFieldsOthers(updatedFields);
        }else{
          setBackFieldsOthers(updatedFields);
        }
      }
    } else {
      if(tabValue === 'Default'){
        if(side === 'front'){
          setFrontFields(updatedFields);
        }else{
          setBackFields(updatedFields);
        }
      }else{
        if(side === 'front'){
          setFrontFieldsOthers(updatedFields);
        }else{
          setBackFieldsOthers(updatedFields);
        }
      }
    }
  }

  const setNewTemplateVal = (val, fieldVal) => {


    const updatedFields = newFields.map(field => {
      if (field.field === fieldVal) {
        return {...field, template_val: val};
      } else {
        return field;
      }
    });

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(updatedFields);
      }else{
        setBackFields(updatedFields);
      }
    }else{
      if(side === 'front'){
        setFrontFieldsOthers(updatedFields);
      }else{
        setBackFieldsOthers(updatedFields);
      }
    }
  }

  const setAutoAlign = (val, row, fieldVal, attribute) => {

    const updatedFields = newFields.map(field => {

      let countIsAutoAlignTrue = 1
      let groupCounter = 0
      let groupCounterField = 1
      let groupHorizontalCounterField = 1
      let countIsAutoHorizontal = 1

      for (const item of newFields) {
        if (item.autoAlign && item.autoAlign.isAutoAlign === true) {
          if(item.autoAlign.group && item.autoAlign.group === 1){
            countIsAutoAlignTrue++;
          }
        }
      }

      for (const item of newFields) {
        if (item.autoAlign && item.autoAlign.isAutoAlign === true) {
          if(item.autoAlign.group && item.autoAlign.group === 1){
            if(item?.autoAlign?.isMoveHorizontal){
              countIsAutoHorizontal++;
            }
          }
        }
      }

      for (const item of newFields) {
        if (item.autoAlign && item.autoAlign.group) {
          groupCounter++;
        }
      }

      let groupVal = 1

      if(row > groupCounter){
        groupVal = groupCounter
      }else{
        groupVal = row
      }

      if(attribute === 'group'){

        for (const item of newFields) {
          if (item.autoAlign && item.autoAlign.isAutoAlign) {
            if(item.autoAlign.group && item.autoAlign.group === parseInt(row)){
              groupCounterField++;
            }
          }
        }

        for (const item of newFields) {
          if (item.autoAlign && item.autoAlign.isAutoAlign === true) {
            if(item.autoAlign.group && item.autoAlign.group === parseInt(row)){
              if(item?.autoAlign?.isMoveHorizontal){
                groupHorizontalCounterField++;
              }
            }
          }
        }
      }

      if (field.field === fieldVal) {
        const existingAutoAlign = field.autoAlign || {};

        let rowCountVal = existingAutoAlign?.row
        let columnCountVal = existingAutoAlign?.column

        if(attribute === 'group'){
          rowCountVal = groupCounterField
          columnCountVal = groupHorizontalCounterField
        }else if(attribute  === 'check'){
          rowCountVal = countIsAutoAlignTrue
          columnCountVal = countIsAutoHorizontal
        }
        
        const autoAlign = {
          isAutoAlign: val,
          row: attribute === 'row' ? parseInt(row) : rowCountVal ? parseInt(rowCountVal) : countIsAutoAlignTrue,
          group: attribute === 'group' ? parseInt(groupVal) : existingAutoAlign?.group ? parseInt(existingAutoAlign.group) : parseInt(1),
          moveUp: attribute === 'moveUp' ? row : existingAutoAlign?.moveUp !== undefined ? existingAutoAlign.moveUp : true,
          isMoveHorizontal: attribute === 'moveHorizontal' ? row : existingAutoAlign?.isMoveHorizontal ? existingAutoAlign.isMoveHorizontal : false,
          column: attribute === 'column' ? parseInt(row) : columnCountVal ? parseInt(columnCountVal) : countIsAutoHorizontal,
        };

        return {...field, autoAlign};
      } else {
        return field;
      }
    });

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(updatedFields);
      } else {
        setBackFields(updatedFields);
      }
    } else {
      if(side === 'front'){
        setFrontFieldsOthers(updatedFields);
      } else {
        setBackFieldsOthers(updatedFields);
      }
    }
  }

  const setMaxLetter = (val, fieldVal) => {
    const updatedFields = newFields.map(field => {
      if (field.field === fieldVal) {
        return {...field, maxLetterPerLine: val};
      } else {
        return field;
      }
    });

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(updatedFields);
      }else{
        setBackFields(updatedFields);
      }
    }else{
      if(side === 'front'){
        setFrontFieldsOthers(updatedFields);
      }else{
        setBackFieldsOthers(updatedFields);
      }
    }
  }

  const setNewStyleVal = (x, y, font, alignment, orientationField, size, color, weight, transform, fieldVal, width, height, labelSize, labelColor) => {

    const updatedFields = newFields.map(field => {

      if (field.field === fieldVal) {
        return {...field,
          labelStyle: {
            fontFamily: font,
            size: labelSize,
            color: labelColor,
          },
          style:{
            x:x,
            y:y,
            font:font,
            alignmentX: alignment,
            orientation: orientationField,
            color:color,
            size:size,
            weight:weight,
            textTransform:transform,
            width:width,
            height: height
          }
        };
      } else {
        return field;
      }
    });

    if (fieldVal === 'address' && multi) {
      const addressField = updatedFields.find(field => field.field === 'address');
      const replicatedStyleFields = ['building', 'room', 'street', 'country'];
    
      const replicatedFields = replicatedStyleFields.map(repField => {
        if (repField === 'address') {
          return addressField;
        }
    
        const fieldToUpdate = updatedFields.filter(field => field.field === repField);
    
        if (repField === 'building') {

          return {
            field: fieldToUpdate[0].field,
            type: 'text',
            template_val: fieldToUpdate[0].template_val,
            label: '',
            style: {
              ...fieldToUpdate.style,
              ...addressField.style
            },
            labelStyle: {
              ...fieldToUpdate.labelStyle,
              ...addressField.labelStyle
            }
          };
        } else {

          return {
            field: fieldToUpdate[0].field,
            type: 'text',
            template_val: fieldToUpdate[0].template_val,
            label: '',
            style: {
              ...fieldToUpdate.style,
              ...addressField.style
            },
            labelStyle: {
              ...fieldToUpdate.labelStyle,
              ...addressField.labelStyle
            },
            iconLabel: '',
            isIcon: false,
            label: ''
          };
        }
      });
    
      // Add the replicated fields to the original fields
      const updatedFieldIndices = replicatedStyleFields.map(field => updatedFields.findIndex(f => f.field === field));
      updatedFieldIndices.forEach((index, i) => {
        updatedFields[index] = replicatedFields[i];
      });

      if(tabValue === 'Default'){
        if(side === 'front'){
          setFrontFields(updatedFields);
        }else{
          setBackFields(updatedFields);
        }
      }else{
        if(side === 'front'){
          setFrontFieldsOthers(updatedFields);
        }else{
          setBackFieldsOthers(updatedFields);
        }
      }
    }else{
      if(tabValue === 'Default'){
        if(side === 'front'){
          setFrontFields(updatedFields);
        }else{
          setBackFields(updatedFields);
        }
      }else{
        if(side === 'front'){
          setFrontFieldsOthers(updatedFields);
        }else{
          setBackFieldsOthers(updatedFields);
        }
      }
    }
  }

  const setMultiLineAddress = (condition) => {
  
    const fieldsToAdd = ['building', 'room', 'street', 'country'];
  
    const newAddressFields = newFields.filter(item => !fieldsToAdd.includes(item.field));

    const addressObject = newFields.find(obj => obj.field === "address");
    let rowCount = 0
  
    const newObjects = fieldsToAdd.map(field => {

      rowCount++
      if (field === 'building') {
        return {
          field: field,
          type: 'text',
          template_val: '',
          row: rowCount,
          label: addressObject.label,
          iconLabel: addressObject.iconLabel,
          isIcon: addressObject.isIcon,
          labelStyle: {
            fontFamily: addressObject.style.font,
            size: addressObject.style.size,
            color: addressObject.style.color
          },
          style: {
            x:'',
            y:'',
            font: addressObject.style.font,
            alignmentX: addressObject.style.alignmentX,
            orientation: addressObject.style.orientation,
            color: addressObject.style.color,
            size: addressObject.style.size,
            weight: addressObject.style.weight,
            textTransform: addressObject.style.textTransform,
            width: addressObject.style.width,
            height: addressObject.style.height
          },
          isOtherField: !others,
        };
      } else {
        return {
          field: field,
          type: 'text',
          template_val: newAddressFields.find(obj => obj.field === field),
          row: rowCount,
          label: '',
          iconLabel: '',
          isIcon: false,
          labelStyle: {
            fontFamily: addressObject.style.font,
            size: addressObject.style.size,
            color: addressObject.style.color
          },
          style: {
            x:'',
            y:'',
            font: addressObject.style.font,
            alignmentX: addressObject.style.alignmentX,
            orientation: addressObject.style.orientation,
            color: addressObject.style.color,
            size: addressObject.style.size,
            weight: addressObject.style.weight,
            textTransform: addressObject.style.textTransform,
            width: addressObject.style.width,
            height: addressObject.style.height
          },
          isOtherField: !others,
        };
      }
    });
  
    let updatedFields = [];
    if (!condition) {
      setMulti(true)
      updatedFields = [...newAddressFields, ...newObjects];
    } else {
      setMulti(false)
      updatedFields = newAddressFields;
    }
  
    if (tabValue === 'Default') {
      if (side === 'front') {
        setFrontFields(updatedFields);
      } else {
        setBackFields(updatedFields);
      }
    } else {
      if (side === 'front') {
        setFrontFieldsOthers(updatedFields);
      } else {
        setBackFieldsOthers(updatedFields);
      }
    }
  };

  const setNewAddressTemplateVal = (val, fieldVal, isRow=1) => {

    const updatedFields = newFields.map(field => {
      if(isRow===2){
        if (isNaN(val)) {
          if (field.field === fieldVal) {
            return { ...field, row: 0 };
          }else{
            return field;
          }
        }else{
          if(val > 4){
            return field;
          }else{
            const hasMatchingRow = newFields.some(obj => obj.row === val);
  
            if(hasMatchingRow){
              return field;
            }else{
              if (field.field === fieldVal) {
                return {...field, row: val};
              } else {
                return field;
              }
            }
            
          }
        }    
      }else if(isRow===3){
        if (field.field === fieldVal) {
          return {...field, moveUp: val};
        } else {
          return field;
        }
      }else{
        if (field.field === fieldVal) {
          return {...field, template_val: val};
        } else {
          return field;
        }
      }
      
    });

    const updatedFieldsWithAddress = updatedFields.map(field => {
      if (field.field === 'address') {
        const building = updatedFields.find(f => f.field === 'building')?.template_val || '';
        const room = updatedFields.find(f => f.field === 'room')?.template_val || '';
        const street = updatedFields.find(f => f.field === 'street')?.template_val || '';
        const country = updatedFields.find(f => f.field === 'country')?.template_val || '';
        
        const build = building ? building : '';
        const roomNo = room ? room : '';
        const strt = street ? street : '';
        const cntry = country ? country : '';
        
        const addressFull = `${build} ${roomNo} ${strt} ${cntry}`;
        return { ...field, template_val: addressFull };
      } else {
        return field;
      }
    });

    if(tabValue === 'Default'){
      if(side === 'front'){
        setFrontFields(updatedFieldsWithAddress);
      }else{
        setBackFields(updatedFieldsWithAddress);
      }
    }else{
      if(side === 'front'){
        setFrontFieldsOthers(updatedFieldsWithAddress);
      }else{
        setBackFieldsOthers(updatedFieldsWithAddress);
      }
    }
  }

  const goOtherFields = () => {
    setOthers(false)
  }

  const goDefaultFields = () => {
    setOthers(true)
  }

  const onUploadTemplate = () => {
    setUploadTemplateModal(true)
  }

  const onPreviewNameCard = async () => {
    try{
      setIsloading(true);

      let generateUrl = 'http://localhost:3002/corps/generate-namecard'

      if (window.location.href.includes("localhost")) {
        generateUrl = 'http://localhost:3002/corps/generate-namecard'
      }else{
          if(window.location.href.includes("dev-corp")){
            generateUrl = 'https://dev-web.lexiscard.asia/api/corps/generate-namecard'
          }else{
            generateUrl = 'https://www.lexiscard.asia/api/corps/generate-namecard'
          }
      }

      if(tabValue ===  'Default'){
        if(side === 'front'){
          fields[1].front = newFields
        }else{
          fields[2].back = newFields
        }
      }else{
        if(side === 'front'){
          fieldsOthers[1].front = newFields
        }else{
          fieldsOthers[2].back = newFields
        }
      }
      
      const data = await ApiPost("/generate-namecard", {
        generateUrl: generateUrl,
        data: {
          email: userData[0].useremail,
          corp_id: corp_id,
          template_id: tabValue === 'Default' ? templateId : templateOtherId,
          templateJson: tabValue ===  'Default' ? fields : fieldsOthers
        },
      });

      if(data.status === 200){
        setIsloading(false);
        setPreviewModal({visible:true, img1: data.data.data[0].img1, img2: data.data.data[1].img2})
      }else{
        setIsloading(false);
        setAlertAttr({visible: true, message: `Failed generating preview.`, color: '#b2102f'});
      }
    } catch (error) {
      setIsloading(false);
      setAlertAttr({visible: true, message: `Error generating preview.`, color: '#b2102f'});
    }
    
  }

  const handleImageUpload = (acceptedFiles) => {

    setUploadFile(acceptedFiles)
    const file = acceptedFiles[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const img = new Image();
  
      // setUploadOrientation('horizontal');
      img.onload = () => {
        if((img.width === 630 && img.height === 344) || (img.width === 344 && img.height === 630)){
          if(img.width === 344 && img.height === 630){
            setUploadOrientation('vertical');
          }else{
            setUploadOrientation('horizontal');
          }
        }else{
          setErrorUpload(true);
        }
      };
  
      img.src = e.target.result;
    };
  
    reader.readAsDataURL(file);
  };

  const cancelUpload = () => {
    setUploadTemplateModal(!uploadTemplateModal);
    setUploadFile()
    setErrorUpload(false);
  }

  const uploadImageTemplate = async (file) => {
    setUploadTemplateModal(!uploadTemplateModal);
    setShowLoader(true);

    const template_id = tabValue === 'Default' ? templateId : templateOtherId

    const uploadedFile = file[0]

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const base64Data = e.target.result.split(',')[1];

        // const formData = new FormData();
        // formData.append('file', file[0]);
        // formData.append('template_id', template_id);
        // formData.append('corp_id', corp_id);
        // formData.append('isFront', side);
        // formData.append('uploadOrientation', uploadOrientation);

        await ApiPost("/upload-namecard-template",{
          file : base64Data,
          fileType: file[0].name.split('.').pop(),
          template_id: template_id,
          corp_id: corp_id,
          isFront: side,
          uploadOrientation: uploadOrientation
        }).then((response)=>{
            updateCacheBuster();
            if(response.data.message === 'success'){
              if(tabValue === 'Others'){
                getTemplateOthers(otherCount);
              }else{
                getTemplate();
              }

              setAlertAttr({visible: true, message: 'Template Uploaded Successfully.', color: '#4caf50'});
            }else{
              if(tabValue === 'Others'){
                getTemplateOthers(otherCount);
              }else{
                getTemplate();
              }

              setAlertAttr({visible: true, message: `Error uploading your template.`, color: '#b2102f'});
            }
            setShowLoader(false);
            setUploadFile();
            setShowLoader(false);
          }).catch((error)=>{
              console.log("error", error);
              setShowLoader(false);
              setAlertAttr({visible: true, message: `Error: ${error}`, color: '#b2102f'});
          })
      }

      reader.readAsDataURL(uploadedFile);
    }
  }

  const multiLineChecker = (cardData) => {
      const fieldKeys = cardData.map(obj => obj.field);
      const filterValues = ["building", "room", "street", "country"];
      const filteredKeys = fieldKeys.filter(key => filterValues.includes(key));

      if(filteredKeys.length != 0){

        setMulti(true);
      }else{
        setMulti(false);
      }
  };

  const handleCloseAlert = () =>{
    setAlertAttr({visible: false, message: null, color: null});
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const onSaveTemplate = (newFields) => {
    setAlertDialog(true);
    setToSaveFields(newFields);
  }

  const getUpdatedAlignment = (updated) => {
    setNewFields(updated);
  }

  const cancelEditQR = () =>{
    setIsloading(true)
    setShowLoader(true)
    setTimeout(()=>{      
      getQRTemplate();
      setEditQRTemplate(false)
      setShowLoader(false)
      setIsloading(false)
    },800)
  }

  const onEditQrDesign = () => {
    setShowLoader(true)
    setTimeout(()=>{      
      setEditQRTemplate(true)
      setShowLoader(false)
    },800)
  }

  const onEditQR = (val, data) => {

    const updatedQrJson = { ...qrJson, ...val };
    const updatedToSaveQrJson = { ...originalQRData, ...data };

    setQRMeta(updatedQrJson)
    setOriginalQRData(updatedToSaveQrJson)
  }

  const onSaveQrDesign = async () => {
    setAlertDialogQR(true);
  }

  const proceedSaveQrDesign = async (toSaveQrJson) => {
    try{
      setShowLoader(true)
      setAlertDialogQR(false)
      setEditQRTemplate(false)
      const data = await ApiPost("/corps/add-qr-template", {
        corp_id: corp_id,
        qrJson: toSaveQrJson,
        user_id: userData[0].id,
      })

      if (data.data.status === 200) {
        setAlertAttr({ visible: true, message: 'QR design updated successfully', color: '#4caf50' });
        setShowLoader(false)
      } else {
        setAlertAttr({ visible: true, message: data.data.message, color: '#b2102f' });
        setShowLoader(false)
      }
    } catch (error) {
      console.log("error on save qr template",error )
      setAlertAttr({ visible: true, message: error.message, color: '#b2102f' });
      setShowLoader(false)
    } 
  }

  const getQRTemplate = async () => {
    try {
      const response = await ApiPost("/corps/get-qr-template", { corp_id: corp_id });

      if(response?.data?.data[0]?.metadata){
        const restructureQrData = { ...qrJson }

        const qrFetchMeta = response.data.data[0].metadata;
        restructureQrData.backgroundOptions.color = qrFetchMeta.backgroundColor;
        restructureQrData.dotsOptions.color = qrFetchMeta.color;
        restructureQrData.image = qrFetchMeta.logo
        restructureQrData.imageOptions.imageSize = qrFetchMeta.logoSize / 100
        restructureQrData.imageOptions.margin  = qrFetchMeta.logoMargin

        if(qrFetchMeta.enableLinearGradient){
          restructureQrData.dotsOptions.type = 'linear';
          restructureQrData.dotsOptions.rotation =  -1
          restructureQrData.dotsOptions.gradient.colorStops[0].color = qrFetchMeta.linearGradient[0];
          restructureQrData.dotsOptions.gradient.colorStops[1].color = qrFetchMeta.linearGradient[1];
        }
        
        restructureQrData.image = qrFetchMeta.logo;

        setQRMeta(restructureQrData);
        setOriginalQRData(qrFetchMeta);
        setQRDefault(restructureQrData)
      }else{
        setOriginalQRData({
          "color": '#811aff',
          "backgroundColor": '#FFFFFF',
          "enableLinearGradient": true,
          "linearGradient": ['#811aff', '#0e80f1'],
          "gradientDirection": ["0%", "100%"],
          "logo": '',
          "logoSize": 30,
          "logoBackgroundColor": 'transparent',
          "logoMargin": 2,
          "logoBorderRadius": 0,
        });
        setQRMeta(qrJsonDefault);
      }
      
    } catch (error) {
      console.log("error on get qr template",error )
    }
  }
  
  return (
    <>
      <Header pageTitle="Name Card Design" />
      <Snackbar
        ContentProps={{
          sx: {
            background: alertAttr.color
          }
        }}
        open={alertAttr.visible}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={anchorOrigin}
        message={alertAttr.message}
        action={action}
      />
      <div className='main_container'>
      {
        editQRTemplate ? 
        <div className='fields_container'>
          <div className='add_fields_container'>
            <div className='fields_header'>
              <div className='fields_toggle'>
                <p style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>QR Code Styling</p>
              </div>
            </div>
            <div className='fields_input' id="step3Edit">
              <AppQRAttributes 
                jsonData={qrMeta ? qrMeta : qrJson}
                originalQrData={originalQRData}
                onChange={onEditQR}
              />
            </div>
          </div>
        </div>
        : null
      }
      {
        editTemplate ?
          tabValue === 'Default' ? 
            <div className='fields_container'>
              <div className='add_fields_container'>

                <div className='fields_header'>
                  {/* <p className='fields_title'>Fields</p> */}

                  <div className='fields_toggle'>

                    <IconButton onClick={() => goDefaultFields()} style={{ marginTop: -5, display: !others ? '' : 'none'}}>
                      <ArrowBackIosNewIcon style={{fontSize:"15px",fontWeight:'bold'}} />
                    </IconButton>

                    <p style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>{others ? 'Default Fields' : 'Other Fields'}</p>

                    <IconButton onClick={() => goOtherFields()} style={{ marginTop: -5, display: others ? '' : 'none'}}>
                      <ArrowForwardIosIcon style={{fontSize:"15px",fontWeight:'bold'}} />
                    </IconButton>

                  </div>
                </div>

                <div className='fields_add_button' id="step2Edit">
                  <AppAddFieldsButton availFields={others ? availFields : otherFields} currentFields={side === 'front' ? frontFields : backFields} addNewField={addNewField} addNewLogo={addNewLogo}/>
                </div>
                <div className='seperator'></div>
                {/* {
                  departmentList.length > 0 ?
                  <>
                    <div style={{color: '#000', fontWeight: '700', fontSize: '25px', marginTop: 20, marginLeft: 30, marginBottom: 15}}>Departments</div>
                    <div className='fields_add_button'>
                      <AppButton 
                        buttonText="All"
                        variant={selectAll ? 'contained' : 'outlined'}
                        style={{background: selectAll ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12}}
                        textStyle={{fontSize: 12, color: selectAll ? 'white' : '#01082E'}}
                        onClick={() => onSelectAll()}
                      />
                      {
                        departmentList.map((dept, index) => {

                          const data = updatedDepartment.find(item => item === dept.id);
                            return (
                                <>
                                    <AppButton 
                                        key={index}
                                        buttonText={dept.department_name}
                                        variant={data && dept.id === data.field ? 'contained' : 'outlined'}
                                        style={{background: data && dept.id === data ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12}}
                                        textStyle={{fontSize: 12, color: data && dept.id === data ? 'white' : '#01082E'}}
                                        onClick={() => onSetDepartment(dept.id)}
                                    />
                                </>
                            )
                        })
                      }
                    </div>
                    <div className='seperator'></div>
                  </>
                  : null
                } */}
                <div className='fields_input' id="step3Edit">
                  <AppFieldsAttribute 
                    currentFields={side === 'front' ? frontFields : backFields}
                    setNewlabel={setNewlabel}
                    setTemplateVal={setNewTemplateVal}
                    setAutoAlign={setAutoAlign}
                    setMaxLetter={setMaxLetter}
                    setLogoType={setLogoType}
                    setNewAddressTemplateVal={setNewAddressTemplateVal}
                    setNewStyleVal={setNewStyleVal}
                    setMultiLineAddress={setMultiLineAddress}
                    others={others}
                    cardOrientation={orientation}
                    template_id={templateId}
                    fieldToFocus={focusField}
                  />
                </div>
              </div>
            </div>
            :
            <div className='fields_container'>
              <div className='add_fields_container'>

                <div className='fields_header'>
                  {/* <p className='fields_title'>Fields</p> */}

                  <div className='fields_toggle'>

                    <IconButton onClick={() => goDefaultFields()} style={{ marginTop: -5, display: !others ? '' : 'none'}}>
                      <ArrowBackIosNewIcon style={{fontSize:"15px",fontWeight:'bold'}} />
                    </IconButton>

                    <p style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>{others ? 'Default Fields' : 'Other Fields'}</p>

                    <IconButton onClick={() => goOtherFields()} style={{ marginTop: -5, display: others ? '' : 'none'}}>
                      <ArrowForwardIosIcon style={{fontSize:"15px",fontWeight:'bold'}} />
                    </IconButton>

                  </div>
                </div>

                <div className='fields_add_button'>
                  <AppAddFieldsButton availFields={others ? availFields : otherFields} currentFields={side === 'front' ? frontFieldsOthers : backFieldsOthers} addNewField={addNewField} addNewLogo={addNewLogo}/>
                </div>
                <div className='seperator'></div>
                {
                  departmentList.length > 0 ?
                  <div style={{maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden'}}>
                    <div style={{color: '#000', fontWeight: '700', fontSize: '25px', marginTop: 20, marginLeft: 30, marginBottom: 15}}>Departments</div>

                    <AppInput  
                      style={{marginLeft: 30, marginRight: 30}}
                      inputStyle={{ marginBottom: 15, backgroundColor: '#FFFFFF', marginTop: 15 }} 
                      defaultValue={searchInstitutionKey} 
                      onChange={(val) => onSearchInstitution(val)}
                      icon={<SearchIcon/>}
                    />

                    <div className='fields_add_button'>
                      {
                        subCompListFiilter?.map((subComp, index) => {

                          let subCompDepts = subComp?.departments;
                          subCompDepts = subCompDepts?.filter(deptId => departmentListFilter?.some(item => item?.id === deptId));

                          return subCompDepts ? (
                            subCompDepts?.map((deptId, deptIndex) => {
                              const getDept = departmentListFilter?.find(dept => dept?.id === deptId);
                        
                              const hasMatchingPair = updatedDepartmentOthers?.some(item => item?.subCompId === subComp?.id && item?.deptId === deptId);

                              return (
                                <AppButton
                                  key={deptIndex}
                                  buttonText={getDept ? `${subComp.name} - ${getDept?.department_name}${getDept?.cost_center ? ` - ${getDept?.cost_center}` : ''}` : `${subComp.name} - ${deptId}`}
                                  variant={hasMatchingPair ? 'contained' : 'outlined'}
                                  style={{
                                    background: hasMatchingPair ? '#01082E' : null,
                                    borderRadius: 50,
                                    borderColor: '#01082E',
                                    marginRight: 12
                                  }}
                                  textStyle={{
                                    fontSize: 12,
                                    color: hasMatchingPair ? 'white' : '#01082E'
                                  }}
                                  onClick={() => onSetDepartment(subComp.id, deptId)}
                                />
                              );
                            })
                          ) : null;
                        })
                        // departmentList.map((dept, index) => {

                        //   const data = updatedDepartmentOthers.find(item => item === dept.id);
                        //     return (
                        //         <>
                        //             <AppButton 
                        //                 key={index}
                        //                 buttonText={dept.department_name}
                        //                 variant={data && dept.id === data.field ? 'contained' : 'outlined'}
                        //                 style={{background: data && dept.id === data ? '#01082E' : null, borderRadius: 50, borderColor: '#01082E', marginRight: 12}}
                        //                 textStyle={{fontSize: 12, color: data && dept.id === data ? 'white' : '#01082E'}}
                        //                 onClick={() => onSetDepartment(dept.id)}
                        //             />
                        //         </>
                        //     )
                        // })
                      }
                    </div>
                    <div className='seperator'></div>
                  </div>
                  : null
                }
                <div className='fields_input'>
                  <AppFieldsAttribute 
                    currentFields={side === 'front' ? frontFieldsOthers : backFieldsOthers} 
                    setNewlabel={setNewlabel} 
                    setTemplateVal={setNewTemplateVal}
                    setAutoAlign={setAutoAlign}
                    setLogoType={setLogoType}
                    setNewAddressTemplateVal={setNewAddressTemplateVal}  
                    setNewStyleVal={setNewStyleVal} 
                    setMultiLineAddress={setMultiLineAddress}
                    others={others}
                    cardOrientation={orientation}
                    template_id={templateOtherId}
                    fieldToFocus={focusField}
                  />
                </div>

              </div>
            </div>
        : ''
      }
        <div className={editQRTemplate ? 'namecard_container_qr' : editTemplate ? orientation ? 'namecard_container_edit_vertical' : 'namecard_container_edit' : orientation ? 'namecard_container_vertical' : 'namecard_container' }>
          <Tabs style={{borderRadius: '10px'}}>
            <TabList className="tablist" style={{borderRadius: '10px'}}>
              <Tab className="tab-1" onClick={()=> onDefault()}><span id="step1">Default</span></Tab>
              {
                
                // templateCount ? 
                <Tab className="tab-2" onClick={()=> onOthers()} disabled={templateCount===0?true:false}><span id="step2">Others ({templateCount})</span></Tab>
                // :
                // <Tab className="tab-2" onClick={()=> onOthers()} disabled={templateCount===0?true:false}>Add Other</Tab>
              }
              <Tab className="tab-3" onClick={()=> onQrCode()}><span id="step3">QR Designer</span></Tab>
            </TabList>
          
            <TabPanel className="panel">
              {
                editTemplate ? 
                  <>
                    <div className='pagination-card'>
                      <p className='side-title'>{side === 'front' ? 'Front' : 'Back'}</p>
                    </div>
                    <div id="step4Edit" className="image_container" style={{height: orientation ? '630px' : '344px', width: orientation ? '344px' : '630px', backgroundImage: `url(${side === 'front' ? templateBackgroundFront+'?'+cacheBuster : templateBackgroundBack+'?'+cacheBuster})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', shadowColor: '#000000', shadowOffset: { width: 0, height: 0 },shadowRadius: 6,shadowOpacity: 1}}>
                        <GridLines className={ orientation ? "grid-area_vertical" : "grid-area" } cellWidth={25} cellHeight={25} strokeWidth={2}>
                          <div style={{height: '100%', width: '100%'}}>
                            <AppEditorFields 
                              isLoading={isLoading} 
                              closeLoader={()=>setIsloading(!isLoading)} 
                              fields={side === 'front' ? frontFields : backFields} 
                              editable={editTemplate} 
                              saveTemplate={onSaveTemplate} 
                              isFront={side} 
                              cancelEdit={cancelEdit} 
                              orientation={orientation}
                              onUploadTemplate={onUploadTemplate}
                              onPreviewNameCard={onPreviewNameCard}
                              isMulti={multi}
                              updatedAlignment={getUpdatedAlignment}
                              onClickFieldFocus={(value) => {
                                let finalValue;
                                if (value === 'country' || value === 'street' || value === 'room' || value === 'building') {
                                  finalValue = 'address'
                                } else {
                                  finalValue = value
                                }
                                setFocusField(finalValue);
                              }}
                            />  
                          </div>
                        </GridLines>
                    </div>
                </>
                :
                <>
                  <div className='pagination-card'>
                    <IconButton onClick={() => goFrontCard()} style={{ display: side === 'back' ? '' : 'none'}}>
                      <ArrowBackIosNewIcon style={{fontSize:"15px",}} />
                    </IconButton>
                    <p id="step4" style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>{side === 'front' ? 'Front' : 'Back'}</p>
                    <IconButton onClick={() => goBackCard()} style={{ display: side === 'front' ? '' : 'none'}}>
                      <ArrowForwardIosIcon style={{fontSize:"15px",}} />
                    </IconButton>
                  </div>
                  {
                    isLoading ? (
                      <div style={{marginTop: 0}}>
                        <div>
                            <Skeleton sx={{ height: 344, width: 630 }} animation="wave" variant="rounded" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div id="step3" className="image_container" style={{height: orientation ? '630px' : '344px', width: orientation ? '344px' : '630px', backgroundImage: `url(${side === 'front' ? templateBackgroundFront+'?'+cacheBuster : templateBackgroundBack+'?'+cacheBuster})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', shadowColor: '#000000', shadowOffset: { width: 0, height: 0 },shadowRadius: 6,shadowOpacity: 1}}>
                            <div style={{height: '100%', width: '100%'}}>
                              <AppEditorFields
                                isLoading={isLoading}
                                closeLoader={()=>setIsloading(!isLoading)}
                                fields={side === 'front' ? frontFields : backFields}
                                editable={editTemplate}
                                isFront={side}
                                orientation={orientation}
                                onUploadTemplate={onUploadTemplate}
                                onPreviewNameCard={onPreviewNameCard}
                                isMulti={multi}
                                updatedAlignment={getUpdatedAlignment}
                                onClickFieldFocus={(value) => {
                                  let finalValue;
                                  if (value === 'country' || value === 'street' || value === 'room' || value === 'building') {
                                    finalValue = 'address'
                                  } else {
                                    finalValue = value
                                  }
                                  setFocusField(finalValue);
                                }}
                              />  
                            </div>
                        </div>
                        <div className='button_right' style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 60, marginRight: 15}}>
                          <div id="step5">
                            {
                              userData[0].role === "Owner" ?
                                <AppButton buttonText="Edit Template" onClick={() => editTemplates()} />
                                :
                                !userData[0]?.metadata?.permission || userData[0].metadata?.permission && userData[0].metadata?.permission.editTemplate ? 
                                  userData[0]?.metadata?.filters && userData[0]?.metadata?.filters.length > 0 ?
                                    hasMatchingItem() ?
                                      <AppButton buttonText="Edit Template" onClick={() => editTemplates()} />
                                      : null
                                    : <AppButton buttonText="Edit Template" onClick={() => editTemplates()} />
                                  : null
                            }
                          </div>
                        </div>
                      </>
                    )
                  }
                </>
              }
              
            </TabPanel>

            <TabPanel className='panel'>
              {
                editTemplate ? 
                  <>
                    <div className='pagination-card' style={{marginTop: -230}}>
                      <p className='side-title'>{side === 'front' ? 'Front' : 'Back'}</p>
                    </div>
                    <div className="image_container" style={{height: orientation ? '630px' : '344px', width: orientation ? '344px' : '630px', backgroundImage: `url(${side === 'front' ? templateBackgroundFrontOthers+'?'+cacheBuster : templateBackgroundBackOthers+'?'+cacheBuster})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', shadowColor: '#000000', shadowOffset: { width: 0, height: 0 },shadowRadius: 6,shadowOpacity: 1}}>
                        <GridLines className={ orientation ? "grid-area_vertical" : "grid-area" } cellWidth={25} cellHeight={25} strokeWidth={2}>
                          <div style={{height: '100%', width: '100%'}}>
                            <AppEditorFields
                              isLoading={isLoading}
                              closeLoader={()=>setIsloading(!isLoading)}
                              fields={side === 'front' ? frontFieldsOthers : backFieldsOthers}
                              editable={editTemplate}
                              saveTemplate={onSaveTemplate}
                              isFront={side}
                              cancelEdit={cancelEdit}
                              onUploadTemplate={onUploadTemplate}
                              onPreviewNameCard={onPreviewNameCard}
                              orientation={orientation}
                              isMulti={multi}
                              updatedAlignment={getUpdatedAlignment}
                              onClickFieldFocus={(value) => {
                                let finalValue;
                                if (value === 'country' || value === 'street' || value === 'room' || value === 'building') {
                                  finalValue = 'address'
                                } else {
                                  finalValue = value
                                }
                                setFocusField(finalValue);
                              }}
                            />  
                          </div>
                        </GridLines>
                    </div>
                  </>
                  :
                  <>
                    <div className='pagination-card' style={{marginTop: -227}}>
                      <IconButton onClick={() => goFrontCard()} style={{ display: side === 'back' ? '' : 'none'}}>
                        <ArrowBackIosNewIcon style={{ fontSize:"15px" }} />
                      </IconButton>
                      <p style={{ fontWeight:'bold', marginRight:"5px", marginLeft:"5px", color:'black'}}>{side === 'front' ? 'Front' : 'Back'}</p>
                      <IconButton onClick={() => goBackCard()} style={{display: side === 'front' ? '' : 'none'}}>
                        <ArrowForwardIosIcon style={{ fontSize:"15px" }} />
                      </IconButton>
                    </div>

                    {
                      isLoading ? (
                        <div style={{marginTop: 0}}>
                          <div>
                              <Skeleton sx={{ height: 344, width: 630 }} animation="wave" variant="rounded" />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="image_container" style={{height: orientation ? '630px' : '344px', width: orientation ? '344px' : '630px', backgroundImage: `url(${side === 'front' ? templateBackgroundFrontOthers+'?'+cacheBuster : templateBackgroundBackOthers+'?'+cacheBuster})`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', shadowColor: '#000000', shadowOffset: { width: 0, height: 0 },shadowRadius: 6,shadowOpacity: 1}}>
                            <div style={{height: '100%', width: '100%'}}>
                              <AppEditorFields 
                                isLoading={isLoading}
                                closeLoader={()=>setIsloading(!isLoading)} 
                                fields={side === 'front' ? frontFieldsOthers : backFieldsOthers}
                                editable={editTemplate}
                                isFront={side}
                                orientation={orientation}
                                onUploadTemplate={onUploadTemplate}
                                onPreviewNameCard={onPreviewNameCard}
                                isMulti={multi}
                                updatedAlignment={getUpdatedAlignment}
                                onClickFieldFocus={(value) => {
                                  let finalValue;
                                  if (value === 'country' || value === 'street' || value === 'room' || value === 'building') {
                                    finalValue = 'address'
                                  } else {
                                    finalValue = value
                                  }
                                  setFocusField(finalValue);
                                }}
                              />  
                            </div>
                          </div>
                          {
                            editTemplate ? (
                              <div style={{marginTop: 200}}>
                                <p className='side-title'>{templateName}</p>
                              </div>
                            ) : (
                              templateCount > 1 ? (
                                <div className='pagination' style={{marginTop: 1}}>
                                  {
                                    otherCount+1 !== 1 && (
                                      <IconButton onClick={async() => await onPrevOther() } >
                                        <ArrowBackIosNewIcon style={{fontSize:"15px",fontWeight:'bold'}}/>
                                      </IconButton>
                                    )
                                  }
                                  <div>
                                    <p className='side-title' style={{marginLeft:"5px",marginRight:"5px"}}>{templateName}</p>
                                  </div>
                                  {
                                    templateCount !== otherCount+1 && (
                                      <IconButton onClick={async() => await onNextOther() } >
                                        <ArrowForwardIosIcon style={{fontSize:"15px",}}/>
                                      </IconButton>
                                    )
                                  }
                                </div>
                              ) : (
                                <div style={{marginTop: 10}}>
                                  <p className='side-title'>{templateName}</p>
                                </div>
                              )
                            )
                          }
      
                          <div className='button_right' style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: templateCount > 1 ? 8:31, marginRight: 15}}>
                          {
                            userData[0].role === "Owner" ?
                              <AppButton buttonText="Edit Template" onClick={() => editTemplates()} />
                              :
                              !userData[0]?.metadata?.permission || userData[0].metadata?.permission && userData[0].metadata?.permission.editTemplate ? 
                                userData[0]?.metadata?.filters && userData[0]?.metadata?.filters.length > 0 ?
                                  hasMatchingItem() ?
                                    <AppButton buttonText="Edit Template" onClick={() => editTemplates()} />
                                    : null
                                  : <AppButton buttonText="Edit Template" onClick={() => editTemplates()} />
                                : null
                          }
                          </div>
                        </>
                      )
                    }
                  </>
              }
            </TabPanel>

            <TabPanel className="panel_qr">
              {
                editQRTemplate ? 
                <>
                  <div className='pagination-card'>
                    <p style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>QR Design</p>
                  </div>
                  <div id="step4Edit" className="image_container" style={{maxWidth: '100%', padding: 10, shadowColor: '#000000', shadowOffset: { width: 0, height: 0 },shadowRadius: 6,shadowOpacity: 1}}>
                    <AppQRGenerator data={"https://example.com"} qrJsonMeta={qrMeta ? qrMeta : qrJson}/>
                  </div>
                  <div className='button_right' style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 60, marginRight: 15}}>
                    <AppButton
                      variant='text'
                      buttonText="Cancel"
                      onClick={()=> cancelEditQR() }
                      className='cancel-btn'
                      style={{color: '#FF0000', background: null, marginLeft: 10}}
                    />
                    <AppButton buttonText="Save QR Design" onClick={()=> onSaveQrDesign()}/>
                  </div>
                </>
                :
                <>
                  <div className='pagination-card'>
                    <p style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>QR Design</p>
                  </div>
                  <div className="image_container" style={{maxWidth: '100%', padding: 10, shadowColor: '#000000', shadowOffset: { width: 0, height: 0 },shadowRadius: 6,shadowOpacity: 1}}>
                    <AppQRGenerator data={"https://example.com"} qrJsonMeta={qrMeta ? qrMeta : qrJson}/>
                  </div>
                  <div className='button_right' style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 60, marginRight: 15}}>
                    <AppButton buttonText="Edit QR Design" onClick={()=> onEditQrDesign() }/>
                  </div>
                </>

              }
              
            </TabPanel>
          </Tabs>
        </div>
    
        <div className={ editQRTemplate ? 'name_card_tagline_edit' : editTemplate ? orientation ? "name_card_tagline_edit_vertical" : "name_card_tagline_edit" : "name_card_tagline"}>
          <div className='top'>
            Customize or Add Name Card
          </div>
          <div className='bottom'>
            <p style={{fontSize: 15, padding:14, paddingBottom: 0}}>Looking to refresh your company's name card or add secondary name cards?</p>
            <p style={{fontSize: 15, padding:14}}>Drop us a message with a photo of your new name card design and we will get back to you with in 1 - 3 business days.</p>
            <div className='button_right'>
              <AppButton 
                buttonText="Contact Us" 
                onClick={()=>setShowModal(!showModal)}
              />
            </div>
          </div>
        </div>
      </div>

      <AppContactUs 
        file={file}
        showModal={showModal}
        toggleModal={toggleModal}
        setFile={(val) => setFile(val)}
        setMessage={(value) => setMessage(value)}
        setSubject={(val) => setSubject(val)}
        contactUs={() => contactUs()}
      />
  
      {/* <AppModal 
        showModal={showModal} 
        header="Contact Us" 
        toggleModal={()=>setShowModal(!showModal)}
        content={
          <div className='content-container'> 
            <AppInput label="Subject" inputStyle={{marginBottom: 15}} onChange={(val)=>console.log(val)} />  
            <AppInput label="Message" multiline rows={15} onChange={(value)=>console.log(value)} />
            
            <div style={{paddingTop:"10px"}}>
              <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload Files(s)</label>
              <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <section className='drag_drop_container'>
                    <div {...getRootProps()} className='files_container'>
                      <input {...getInputProps()}/>
                      <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                      <p style={{color:'rgb(20, 110, 228)'}}>Drag and drop files here or browse</p>
                    </div>
                    
                  </section>
                )}
              </Dropzone>
              <div style={{paddingTop:"10px",color:'gray',fontSize:'12px'}}>
              <p>Supported files: jpg,jpeg,png,pdf</p>
              <p>Max. file size: 5mb</p>
              </div>
            </div>
          </div>
        }
      /> */}

      {/* MODAL FOR UPLOAD TEMPLATE  */}
      <AppModal 
        showModal={uploadTemplateModal} 
        header="Upload Name Card Template" 
        toggleModal={()=> cancelUpload()}
        onClick={() => errorUpload ? console.log('cannot upload') : uploadImageTemplate(uploadFile)}
        content={
          <div className='content-container'> 
            <div style={{paddingTop:"10px"}}>
              <label style={{paddingTop:"10px",paddingBottom:"10px"}}>Upload Files(s)</label>
              <Dropzone onDrop={acceptedFiles => handleImageUpload(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <section className={errorUpload ? 'drag_drop_container_err' : 'drag_drop_container'}>
                    <div {...getRootProps()} className='files_container'>
                      <input {...getInputProps()}/>
                      <CloudUploadIcon style={{marginRight:"10px",color:'rgb(20, 110, 228)'}} />
                      <p style={{color:'rgb(20, 110, 228)'}}>{uploadFile ? uploadFile[0]?.name.substring(0,12)+"..." : 'Drag and drop files here or browse'}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div style={{paddingTop:"10px",color:'gray',fontSize:'12px'}}>    
              <p>Supported files: jpg,jpeg,png</p>
              <p style={{color: errorUpload ? 'red' : 'gray'}}>Accepted image dimensions: 630x344 or 344x630</p>
              <p style={{color: errorUpload ? 'red' : 'gray'}}>Landscape Namecard: 630x344 px</p>
              <p style={{color: errorUpload ? 'red' : 'gray'}}>Portrait Namecard: 344x630 px</p>
              <p>Max. file size: 5mb</p>
              </div>
            </div>
          </div>
        }
      />

      <AppLoader loader={showLoader} /> 

      {/* <AppAlertDialog 
        visible={alertDialog}  
        title={'Confirm Save'}
        message={'Are you sure to save your update on template design?'}
        positiveButtonName='Proceed'
        onNegativePress={()=> setAlertDialog(!alertDialog)}
        onPositivePress={()=> tabValue === 'Default' ? saveTemplate(toSaveFeilds) : saveTemplateOthers(toSaveFeilds)}
        onClose={()=> setAlertDialog(!alertDialog)}
      /> */}

      <AppModal
        showModal={alertDialog}
        toggleModal={()=> setAlertDialog(!alertDialog)}
        onClick={()=> tabValue === 'Default' ? saveTemplate(toSaveFeilds) : saveTemplateOthers(toSaveFeilds)}
        header='Confirm Save Template'
        functionText="Proceed"
        content={
            <div>
              <p>Are you sure to save your update on template design?</p>
            </div>
        }
      /> 

      {/* NAMECARD PREVIEW MODAL */}
      <AppModal
        showModal={previewModal.visible}
        header="NameCard Preview"
        toggleModal={()=> setPreviewModal({visible: false, img1: null, img2: null})}
        cancelButton={false}
        disableIndicator={true}
        functionText={"Close"}
        onClick={()=> setPreviewModal({visible: false, img1: null, img2: null})}
        style={{width: '50%'}}
        content={
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.5)',
                width: tabValue === 'Default' ? side === 'front' ? frontOrientation === 'horizontal' ? 634 : 344 : backOrientation === 'horizontal' ? 634 : 344 : side === 'front' ? frontOrientationOthers === 'horizontal' ? 634 : 344 : backOrientationOthers === 'horizontal' ? 634 : 344,
                height: tabValue === 'Default' ? side === 'front' ? frontOrientation === 'horizontal' ? 344 : 634 : backOrientation === 'horizontal' ? 344 : 634 : side === 'front' ? frontOrientationOthers === 'horizontal' ? 344 : 634 : backOrientationOthers === 'horizontal' ? 344 : 634,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url('data:image/png;base64,${previewFront ? previewModal.img1 : previewModal.img2}')`,
                marginTop: '25px',
                marginBottom: '150px',
                marginRight: 'auto',
                marginLeft: 'auto'
              }}
            ></div>
            <div className='pagination-card' style={{marginBottom: 50}}>
              <IconButton onClick={() => setPreviewFront(true)} style={{ display: !previewFront ? 'block' : 'none'}}>
                <ArrowBackIosNewIcon style={{fontSize:"15px",}} />
              </IconButton>
              <p id="step4" style={{marginRight:"5px",marginLeft:"5px",color:'black',fontWeight:'bold'}}>{previewFront ? 'Front' : 'Back'}</p>
              <IconButton onClick={() => setPreviewFront(false)} style={{ display: previewFront ? 'block' : 'none'}}>
                <ArrowForwardIosIcon style={{fontSize:"15px",}} />
              </IconButton>
            </div>
          </>
        }
      />

      <AppModal
        showModal={alertDialogQR}
        toggleModal={()=> setAlertDialogQR(!alertDialogQR)}
        onClick={()=> proceedSaveQrDesign(originalQRData)}
        header='Confirm Save QR Design'
        functionText="Proceed"
        content={
            <div>
              <p>Are you sure to save your update on QR design?</p>
            </div>
        }
      />

      {runTour && (
        <Joyride
          steps={editTemplate ? tourStepsEdit : tourSteps}
          continuous={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          showProgress={true}
          run={runTour}
          disableBeacon={false}
          showSkipButton={true}
          hideCloseButton={true}
          callback={(item) => editTemplate ? stopWalkthroughEdit(item) : stopWalkthrough(item)}
          styles={{
            options: {
              arrowColor: '#00072d',
              backgroundColor: '#00072d',
              overlayColor: 'rgba(77, 74, 95, 0.4)',
              primaryColor: '#4d4a5f',
              textColor: '#FFFFFF',
              width: 500,
              zIndex: 99999,
            }
          }}
        />
      )}
    </>
  )
}